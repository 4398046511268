import { STATIC_MAPS_API_KEY } from '../constants';

const rootUrl = 'https://maps.googleapis.com/maps/api/staticmap';
const icon = 'https://goo.gl/Lmrqp6';
const format = 'png';
const pathStyle = 'color:0x50EFB699|weight:6';

export const getRouteMaps = (points, width, height) => {
  if (!points || (points && points.length < 2)) {
    return '';
  }
  let length = points.length;
  let origin = `&markers=icon:${encodeURI(icon)}|${points[0][0]},${points[0][1]}`;
  let destination = `&markers=icon:${encodeURI(icon)}|${points[length-1][0]},${points[length-1][1]}`;

  let uri = `${rootUrl}?key=${STATIC_MAPS_API_KEY}&path=${pathStyle}|${points.join('|')}${origin}${destination}&format=${format}&maptype=roadmap&size=${parseInt(width, 10)}x${parseInt(height, 10)}`;

  return uri;
}