import {
  GET_EXTRACTS,
  GET_EXTRACTS_SUCCESS,
} from '../actions/extracts';

const initialState = {
  period: 'daily',
  driver_id: null,
  list: []
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EXTRACTS_SUCCESS :
      return {...state, ...action.payload }

    case GET_EXTRACTS :
      return { list: [], period: action.period, driver_id: action.driver_id }

    default:
      return state;
  }
}

export default reducer
