import React from "react";
import "../../../styles/statistics.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Line } from "react-chartjs-2";
import { format, parseISO } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import { object } from "prop-types";
import { useSelector } from "react-redux";

let LineOptions = {
  plugins: {
    legend: {
      position: "bottom",
      align: "center",
      labels: {
        padding: 10,
        boxWidth: 35,
        color: "#495057",
      },
    },
  },
  scales: {
    x: {
      ticks: {
        color: "#495057",
      },
      grid: {
        color: "#ebedef",
      },
    },
    y: {
      ticks: {
        color: "#495057",
      },
      grid: {
        color: "#ebedef",
      },
    },
  },
};

export const ChartLineEvolutionNumberRaces = () => {
  const { statisticsMonthly } = useSelector((state) => state.statistics);

  const date = Array.from(statisticsMonthly).map((item) =>
    parseISO(item.month)
  );

  const month = date.map((item) => format(item, "MMM", { locale: ptBR }));

  const finished = Array.from(statisticsMonthly).map(
    (item) => item.rides.finished
  );

  const missed = Array.from(statisticsMonthly).map((item) => item.rides.missed);

  const cancelled = Array.from(statisticsMonthly).map(
    (item) => item.rides.cancelled
  );

  return (
    <div className="card">
      <div className="info">
        <div>
          <TrendingUpIcon />
          <h4>Evolução do Número de Corridas</h4>
        </div>
      </div>
      <div className="card__line">
        <Line
          className="pieChart"
          data={{
            labels: month,
            datasets: [
              {
                label: "Finalizadas",
                data: finished,
                fill: false,
                borderColor: "#50EFB573",
                backgroundColor: "#50EFB573",
                tension: 0,
              },
              {
                label: "Não Atendidas",
                data: missed,
                fill: false,
                borderColor: "#999999",
                backgroundColor: "#999999",
                tension: 0,
              },
              {
                label: "Canceladas",
                data: cancelled,
                fill: false,
                borderColor: "#F21D1D",
                backgroundColor: "#F21D1D",
                tension: 0,
              },
            ],
          }}
          options={LineOptions}
          height={110}
        />
      </div>
    </div>
  );
};

ChartLineEvolutionNumberRaces.propTypes = {
  statisticsFinancial: object,
  statisticsMonthly: object,
};
