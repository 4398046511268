import React from 'react';
import { RadioGroup, Radio } from 'rsuite'
import { RadioWrapper } from './style'


const RadioButtonRecorrently = props => {
  return (
    <RadioWrapper>
      <RadioGroup onChange={value => props.handleDaysWeekRadio(value)} name="radioRecorrently" inline appearance="picker" >
        <span style={{paddingLeft: '10px'}}>Modo de envio: </span>
        <Radio value="1">Dia(s) da semana</Radio>
        <Radio value="2">Dia do mês</Radio>
      </RadioGroup>
    </RadioWrapper>
  )}

export default RadioButtonRecorrently;
