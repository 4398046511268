// @flow

export type LatLng = {
  latitude :string,
  longitude :string
}

export const AcceptedPaymentMethods = {
  CASH: 1,
  CREDIT_CARD: 2,
  CASH_AND_CREDIT_CARD: 3
}

export const AcceptedPaymentMethodsLabels = {
  [AcceptedPaymentMethods.CASH]: 'Dinheiro',
  [AcceptedPaymentMethods.CREDIT_CARD]: 'Cartão de crédito',
  [AcceptedPaymentMethods.CASH_AND_CREDIT_CARD]: 'Cartão e dinheiro',
}

export const DiscountMethod = {
  FIXED: 1,
  PERCENTAGE: 2
}

export const DiscountMethodLabels = {
  [DiscountMethod.FIXED]: 'Diária Fixa',
  [DiscountMethod.PERCENTAGE]: 'Percentual sobre a viagem'
}

export const PromocodeStatus = {
  active: 'Ativo',
  inactive: 'Inativo',
  paused: 'Pausado',
}

export type ListRidesParams = {
  search? :string,
  driver_id? :string,
  user_id? :string,
  promocode_id? :string,
  page :number,
  payment_method? :number,
  status_method? :number,
  payment_status_method? :number,
  category? :number,
  start_at? :Date,
  end_at? :Date,
  order_id? :string
}
export type Place = {
  id :string,
  place_id :string,
  name :string,
  address :string,
  latlng :LatLng,
  data? :any
}

export type RideRequest = {
  distance :number,
  duration :number,
  category :number,
  payment_type :number,
  start_location :LatLng,
  start_address :string,
  reference_point :string,
  final_location :LatLng,
  final_address :string,
  card_id :string,
  scheduled_to? :Date;

  price :number;
}

export const RideStatus = {
  REQUESTING: 10,
  WAITING_DRIVER_RESPONSE: 20,
  DRIVER_ON_THE_WAY: 30,
  STARTED: 40,
  FINISHED: 50,
  CANCELED_DRIVER: 100,
  CANCELED_USER: 105,
  CANCELED_NO_DRIVERS: 110,
  CANCELED_PAYMENT: 115
}

export const RideStatusLabels = {
  10: 'Solicitando viagem',
  20: 'Aguardando a resposta do motorista',
  30: 'Aguardando motorista',
  40: 'Em andamento',
  50: 'Finalizada',
  100: 'Cancelada pelo motorista',
  105: 'Cancelada pelo usuário',
  110: 'Cancelada sem motorista',
  115: 'Pagamento cancelado'
}


export const PaymentType = {
  CASH: 1,
  CREDIT_CARD: 2
}

export const SocketEvent = {
  CONNECTED: 'SOCKET_CONNECTED',
  DISCONNECTED: 'SOCKET_DISCONNECTED',

  DRIVER_REQUEST_EXPIRED: 'DRIVER_REQUEST_EXPIRED',
  RIDE_REQUESTED: 'RIDE_REQUESTED',
  RIDE_CANCELED: 'RIDE_CANCELED',
  RIDE_ACCEPTED: 'RIDE_ACCEPTED',
  RIDE_STARTED: 'RIDE_STARTED',
  RIDE_FINISHED: 'RIDE_FINISHED',
  SET_RIDE: 'SET_RIDE',
  DRIVER_LOCATION: 'DRIVER_LOCATION',
  CHAT_MESSAGE: 'CHAT_MESSAGE',
  CHAT_READ: 'CHAT_READ'
}

export const UserStatus = {
  FREE: 0,
  REQUESTING: 10,
  WAITING_DRIVER_ARRIVAL: 30,
  RIDING: 40
}

export const UserStatusLabels = {
  [UserStatus.FREE]: 'Livre',
  [UserStatus.REQUESTING]: 'Solicitando',
  [UserStatus.WAITING_DRIVER_ARRIVAL]: 'Aguardando Motorista',
  [UserStatus.RIDING]: 'Em viagem'
}

export const DriverStatus = {
  OFFLINE: 0,
  FREE: 5,
  WAITING_DRIVER_RESPONSE: 20,
  ON_THE_WAY: 30,
  RIDING: 40,
  FINISHING: 50
}

export const DriverStatusLabels = {
  0: 'OFFLINE',
  5: 'DISPONÍVEL',
  20: 'RESPONDENDO SOLICITAÇÃO',
  30: 'A CAMINHO',
  40: 'EM VIAGEM',
  50: 'ENCERRANDO VIAGEM'
}

export const FilterSort = {
  5: 'CORRIDAS',
  10: 'AVALIAÇÃO',
  15: 'CORRIDAS CANCELADAS'
}

export const FilterSortUser = {
  5: 'CORRIDAS',
  10: 'CORRIDAS CANCELADAS'
}

export const DriverSituation = {
  INATIVO: 0,
  ATIVO: 1,
  INADIMPLENTE: 20
}

export const DriverSituationLabels = {
  0: 'INATIVO',
  1: 'ATIVO',
  20: 'INADIMPLENTE'
}

export const PaymentStatus = {
  WAITING: 9,
  PRE_AUTHORIZED: 10,
  IN_ANALYSIS: 12,
  PAID: 20,
  SETTLED: 21,
  CANCELLED: 31,
  REFUNDED: 32,
  REVERSED: 33,
  ERROR: 50
}

export const MoipPaymentStatus = {
  WAITING: PaymentStatus.WAITING,
  PRE_AUTHORIZED: PaymentStatus.PRE_AUTHORIZED,
  IN_ANALYSIS: PaymentStatus.IN_ANALYSIS,
  AUTHORIZED: PaymentStatus.PAID,
  SETTLED: PaymentStatus.SETTLED,
  CANCELLED: PaymentStatus.CANCELLED,
  REFUNDED: PaymentStatus.REFUNDED,
  REVERSED: PaymentStatus.REVERSED,
}

export const PaymentStatusLabels = {
  0: 'Não processado',
  [PaymentStatus.WAITING]: 'Aguardando',
  [PaymentStatus.PRE_AUTHORIZED]: 'Autorizado',
  [PaymentStatus.IN_ANALYSIS]: 'Em Análise',
  [PaymentStatus.PAID]: 'Pago',
  [PaymentStatus.SETTLED]: 'Creditada',
  [PaymentStatus.CANCELLED]: 'Cancelada',
  [PaymentStatus.REFUNDED]: 'Estornada',
  [PaymentStatus.REVERSED]: 'Revertida',
}

export const AspirantDriverStatus = {
  WAITING_APPROVAL: 0,
  IN_ANALYSIS: 10,
  REJECTED: 20,
  APPROVED: 30,
}

export const AspirantDriverStatusLabels = {
  [AspirantDriverStatus.WAITING_APPROVAL]: 'Aguardando aprovação',
  [AspirantDriverStatus.IN_ANALYSIS]: 'Em análise',
  [AspirantDriverStatus.REJECTED]: 'Rejeitado',
  [AspirantDriverStatus.APPROVED]: 'Aprovado'
}

export const AdsType = {
  1: 'Anúncio',
  2: 'Voucher'
}

export const AdsStatus = {
  1: 'Ativo',
  2: 'Pausado',
  5: 'Rascunho',
  7: 'Expirado'
}

export const SelectedTarget = {
  MOTORISTAS: 1,
  PASSAGEIROS: 2,
  MOTORISTA: 3,
  PASSAGEIRO: 4
}

export const WhyRiderHasCanceledRide = {
  LONG_TIME_DRIVER_RESPONSE: 1,
  LONG_TIME_DRIVER_ARRIVAL: 2,
  RIDE_PRICE: 3,
  ANOTHER_APP: 4,
  ANOTHER_REASON: 5
}

export const WhyRiderHasCanceledRideLabel = {
  [WhyRiderHasCanceledRide.LONG_TIME_DRIVER_RESPONSE]: 'Demora na resposta do motorista',
  [WhyRiderHasCanceledRide.LONG_TIME_DRIVER_ARRIVAL]: 'Demora na chegada do motorista até o local',
  [WhyRiderHasCanceledRide.RIDE_PRICE]: 'Valor da corrida',
  [WhyRiderHasCanceledRide.ANOTHER_APP]: 'Escolha por outro app/ outro meio de locomoção',
  [WhyRiderHasCanceledRide.ANOTHER_REASON]: 'Outros motivos'
}