import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { push } from 'react-router-redux';
import { RouteWithSubRoutes } from '../index';
import { store } from '../store';
import { listAds, filterAdsByStatus } from '../actions/ads';
import { AdsStatus, AdsType } from '../api/types';
import '../styles/css/ads.css';

class AdsListPage extends Component {

  static propTypes = {
    statusFilter: PropTypes.number,
    list: PropTypes.array,
    listAds: PropTypes.func,
    filterAdsByStatus: PropTypes.func,
  }

  constructor(props) {
    super(props);

    this.handleStatusClick = this.handleStatusClick.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.show = this.show.bind(this);
    this.add = this.add.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.props.listAds()
    }, 300);
  }

  handleStatusClick({ target }) {
    let status = Number(target.attributes.getNamedItem('data-id').value);

    this.props.filterAdsByStatus(status);
  }

  show(id) {
    store.dispatch(push(`/ads/id/${id}`));
  }

  add() {
    store.dispatch(push(`/ads/id/new`));
  }

  renderItem(item) {
    const clickLabel = item.type == 1 ? 'Clicks' : 'Resgates';
    if (item) {
      return (
        <div className="ads-list-item" key={item.id} style={{cursor: 'pointer'}} onClick={() => this.show(item.id)}>
          <div>
            <img src={item.image} alt="img" />
          </div>
          <div>
            <h2>{item.name}</h2>
            <strong>Cliente</strong> {item.client_name}<br />
            <strong>Tipo</strong> {AdsType[item.type]}<br />
            <strong>Local</strong> {item.address}<br />
            <strong>Categorias</strong> { item.categories.map(id => {
              let found = this.props.categories.find(cat => cat.id == id);

              return found ? found.name : id;
            }).join(' ,')}<br />
            <strong>Quota impressões</strong> {item.n_max_prints}<br />
            <strong>Impressões Realizadas</strong> {item.n_prints}<br />
            <strong>Impressões Disponíveis</strong> {item.n_max_prints - item.n_prints}<br />

            <strong>Quota {clickLabel}</strong> {item.n_max_clicks}<br />
            <strong>{clickLabel} Realizados</strong> {item.n_clicks}<br />
            <strong>{clickLabel} Disponíveis</strong> {item.n_max_clicks - item.n_clicks}<br />

            <strong>Valor contratado</strong> R${item.price}<br />
            <strong>Status</strong> {AdsStatus[item.status]}<br />
            <strong>Data</strong> {moment(item.created_at).format('DD/MM/YYYY')}<br />
          </div>
        </div>
      )
    }
  }

  renderList() {
    const list = this.props.list;

    return (
      <div>
        <div className="header-buttons">
          <h3>Anúncios e Cupons</h3>
          <div>
            <label>Status: &nbsp;</label>
            <div className="btn-group">
              {
                Object.keys(AdsStatus).map(id => (
                  <button
                    key={id}
                    data-id={id}
                    className={`btn ${this.props.statusFilter == id ? 'btn-primary' : 'btn-light'}`}
                    onClick={this.handleStatusClick}
                  >
                    {AdsStatus[id]}
                  </button>
                ))
              }
            </div>

            <button
              className="btn btn-primary"
              style={{ marginLeft: 20 }}
              onClick={this.add}
            >
              Adicionar
            </button>
          </div>
        </div>
        <p style={{clear:'both'}} />

        <div>
          {
            list.length > 0 &&
              list.map(this.renderItem)
          }
          {
            list.length == 0 &&
              <div style={{textAlign:'center',marginTop: 100}}>Nenhum anúncio encontrado</div>
          }
        </div>
      </div>
    )
  }

  render() {
    const { match } = this.props;

    return (
      <div className="page-list">
        {
          match.path == '/ads' && match.isExact &&
            this.renderList()
        }

        {
          this.props.routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))
        }
      </div>
    )
  }
}


const mapStateToProps = state => ({
  list: state.ads.all.filter(({ status }) => state.ads.statusFilter == status),
  categories: state.categories.all,
  statusFilter: state.ads.statusFilter
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    listAds,
    filterAdsByStatus
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AdsListPage)
