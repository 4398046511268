import { AUTH } from '../actions/auth';

const initialState = {
  isLoggedIn: false,
  isLogginIn: false,
  error: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH.LOGIN :
      return { isLogginIn: true };

    case AUTH.LOGIN_ERROR :
      return { error: action.error };

    case AUTH.LOGGED_IN :
      return { ...action.user, isLoggedIn: true }

    case AUTH.LOGGED_OUT :
      return {};

    default :
      return state;
  }
}

export default reducer
