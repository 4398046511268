import Api from '../api';

export const GET_CITIES = 'GET_CITIES';
export const GET_CITIES_SUCCESSO = 'GET_CITIES_SUCCESSO';
export const GET_CITIES_ERROR = 'GET_CITIES_ERROR';


export const getCities = () => async dispatch => {
  dispatch({
    type: GET_CITIES
  })

  try {
    let cities = await Api.cities();
    console.log('Debug Cidades', cities)

    return dispatch({
      type: GET_CITIES_SUCCESSO,
      cities
    })
  } catch (error) {
    return dispatch({
      type: GET_CITIES_ERROR,
      error
    })
  }
}