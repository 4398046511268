import React from "react";
import "../../../styles/statistics.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Bar } from "react-chartjs-2";
import ScheduleIcon from "@material-ui/icons/Schedule";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { formatPrice } from "../../../utils/string";
import { useSelector } from "react-redux";

let barOptions = {
  maintainAspectRatio: false,
  aspectRatio: 0.8,
  plugins: {
    legend: {
      display: false,
      labels: {
        color: "#495057",
      },
    },
  },
  scales: {
    x: {
      ticks: {
        color: "#495057",
      },
      grid: {
        color: "#ebedef",
      },
    },
    y: {
      ticks: {
        color: "#495057",
      },
      grid: {
        color: "#ebedef",
      },
    },
  },
};

export const ChartBarTimesHighestDemand = () => {
  const { statisticsFinancial } = useSelector((state) => state.statistics);

  const labels =
    statisticsFinancial &&
    statisticsFinancial?.requests_by_hour?.map((item) => item.hour);

  const data =
    statisticsFinancial &&
    statisticsFinancial?.requests_by_hour?.map((item) => item.total_requests);

  return (
    <div className="cards__top">
      <div className="demand__history">
        <div className="title">
          <ScheduleIcon />
          <h4>Horário com Maior Demanda</h4>
        </div>

        <div className="card__bar">
          <Bar
            className="pieChart"
            data={{
              labels: labels,
              datasets: [
                {
                  label: "demand",
                  backgroundColor: "#28C88E",
                  data,
                },
              ],
            }}
            options={barOptions}
            height={110}
          />
        </div>
      </div>

      <div className="average__ticket">
        <div className="title">
          <CheckCircleOutlineIcon />
          <h4>Ticket Médio</h4>
        </div>

        <div className="card__bar">
          <span>
            <strong>
              {!!statisticsFinancial &&
                formatPrice(statisticsFinancial?.financial?.avg_price)}
            </strong>
          </span>
        </div>
      </div>
    </div>
  );
};
