import styled from 'styled-components';

export const FormGroup = styled.form `
  width: 60%;
  display: flex;
  flex-direction: column;
  background-color: #F6F4FC;
  margin: 20px;
  border-radius: 5px;
  height: 260px;
  box-shadow: 0px 2px 7px #888888;
`;

export const Label = styled.label `
  margin-bottom: 0.5px;
  color: red;
  padding: 10px;
`;

export const Input = styled.input`
	padding: 0.5em;
	color: black;
	background: inherit;
	border: none;
	border-radius: 3px;
	width: 100%;
  margin-bottom: 0.5em;
  border-bottom: 1px solid #CED4DA;
  font-weight: bold;
  &:focus {
    outline: none;
  }
`;

export const InputMessage = styled.textarea`
  padding: 0.5em;
  color: black;
  background: inherit;
  border: none;
  width: 100%;
  height: 200px;
  font-weight: bold;
  &:focus {
    outline: none;
  }
`

export const ButtonPicker = styled.button`
  width: 30px;
  height: 30ppx;
  border: none;
  background-color: inherit;
  &:focus {
    outline: none;
  }
`
export const TextEmojiWrapper = styled.div `
  display: flex;
  align-content: space-between;
`
export const ButtonSend = styled.button `
  position: relative;
  background-color: #F6F4FC;
  top: 50px;
  width: 50%;  
  height: 40px;
  margin-top: 20px;
  border-radius: 5px; 
`