import styled from 'styled-components';
import Paginator from '../../paginator'

export const Container = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;  
  @media screen and (min-width: 768px) {
    flex-direction: row;    
    
  };
`;

export const WrapperTitle = styled.div`
  display: flex;
  align-items: center;
  width: 87%;
  justify-content: space-between;
`

export const WrapperDropdown = styled.div`
  
`

export const Divisor = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 48vw;
`;

export const Title = styled.h2 `
  font-size: 24px;
  font-weight: 300;
  padding: 20px 0 0px 20px;
  color: rgb(55, 58, 60);
`

export const WrapperNotifications = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const PaginatorStyled = styled(Paginator)`
  display:flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-right: 10px;
`


export const WrapperPaginator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  align-self: flex-start;
  
`
