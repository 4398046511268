import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  RideStatusLabels,
  RideStatus,
  PaymentType,
  PaymentStatusLabels,
  WhyRiderHasCanceledRide,
  WhyRiderHasCanceledRideLabel,
} from "../api/types";

class Rides extends Component {
  static propTypes = {
    rides: PropTypes.array,
    params: PropTypes.object,
    onSelect: PropTypes.func.isRequired,
    showMobyDebit: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.renderItem = this.renderItem.bind(this);
  }

  renderItem(item, index) {
    const { params, showMobyDebit } = this.props;

    const getProperlyCancelReason = (item) => {
      const { reason_cancel_choice } = item;

      const options = {
        [WhyRiderHasCanceledRide.LONG_TIME_DRIVER_RESPONSE]:
          WhyRiderHasCanceledRideLabel[
            WhyRiderHasCanceledRide.LONG_TIME_DRIVER_RESPONSE
          ],
        [WhyRiderHasCanceledRide.LONG_TIME_DRIVER_ARRIVAL]:
          WhyRiderHasCanceledRideLabel[
            WhyRiderHasCanceledRide.LONG_TIME_DRIVER_ARRIVAL
          ],
        [WhyRiderHasCanceledRide.RIDE_PRICE]:
          WhyRiderHasCanceledRideLabel[WhyRiderHasCanceledRide.RIDE_PRICE],
        [WhyRiderHasCanceledRide.ANOTHER_APP]:
          WhyRiderHasCanceledRideLabel[WhyRiderHasCanceledRide.ANOTHER_APP],
        [WhyRiderHasCanceledRide.ANOTHER_REASON]:
          WhyRiderHasCanceledRideLabel[WhyRiderHasCanceledRide.ANOTHER_REASON],
      };
      return options[reason_cancel_choice] || null;
    };

    const getRideStatusStyle = () => {
      const { status } = item;

      const options = {
        [RideStatus.WAITING_DRIVER_RESPONSE]: "#FCFCED",
        [RideStatus.DRIVER_ON_THE_WAY]: "#D9EDF8",
        [RideStatus.STARTED]: "#F0FAF0",
        [RideStatus.FINISHED]: "#CFF1E8",
        [RideStatus.CANCELED_USER]: "#FFF3E5",
        [RideStatus.CANCELED_DRIVER]: "#FAECEB",
      };

      return options[status] || "373a3c";
    };

    const amountMobyOnCredit = (
      (item?.payment?.moby_tax - item?.payment?.moby_credit) /
      100
    ).toFixed(2);

    return (
      <tr
        key={item.id}
        style={{ cursor: "pointer" }}
        onClick={() => this.props.onSelect(item.id)}
      >
        {!params.user_id && <td>{!!item.user && item.user.name}</td>}
        {!params.driver_id && <td>{!!item.driver && item.driver.name}</td>}
        <td>{item?.city?.name}</td>
        <td>{item.category_name}</td>
        <td>{item.est_price}</td>
        <td>{item.price}</td>
        <td>{item.profit}</td>
        <td>
          {item.payment_type == PaymentType.CASH
            ? item.moby_debit
            : amountMobyOnCredit}
        </td>
        <td>{item.promocode ? item.promocode.code : ""}</td>
        <td style={{ backgroundColor: getRideStatusStyle() }}>
          {RideStatusLabels[item.status]}
        </td>
        <td>{getProperlyCancelReason(item)}</td>

        <td>{item.payment_type == PaymentType.CASH ? "Dinheiro" : "Cartão"}</td>
        <td>
          {item.status >= RideStatus.DRIVER_ON_THE_WAY &&
          item.payment_type == PaymentType.CASH
            ? "Pago"
            : PaymentStatusLabels[item.payment_status]}
        </td>
        {!!showMobyDebit && <td>{item.moby_debit}</td>}
        <td>{moment(item.requested_at).format("DD/MM/YY HH:mm")}</td>
        <td>
          {item.finished_at
            ? moment(item.finished_at).format("DD/MM/YY HH:mm")
            : ""}
        </td>
        <td>
          {item.payment_type == PaymentType.CASH &&
            item.finished_at != null && <span>Faturada</span>}

          {item.payment_type != PaymentType.CASH &&
            item.finished_at != null &&
            (moment().diff(moment(item.finished_at).add(2, "days"), "days") >=
            0 ? (
              <span>Faturada</span>
            ) : (
              <span>Pendente</span>
            ))}

          {item.finished_at == null && <span>-</span>}
        </td>
      </tr>
    );
  }

  render() {
    let { rides, params, showMobyDebit } = this.props;

    if (rides.length < 1) {
      return <div />;
    }

    return (
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            {!params.user_id && <th>Passageiro</th>}
            {!params.driver_id && <th>Motorista</th>}
            <th>Praça</th>
            <th>Categoria</th>
            <th style={{ width: 50 }}>Valor Estimado</th>
            <th style={{ width: 50 }}>Valor Realizado</th>
            <th style={{ width: 50 }}>Valor Motorista</th>
            <th style={{ width: 50 }}>Valor Franqueado</th>
            <th style={{ width: 50 }}>CUPOM</th>
            <th style={{ width: 150 }}>Status</th>
            <th style={{ width: 180 }}>Motivo Cancelamento</th>
            <th style={{ width: 50 }}>Método Pagamento</th>
            <th style={{ width: 50 }}>Status Pagamento</th>
            {!!showMobyDebit && <th style={{ width: 50 }}>Débito MobyGo</th>}
            <th>Data Solicitação</th>
            <th>Data Chegada</th>
            <th>Faturada</th>
          </tr>
        </thead>
        <tbody>{rides.map(this.renderItem)}</tbody>
      </table>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.categories.all,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Rides);
