import "../styles/teamDetails.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect, useMemo } from "react";
import { toast } from "react-toastify";
import { push } from "react-router-redux";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, Label, Input } from "reactstrap";

import { validateCpfCnpj, validatePhone } from "../utils/string";
import { store } from "../store";
import api from "../api";
import { getOperations } from "../actions/cities";

const newArray = [
  { name: "Mapa", value: "map" },
  { name: "Cupons", value: "promocodes" },
  { name: "Passageiros", value: "riders" },
  { name: "Motoristas", value: "drivers" },
  { name: "Cadastro Motorista", value: "aspirant_drivers" },
  { name: "Cadastro Veículos", value: "aspirant_drivers" },
  { name: "Tarifas", value: "fares" },
  { name: "Viagens", value: "rides" },
  { name: "Notificações", value: "push_notifications" },
  { name: "Equipe", value: "admins" },
  { name: "Estatísticas", value: "analytics" },
  { name: "Franquia", value: "operation" },
];

const initialValues = {
  name: "",
  cpfCnpj: "",
  social: "",
  email: "",
  phone: "",
  role_description: '',
  cities: [],
};

export const TeamDetails = ({ match }) => {
  const dispatch = useDispatch();

  const _cities = useSelector(state => state.cities.operations)
  const [values, setValues] = useState(initialValues);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [permissions, setPermissions] = useState({});

  const cities = useMemo(() => {
    if (values.cities.length) {
      return _cities.sort((a, b) => {
        return values.cities.some(c => c.id === a.id) ? -1 : 1
      })
    }

    return _cities
  }, [ !!values.cities?.length ]);

  useEffect(() => {
    dispatch(getOperations())
  }, []);

  const { id } = match.params;

  const handleCpfCnpj = e => {
    const { name, value } = e.target;

    const newValue = validateCpfCnpj(value);

    setValues({ ...values, [name]: newValue });
  };

  const handlePhone = e => {
    const { name, value } = e.target;

    const newValue = validatePhone(value);

    setValues({ ...values, [name]: newValue });
  };

  const HandleOnChange = e => {
    const { name, value } = e.target;

    setValues({ ...values, [name]: value });
  };

  const handlePermissions = e => {
    const { value, checked } = e.target;

    if (checked) {
      setPermissions({ ...permissions, [value]: true });
    } else {
      Object.keys(permissions).forEach(key => {
        if (key === value) {
          delete permissions[key];
          setPermissions({ ...permissions });
        }
      });
    }
  };

  const handleChangeCity = e => {
    const { value, checked } = e.target;

    const cities = checked
      ? [ ...values.cities, { id: value } ]
      : values.cities.filter(city => city.id !== value)

    setValues(values => ({
      ...values,
      cities,
    }))
  }

  const handleUpdateCollaborator = async () => {
    try {
      const payload = {
        phone: values.phone,
        document_number: values.cpfCnpj,
        name: values.name,
        email: values.email,
        password: password,
        permissions,
        cities: values.cities,
        role_description: values.role_description,
      };

      if (password !== repeatPassword) {
        toast.error("Senhas diferentes");
        return;
      }

      await api.updateCollaboratorId({ id, payload });

      toast.success("Colaborador atualizado com sucesso!");

      setTimeout(() => {
        store.dispatch(push(`/team`));
      }, 2000);

    } catch (error) {
      toast.error(error.message);
      console.log("Error ao atualizar o colaborador");
    }
  };

  const handleRemoveCollaborator = async () => {
    if (!window.confirm('Deseja realmente excluir este usuário?')) {
      return;
    }

    try {
      await api.removeCollaborator(id);

      toast.success('Usuário removido com sucesso');

      store.dispatch(push(`/team`));
    } catch (e) {
      toast.error(e.message);
    }
  }

  const handleFetchDataCollaborator = async () => {
    try {
      const collaborator = await api.getCollaboratorId(id);

      console.log("collaborator:", collaborator);

      const newValues = {
        name: collaborator.name,
        cpfCnpj: collaborator.cpf,
        email: collaborator.email,
        phone: collaborator.phone,
        cities: collaborator?.cities,
        role_description: collaborator.role_description,
      };

      setValues(newValues);

      const permissions = collaborator.permissions;

      setPermissions(permissions);
    } catch (error) {
      console.log("error:", error.message);
    }
  };

  const back = () => {
    store.dispatch(push(`/team`));
  };

  useEffect(() => {
    handleFetchDataCollaborator();
  }, []);

  return (
    <div className="container__franchisees__details">
      <div className="wrapper">
        <div className="header">
          <div className="title">
            <i className="pi pi-arrow-left" onClick={back} />
            <h1>Detalhes colaborador</h1>
          </div>
        </div>
        <div className="main">
          <form>
            <div className="form__header">
              <h5>Dados do franqueados</h5>
            </div>
            <div className="wrapper__form">
              <div className="form-group">
                <label>Nome Completo</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={values.name}
                  onChange={HandleOnChange}
                />
              </div>

              <div className="form-group">
                <label>CPF/CNPJ</label>
                <input
                  type="text"
                  className="form-control"
                  name="cpfCnpj"
                  value={values.cpfCnpj}
                  onChange={handleCpfCnpj}
                />
              </div>

              <div className="form-group">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={values.email}
                  onChange={HandleOnChange}
                />
              </div>

              <div className="form-group">
                <label>Telefone</label>
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  value={values.phone}
                  onChange={handlePhone}
                />
              </div>

              <div className="form-group">
                <label>Cargo</label>
                <input
                  type="role_description"
                  className="form-control"
                  name="role_description"
                  value={values.role_description}
                  onChange={HandleOnChange}
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="wrapper">
        <div className="main">
          <form>
            <div className="form__header">
              <h5>Senha do franqueado</h5>
            </div>
            <div className="wrapper__form">
              <div className="form-group">
                <label>Senha</label>
                <input
                  className="form-control"
                  type="text"
                  name="password"
                  placeholder="Senha"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </div>

              <div className="form-group">
                <label>Repetir senha</label>
                <input
                  className="form-control"
                  type="text"
                  name="passwordRepeat"
                  placeholder="password"
                  value={repeatPassword}
                  onChange={e => setRepeatPassword(e.target.value)}
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="wrapper">
        <div className="main">
          <form style={{marginBottom: '40px'}}>
            <div className="form__header">
              <h5>Cidades</h5>
            </div>

            <h6>Selecione as cidades que o colaborador terá acesso:</h6>
            <div className="wrapper__form">
              <div
                className="content__checked"
                style={{
                  maxHeight: '300px',
                  overflowY: 'auto',
                  flexWrap: 'nowrap',
                  paddingLeft: 20,
                }}
              >
                {cities.map((item, index) => (
                  <FormGroup key={item.index} check inline className="check">
                    <Label check>
                      <Input
                        type="checkbox"
                        // eslint-disable-next-line no-prototype-builtins
                        checked={values.cities.some(city => city.id === item.id)}
                        name={item.name}
                        value={item.id}
                        onChange={handleChangeCity}
                      />
                      <span>{item.name}</span>
                    </Label>
                  </FormGroup>
                ))}
              </div>
            </div>
          </form>

          <form>
            <div className="form__header">
              <h5>Visão do franqueado</h5>
            </div>

            <h6>Selecione as abas do menu que o franqueado terá acesso:</h6>
            <div className="wrapper__form">
              <div className="content__checked">
                {newArray.map((item, index) => (
                  <FormGroup key={item.index} check inline className="check">
                    <Label check>
                      <Input
                        type="checkbox"
                        // eslint-disable-next-line no-prototype-builtins
                        checked={permissions?.hasOwnProperty(item.value)}
                        name={item.name}
                        value={item.value}
                        onChange={handlePermissions}
                      />
                      <span>{item.name}</span>
                    </Label>
                  </FormGroup>
                ))}
              </div>
            </div>
          </form>

          <div className="btn">
            <button onClick={handleRemoveCollaborator}>Remover</button>

            <button onClick={handleUpdateCollaborator}>
              Salvar alterações
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
