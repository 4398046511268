import styled from 'styled-components';

export const Container = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;  
  @media screen and (min-width: 768px) {
    flex-direction: row;    
    
  }
`;

export const Divisor = styled.div `
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 48vw;
`;

export const Title = styled.h2 `
  font-size: 24px;
  font-weight: 300;
  padding: 20px;
  color: rgb(55, 58, 60);
`

export const SubTitle = styled.h3 `
  font-size: 14px;
  font-weight: bold;
  margin-top: 80px;  
`

export const InputSingle = styled.input `
  width: 60%;
  height: 40px;
  padding-left: 10px;
  border-radius: 4px;
  border: 0.5px solid rgb(204, 204, 204);
  margin-bottom: 20px
`

export const InputDays = styled.input`
  width: 10%;
  height: 40px;
  border-radius: 4px;
  border: 0.5px solid rgb(204, 204, 204);
  padding-left: 10px;

`