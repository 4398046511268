import LoginPage from "./pages/login-page";
import AspirantDriversPage from "./pages/aspirant-drivers-page";
import AspirantDriverInfoPage from "./pages/aspirant-driver-info";
import DriversPage from "./pages/drivers-page";
import DriverPage from "./pages/driver-page";
import DriverInfoPage from "./pages/driver-info-page";
import DriverExtractsPage from "./pages/driver-extracts-page";
import DriverBankAccount from "./pages/driver-bank-account";
import RidesPage from "./pages/rides-page";
import RidePage from "./pages/ride-page";
import CategoriesPage from "./pages/categories-page";
import CategoryPage from "./pages/category-page";
import UserRidesPage from "./pages/user-rides-page";
import RidersPage from "./pages/riders-page";
import RiderPage from "./pages/rider-page";
import RiderInfoPage from "./pages/rider-info-page";
import AdsListPage from "./pages/ads-list-page";
import AdsPage from "./pages/ads-page";
import MapsPage from "./pages/maps-page";
import CitiesPage from "./pages/cities-page";
import CityPage from "./pages/city-page";
import AddCityPage from "./pages/add-city-page";
import FarePage from "./pages/fare-page";
import PromocodesPage from "./pages/promocodes-page";
import PromocodePage from "./pages/promocode-page";
import AspirantCarsPage from "./pages/aspirant-cars-page";
import AspirantCarPage from "./pages/aspirant-car-page";
import StatisticsPage from "./pages/statistics/statistics-page";
import { Franchisees } from "./pages/franchisees";
import { FranchiseesAdd } from "./pages/franchisees-add";
import { FranchiseesDetails } from "./pages/franchisees-details";
import { FranchiseeDetails } from "./pages/franchisee-details";
import { FranchiseesPayment } from "./pages/franchisees-payment";
import { FranchiseesPaymentAdd } from "./pages/franchisees-payment-add";
import { FranchiseesAddLogin } from "./pages/franchisees-add-login";
import { Team } from "./pages/team";
import { TeamAdd } from "./pages/team-add";
import { TeamDetails } from "./pages/team-details";

import CreateNotificationpage from "./components/push_notification/Create-notification-page";
import NotificationPage from "./components/push_notification/History-notification-page";
import EditNotificationPage from "./pages/Edit-notification-page";

// Analytics

import AnalyticsRides from "./pages/Analytics-rides";
import AnalyticsUsers from "./pages/Analytics-users";
import { FranchiseesDetailsAdmin } from "./pages/franchisees-details-admin";
import AspirantDriverPage from "pages/aspirant-driver-page";
import AspirantDriverBankAccount from "pages/aspirant-driver-bank-account";
import { StatisticsGeneral } from "pages/statistics/statistics-general/statistics-general";
import { StatisticsRiders } from "pages/statistics/statistics-riders/statistics-riders";
import { StatisticsIndicators } from "pages/statistics/statistics-indicators/statistics-indicators";

export const routes = [
  {
    path: "/",
    component: MapsPage,
    exact: true,
  },
  {
    path: "/statistics",
    component: StatisticsPage,
    routes: [
      {
        path: "/statistics/indicators",
        component: StatisticsIndicators,
        exact: true,
      },
      {
        path: "/statistics/general",
        component: StatisticsGeneral,
        exact: true,
      },
      {
        path: "/statistics/riders",
        component: StatisticsRiders,
        exact: true,
      },
    ],
  },
  {
    path: "/notification-add",
    component: CreateNotificationpage,
  },
  {
    path: "/notification",
    component: NotificationPage,
    exact: true,
  },
  {
    path: "/franchisees",
    component: Franchisees,
    exact: true,
  },
  {
    path: "/franchisees-add",
    component: FranchiseesAdd,
    exact: true,
  },
  {
    path: "/franchisees-details/id/:id",
    component: FranchiseesDetails,
    exact: true,
  },
  {
    path: "/franchisees-details",
    component: FranchiseesDetails,
    exact: true,
  },
  {
    path: "/franchisees-details/id/:id_admin/admin/:id",
    component: FranchiseesDetailsAdmin,
    exact: true,
  },
  {
    path: "/franchisees-payment/id/:id",
    component: FranchiseesPayment,
    exact: true,
  },
  {
    path: "/franchisees-payment-add/id/:id",
    component: FranchiseesPaymentAdd,
    exact: true,
  },
  {
    path: "/franchisees-payment-edit/city/id/:id/payment/id/:payment_id",
    component: FranchiseesPaymentAdd,
    exact: true,
  },
  {
    path: "/franchisee-details/id/:id",
    component: FranchiseeDetails,
    exact: true,
  },
  {
    path: "/franchisees-add-login",
    component: FranchiseesAddLogin,
    exact: true,
  },
  {
    path: "/analytics-rides",
    component: AnalyticsRides,
  },
  {
    path: "/analytics-users",
    component: AnalyticsUsers,
  },
  {
    path: "/notification/id/:id/info",
    component: EditNotificationPage,
  },
  {
    path: "/login",
    component: LoginPage,
    exact: true,
  },
  {
    path: "/riders",
    component: RidersPage,
    routes: [
      {
        path: "/riders/id/:id",
        component: RiderPage,
        routes: [
          {
            path: "/riders/id/:id/info",
            component: RiderInfoPage,
            exact: true,
          },
          {
            path: "/riders/id/:user_id/rides",
            component: UserRidesPage,
            exact: true,
          },
        ],
      },
    ],
  },
  {
    path: "/drivers",
    component: DriversPage,
    routes: [
      {
        path: "/drivers/id/:id",
        component: DriverPage,
        routes: [
          {
            path: "/drivers/id/:id/info",
            component: DriverInfoPage,
            exact: true,
          },
          {
            path: "/drivers/id/:driver_id/rides",
            component: UserRidesPage,
            exact: true,
          },
          {
            path: "/drivers/id/:id/extracts",
            component: DriverExtractsPage,
            exact: true,
          },
          {
            path: "/drivers/id/:id/bank-account",
            component: DriverBankAccount,
            exact: true,
          },
        ],
      },
      {
        path: "/drivers/create",
        component: DriverInfoPage,
      },
    ],
  },
  {
    path: "/aspirant-drivers",
    component: AspirantDriversPage,
    routes: [
      {
        path: "/aspirant-drivers/id/:id",
        component: AspirantDriverPage,
        routes: [
          {
            path: "/aspirant-drivers/id/:id/info",
            component: AspirantDriverInfoPage,
            exact: true,
          },
          {
            path: "/aspirant-drivers/id/:id/bank-account",
            component: AspirantDriverBankAccount,
            exact: true,
          },
        ],
      },
    ],
  },
  {
    path: "/aspirant-cars",
    component: AspirantCarsPage,
    routes: [
      {
        path: "/aspirant-cars/id/:id",
        component: AspirantCarPage,
      },
    ],
  },
  {
    path: "/rides",
    component: RidesPage,
    exact: true,
  },
  {
    path: "/ride/id/:id",
    component: RidePage,
  },
  {
    path: "/categories",
    component: CategoriesPage,
  },
  {
    path: "/category/id/:id",
    component: CategoryPage,
  },
  {
    path: "/cities",
    component: CitiesPage,
    routes: [
      {
        path: "/cities/add",
        component: AddCityPage,
      },
      {
        path: "/cities/id/:id",
        component: CityPage,
        routes: [
          {
            path: "/cities/id/:id/fare/id/:fare_id",
            component: FarePage,
          },
        ],
      },
    ],
  },
  {
    path: "/ads",
    component: AdsListPage,
    routes: [
      {
        path: "/ads/id/:id",
        component: AdsPage,
      },
    ],
  },
  {
    path: "/promocodes",
    component: PromocodesPage,
    routes: [
      {
        path: "/promocodes/id/:id",
        component: PromocodePage,
      },
      {
        path: "/promocodes/create",
        component: PromocodePage,
      },
    ],
  },
  {
    path: "/team",
    component: Team,
  },
  {
    path: "/team-add",
    component: TeamAdd,
  },
  {
    path: "/team-details/id/:id",
    component: TeamDetails,
  },
];
