import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class Avatar extends Component {
  render() {
    const { size, source } = this.props;

    return (
      <div
        style={{
          width: size,
          height: size,
          borderRadius: size / 2,
          borderWidth: 1,
          borderColor: 'white',
          backgroundImage: `url(${source})`,
          backgroundPosition: 'center center',
          backgroundSize: 'cover'
        }}
      />
    )
  }
}

Avatar.propTypes = {
  size: PropTypes.number,
  source: PropTypes.string
}

Avatar.defaultProps = {
  size: 40
}