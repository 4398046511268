import React from "react";
import Modal from "react-modal";
import * as S from "./styled";

const customStyles = {
  content: {
    top: "5%",
    left: 0,
    right: 0,
    width: "100%",
    heigh: "auto",
  },
};

type ModalConfirmWhiteDrawProps = {
  modalIsOpen: boolean;
  changeStatusModal: () => void;
  handleModalProofClosed:() => void;
  url:string;
};


export const ModalProofLive: React.FC<ModalConfirmWhiteDrawProps> = ({
  modalIsOpen,
  changeStatusModal,
  handleModalProofClosed,
  url
}) => {

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={handleModalProofClosed}
      style={customStyles}
      contentLabel="Solicitação de saque"
    >
      <iframe
        src={url}
        className="iframe-no-border"
        style={{ border: "none" }}
        title="Iframe Prova de vida"
        width= "100%"
        height="85%"
      />

      <hr />
      <S.ModalButtons>
        <button
          type="button"
          className="btn btn-success pull-left"
          onClick={changeStatusModal}
        >
          Confirmar
        </button>

        <button
          type="button"
          className="btn btn-secondary pull-left"
          data-dismiss="modal"
          onClick={handleModalProofClosed}
        >
          Cancelar
        </button>
      </S.ModalButtons>
    </Modal>
  );
};
