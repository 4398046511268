import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { push } from "react-router-redux";
import { store } from "../store";
import { toast } from "react-toastify";
import Avatar from "../components/avatar";
import {
  RideStatus,
  RideStatusLabels,
  PaymentStatusLabels,
  PaymentType,
  DiscountMethodLabels,
  WhyRiderHasCanceledRideLabel,
} from "../api/types";
import { getRide, releaseRide } from "../actions/rides";
import { getRouteMaps } from "../utils";
import Api from "../api/index";

class RidePage extends Component {
  static propTypes = {
    getRide: PropTypes.func,
    releaseRide: PropTypes.func,
    rides: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    categories: PropTypes.array,
    match: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {};

    this.unlockRide = this.unlockRide.bind(this);
  }

  componentDidMount() {
    this.getRide();
  }

  async getRide() {
    let { id } = this.props.match.params;

    if (id) {
      this.rideId = id;

      let { ride } = await this.props.getRide(id);
      console.log("ride", ride);

      if (ride && ride.info && ride.info.route) {
        this.setState({
          image_route: getRouteMaps(ride.info.route, 450, 400),
        });
      }
    }
  }

  async unlockRide() {
    if (window.confirm("Deseja realmente cancelar essa corrida?")) {
      await Api.unlockRide(this.props.match.params);
    }
  }

  get ride() {
    return this.props.rides[this.rideId];
  }

  releaseRide = async () => {
    if (
      !window.confirm(
        "Deseja encerrar essa viagem? O cálculo do valor será feito com base na estimativa da viagem solicitada"
      )
    ) {
      return;
    }

    let { error } = await this.props.releaseRide(this.rideId);

    if (error) {
      toast.warn("Não foi possível encerrar a viagem");
    } else {
      toast.success("Viagem encerrada com sucesso");
    }
  };

  navToRider = () => {
    store.dispatch(push(`/riders/id/${this.ride.user_id}/info`));
  };

  navToDriver = () => {
    store.dispatch(push(`/drivers/id/${this.ride.driver_id}/info`));
  };

  formatPrice = (val) => (
    <span style={{ color: this.priceColor(val) }}>
      R$ {parseFloat(val).toFixed(2)}
    </span>
  );

  priceColor = (val) => {
    return val == 0 ? "blue" : val < 0 ? "red" : "blue";
  };

  render() {
    const { rides } = this.props;

    const ride = rides[this.rideId];

    if (!ride) {
      return <div />;
    }

    let category = "";
    for (let cat of this.props.categories) {
      if (cat.id == ride.type) {
        category = cat.name;
        break;
      }
    }

    return (
      <div style={{ width: 500, marginLeft: "auto", marginRight: "auto" }}>
        <div className="card bg-light">
          <div className="card-header">Viagem</div>
          <div className="card-body">
            <form>
              <div className="form-group">
                <label>ID</label>
                <input
                  readOnly
                  className="form-control-plaintext"
                  value={ride.id}
                />
              </div>
              <div
                className="form-group"
                style={{ cursor: "pointer" }}
                onClick={this.navToRider}
              >
                <label>Passageiro</label>
                <div style={{ display: "flex" }}>
                  <div style={{ width: 40, height: 40, marginRight: 10 }}>
                    <Avatar source={ride.user.avatar_small} size={40} />
                  </div>
                  <input
                    readOnly
                    style={{ pointerEvents: "none" }}
                    className="form-control-plaintext"
                    value={ride.user.name}
                  />
                </div>
              </div>
              {!!ride.driver && (
                <div
                  className="form-group"
                  style={{ cursor: "pointer" }}
                  onClick={this.navToDriver}
                >
                  <label>Motorista</label>
                  <div style={{ display: "flex" }}>
                    <div style={{ width: 40, height: 40, marginRight: 10 }}>
                      <Avatar source={ride.driver.avatar_small} size={40} />
                    </div>
                    <input
                      readOnly
                      className="form-control-plaintext"
                      style={{ pointerEvents: "none" }}
                      value={ride.driver.name}
                    />
                  </div>
                </div>
              )}
              <div className="form-group">
                <label>Origem</label>
                <input
                  readOnly
                  className="form-control-plaintext"
                  value={ride.start_address}
                />
              </div>
              {!!ride.started_at && (
                <div className="form-group">
                  <label>Início</label>
                  <input
                    readOnly
                    className="form-control-plaintext"
                    value={moment(ride.started_at).format(
                      "DD/MM/YY [ás] HH:mm"
                    )}
                  />
                </div>
              )}
              <div className="form-group">
                <label>Destino</label>
                <input
                  readOnly
                  className="form-control-plaintext"
                  value={ride.final_address}
                />
              </div>
              {!!ride.finished_at && (
                <div className="form-group">
                  <label>Finalizada</label>
                  <input
                    readOnly
                    className="form-control-plaintext"
                    value={moment(ride.finished_at).format(
                      "DD/MM/YY [ás] HH:mm"
                    )}
                  />
                </div>
              )}
              <div className="form-group">
                <label>Praça</label>
                <input
                  readOnly
                  className="form-control-plaintext"
                  value={ride?.city?.name}
                />
              </div>
              <div className="form-group">
                <label>Valor</label>
                <input
                  readOnly
                  className="form-control-plaintext"
                  value={`R$ ${ride.price}`}
                />
              </div>
              <div className="form-group">
                <label>Lucro motorista</label>
                <input
                  readOnly
                  className="form-control-plaintext"
                  style={ride.profit < 0 ? { color: "red" } : null}
                  value={`R$ ${Number(ride.profit).toFixed(2)}`}
                />
              </div>
              {ride.moip_tax > 0 && (
                <div className="form-group">
                  <label>Taxa meio de pagamento</label>
                  <input
                    readOnly
                    className="form-control-plaintext"
                    value={`R$ ${ride.moip_tax}`}
                  />
                </div>
              )}

              {ride?.payment?.moby_tech_tax > 0 && (
                <div className="form-group">
                  <label>Taxa de tecnologia</label>
                  <input
                    readOnly
                    className="form-control-plaintext"
                    value={`R$ ${(ride?.payment?.moby_tech_tax / 100).toFixed(
                      2
                    )}`}
                  />
                </div>
              )}

              {ride.moby_tax > 0 && (
                <div className="form-group">
                  <label>Taxa MobyGo</label>
                  <div>
                    <input
                      readOnly
                      className="form-control-plaintext"
                      value={`R$ ${ride.moby_tax}`}
                      style={{ width: "auto", paddingBottom: 0 }}
                    />

                    {!!ride.payment && !!ride.payment.moby_credit && (
                      <span className="moby-tax-details">
                        <div>
                          Porcentagem Moby: R${" "}
                          {(
                            (ride.moby_tax * 100 - ride.payment.moby_credit) /
                            100
                          ).toFixed(2)}
                        </div>
                        <div>
                          Débitos: R${" "}
                          {(ride.payment.moby_credit / 100).toFixed(2)}
                        </div>
                      </span>
                    )}
                  </div>
                </div>
              )}
              {(ride.moby_debit || ride.moby_credit) && (
                <>
                  <div className="form-group">
                    <label>Conta MobyGo</label>

                    <div>Debitado: {this.formatPrice(-ride.moby_debit)}</div>
                    <div>Creditado: {this.formatPrice(ride.moby_credit)}</div>
                    <div>
                      Balanço:{" "}
                      {this.formatPrice(ride.moby_credit - ride.moby_debit)}
                    </div>
                  </div>
                  <div className="form-group">
                    <label>Método desconto MobyGo</label>
                    <div>
                      {DiscountMethodLabels[ride.driver_discount_method]}
                    </div>
                  </div>
                </>
              )}
              {!!ride.promocode && (
                <div className="form-group">
                  <label>Promocode</label>

                  <div style={{ marginTop: "6px" }}>
                    <strong style={{ color: "blue" }}>
                      {ride.promocode.code}
                    </strong>{" "}
                    {ride.promocode.discount}%
                  </div>
                  <div>
                    Desconto aplicado de{" "}
                    <span style={{ color: "green" }}>R$ {ride.discount}</span>
                  </div>
                </div>
              )}
              <div className="form-group">
                <label>Distância percorrida</label>
                <input
                  readOnly
                  className="form-control-plaintext"
                  value={`${ride.distance} Km`}
                />
              </div>
              <div className="form-group">
                <label>Duração</label>
                <input
                  readOnly
                  className="form-control-plaintext"
                  value={`${ride.duration} minutos`}
                />
              </div>
              <div className="form-group">
                <label>Categoria</label>
                <input
                  readOnly
                  className="form-control-plaintext"
                  value={category}
                />
              </div>
              <div className="form-group">
                <label>Status</label>
                <input
                  readOnly
                  className="form-control-plaintext"
                  value={RideStatusLabels[ride.status]}
                />
              </div>
              <div className="form-group">
                <label>Motivo cancelamento</label>
                <input
                  readOnly
                  className="form-control-plaintext"
                  value={
                    WhyRiderHasCanceledRideLabel[ride.reason_cancel_choice]
                  }
                />
              </div>
              <div className="form-group">
                <label>Descrição cancelamento</label>
                <input
                  readOnly
                  className="form-control-plaintext"
                  value={ride.reason_cancel_observation}
                />
              </div>
              <div className="form-group">
                <label>Pagamento</label>
                <input
                  readOnly
                  className="form-control-plaintext"
                  value={
                    ride.payment_type == PaymentType.CASH
                      ? "Dinheiro"
                      : "Cartão"
                  }
                />
              </div>
              <div className="form-group">
                <label>Status Pagamento</label>
                <input
                  readOnly
                  className="form-control-plaintext"
                  value={
                    ride.payment_type == PaymentType.CASH
                      ? "Pago"
                      : PaymentStatusLabels[ride.payment_status]
                  }
                />
              </div>
            </form>
          </div>
        </div>

        <div className="card bg-light">
          <div className="card-header">Solicitação</div>
          <div className="card-body">
            <form>
              {!!ride.requested_at && (
                <div className="form-group">
                  <label>Data</label>
                  <input
                    readOnly
                    className="form-control-plaintext"
                    value={moment(ride.requested_at).format(
                      "DD/MM/YY [ás] HH:mm"
                    )}
                  />
                </div>
              )}
              <div className="form-group">
                <label>Origem</label>
                <input
                  readOnly
                  className="form-control-plaintext"
                  value={ride.request_start_address}
                />
              </div>
              <div className="form-group">
                <label>Destino</label>
                <input
                  readOnly
                  className="form-control-plaintext"
                  value={ride.request_final_address}
                />
              </div>
              <div className="form-group">
                <label>Valor Estimado</label>
                <input
                  readOnly
                  className="form-control-plaintext"
                  value={`R$ ${ride.est_price}`}
                />
              </div>
              <div className="form-group">
                <label>Distância</label>
                <input
                  readOnly
                  className="form-control-plaintext"
                  value={`${ride.est_distance} KM`}
                />
              </div>
              <div className="form-group">
                <label>Duração</label>
                <input
                  readOnly
                  className="form-control-plaintext"
                  value={`${ride.est_duration} min.`}
                />
              </div>
            </form>
          </div>
        </div>

        {!!this.state.image_route && (
          <div className="card bg-light">
            <div className="card-header">Mapa</div>
            <div className="card-body">
              <img src={this.state.image_route} alt="" />
            </div>
          </div>
        )}

        {ride.status == RideStatus.STARTED && (
          <div className="form-footer" style={{ marginBottom: "40px" }}>
            <button
              type="button"
              disabled={!!this.state.busy}
              className="btn btn-primary"
              onClick={this.releaseRide}
            >
              Encerrar Viagem
            </button>
          </div>
        )}
        <button
          type="button"
          enabled={!this.state.busy}
          className="btn btn-danger pull-left"
          data-dismiss="modal"
          onClick={this.unlockRide}
        >
          Destravar corrida
        </button>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rides: state.rides.ridesById,
  categories: state.categories.all,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getRide,
      releaseRide,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RidePage);
