import React, { useState, useEffect } from "react";
import "../../../styles/statistics.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { RidesPerPeriod } from "./RidesPerPeriod";
import { RidersWithoutRide } from "./RidersWhithoutRide";
import { RidersIncompleteInfo } from "./RidersIncompleteInfo";
import {
  getStatisticsRiders,
  setStatisticsLoading,
  setStatisticsNotLoading,
} from "../../../actions/statistics";
import { useDispatch, useSelector } from "react-redux";
import { RidersNewByDay } from "./RidersNewByDay";
import { RidersNewByMonth } from "./RidersNewByMonth";
import { RidersNewByWeek } from "./RidersNewByWeek";
import { RidersNewByYear } from "./RidersNewByYear";
import StatisticsFilter from "../statistics-filter/StatisticsFilter";

export const StatisticsRiders = () => {
  const dispatch = useDispatch();
  const city_id = useSelector((state) => state.preferences.city_id);
  const { initialDate, finalDate } = useSelector(
    (state) => state.statistics.statisticsFilters
  );

  const fetchListAnalytics = async () => {
    dispatch(setStatisticsLoading());

    try {
      const params = {
        city_id,
        start_at: initialDate,
        end_at: finalDate,
      };

      await dispatch(getStatisticsRiders(params));
    } catch (error) {
      console.log("erro aqui:", error);
    } finally {
      dispatch(setStatisticsNotLoading());
    }
  };

  useEffect(() => {
    fetchListAnalytics();
  }, [city_id]);

  return (
    <div className="container__statistics">
      <div className="wrapper">
        <StatisticsFilter fetchListAnalytics={fetchListAnalytics} />

        <div className="main">
          <div className="general__wrapper">
            <RidesPerPeriod />
            <RidersWithoutRide />
            <RidersIncompleteInfo />
          </div>
          <div className="general__wrapper">
            <RidersNewByDay />
            <RidersNewByWeek />
            <RidersNewByMonth />
            <RidersNewByYear />
          </div>
        </div>
      </div>
    </div>
  );
};
