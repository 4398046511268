import React from "react";
import "../../../styles/statistics.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector } from "react-redux";

export const RidersNewByYear = () => {
  const { statisticsRiders } = useSelector((state) => state.statistics);

  return (
    <div className="general__card">
      <div className="title">
        <h4>Novos no ano</h4>
      </div>
      <div className="content">
        <span>
          <strong>
            {!!statisticsRiders && statisticsRiders.total_new_users_year}
          </strong>
        </span>
      </div>
    </div>
  );
};
