import React from 'react';
import { RadioGroup, Radio } from 'rsuite'
import { RadioWrapper } from './style'


const RadioButton = props => {
  return (
    <RadioWrapper>
      <RadioGroup onChange={props.handleAgended} name="radioList" inline appearance="picker" defaultValue="1">
        <span style={{paddingLeft: '10px'}}>Enviar em: </span>
        <Radio value="1">Now</Radio>
        <Radio value="2">Agendar</Radio>
        <Radio value="3">Recorrente</Radio>
      </RadioGroup>
    </RadioWrapper>
  )}

export default RadioButton;
