import styled from 'styled-components'

export const Title = styled.h2`
  font-size: 24px;
  color: black;
  padding: 20px;
`

export const SubTitle = styled.h3 `
  font-size: 14px;
  font-weight: bold;
  margin-top: 80px;  
`

export const WrapperFilters = styled.div`
  display: flex;
  padding: 20px;
  margin-right: 100px;

`

export const WrapperTable = styled.div`
  display: flex;
  padding: 20px;
  justify-content: center;
`

export const Table = styled.table`
  width: 100%;
`

export const THead = styled.thead`
  
  font-size: 18px;
 
  
`

export const TBody = styled.tbody`

`

export const TRow = styled.tr`
   background: ${props => props.index % 2 === 0 ? 'rgba(0,0,0,.05)' : 'inherit'};
   border-top: 1px solid #dee2e6;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
`

export const TH = styled.th`
  padding: 10px;
  flex: 1;
  border-top: 1px solid #dee2e6;
  border-bottom: 2px solid #dee2e6;
`

export const TD = styled.td`

`