import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { store } from '../store';
import UserRidesContainer from '../containers/user-rides-container';
import '../styles/css/drivers.css';

export default class UserRidesPage extends Component {

  static propTypes = {
    rides: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    params: PropTypes.object,
    categories: PropTypes.array,
    match: PropTypes.object,
    page: PropTypes.number,
    pages: PropTypes.number,
    getRides: PropTypes.func
  }

  constructor(props) {
    super(props);

    this.onSelectRide = this.onSelectRide.bind(this);
  }

  onSelectRide(id) {
    store.dispatch(push(`/ride/id/${id}`));
  }

  render() {
    return (
      <UserRidesContainer
        onSelectRide={this.onSelectRide}
        searchParams={this.props.match.params}
      />
    )
  }
}

