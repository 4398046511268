import { GET_RIDES_ANALYTICS_SUCCESS } from '../actions/analytics'

const initialState = {

  ridesAnalytics: [],
  page: 0,
  pages: 0,
  total: 0,
  params: {
    page: 1
  }
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case GET_RIDES_ANALYTICS_SUCCESS: {
      return {
        ...state,
        ridesAnalytics: action.rides.results,
        page: action.rides.page,
        pages: action.rides.pages,
        total: action.rides.total,
        params: { page: action.rides.page}

      }
    }
    default:
      return state
  }
}

export default reducer