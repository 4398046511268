import { GET_INACTIVE_REASON_SUCCESS } from "../actions/drives-inactive-reason";

const initialState = {
  all: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INACTIVE_REASON_SUCCESS:
      return { ...state, all: action.reasons };

    default:
      return state;
  }
};

export default reducer;
