import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../styles/css/cupons.css";
import Promocode from "../components/promocode";

const PromocodePage = ({ match }) => {
  return (
    <div>
      <div
        className="container-fluid"
        style={{ paddingLeft: 40, paddingRight: 40 }}
      >
        <div className="header-buttons">
          <div>
            <h3>Cupom</h3>
          </div>
        </div>
        <div style={{ width: 580, marginLeft: "auto", marginRight: "auto" }}>
          <div className="card bg-light">
            <div className="card-header"> Dados do cupom</div>
            <div className="card-body">
              <Promocode id={match.params.id} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromocodePage;
