import React from "react";
import Modal from "react-modal";
import { banks } from "../../../utils/banks";
import { number, bool, func, object } from "prop-types";
import * as S from "./styled";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

export const ModalConfirmWhiteDraw = ({
  modalIsOpen,
  setModalIsOpen,
  requestWithdrawal,
  currentBalance,
  operation,
}) => {
  const bank = operation?.bank_account;
  const bankName = banks.find(
    item => item.id.toString() == bank.bank_number
  ).name;

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={setModalIsOpen}
        style={customStyles}
        contentLabel="Solicitação de saque"
      >
        <h2>Solicitação de saque</h2>
        <hr />

        <div>
          <p>
            {operation.company_name}, você está prestes a solicitar um saque no
            valor de R$ {currentBalance} para os dados bancários a seguir:
          </p>
          <p>
            <strong>Banco:</strong> {bankName} <br />
            <strong>Agencia:</strong> {bank.agency_number}-
            {bank.agency_check_number}
            <br />
            <strong>Conta:</strong>
            {bank.account_number}-{bank.account_check_number} <br />
            <strong>Favorecido:</strong> {bank.holder.accountOwner} <br />
            <strong>Documento: </strong>
            {bank.holder.cpf}
          </p>
          <p>Podemos confirmar sua solicitação?</p>
        </div>
        <hr />
        <S.ModalButtons>
          <button
            type="button"
            className="btn btn-success pull-left"
            onClick={requestWithdrawal}
          >
            Confirmar
          </button>

          <button
            type="button"
            className="btn btn-secondary pull-left"
            data-dismiss="modal"
            onClick={setModalIsOpen}
          >
            Cancelar
          </button>
        </S.ModalButtons>
      </Modal>
    </div>
  );
};

ModalConfirmWhiteDraw.propTypes = {
  modalIsOpen: bool,
  setModalIsOpen: func,
  requestWithdrawal: func,
  currentBalance: number,
  operation: object,
};

export default ModalConfirmWhiteDraw;
