import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "react-router-redux";
import { RouteWithSubRoutes } from "../";
import { store } from "../store";
import { getDrivers, getDebits } from "../actions/drivers";
import {
  DriverStatus,
  DriverStatusLabels,
  DriverSituationLabels,
} from "../api/types";
import Api from "../api";
import Avatar from "../components/avatar";
import Paginator from "../components/paginator";
import DriversFilter from "../components/drivers-filter";
import "../styles/css/drivers.css";

const statusColors = {
  [DriverStatus.OFFLINE]: "#FAECEB",
  [DriverStatus.FREE]: "#F0FAF0",
};
const getStatusColor = status => statusColors[status] || "red";

const capSentence = text => {
  const words = text.toLowerCase().split(" ");

  return words
    .map(word => (word ? word[0].toUpperCase() + word.slice(1) : ""))
    .join(" ");
};

const DriversPage = ({ match, routes }) => {
  const dispatch = useDispatch();
  const [excel, setExcel] = useState();
  const [excelReady, setExcelReady] = useState();

  const { user, city_id, categories, drivers, page, pages, total, params, debits } =
    useSelector(state => ({
      user: state.account,
      city_id: state.preferences.city_id,
      categories: state.categories.all,
      drivers: state.drivers.drivers,
      page: state.drivers.params.page,
      pages: state.drivers.pages,
      total: state.drivers.total,
      params: state.drivers.params,
      debits: state.drivers.debits,
    }));

  useEffect(() => {
    dispatch(getDrivers(params));

    dispatch(getDebits(city_id));
  }, [city_id]);

  const onChangePage = page => {
    dispatch(getDrivers({ ...params, page }));
  };

  const show = id => {
    store.dispatch(push(`/drivers/id/${id}/info`));
  };

  const add = () => {
    store.dispatch(push(`/drivers/create`));
  };

  const onChangeParams = params => {
    dispatch(getDrivers({ ...params, page: 1 }));
  };

  const downloadExcel = async () => {
    setExcel(false);

    try {
      const excel = await Api.getAllDrivers();

      setExcel(excel);
    } catch (e) {
      console.log(e);
      alert("Erro ao carregar o excel");
    }
  };

  const renderItem = item => (
    <tr
      key={item.id}
      style={{ cursor: "pointer" }}
      onClick={() => show(item.id)}
    >
      <td>{item.id.substring(0, 8)}</td>
      <td>
        <Avatar source={item.avatar_small} />
      </td>
      <td>{item.name ? capSentence(`${item.name} ${item.surname}`) : ""}</td>
      <td>{item.city_name}</td>
      <td>{item.phone}</td>
      <td>
        {item.rating}
        {item.n_ratings ? ` (${item.n_ratings})` : ""}
      </td>
      <td>{item.n_rides}</td>
      {/* {item.totalcorridames && <td>{item.totalcorridames}</td>} */}
      {params.rides == 15 && <td>{item.driverscancell}</td>}
      <td
        style={{ color: item.debit > 0 ? "red" : "black" }}
      >
        {`R$ ${item.debit}`}
      </td>

      <td>{DriverSituationLabels[item.situation]}</td>
      {params.situation === "0" && <td>{item.reason}</td>}
      <td style={{ backgroundColor: getStatusColor(item.status) }}>
        {item.status == DriverStatus.FREE && !item.geolocation_updated_at
          ? DriverStatusLabels[DriverStatus.OFFLINE]
          : DriverStatusLabels[item.status]}
      </td>
    </tr>
  );

  return (
    <div
      className="container-fluid"
      style={{ paddingLeft: 40, paddingRight: 40 }}
    >
      {match.path === "/drivers" && match.isExact && (
        <div>
          <div className="header-buttons">
            <div style={{ display: "flex" }}>
              <h3>Motoristas</h3>{" "}
              <h3 style={{ display: "inline-block", paddingLeft: 40 }}>
                Débitos pendentes: R${" "}
                {Number(debits.sum).toLocaleString("pt-BR")}
              </h3>
            </div>
            <button className="btn btn-primary" onClick={add}>
              Adicionar
            </button>
          </div>

          <p style={{ clear: "both" }} />

          <DriversFilter
            params={params}
            categories={categories}
            onSubmit={onChangeParams}
          />

          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th style={{ width: 50 }}>ID</th>
                <th style={{ width: 60 }}>&nbsp;</th>
                <th>Nome</th>
                <th>Praça</th>
                <th>Telefone</th>
                <th>Avaliação</th>
                <th>Viagens</th>
                {/* {drivers.some(driver => driver.hasOwnProperty('totalcorridames')) && <th>Total de viagens no mês</th> } */}
                {params.rides == 15 && <th>Cancelamento</th>}
                <th>Débito</th>
                <th>Situação</th>
                {params.situation === "0" && <th>Motivo</th>}

                <th>Status</th>
              </tr>
            </thead>
            <tbody>{drivers.map(renderItem)}</tbody>
          </table>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div>
              <Paginator
                page={page}
                pages={pages}
                total={total}
                onChange={onChangePage}
              />
              {!!total && `Exibindo ${drivers.length} de ${total}`}
            </div>

            <div>
              {!excel && (
                <button
                  type="button"
                  onClick={downloadExcel}
                  className="btn btn-primary"
                >
                  Download Excel
                </button>
              )}
              {!!excel && (
                <a
                  type="button"
                  title="condutores.xls"
                  href={`data:application/vnd.ms-excel;charset=utf-8,${encodeURIComponent(
                    excel
                  )}`}
                  download={`condutores.xls`}
                  ref={ref => {
                    if (!excelReady) {
                      setExcelReady(true);
                      ref.click();
                    }
                  }}
                  className="btn btn-primary"
                >
                  Download Excel
                </a>
              )}
            </div>
          </div>
        </div>
      )}

      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </div>
  );
};

export default DriversPage;
