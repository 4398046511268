import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {func, array, object, string,} from 'prop-types';
import moment from 'moment';
import {
  getExtracts,
  DAILY_EXTRACT,
  WEEKLY_EXTRACT,
  MOUNTHLY_EXTRACT
} from '../actions/extracts';

class DriverExtracts extends Component {

  static propTypes = {
    getExtracts: func,
    extracts: array,
    driver: object,
    driver_id: string,
    period: string,
    match: object,
    history: object,
  }

  constructor(props) {
    super();
    this.daily = this.daily.bind(this);
    this.weekly = this.weekly.bind(this);
    this.mounthly = this.mounthly.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.close = this.close.bind(this);

  }

  async componentDidMount() {
    let { id } = this.props.match.params;

    if (id) {
      this.driver_id = id;
      this.props.getExtracts(id, this.props.period)
    }
  }

  daily() {
    this.props.getExtracts(this.driver_id, DAILY_EXTRACT)
  }

  weekly() {
    this.props.getExtracts(this.driver_id, WEEKLY_EXTRACT)
  }
  mounthly() {
    this.props.getExtracts(this.driver_id, MOUNTHLY_EXTRACT)
  }

  close() {
    this.props.history.goBack();
  }

  renderItem(extract, index) {
    return (
      <div className="card extract-card" key={index}>
        <div className="card-body">
          <h2>
            {
              this.props.period == DAILY_EXTRACT
                ? `DIA ${moment(extract.start_dt).utc().format('DD/MM')}`
                : null
            }
            {
              this.props.period == WEEKLY_EXTRACT
                ? `Semana de ${moment(extract.start_dt).utc().format('DD/MM')} a ${moment(extract.end_dt).utc().format('DD/MM/YYYY')}`
                : null
            }
            {
              this.props.period == MOUNTHLY_EXTRACT
                ? `Mês de ${moment(extract.start_dt).utc().format('DD/MM')} a ${moment(extract.end_dt).utc().format('DD/MM/YYYY')}`
                : null
            }
          </h2>
          <div className="row-item">
            <h3>RESUMO</h3>
            <div>
              <div>CORRIDAS</div>
              <div>{extract.n_rides}</div>
            </div>
            <div>
              <div>LUCRO DO MOTORISTA</div>
              <div>R$ {extract.amount.profit.toFixed(2)}</div>
            </div>
            <div>
              <div>TOTAL</div>
              <div>R$ {extract.amount.total.toFixed(2)}</div>
            </div>
          </div>
          {
            !!extract.cash &&
            <div className="row-item">
              <h3>DINHEIRO</h3>
              <div>
                <div>CORRIDAS</div>
                <div>{extract.cash.n_rides}</div>
              </div>
              <div>
                <div>TOTAL</div>
                <div>R$ {extract.cash.amount.total.toFixed(2)}</div>
              </div>
            </div>
          }
          {
            !!extract.card &&
            <div className="row-item">
              <h3>CARTÃO</h3>
              <div>
                <div>CORRIDAS</div>
                <div>{extract.card.n_rides}</div>
              </div>
              <div>
                <div>BRUTO</div>
                <div>R$ {extract.card.amount.gross.toFixed(2)}</div>
              </div>
              <div>
                <div>DIÁRIA MOBY</div>
                <div style={{color:'red'}}>- R$ {extract.card.amount.moby_tax.toFixed(2)}</div>
              </div>
              <div>
                <div>TAXAS CARTÃO</div>
                <div style={{color:'red'}}>- R$ {extract.card.amount.moip_taxes.toFixed(2) }</div>
              </div>
              <div>
                <div>LUCRO DO MOTORISTA</div>
                <div>R$ {extract.card.amount.profit.toFixed(2)}</div>
              </div>
              {
                !!extract.card.amount.tips &&
                  <div>
                    <div>GORJETAS</div>
                    <div>R$ {extract.card.amount.tips.toFixed(2)}</div>
                  </div>
              }
              {
                !!extract.card.amount.tips &&
                  <div>
                    <div>LUCRO TOTAL</div>
                    <div>R$ {extract.card.amount.total.toFixed(2)}</div>
                  </div>
              }
            </div>
          }
        </div>
      </div>
    )
  }

  renderFilters() {
    const { period } = this.props;

    return (
      <div style={{ position: 'absolute', top: -70, right: 0, alignItems: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <label>Período: &nbsp;&nbsp;</label>
        <ul className="nav nav-pills">
          <li className="nav-item">
            <a className={`nav-link ${period == DAILY_EXTRACT ? 'active' : ''}`} onClick={this.daily}>Diário</a>
          </li>
          <li className="nav-item">
            <a className={`nav-link ${period == WEEKLY_EXTRACT ? 'active' : ''}`} onClick={this.weekly}>Semanal</a>
          </li>
          <li className="nav-item">
            <a className={`nav-link ${period == MOUNTHLY_EXTRACT ? 'active' : ''}`} onClick={this.mounthly}>Mensal</a>
          </li>
        </ul>
      </div>
    )
  }

  render() {
    return (
      <div>
        <div style={{position: 'relative', minHeight: 1 }}>
          {
            this.renderFilters()
          }
        </div>
        <div style={{ width: 500, marginLeft: 'auto', marginRight: 'auto'}}>
          {
            this.props.extracts.map(this.renderItem)
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  extracts: state.extracts.list,
  period: state.extracts.period,
  driver: state.drivers.driver
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getExtracts
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DriverExtracts)
