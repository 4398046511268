import React, { useState } from "react";
import "../../../styles/css/statistics.css";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import ptBR from "date-fns/locale/pt-BR";
import DateFnsUtils from "@date-io/date-fns";
import { func } from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { setInitialDate, setFinalDate } from "../../../actions/statistics";

export const StatisticsFilter = ({ fetchListAnalytics }) => {
  const dispatch = useDispatch();
  const { initialDate, finalDate } = useSelector(
    (state) => state.statistics.statisticsFilters
  );

  const [openModalFilter, setOpenModalFilter] = useState(false);

  const submitForm = () => {
    fetchListAnalytics();
    setOpenModalFilter(false);
  };

  return (
    <div className="statistics-filter">
      <span onClick={() => setOpenModalFilter(!openModalFilter)}>
        Filtrar data
      </span>

      {openModalFilter && (
        <div className="card card-filter-date">
          <div className="btns-date">
            <div className="date">
              <div>
                <label>De:</label>

                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                  <KeyboardDatePicker
                    id="date-picker-dialog"
                    format="dd/MM/yyyy"
                    value={initialDate}
                    onChange={(e) => dispatch(setInitialDate(e))}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    className="input-controlled-select"
                    multiline
                    inputVariant="outlined"
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>

            <div>
              <div>
                <label className="title-label-date">Até:</label>

                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                  <KeyboardDatePicker
                    id="date-picker-dialog"
                    format="dd/MM/yyyy"
                    value={finalDate}
                    onChange={(e) => dispatch(setFinalDate(e))}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    className="input-controlled-select"
                    multiline
                    inputVariant="outlined"
                  />
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>
          <div className="btn-filter">
            <button icon="pi pi-check" iconPos="right" onClick={submitForm}>
              Aplicar filtro
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

StatisticsFilter.propTypes = {
  fetchListAnalytics: func,
};

export default StatisticsFilter;
