import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import '../styles/css/posts.css';

export default class Paginator extends Component {
  static propTypes = {
    page: PropTypes.number,
    pages: PropTypes.number,
    onChange: PropTypes.func,
    size: PropTypes.number
  }

  static defaultProps = {
    size: 4
  }

  constructor(props) {
    super(props);
    this.renderItem = this.renderItem.bind(this);
  }

  renderItem(page, label) {
    return (
      <PaginationItem
        active={page == this.props.page && !label}
        key={page + '' + label}
      >
        <PaginationLink onClick={() => this.props.onChange(page)}>
          { label || page }
        </PaginationLink>
      </PaginationItem>
    )
  }

  render() {
    let { pages, page, size } = this.props;

    let start = Math.max(page - size, 1);
    let end = Math.min(page + size, pages);

    let items = [];
    for (let i = start; i <= end; i++) {
      items.push(i);
    }

    return (
      <Pagination>
        {
          page != 1 &&
            this.renderItem(1, '<<')
        }
        {
          items.map(page => this.renderItem(page))
        }
        {
          page != pages &&
            this.renderItem(pages, '>>')
        }
      </Pagination>
    )
  }
}