import "../styles/franchiseesAddLogin.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FormGroup, Label, Input } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import { useDebounce } from "use-debounce";

import api from "../api";
import { validateCpfCnpj, validatePhone } from "../utils/string";
import { getOperations } from "../actions/cities";

const newArray = [
  { name: "Mapa", value: "map" },
  { name: "Cupons", value: "promocodes" },
  { name: "Passageiros", value: "riders" },
  { name: "Motoristas", value: "drivers" },
  { name: "Cadastro Motorista", value: "aspirant_drivers" },
  { name: "Cadastro Veículos", value: "aspirant_drivers" },
  { name: "Tarifas", value: "fares" },
  { name: "Viagens", value: "rides" },
  { name: "Notificações", value: "push_notifications" },
  { name: "Equipe", value: "admins" },
  { name: "Estatísticas", value: "analytics" },
  { name: "Franquia", value: "operation" },
];

const initialValues = {
  name: "",
  cpfCnpj: "",
  social: "",
  email: "",
  phone: "",
  cities: [],
  franchisee: "",
  role_description: "",
};

export const FranchiseesAddLogin = () => {
  const dispatch = useDispatch();
  const { user, cities, city_id } = useSelector(state => ({
    cities: state.cities.operations,
    user: state.account,
    city_id: state.preferences.city_id,
  }));
  const [values, setValues] = useState(() => {
    return {
      ...initialValues,
      cities: city_id ? [city_id] : [],
    };
  });
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [permissions, setPermissions] = useState({});

  const [exists, setExists] = useState(false);
  const [adminEmail] = useDebounce(values.email, 700);

  useEffect(() => {
    if (adminEmail?.includes("@")) {
      api.adminExists(adminEmail).then(setExists);
    } else {
      setExists(false);
    }
  }, [adminEmail]);

  useEffect(() => {
    dispatch(getOperations());
  }, []);

  const handleCpfCnpj = e => {
    const { name, value } = e.target;

    const newValue = validateCpfCnpj(value);

    setValues({ ...values, [name]: newValue });
  };

  const handlePhone = e => {
    const { name, value } = e.target;

    const newValue = validatePhone(value);

    setValues({ ...values, [name]: newValue });
  };

  const HandleOnChange = e => {
    const { name, value } = e.target;

    setValues({ ...values, [name]: value });
  };

  const handlePermissions = e => {
    const { value, checked } = e.target;

    if (checked) {
      setPermissions({ ...permissions, [value]: true });
    } else {
      Object.keys(permissions).forEach(key => {
        if (key === value) {
          delete permissions[key];
          setPermissions({ ...permissions });
        }
      });
    }
  };

  const goBack = () => {
    const route = city_id ? `franchisee-details/id/${city_id}` : "franchisees";

    dispatch(push(route));
  };

  function isUUID(value) {
    const uuidRegex =
      /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return uuidRegex.test(value);
  }

  const handleCreateFranchisees = async () => {
    if (!exists && password !== repeatPassword) {
      toast.error("Senhas diferentes");
      return;
    }
    if (!exists && password.length < 8) {
      toast.error("A senha deve conter no mínimo 8 caracteres");
      return;
    }
    try {
      const { name, phone, cpfCnpj, cities, email, role_description } = values;
      await api.createCollaborator({
        name,
        phone: phone.replace(/[^0-9]/g, ""),
        cpf: cpfCnpj.replace(/[^0-9]/g, ""),
        email,
        password,
        permissions,
        cities: isUUID(cities) ? cities : cities.map(id => ({ id })),
        role_description,
      });

      toast.success("Colaborador adicionado com sucesso!");

      setValues({});
      setPassword("");
      setPermissions({});
      setRepeatPassword("");

      goBack();
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };

  return (
    <div className="container__franchisees__add">
      <div className="wrapper">
        <div className="header">
          <h1>Adicionar login</h1>
        </div>
      </div>

      <div className="wrapper">
        <div className="main">
          <form>
            <div className="form__header">
              <h5>Dados do franqueado</h5>
            </div>
            <div className="wrapper__form">
              <div className="form-group">
                <label>Nome Completo</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={values.name}
                  onChange={HandleOnChange}
                />
              </div>

              <div className="form-group">
                <label>CPF/CNPJ</label>
                <input
                  type="text"
                  className="form-control"
                  name="cpfCnpj"
                  value={values.cpfCnpj}
                  onChange={handleCpfCnpj}
                />
              </div>

              <div className="form-group">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={values.email}
                  onChange={HandleOnChange}
                />
              </div>

              <div className="form-group">
                <label>Telefone</label>
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  value={values.phone}
                  onChange={handlePhone}
                />
              </div>

              <div className="form-group">
                <label>Cargo</label>
                <input
                  type="text"
                  className="form-control"
                  name="role_description"
                  value={values.role_description}
                  onChange={HandleOnChange}
                />
              </div>

              {user.role === "admin" && (
                <div className="form-group">
                  <label>Praça</label>
                  <select
                    className="form-control custom-select"
                    name="cities"
                    value={values?.cities}
                    onChange={HandleOnChange}
                  >
                    <option value="" />
                    {cities?.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>

      {!exists && (
        <div className="wrapper">
          <div className="main">
            <form>
              <div className="form__header">
                <h5>Senha do franqueado</h5>
              </div>
              <div className="wrapper__form">
                <div className="form-group">
                  <label>Senha</label>
                  <input
                    className="form-control"
                    type="text"
                    name="password"
                    placeholder="Senha"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label>Repetir senha</label>
                  <input
                    className="form-control"
                    type="text"
                    name="passwordRepeat"
                    placeholder="password"
                    value={repeatPassword}
                    onChange={e => setRepeatPassword(e.target.value)}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="wrapper">
        <div className="main">
          <form>
            <div className="form__header">
              <h5>Visão do franqueado</h5>
            </div>

            <h6>Selecione as abas do menu que o franqueado terá acesso:</h6>
            <div className="wrapper__form">
              <div className="content__checked">
                {newArray.map((item, index) => (
                  <FormGroup key={item.index} check inline className="check">
                    <Label check>
                      <Input
                        type="checkbox"
                        // checked={permissions[item] === item}
                        name={item.name}
                        value={item.value}
                        onChange={handlePermissions}
                      />
                      <span>{item.name}</span>
                    </Label>
                  </FormGroup>
                ))}
              </div>
            </div>
          </form>

          <div className="btns">
            <button className="btn btn-secondary" onClick={goBack}>
              Cancelar
            </button>
            <button
              className="btn btn-primary"
              onClick={handleCreateFranchisees}
            >
              Adicionar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
