import Api from '../api';

export const GET_EXTRACTS = 'GET_EXTRACTS';
export const GET_EXTRACTS_SUCCESS = 'GET_EXTRACTS_SUCCESS';
export const GET_EXTRACTS_ERROR = 'GET_EXTRACTS_ERROR';

export const DAILY_EXTRACT = 'daily';
export const WEEKLY_EXTRACT = 'weekly';

export const MOUNTHLY_EXTRACT = 'mounthly';



export const getExtracts = (driver_id, period) => async (dispatch, getState) => {
  dispatch({
    type: GET_EXTRACTS,
    driver_id,
    period
  })

  try {
    let list = await Api.getExtracts(driver_id, period);

    return dispatch({
      type: GET_EXTRACTS_SUCCESS,
      payload: {
        list,
        driver_id,
        period
      }
    })
  } catch (error) {
    return dispatch({
      type: GET_EXTRACTS_ERROR,
      error
    })
  }
}
