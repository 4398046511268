import React from 'react'
import { DatePicker } from 'rsuite';
import * as S from './style'

const DatePickerRecorrently = () => {

  return (
    <S.dateWrapper>
      <div>
        <S.SubTitle>Selecione a data inicial</S.SubTitle>
        <div>
          <DatePicker style={{marginRight: '20px'}} placeholder="Selecione a data inicial" />
        </div>
      </div>
      <div>
        <S.SubTitle>Selecione a data final</S.SubTitle>
        <div >
          <DatePicker placeholder="Selecione a data final" />
        </div>
      </div>

    </S.dateWrapper>
  )

}

export default DatePickerRecorrently