import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { RouteWithSubRoutes } from "../";
import { store } from "../store";
import { getCities } from '../actions/cities';
import '../styles/css/cities.css';

class CitiesPage extends Component {

  static propTypes = {
    cities: PropTypes.array,
    getCities: PropTypes.func,
    match: PropTypes.object,
    history: PropTypes.object,
  }

  constructor(props) {
    super();

    this.renderCity = this.renderCity.bind(this);
    this.show = this.show.bind(this);
    this.addCity = this.addCity.bind(this);

    props.getCities();
  }

  show(id) {
    store.dispatch(push(`/cities/id/${id}`));
  }

  addCity() {
    store.dispatch(push(`/cities/add`));
  }


  renderCity(city) {
    return (
      <div
        key={city.id}
        className="city-list-item"
        onClick={() => this.show(city.id)}
      >
        <h3>{city.name}</h3>
      </div>
    )
  }

  renderCities() {
    const { cities } = this.props;

    return (
      <div>
        <div className="header-buttons">
          <h5>Selecione uma cidade para exibir as tarifas</h5>
          <button className="btn btn-primary" onClick={this.addCity}>Adicionar Cidade</button>
        </div>
        <p style={{clear:'both'}} />

        {
          cities.map(this.renderCity)
        }
      </div>
    )
  }

  render() {
    const path = this.props.location.pathname.split('/').pop();

    return (
      <div className="page-list cities-page">
        {
          path == 'cities' &&
            this.renderCities()
        }

        {
          this.props.routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))
        }
      </div>
    )
  }
}


const mapStateToProps = state => ({
  cities: state.cities.all
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getCities
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CitiesPage)
