import React from "react";
import DatePicker from "react-datepicker";
import { CustomDateInput } from "components/customDateInput/CustomDateInput";
import moment from "moment";
import { string, func, bool, object } from "prop-types";

export const CustomDatePicker = ({
  date,
  maxDate,
  dateDatefult,
  onChange,
  readOnly = false,
  showYearDropdown = false,
}) => {
  return (
    <DatePicker
      showYearDropdown={showYearDropdown}
      maxDate={maxDate}
      dropdownMode="select"
      customInput={
        <CustomDateInput
          onClick={onChange}
          value={date}
          readOnly={readOnly}
          key={`birthdate-${date}`}
        />
      }
      selected={date ? moment(date) : dateDatefult}
      onChange={onChange}
    />
  );
};

CustomDatePicker.propTypes = {
  date: object,
  maxDate: object,
  dateDatefult: string,
  readOnly: bool,
  onChange: func,
  showYearDropdown: bool,
};
