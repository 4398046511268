import React, { useState, useEffect } from "react";
import "../../../styles/statistics.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { ChartPiePaymentsMethods } from "./ChartPiePaymentsMethods";
import { ChartBarTimesHighestDemand } from "./ChartBarTimesHighestDemand";
import { ChartLineEvolutionNumberRaces } from "./ChartLineEvolutionNumberRaces";
import { RoyaltiesInfo } from "./RoyaltiesInfo";
import { GeneralGains } from "./GeneralGains";
import { RidesInProgress } from "./RidesInProgress";
import { DriversOnline } from "./DriversOnline";
import { object } from "prop-types";
import { useDispatch, useSelector } from "react-redux";

import {
  getStatisticsDrivers,
  getStatisticsFinancial,
  getStatisticsMonthly,
  getStatisticsRides,
  getStatisticsRoyalties,
  setStatisticsLoading,
  setStatisticsNotLoading,
} from "../../../actions/statistics";
import { StatisticsFilter } from "../statistics-filter/StatisticsFilter";

export const StatisticsGeneral = () => {
  const dispatch = useDispatch();
  const city_id = useSelector((state) => state.preferences.city_id);
  const { initialDate, finalDate } = useSelector(
    (state) => state.statistics.statisticsFilters
  );

  const fetchListAnalytics = async () => {
    dispatch(setStatisticsLoading());

    try {
      const params = {
        city_id,
        start_at: initialDate,
        end_at: finalDate,
      };

      await dispatch(getStatisticsDrivers(params));
      await dispatch(getStatisticsFinancial(params));
      await dispatch(getStatisticsMonthly(params));
      await dispatch(getStatisticsRides(params));
      await dispatch(getStatisticsRoyalties(params));
    } catch (error) {
      console.log("erro aqui:", error);
    } finally {
      dispatch(setStatisticsNotLoading());
    }
  };

  useEffect(() => {
    fetchListAnalytics();
  }, [city_id]);

  return (
    <div className="container__statistics">
      <div className="wrapper">
        <StatisticsFilter fetchListAnalytics={fetchListAnalytics} />
        <div className="main">
          <div className="graphics">
            <ChartLineEvolutionNumberRaces />

            <ChartPiePaymentsMethods />
          </div>
          <div className="general__information">
            <GeneralGains />
            <RoyaltiesInfo />

            <div className="wrapper__info">
              <ChartBarTimesHighestDemand />

              <div className="cards__bottom">
                <RidesInProgress />
                <DriversOnline />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

StatisticsGeneral.propTypes = {
  match: object,
  history: object,
  location: object,
  routes: object,
};
