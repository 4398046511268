import styled from 'styled-components'

export const CardWrapper = styled.div`
  width: 700px;
  height: 130px;
  border: 0.5px solid rgb(204, 204, 204); 
  padding: 10px;
  margin: 10px 0 0 10px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;

 
`

export const CardTitle = styled.h3`
  width: 100%;  
  font-size: 16px;
  font-weight: bold;
  color: black;    
  &:hover {
    cursor: pointer;
  }
`

export const CardMessage = styled.p`
  margin-top: 5px;
  width: 100%;
  font-size: 16px;
  color: black;  
`

export const WrapperBadget = styled.div`
  width: 100%;
  height: 30px;  
  display: flex;
  align-items: center;
  align-self: flex-end;
`

export const BadgetParagraph = styled.p`
  font-size: 14px;
  margin: 0;
  margin-right: 20px;
  margin-left: 5px;
`

export const ButtonCancel = styled.button`
  width: auto;
  height: 40px;
  font-size: 14px;
  color: red;
  background-color: none;
  border: 0.5px solid red;
`