import React from "react";
import "../../../styles/statistics.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Pie } from "react-chartjs-2";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { formatPrice } from "../../../utils/string";
import { useSelector } from "react-redux";

const pieOptions = {
  plugins: {
    tooltip: {
      callbacks: {
        label: function (tooltipItem) {
          return tooltipItem.label;
        },
      },
    },
    legend: {
      position: "bottom",
      align: "center",
      labels: {
        boxWidth: 40,
        boxHeight: 17,
        font: {
          size: 13,
        },
        color: "#495057",
      },
    },
  },
};

export const ChartPiePaymentsMethods = () => {
  const { statisticsFinancial } = useSelector((state) => state.statistics);

  const formatPieLabel = (label, value) => {
    return `${label} ${value ? `- ${formatPrice(value)}` : ""}`;
  };

  return (
    <div className="card">
      <div className="info">
        <div>
          <AttachMoneyIcon />
          <h4>Formas de Pagamentos</h4>
        </div>
      </div>
      <div className="card__pie">
        <Pie
          className="pieChart"
          data={{
            labels: [
              formatPieLabel(
                "Dinheiro",
                statisticsFinancial?.financial?.cash_amount
              ),
              formatPieLabel(
                "Cupons de Desconto",
                statisticsFinancial?.financial?.wirecard_amount
              ),
              formatPieLabel(
                "Cartão de Crédito",
                statisticsFinancial?.financial?.credit_card_amount
              ),
            ],
            datasets: [
              {
                data: [
                  statisticsFinancial &&
                    statisticsFinancial?.financial.cash_amount,
                  statisticsFinancial &&
                    statisticsFinancial?.financial.wirecard_amount,
                  statisticsFinancial &&
                    statisticsFinancial?.financial.credit_card_amount,
                ].filter(Boolean),
                backgroundColor: ["#28C88E", "#DADADA", "#50EFB5"],
                hoverBackgroundColor: ["#28C88E", "#DADADA", "#50EFB5"],
              },
            ],
          }}
          options={pieOptions}
        />
      </div>
    </div>
  );
};
