import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import moment from 'moment';
import { push } from 'react-router-redux';
import Ionicon from 'react-ionicons';
import isEqual from 'react-fast-compare';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import { RouteWithSubRoutes } from "../";
import { store } from "../store";
import { getCity, clearCity, updateCity, updateCityProps } from '../actions/cities';
import '../styles/css/cities.css';

const Days = [
  { id: 0, name: 'Domingo'},
  { id: 1, name: 'Segunda-feira'},
  { id: 2, name: 'Terça-feira'},
  { id: 3, name: 'Quarta-feira'},
  { id: 4, name: 'Quinta-feira'},
  { id: 5, name: 'Sexta-feira'},
  { id: 6, name: 'Sábado'}
]

const DayNames = (() => {
  let obj = {}
  for (let day of Days) {
    obj[day.id] = day.name;
  }
  return obj;
})()

class CityPage extends Component {

  static propTypes = {
    cities: PropTypes.array,
    getCity: PropTypes.func,
    updateCityProps: PropTypes.func,
    updateCity: PropTypes.func,
    clearCity: PropTypes.func,
    match: PropTypes.object,
    history: PropTypes.object,
  }

  constructor(props) {
    super();

    this.renderFares = this.renderFares.bind(this);
    this.renderFare = this.renderFare.bind(this);
    this.addFare = this.addFare.bind(this);
    this.addDynamicFare = this.addDynamicFare.bind(this);
    this.onOrderDynamicFare = this.onOrderDynamicFare.bind(this);
    this.onDynamicFareDelete = this.onDynamicFareDelete.bind(this);
    this.onDynamicFareSave = this.onDynamicFareSave.bind(this);

    this.state = {
      dynamicFareIndex: null
    }
  }

  componentDidMount() {
    let { id } = this.props.match.params;

    if (id && id !== 'new') {
      this.props.getCity(id)
    } else {
      this.props.clearCity();
    }
  }

  showFare(id) {
    store.dispatch(push(`/cities/id/${this.props.city.id}/fare/id/${id}`));
  }

  addFare() {
    store.dispatch(push(`/cities/id/${this.props.city.id}/fare/id/new`));
  }

  addDynamicFare() {
    let { dynamic_fares } = this.props.city;
    const fare = {
      day_start: '',
      day_end: '',
      hour_start: '00:00',
      hour_end: '23:59',
      isSaved: false
    }
    dynamic_fares = [ fare, ...dynamic_fares, ];

    this.props.updateCityProps({ dynamic_fares });

    this.showDynamicFare(0)
  }

  showDynamicFare(dynamicFareIndex) {
    this.setState({
      dynamicFareIndex
    })
  }

  renderFares() {
    const { city } = this.props;

    return (
      <div>
        {
          (!city.fares || (city.fares && city.fares.length < 1)) &&
            <div className="no-fares">Nenhuma tarifa adicionada</div>
        }

        {
          !!city.fares && city.fares.length > 0 &&
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th style={{width: 50}}>ID</th>
                  <th>Categoria</th>
                  <th>Porcentagem<br />MobyGo</th>
                  <th>Bandeira</th>
                  <th>Mínimo</th>
                  <th>Quilômetro</th>
                  <th>Minuto</th>
                </tr>
              </thead>
              <tbody>
                {
                  city.fares.map(this.renderFare)
                }
              </tbody>
            </table>
        }
      </div>
    )
  }

  renderFare(fare) {
    return (
      <tr key={fare.id} style={{cursor: 'pointer'}} onClick={() => this.showFare(fare.id)}>
        <td>{fare.id.substr(0, 8)}</td>
        <td>{fare.name}</td>
        <td style={{width: 100}}>{fare.percentage_moby}</td>
        <td style={{width: 100}}>{fare.price_base}</td>
        <td style={{width: 100}}>{fare.price_minimum}</td>
        <td style={{width: 100}}>{fare.price_km}</td>
        <td style={{width: 100}}>{fare.price_minute}</td>
      </tr>
    )
  }

  renderDynamicFares() {
    const { dynamic_fares: fares } = this.props.city;
    if (!fares) {
      return;
    }

    return fares.map((fare, index) => (
      <DynamicFareRow
        fare={fare}
        index={index}
        key={index}
        onClick={() => this.showDynamicFare(index)}
        onOrder={this.onOrderDynamicFare}
      />
    ))
  }

  async onOrderDynamicFare(prevIndex, index) {
    let dynamic_fares = this.reindex(this.props.city.dynamic_fares, prevIndex, index);

    toast.dismiss();
    toast.info('Reordenando...')

    let { error } = await this.props.updateCity({
      id: this.props.city.id,
      dynamic_fares
    })

    toast.dismiss();
    if (error) {
      toast.error('Erro ao reordenar as prioridades das tarifas dinâmicas')
    } else {
      toast.success('Reordenado com sucesso')
    }
  }

  reindex(arr, index, new_index) {
    arr = [ ...arr];
    let item = arr[index];
    if (new_index >= 0 && new_index <= (arr.length - 1)) {
      arr.splice(index, 1);
      arr.splice(new_index, 0, item)
    }

    return arr;
  }

  async onDynamicFareSave(props) {
    let dynamic_fares = this.props.city.dynamic_fares.map((fare, _index) => {
      if (this.state.dynamicFareIndex == _index) {
        fare = {...fare, ...props, isSaved: undefined }
      }
      return fare;
    })

    let { error } = await this.props.updateCity({
      id: this.props.city.id,
      dynamic_fares
    })

    if (error) {
      toast.error('Erro ao salvar a tarifa')
    } else {
      toast.success('Tarifa salva')

      this.setState({
        dynamicFareIndex: null
      })
    }
  }

  async onDynamicFareDelete(removeFromDb = true) {
    let dynamic_fares = this.props.city.dynamic_fares.filter((fare, index) => index != this.state.dynamicFareIndex);

    if (removeFromDb === false) {
      this.props.updateCityProps({
        dynamic_fares
      })

      this.setState({
        dynamicFareIndex: null
      })

      return;
    }

    let { error } = await this.props.updateCity({
      id: this.props.city.id,
      dynamic_fares
    })

    if (error) {
      toast.error('Erro ao remover a tarifa')
    } else {
      toast.success('Tarifa removida com sucesso')

      this.setState({
        dynamicFareIndex: null
      })
    }
  }

  render() {
    const path = this.props.location.pathname.split('/');

    const renderFares = path[4] == null;

    const { city } = this.props;

    return (
      <div className="page-list cities-page">
        <div className="city" key={city.id}>
          <div className="header-buttons">
            <h3>{city.name}</h3>
            {
              renderFares &&
                <button
                  className="btn btn-secondary btn-sm"
                  onClick={() => this.addFare(city.id)}
                >
                  <div style={{display:'flex', alignItems: 'center'}}>
                    <Ionicon
                      icon="md-add"
                      color="#e7e7e7"
                      fontSize="14px"
                    /> &nbsp;Adicionar Tarifa
                  </div>
                </button>
            }

            {
              !renderFares &&
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => this.props.history.goBack()}
              >
                Voltar
              </button>
            }
          </div>

          {
            renderFares &&
              this.renderFares()
          }

          {
            renderFares &&
              <div>
                <div className="header-buttons" style={{marginTop: '30px'}}>
                  <h5>Tarifas Dinâmicas</h5>
                  <button
                    className="btn btn-secondary btn-sm"
                    onClick={this.addDynamicFare}
                  >
                    <div style={{display:'flex', alignItems: 'center'}}>
                      <Ionicon
                        icon="md-add"
                        color="#e7e7e7"
                        fontSize="14px"
                      /> &nbsp;Adicionar Tarifa Dinâmica
                    </div>
                  </button>
                </div>

                <div>
                  {
                    this.renderDynamicFares()
                  }
                </div>
              </div>
          }


          {
            this.props.routes.map((route, i) => (
              <RouteWithSubRoutes key={i} {...route} />
            ))
          }

          {
            this.state.dynamicFareIndex != null &&
              <DynamicFareForm
                fare={city.dynamic_fares[this.state.dynamicFareIndex]}
                onSave={this.onDynamicFareSave}
                onDelete={this.onDynamicFareDelete}
                onDismiss={() => this.setState({ dynamicFareIndex: null })}
              />
          }
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  city: state.cities.current
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getCity,
    clearCity,
    updateCityProps,
    updateCity
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CityPage)

class DynamicFareRow extends React.PureComponent {
  static propTypes = {
    onClick: PropTypes.func,
    onOrder: PropTypes.func,
    index: PropTypes.number,
    fare: PropTypes.object.isRequired
  }

  render() {
    const { fare, index } = this.props;

    return (
      <div className="dynamic-fare-row">
        <div onClick={this.props.onClick}>
          <span className="factor">{ fare.factor } <span>X</span></span>

          <strong>{DayNames[fare.day_start]}</strong>
          {
            fare.hour_start != fare.hour_end &&
              <span>&nbsp;das <strong>{fare.hour_start}</strong></span>
          }
          {
            fare.day_start != fare.day_end &&
              <span>&nbsp;até <strong>{DayNames[fare.day_end]}</strong></span>
          }
          {
            fare.hour_start != fare.hour_end &&
              <span>&nbsp;ás <strong>{fare.hour_end}</strong></span>
          }
        </div>

        <div>
          <span>
            <Ionicon
              onClick={() => this.props.onOrder(index, index+1)}
              icon="md-arrow-down"
              fontSize="25px"
              color="#333333"
            />
          </span>

          <span>
            <Ionicon
              onClick={() => this.props.onOrder(index, index-1)}
              icon="md-arrow-up"
              className="icon"
              fontSize="25px"
              color="#333333"
            />
          </span>
        </div>
      </div>
    )
  }
}

class DynamicFareForm extends React.PureComponent {
  static propTypes = {
    fare: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onDismiss: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.onSave = this.onSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
    this.onTimeChange = this.onTimeChange.bind(this);

    this.state = {}
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { fare } = nextProps;

    if (isEqual(fare, prevState.fare)) {
      return null;
    }

    return {
      fare,
      ...fare
    }
  }

  onTimeChange(prop, moment) {
    let value = moment
      ? moment.format('HH:mm')
      : prop == 'hour_start'
        ? '00:00'
        : '23:59';

    let props = {[prop]: value}

    let { day_start, day_end, hour_start, hour_end } = this.state;

    if (day_start === day_end) {
      if (prop == 'hour_start') {
        props = this.checkHours(value, hour_end)
      } else if (prop == 'hour_end') {
        props = this.checkHours(hour_start, value)
      }
    }

    this.setState(props)
  }

  onChange({ target }) {
    const getAttr = attr => {
      let item = target.attributes.getNamedItem(attr)
      return item ? item.value : null;
    }

    let type = getAttr('data-type');
    let prop = getAttr('data-name');
    let value = target.value;
    if (type == 'number') {
      value = String(value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1'));
    }

    let { day_start, day_end, hour_start, hour_end } = this.state;

    let props = {[prop]: value};
    if (prop == 'day_start') {
      if (value > day_end) {
        props.day_end = value;
      }
    }

    if (day_start == day_end || (prop == 'day_start' && value == day_end) || (prop == 'day_end' && value == day_start)) {
      Object.assign(props, this.checkHours(hour_start, hour_end))
    }

    this.setState(props)
  }

  checkHours(hour_start, hour_end) {
    let start = hour_start.replace(':', '');
    let end = hour_end.replace(':', '');

    let props = {
      hour_start,
      hour_end
    }

    if (start > end) {
      props.hour_end = hour_start;
    } else if (end < start) {
      props.hour_start = hour_end;
    }

    return props;
  }

  renderContent() {
    const fare = this.state;

    return (
      <div className="dynamic-fare">
        <div>
          <label>Fator Multiplicador</label>
          <input
            className="form-control"
            data-name="factor"
            data-type="number"
            onChange={this.onChange}
            value={fare.factor}
          />
        </div>
        <div>
          <label>Início:</label>
          <div>
            <select
              className="form-control custom-select"
              data-name="day_start"
              data-type="number"
              onChange={this.onChange}
              value={fare.day_start}
            >
              <option value={null}>Selecione..</option>
              {
                Days.map(day => (
                  <option value={day.id} key={day.id}>{day.name}</option>
                ))
              }
            </select>

            <span>ás</span>

            <TimePicker
              value={moment(fare.hour_start, 'HH:mm')}
              showSecond={false}
              format="HH:mm"
              onChange={value => this.onTimeChange('hour_start', value)}
            />
          </div>
        </div>

        <div>
          <label>Término:</label>
          <div>
            <select
              className="form-control custom-select"
              data-name="day_end"
              data-type="number"
              onChange={this.onChange}
              value={fare.day_end}
            >
              <option value={null} />
              {
                Days.map(day => (
                  <option
                    disabled={fare.day_start > day.id}
                    value={day.id}
                    key={day.id}
                  >
                    {day.name}
                  </option>
                ))
              }
            </select>

            <span>ás</span>
            <TimePicker
              value={moment(fare.hour_end, 'HH:mm')}
              showSecond={false}
              format="HH:mm"
              onChange={value => this.onTimeChange('hour_end', value)}
            />
          </div>
        </div>
      </div>
    )
  }

  onSave() {
    const fare = { ...this.state };
    delete fare.fare;

    let factor = parseFloat(fare.factor);

    if (isNaN(factor) || factor < 0) {
      alert('Fator de multiplicação inválido')
      return;
    }

    if (!fare.day_start) {
      return alert('Selecione o dia de início')
    }

    if (fare.day_start == fare.day_end && fare.hour_start == fare.hour_end) {
      alert('Os horarios de início e término não podem ser iguais')
      return;
    }

    if (factor < 1) {
      if (!window.confirm(`A tarifa será multiplicada por ${factor} resultando em descontos, tem certeza que deseja alterar?`)) {
        return;
      }
    }

    this.props.onSave(fare)
  }

  onDelete() {
    if (window.confirm('Deseja remover esta tarifa?')) {
      this.props.onDelete();
    }
  }

  onDismiss() {
    if (this.props.fare.isSaved === false) {
      this.props.onDelete(false);
    } else {
      this.props.onDismiss();
    }
  }

  render() {
    return (
      <div className="modal dynamic-fare-modal" role="dialog" data-show="true" data-backdrop="static">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Tarifa dinâmica</h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.onDismiss}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {
                this.renderContent()
              }
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.onSave}
              >
                Salvar
              </button>
              <button
                type="button"
                className="btn btn-warn"
                onClick={this.onDelete}
              >
                Remover
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
