import account from "./account";
import drivers from "./drivers";
import categories from "./categories";
import rides from "./rides";
import extracts from "./extracts";
import aspirantDrivers from "./aspirant-drivers";
import riders from "./riders";
import ads from "./ads";
import cities from "./cities";
import fares from "./fares";
import globalMenu from "./globalMenu";
import preferences from "./preferences";
import driversInactiveReason from "./drives-inactive-reason";
import inactiveCars from "./inactiveCars";
import notifications from "./push-notification";
import analytics from "./analytics";
import analyticsUsers from "./analytics-users";
import promocodes from "./promocodes";
import statistics from "./statistics";

const reducers = {
  account,
  drivers,
  categories,
  rides,
  extracts,
  aspirantDrivers,
  riders,
  ads,
  cities,
  fares,
  globalMenu,
  preferences,
  driversInactiveReason,
  inactiveCars,
  notifications,
  analytics,
  analyticsUsers,
  promocodes,
  statistics,
};

export default reducers;
