import React from 'react';
import { MultiSelect } from './style'

const TargetSelect = props => {
  return (
    <MultiSelect
      placeholder={props.placeholder ? props.placeholder : 'Selecione'}
      onChange={selectedOption => props.handleSelect(selectedOption)}
      isMulti={props.isMulti}
      options={props.options}
    />
  )
}

export default TargetSelect



