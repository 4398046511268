import React, { useEffect, useState } from "react";
import "../styles/team.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { store } from "../store";
import { push } from "react-router-redux";
import api from "../api";

export const Team = () => {
  const [franchisees, setFranchisees] = useState([]);
  const [cities, setCities] = useState([]);

  useEffect(() => {
    const handleFranchisees = async () => {
      const user = JSON.parse(localStorage.getItem("user") || "[]");

      const payload = {
        city_id: user.role === "admin" ? "" : user.cities.id,
      };

      const collaborations = await api.getCollaborator(payload);

      const cities = await api.cities();

      setFranchisees(collaborations);
      setCities(cities);
    };

    handleFranchisees();
  }, []);

  const show = id => {
    store.dispatch(push(`/team-details/id/${id}`));
  };

  const renderItem = (item, index) => {
    return (
      <tr key={index} onClick={() => show(item.id)}>
        <td>{item?.name}</td>
        <td>{item?.email}</td>
        <td>
          {item?.role === "city_manager" ? (
            <span>{item.role_description}</span>
          ) : (
            <span>Admin</span>
          )}
        </td>
        <td>{item.cities?.map(city => <div>{city.name}</div>)}</td>
      </tr>
    );
  };

  return (
    <div className="container__franchisees">
      <div className="wrapper">
        <div className="header">
          <h1>Equipe</h1>
          <Link to={"/team-add"}>
            <button>Adicionar colaborador</button>
          </Link>
        </div>
        {/*
        <div className="filter">
          <FilterFranchisees data={cities} onSubmit={HandleFiltered} />
        </div> */}

        <div className="main">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>Nome</th>
                <th>E-mail</th>
                <th>Cargo</th>
                <th>Cidade</th>
              </tr>
            </thead>
            <tbody>
              {franchisees.length &&
                franchisees.map((item, index) => renderItem(item, index))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
