import {
  GET_RIDERS_SUCCESS,
  GET_RIDER,
  GET_RIDER_SUCCESS,
  UPDATE_RIDER_SUCCESS,
  SET_RIDER,
  UPDATE_RIDER_PROPS,
  UNLOCK_USER,
} from "../actions/riders";

const initialState = {
  riders: [],
  params: {
    page: 0,
    search: "",
    status: null,
  },
  pages: 0,
  rider: {
    id: "",
    name: "",
    surname: "",
    email: "",
    phone: "",
    city_name: "",
    city_id: "",
    avatar_small: "",
    status: 0,
    customer_id: "",
  },
  totals: {
    n_rides: 0,
    n_rides_status10: 0,
    n_rides_status20: 0,
    n_rides_status30: 0,
    n_rides_status40: 0,
    n_rides_status50: 0,
    n_rides_status100: 0,
    n_rides_status105: 0,
    n_rides_status110: 0,
    n_rides_status115: 0,
    cash_total: 0,
    credit_card_total: 0,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RIDERS_SUCCESS:
      return { ...state, ...action.payload };

    case GET_RIDER: {
      return { ...state, rider: { ...initialState.rider } };
    }

    case GET_RIDER_SUCCESS: {
      let rider =
        action.rider != null ? action.rider : { ...initialState.rider };

      return { ...state, rider };
    }

    case UPDATE_RIDER_PROPS: {
      return { ...state, rider: { ...state.rider, ...action.props } };
    }

    case SET_RIDER: {
      return {
        ...state,
        rider: action.rider != null ? action.rider : { ...initialState.rider },
      };
    }

    case UPDATE_RIDER_SUCCESS: {
      let riders = state.riders.map(rider => {
        return rider.id == action.rider.id
          ? { ...rider, ...action.rider }
          : rider;
      });

      return { ...state, riders };
    }

    case UNLOCK_USER: {
      return { ...state };
    }

    default:
      return state;
  }
};

export default reducer;
