import React from "react";
import "../../../styles/statistics.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector } from "react-redux";

export const ActiveUsersToday = () => {
  const { statisticsIndicators } = useSelector((state) => state.statistics);

  return (
    <div className="general__card">
      <div className="title">
        <h4>DAU (Usuários Ativos Diários)</h4>
      </div>
      <div className="content">
        <span>
          <strong>
            {!!statisticsIndicators &&
              statisticsIndicators.total_active_users_today}
          </strong>
        </span>
      </div>
    </div>
  );
};
