import React, { useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { toast } from 'react-toastify';
import { DateRangePicker } from 'rsuite';
import ReactSelect from "react-select"
import 'rsuite/dist/styles/rsuite-default.css';
import { push } from 'react-router-redux';
import { store } from '../../store';
import Paginator from '../../components/paginator'
import * as S from './styled'
import { getAnalyticsUsers } from '../../actions/analytics-users'


const classifications = [
  {
    value: 1,
    label: 'Motoristas - Por maior números de viagens concluídas'
  },
  {
    value: 2,
    label: 'Motoristas - Por maior números de viagens canceladas'
  },
  {
    value: 3,
    label: 'Passageiros - Por maior números de viagens concluídas'
  },
  {
    value: 4,
    label: 'Passageiros - Por maior números de viagens canceladas'
  }
]

function AnalyticsUsers(props) {
  let [page] = useState(1)
  const [filters, setFilters] = useState({
    dateInitial: null,
    dateFinal: null,
    cityId: null,
    ridesType: null,
  })

  const handleSubmit = () => {
    if (!filters.ridesType) return toast.warn('Selecione uma categoria')
    props.getAnalyticsUsers({...props.params, page, dateInitial: filters.dateInitial, dateFinal: filters.dateFinal, cityId: filters.cityId, ridesType: filters.ridesType})
  }

  const onChangePage = page => {
    props.getAnalyticsUsers({ ...props.params, page, dateInitial: filters.dateInitial, dateFinal: filters.dateFinal, cityId: filters.cityId, ridesType: filters.ridesType });
  }

  const handleInitialDate = date => {
    let [initial, final] = date
    setFilters({
      ...filters,
      dateInitial: initial,
      dateFinal: final
    })

  }

  const handleCleanDate = () => {
    setFilters({
      ...filters,
      dateInitial: null,
      dateFinal: null
    })
  }

  const handleCity = city => {
    setFilters({
      ...filters,
      cityId: city
    })
  }

  const handleRideType = type => {
    setFilters({
      ...filters,
      ridesType: type
    })
  }

  const handleShow = (id, isDriver) => {
    isDriver ? store.dispatch(push(`/drivers/id/${id}/info`)) : store.dispatch(push(`/riders/id/${id}/info`))
  }

  const renderRowsTable = (data, i) => {
    return (
      <S.TRow
        index={i}
        onClick={() => data.situation !== undefined ? handleShow(data.user_id, true) : handleShow(data.user_id, false)}
      >
        <S.TD>{`${data.name} ${data.surname}`}</S.TD>
        <S.TD>{data.email}</S.TD>
        <S.TD>{data.phone}</S.TD>
        <S.TD>{data.city_name}</S.TD>
        <S.TD>{data.nro_corridas} </S.TD>

      </S.TRow>
    )
  }



  return (
    <div>
      <S.Title>
        Analytics Drivers
      </S.Title>

      <div style={{display: 'flex', paddingTop: 20, paddingBottom: 20, backgroundColor: '#E6E6E6'}}>
        <S.WrapperFilters>
          <div style={{marginRight: '20px'}}>
            <S.SubTitle>Mês de corridas</S.SubTitle>
            <DateRangePicker
              selected={null}
              style={{width: 200}}
              onOk={date => handleInitialDate(date)}
              onClean={() => handleCleanDate()}
            />
          </div>
          <div style={{width: '300px', marginRight: '20px'}}>
            <S.SubTitle>Cidade</S.SubTitle>
            <ReactSelect
              style={{width: '100%'}}
              placeholder="Exibir cidade desejada"
              options={props.cities.map(c => {
                return {
                  value: c.id,
                  label: c.name
                }
              })}
              onChange={defaultValue => handleCity(defaultValue.value)}
            />
          </div>

          <div style={{width: '400px'}}>
            <S.SubTitle>Classificar por:</S.SubTitle>
            <ReactSelect
              style={{width: '100%'}}
              placeholder="Selecionar usuários base e tipo"
              //isClearable={isClearable}
              options={classifications.map(c => {
                return {
                  value: c.value,
                  label: c.label
                }
              })}
              onChange={defaultValue => handleRideType(defaultValue.value)}
            />
          </div>
          <button
            style={{
              height: 40,
              width: 100,
              borderRadius: 5,
              color: 'black',
              backgroundColor: '#00F7BA',
              fontSize: 16,
              fontWeight: 400,
              alignSelf: "flex-end",
              marginLeft: 20
            }}
            onClick={() => handleSubmit()}
          >Buscar</button>
        </S.WrapperFilters>
      </div>
      <S.WrapperTable>
        <S.Table>
          <S.THead>
            <S.TRow>
              <S.TH>Nome</S.TH>
              <S.TH>Email</S.TH>
              <S.TH>Telefone</S.TH>
              <S.TH>Cidade</S.TH>
              <S.TH>Total de corridas finalizadas/canceladas</S.TH>
            </S.TRow>
          </S.THead>
          <S.TBody>
            {
              props.usersAnalytics && props.usersAnalytics.map((data, i) => renderRowsTable(data, i))
            }
          </S.TBody>

        </S.Table>

      </S.WrapperTable>

      <Paginator
        page={props.page}
        pages={props.pages}
        onChange={onChangePage}
      />
      {
        `Exibindo ${props.usersAnalytics.length} de ${props.total}`
      }
    </div>
  )
}

const mapStateToProps = state => ({
  cities: state.cities.all,
  page: state.analyticsUsers.page,
  pages: state.analyticsUsers.pages,
  total: state.analyticsUsers.total,
  params: state.analyticsUsers.params,
  usersAnalytics: state.analyticsUsers.usersAnalytics
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    getAnalyticsUsers
  }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalyticsUsers);