import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { push } from "react-router-redux";
import { RouteWithSubRoutes } from "../";
import { store } from "../store";
import { getAspirantDrivers } from "../actions/aspirant-drivers";
import DriversFilter from "../components/aspirant-drivers-filter";
import { AspirantDriverStatusLabels } from "../api/types";
import Paginator from "../components/paginator";
import "../styles/css/drivers.css";

class AspirantDriversPage extends Component {
  static propTypes = {
    drivers: PropTypes.array,
    page: PropTypes.number,
    pages: PropTypes.number,
    params: PropTypes.object,
    getAspirantDrivers: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.routes = props.routes;

    this.onChangePage = this.onChangePage.bind(this);
    this.onChangeParams = this.onChangeParams.bind(this);
    this.renderItem = this.renderItem.bind(this);

    props.getAspirantDrivers(props.params);
  }

  onChangePage(page) {
    this.props.getAspirantDrivers({ ...this.props.params, page });
  }

  onChangeParams(params) {
    this.props.getAspirantDrivers({ ...params, page: 1 });
  }

  show(id) {
    store.dispatch(push(`/aspirant-drivers/id/${id}/info`));
  }

  add() {
    store.dispatch(push(`/aspirant-drivers/create`));
  }

  renderItem(item) {
    return (
      <tr
        key={item.id}
        style={{ cursor: "pointer" }}
        onClick={() => this.show(item.id)}
      >
        <td>{item.id.substring(0, 8)}</td>
        <td>{item.name + " " + item.surname}</td>
        <td>{item.phone}</td>
        <td>
          {item.city?.name ||
            (item.address &&
              typeof item.address === "object" &&
              `${item.address.city} - ${item.address.state}`)}
        </td>
        <td>{AspirantDriverStatusLabels[item.status]}</td>
        <td>{moment(item.created_at).format("DD/MM/YY HH:mm")}</td>
      </tr>
    );
  }

  render() {
    const { match, drivers } = this.props;

    return (
      <div className="full-page-list">
        {match.path == "/aspirant-drivers" && match.isExact && (
          <div>
            <div className="header-buttons">
              <h3>Pré-Cadastro Motoristas</h3>
            </div>
            <p style={{ clear: "both" }} />

            <DriversFilter
              params={this.props.params}
              onSubmit={this.onChangeParams}
            />

            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th style={{ width: 50 }}>ID</th>
                  <th>Nome</th>
                  <th>Telefone</th>
                  <th>Cidade</th>
                  <th>Status</th>
                  <th>Cadastrado em</th>
                </tr>
              </thead>
              <tbody>{drivers.map((item) => this.renderItem(item))}</tbody>
            </table>

            <Paginator
              page={this.props.page}
              pages={this.props.pages}
              onChange={this.onChangePage}
            />
            {!!this.props.total &&
              `Exibindo ${drivers.length} de ${this.props.total}`}
          </div>
        )}

        {this.routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  drivers: state.aspirantDrivers.drivers,
  page: state.aspirantDrivers.params.page,
  pages: state.aspirantDrivers.pages,
  params: state.aspirantDrivers.params,
  total: state.aspirantDrivers.total,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAspirantDrivers,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AspirantDriversPage);
