import Api from "../api";

export const GET_CARS_INACTIVE = 'GET_CARS_INACTIVE';
export const GET_CAR_INACTIVE = 'GET_CAR_INACTIVE';
export const UPDATE_INACTIVE_CAR = 'UPDATE_INACTIVE_CAR';

export const DELETE_CAR = 'DELETE_CAR';
export const DELETE_CAR_ERROR = 'DELETE_CAR_ERROR'

export const getCarsInactive = requestParams => async (dispatch, getState) => {

  try {
    const city_id = getState().preferences.city_id
    const cars = await Api.getCarsInactive({ city_id })

    return dispatch({
      type: GET_CARS_INACTIVE,
      cars
    })
  } catch (error) {
    console.log(error)
  }
}

export const getCarInactive = requestParams => async dispatch => {


  try {
    let car = await Api.getCarInactive(requestParams)

    return dispatch({
      type: GET_CAR_INACTIVE,
      car
    })
  } catch (error) {
    console.log(error)
  }

}

export const updateInactiveCar = car => async dispatch => {
  try {
    await Api.updateCar(car);
    dispatch({
      type: UPDATE_INACTIVE_CAR,
      car
    });
  } catch (error) {
    console.info(error);
  }
};

export const deleteCar = id => async dispatch => {
  try {
    console.log('é os id', typeof(id))
    await Api.deleteCar(id)
    dispatch({
      type: DELETE_CAR,
      id
    })
  } catch (error) {
    return dispatch({
      type: DELETE_CAR_ERROR,
      error
    })
  }
}


