/* eslint-disable arrow-parens */
import React, { useCallback, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";

import {
  updateAspirantDriverBank,
  getAspirantBankDriver,
} from "../actions/aspirant-drivers";
import BankAccount from "components/bankAccount/BankAccount";
import { object, func } from "prop-types";
import { BankAccountInitialValues } from "constants/BankAccount.constant";

const AspirantDriverBankAccount = ({
  driver,
  getAspirantBankDriver,
  match,
}) => {
  const [bank, setBank] = useState(BankAccountInitialValues);
  const driverId = driver?.id || match?.params?.id;
  const dispatch = useDispatch();

  const getBankAccountDriver = useCallback(async () => {
    try {
      const { account } = await getAspirantBankDriver(driverId);
      setBank(account);
    } catch (error) {
      console.log(error);
    }
  }, [getAspirantBankDriver, driverId]);

  useEffect(() => {
    getBankAccountDriver();
  }, [getBankAccountDriver]);

  const updateBank = async (bankAccount) => {
    try {
      dispatch(updateAspirantDriverBank(driverId, bankAccount));
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div>
      <div style={{ width: 500, marginLeft: "auto", marginRight: "auto" }}>
        <BankAccount
          bank={bank}
          getBankAccountDriver={getBankAccountDriver}
          updateBank={updateBank}
        />
      </div>
    </div>
  );
};

AspirantDriverBankAccount.propTypes = {
  getAspirantBankDriver: func,
  updateAspirantDriverBank: func,
  driver: object,
  match: object,
};

const mapStateToProps = (state) => ({
  driver: state.drivers.driver,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAspirantBankDriver,
      updateAspirantDriverBank,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AspirantDriverBankAccount);
