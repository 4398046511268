import {
  STATISTICS_DRIVERS_SUCCESS,
  STATISTICS_MONTHLY_SUCCESS,
  STATISTICS_FINANCIAL_SUCCESS,
  STATISTICS_ROYALTIES_SUCCESS,
  STATISTICS_RIDES_SUCCESS,
  STATISTICS_RIDERS_SUCCESS,
  STATISTICS_INDICATORS_SUCCESS,
  STATISTICS_SET_INITIAL_DATE,
  STATISTICS_SET_FINAL_DATE,
  STATISTICS_SET_LOADING,
  STATISTICS_SET_NOT_LOADING,
} from "../actions/statistics";

const initialState = {
  loading: false,
  statisticsFilters: {
    initialDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    finalDate: new Date(),
  },
  statisticsMonthly: [],
  statisticsFinancial: {
    financial: {},
    requests_by_hour: [],
  },
  statisticsRoyalties: {},
  statisticsHours: [],
  statisticsRides: {},
  statisticsDrivers: {},
  statisticsIndicators: {
    total_active_users_month: 0,
    total_active_users_today: 0,
  },
  statisticsRiders: {
    total_with_rides: 0,
    total_whithout_rides: 0,
    total_incomplete_users: 0,
    total_new_users_today: 0,
    total_new_users_week: 0,
    total_new_users_month: 0,
    total_new_users_year: 0,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STATISTICS_DRIVERS_SUCCESS: {
      return {
        ...state,
        statisticsDrivers: action.statisticsDrivers,
      };
    }
    case STATISTICS_MONTHLY_SUCCESS: {
      return {
        ...state,
        statisticsMonthly: action.statisticsMonthly,
      };
    }
    case STATISTICS_FINANCIAL_SUCCESS: {
      return {
        ...state,
        statisticsFinancial: action.statisticsFinancial,
      };
    }
    case STATISTICS_ROYALTIES_SUCCESS: {
      return {
        ...state,
        statisticsRoyalties: action.statisticsRoyalties,
      };
    }

    case STATISTICS_RIDES_SUCCESS: {
      return {
        ...state,
        statisticsRides: action.statisticsRides,
      };
    }

    case STATISTICS_RIDERS_SUCCESS: {
      return {
        ...state,
        statisticsRiders: action.statisticsRiders,
      };
    }

    case STATISTICS_INDICATORS_SUCCESS: {
      return {
        ...state,
        statisticsIndicators: action.statisticsIndicators,
      };
    }

    case STATISTICS_SET_INITIAL_DATE: {
      return {
        ...state,
        statisticsFilters: {
          ...state.statisticsFilters,
          initialDate: action.initialDate,
        },
      };
    }

    case STATISTICS_SET_FINAL_DATE: {
      return {
        ...state,
        statisticsFilters: {
          ...state.statisticsFilters,
          finalDate: action.finalDate,
        },
      };
    }

    case STATISTICS_SET_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    case STATISTICS_SET_NOT_LOADING: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
};

export default reducer;
