import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { store } from '../store';
import { getCategories } from '../actions/categories';
import '../styles/css/drivers.css';

class CategoriesPage extends Component {

  static propTypes = {
    categories: PropTypes.array,
    getCategories: PropTypes.func
  }

  constructor(props) {
    super(props);

    this.renderItem = this.renderItem.bind(this);
    this.show = this.show.bind(this);
    this.add = this.add.bind(this);

    props.getCategories();
  }

  show(id) {
    store.dispatch(push(`/category/id/${id}`));
  }

  add() {
    store.dispatch(push(`/category/id/new`));
  }

  renderItem(item) {
    return (
      <tr key={item.id} style={{cursor: 'pointer'}} onClick={() => this.show(item.id)}>
        <td>{item.id}</td>
        <td>{item.name}</td>
        <td style={{width: 120}}>{item.percentage_moby} %</td>
        <td style={{width: 120}}>{item.price_base}</td>
        <td style={{width: 120}}>{item.price_minimum}</td>
        <td style={{width: 120}}>{item.price_km}</td>
        <td style={{width: 120}}>{item.price_minute}</td>
        <td style={{width: 120}}>{item.capacity}</td>
      </tr>
    )
  }

  render() {
    const { categories } = this.props;

    return (
      <div className="page-list">
        <div>
          <div className="header-buttons">
            <h3>Categorias</h3>
            <button className="btn btn-primary" onClick={this.add}>Adicionar Categoria</button>
          </div>
          <p style={{clear:'both'}} />

          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th style={{width: 50}}>ID</th>
                <th>Nome</th>
                <th>Porcentagem MobyGo</th>
                <th>Bandeira</th>
                <th>Mínimo</th>
                <th>Quilômetro</th>
                <th>Minuto</th>
                <th>Capacidade</th>
              </tr>
            </thead>
            <tbody>
              {
                categories.map(this.renderItem)
              }
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  categories: state.categories.all
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getCategories
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesPage)
