import Api from '../api';

export const GET_CATEGORY = 'GET_CATEGORY';
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS';
export const GET_CATEGORY_ERROR = 'GET_CATEGORY_ERROR';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR';

export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_ERROR = 'CREATE_CATEGORY_ERROR';

export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR';

export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR';

export const UPDATE_CATEGORY_PROPS = 'UPDATE_CATEGORY_PROPS';
export const CLEAR_CATEGORY = 'CLEAR_CATEGORY';

export const getCategories = () => async (dispatch, getState) => {
  dispatch({
    type: GET_CATEGORIES
  })

  try {
    let categories = await Api.categories();

    return dispatch({
      type: GET_CATEGORIES_SUCCESS,
      categories
    })
  } catch (error) {
    return dispatch({
      type: GET_CATEGORIES_ERROR,
      error
    })
  }
}

export const clearCategory = () => async (dispatch, getState) => {
  return dispatch({
    type: CLEAR_CATEGORY
  })
}

export const getCategory = id => async (dispatch, getState) => {
  dispatch({
    type: GET_CATEGORY
  })

  try {
    let category = await Api.getCategory(id);

    return dispatch({
      type: GET_CATEGORY_SUCCESS,
      category
    })
  } catch (error) {
    return dispatch({
      type: GET_CATEGORY_ERROR,
      error
    })
  }
}

export const createCategory = category => async (dispatch, getState) => {
  dispatch({
    type: CREATE_CATEGORY,
    category
  })

  try {
    category = await Api.createCategory(category);

    return dispatch({
      type: CREATE_CATEGORY_SUCCESS,
      category
    })
  } catch (error) {
    return dispatch({
      type: CREATE_CATEGORY_ERROR,
      error
    })
  }
}

export const updateCategory = category => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_CATEGORY,
    category
  })

  try {
    category = await Api.updateCategory(category);

    return dispatch({
      type: UPDATE_CATEGORY_SUCCESS,
      category
    })
  } catch (error) {
    return dispatch({
      type: UPDATE_CATEGORY_ERROR,
      error
    })
  }
}

export const deleteCategory = id => async (dispatch, getState) => {
  dispatch({
    type: DELETE_CATEGORY
  })

  try {
    await Api.deleteCategory(id);

    return dispatch({
      type: DELETE_CATEGORY_SUCCESS,
      id
    })
  } catch (error) {
    return dispatch({
      type: DELETE_CATEGORY_ERROR,
      error
    })
  }
}

export const updateCategoryProps = props => async (dispatch, getState) => {
  return dispatch({
    type: UPDATE_CATEGORY_PROPS,
    props
  })
}