import Api from "../api";

export const GET_DRIVERS = "GET_DRIVERS";
export const GET_DRIVERS_SUCCESS = "GET_DRIVERS_SUCCESS";
export const GET_DRIVERS_ERROR = "GET_DRIVERS_ERROR";

export const GET_DRIVER = "GET_DRIVER";
export const GET_DRIVER_SUCCESS = "GET_DRIVER_SUCCESS";
export const GET_DRIVER_ERROR = "GET_DRIVER_ERROR";

export const CREATE_DRIVER = "CREATE_DRIVER";
export const CREATE_DRIVER_SUCCESS = "CREATE_DRIVER_SUCCESS";
export const CREATE_DRIVER_ERROR = "CREATE_DRIVER_ERROR";

export const UPDATE_DRIVER = "UPDATE_DRIVER";
export const UPDATE_DRIVER_SUCCESS = "UPDATE_DRIVER_SUCCESS";
export const UPDATE_DRIVER_ERROR = "UPDATE_DRIVER_ERROR";

export const UPDATE_DRIVER_CAR = "UPDATE_DRIVER_CAR";

export const SET_DRIVER = "SET_DRIVER";
export const UPDATE_DRIVER_PROPS = "UPDATE_DRIVER_PROPS";

export const GET_BANK_ACCOUNT_BY_DRIVER = "GET_BANK_ACCOUNT_BY_DRIVER";
export const GET_BANK_ACCOUNT_BY_DRIVER_SUCCESS =
  "GET_BANK_ACCOUNT_BY_DRIVER_SUCCESS";
export const GET_BANK_ACCOUNT_BY_DRIVER_ERROR =
  "GET_BANK_ACCOUNT_BY_DRIVER_ERROR";

export const UPDATE_DRIVER_BANK = "UPDATE_DRIVER_BANK";
export const UPDATE_DRIVER_BANK_SUCCESS = "UPDATE_DRIVER_BANK_SUCCESS";
export const UPDATE_DRIVER_BANK_ERROR = "UPDATE_DRIVER_BANK_ERROR";

export const GET_DEBITS = "GET_DEBITS";
export const GET_DEBITS_SUCESS = "GET_DEBITS_SUCESS";

export const UNLOCK_DRIVER = "UNLOCK_DRIVER";

export const unlockDriver = (id) => async (dispatch) => {
  try {
    await Api.unlockDriver(id);
    dispatch({
      type: UNLOCK_DRIVER,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getDebits = (city_id) => async (dispatch, getState) => {
  dispatch({
    type: GET_DEBITS,
  });

  let debits = await Api.getDebits({ city_id });

  return dispatch({
    type: GET_DEBITS_SUCESS,
    debits,
  });
};

export const getDrivers = (requestParams) => async (dispatch, getState) => {
  dispatch({
    type: GET_DRIVERS,
  });

  let { city_id } = getState().preferences;

  try {
    let {
      results: drivers,
      params,
      pages,
      rides,
      total,
    } = await Api.getDrivers({ ...requestParams, city_id });
    //let debits = await Api.getDebits(city_id);
    //console.log('DEBITOSSSSSSSS', city_id)

    return dispatch({
      type: GET_DRIVERS_SUCCESS,
      payload: {
        drivers,
        params,
        pages,
        rides,
        total,
        //debits
      },
    });
  } catch (error) {
    console.log(error);
    return dispatch({
      type: GET_DRIVERS_ERROR,
      error,
    });
  }
};

export const getDriver = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_DRIVER,
    id,
  });

  try {
    let driver = await Api.getDriver(id);

    if (driver?.info?.address?.zipCode) {
      const hasHifen = driver?.info?.address?.zipCode.search("-");

      if (hasHifen === -1 && driver?.info?.address?.zipCode.length === 8) {
        driver.info.address.zipCode = `${driver.info.address.zipCode.substr(
          0,
          5
        )}-${driver.info.address.zipCode.substr(5, 3)}`;
      }
    }
    return dispatch({
      type: GET_DRIVER_SUCCESS,
      driver,
    });
  } catch (error) {
    return dispatch({
      type: GET_DRIVER_ERROR,
      error,
    });
  }
};

export const createDriver = (driver) => async (dispatch, getState) => {
  dispatch({
    type: CREATE_DRIVER,
    driver,
  });

  try {
    driver = await Api.createDriver(driver);

    return dispatch({
      type: CREATE_DRIVER_SUCCESS,
      driver,
    });
  } catch (error) {
    return dispatch({
      type: CREATE_DRIVER_ERROR,
      error,
    });
  }
};

export const updateDriver = (driver) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_DRIVER,
    driver,
  });

  try {
    driver = await Api.updateDriver(driver);

    return dispatch({
      type: UPDATE_DRIVER_SUCCESS,
      driver,
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_DRIVER_ERROR,
      error,
    });
  }
};

export const updateDriverProps = (props) => async (dispatch, getState) => {
  return dispatch({
    type: UPDATE_DRIVER_PROPS,
    props,
  });
};

export const setDriver = (driver) => async (dispatch, getState) => {
  return dispatch({
    type: SET_DRIVER,
    driver,
  });
};

export const getBankAccountByDriver =
  (driver_id) => async (dispatch, getState) => {
    dispatch({
      type: GET_BANK_ACCOUNT_BY_DRIVER,
      driver_id,
    });

    let account = {};

    try {
      let bankAccount = await Api.getDriverBankAccount(driver_id);

      if (bankAccount) {
        account = {
          id: bankAccount.id,
          type: bankAccount.type,
          bankNumber: bankAccount.bank_number,
          bankName: bankAccount.bank_number + " - " + bankAccount.bank_name,
          agencyNumber: bankAccount.agency_number,
          agencyCheckNumber: bankAccount.agency_check_number,
          accountNumber: bankAccount.account_number,
          accountCheckNumber: bankAccount.account_check_number,
          holder: {
            ...bankAccount.holder,
            fullname: bankAccount.holder.name,
          },
        };
      }
      return dispatch({
        type: GET_BANK_ACCOUNT_BY_DRIVER_SUCCESS,
        account,
      });
    } catch (error) {
      return dispatch({
        type: GET_BANK_ACCOUNT_BY_DRIVER_ERROR,
        error,
      });
    }
  };

export const updateDriverCar = (car) => async (dispatch) => {
  try {
    await Api.updateCar(car);
    dispatch({
      type: UPDATE_DRIVER_CAR,
      car,
    });
  } catch (error) {
    console.info(error);
  }
};

export const updateDriverBank = (driverId, bank) => async (dispatch) => {
  const bankAccount = {
    type: bank.type,
    bank_number: bank.bankNumber,
    bank_name: bank.bankName,
    agency_number: bank.agencyNumber,
    agency_check_number: bank.agencyCheckNumber,
    account_number: bank.accountNumber,
    account_check_number: bank.accountCheckNumber,
    holder: {
      ...bank.holder,
      fullname: bank.holder.name,
    },
  };

  dispatch({
    type: UPDATE_DRIVER_BANK,
    bankAccount,
  });

  try {
    await Api.updateDriverBankAccount(driverId, bankAccount);

    return dispatch({
      type: UPDATE_DRIVER_BANK_SUCCESS,
      driver,
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_DRIVER_BANK_ERROR,
      error,
    });
  }
};
