import React from "react";
import moment from "moment";

interface CustomDateInputProps {
  onClick: () => void;
  value: string;
  readOnly: boolean;
}

export const CustomDateInput: React.FC<CustomDateInputProps> = ({
  value,
  onClick,
  readOnly,
}) => {
  return (
    <div onClick={onClick}>
      <div className="input-group">
        <div className="input-group-prepend">
          <span
            className="input-group-text"
            id="validationTooltipUsernamePrepend"
          >
            <i className="far fa-calendar-alt" />
          </span>
        </div>
        <input
          type="text"
          className="form-control"
          value={moment(value).format("DD/MM/YYYY") || ""}
          style={{ cursor: "pointer" }}
          readOnly={readOnly}
        />
      </div>
    </div>
  );
};
