import "../styles/franchiseesAdd.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { push } from "react-router-redux";
import { FormGroup, Label, Input } from "reactstrap";
import { useDebounce } from "use-debounce";
import estadosBrasil from "../utils/estados-brasil";
import InputMask from "react-input-mask";

import moment from "moment";

import api from "../api";
import { store } from "../store";
import { validateCpfCnpj, validatePhone } from "../utils/string";
import { CustomDatePicker } from "components/customDatePiecker/CustomDatePiecker";

const newArray = [
  { name: "Mapa", value: "map" },
  { name: "Cupons", value: "promocodes" },
  { name: "Passageiros", value: "riders" },
  { name: "Motoristas", value: "drivers" },
  { name: "Cadastro Motorista", value: "aspirant_drivers" },
  { name: "Tarifas", value: "fares" },
  { name: "Viagens", value: "rides" },
  { name: "Notificações", value: "push_notifications" },
  { name: "Equipe", value: "admins" },
  { name: "Estatísticas", value: "analytics" },
  { name: "Franquia", value: "operation" },
];

const initialValues = {
  name: "",
  documentNumber: "",
  cpfCnpj: "",
  social: "",
  email: "",
  phone: "",
  cities: "",
};

export const FranchiseesAdd = () => {
  const [cities, setCities] = useState([]);
  const [values, setValues] = useState(initialValues);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [permissions, setPermissions] = useState({});
  const [states, setStates] = useState(estadosBrasil);

  const [adminExists, setAdminExists] = useState(false);
  const [adminEmail] = useDebounce(values.email, 700);

  useEffect(() => {
    if (adminEmail?.includes("@")) {
      api.adminExists(adminEmail).then(setAdminExists);
    } else {
      setAdminExists(false);
    }
  }, [adminEmail]);

  const handleCpfCnpj = e => {
    const { name, value } = e.target;

    const newValue = validateCpfCnpj(value);

    setValues({ ...values, [name]: newValue });
  };

  const handlePhone = e => {
    const { name, value } = e.target;

    const newValue = validatePhone(value);

    setValues({ ...values, [name]: newValue });
  };

  const HandleOnChange = e => {
    const { name, value } = e.target;

    setValues({ ...values, [name]: value });
  };

  const handlePermissions = e => {
    const { value, checked } = e.target;

    if (checked) {
      setPermissions({ ...permissions, [value]: true });
    } else {
      Object.keys(permissions).forEach(key => {
        if (key === value) {
          delete permissions[key];
          setPermissions({ ...permissions });
        }
      });
    }
  };

  const HandleOnChangeAddressInfo = e => {
    const { name, value } = e.target;

    setValues({
      ...values,
      address: { ...values?.address, [name]: value },
    });
  };

  const handleCreateFranchisees = async () => {
    try {
      const payload = {
        active: true,
        id: values.cities,
        company_name: values.social,
        document_number: values.documentNumber.replace(/[^0-9]/g, ""),
        phone: values.phone.replace(/[^0-9]/g, ""),
        admin: {
          name: values.name,
          email: values.email,
          password: password,
          phone: values.phone.replace(/[^0-9]/g, ""),
          birthdate: moment(values.birthdate).format("YYYY-MM-DD"),
          cpf: values.cpfCnpj.replace(/[^0-9]/g, ""),
          permissions,
          role_description: "Proprietário da franquia",
        },
        address: {
          street: values?.address?.street,
          street_number: values?.address?.street_number,
          zip_code: values?.address?.zip_code
            ?.replace("-", "")
            ?.replace(".", ""),
          district: values?.address?.district,
          city: values?.address?.city,
          state: values?.address?.state,
        },
      };

      if (
        !payload?.address?.street ||
        !payload?.address?.street_number ||
        !payload?.address?.zip_code ||
        !payload?.address?.district ||
        !payload?.address?.city ||
        !payload?.address?.state
      ) {
        toast.error("Todos os dados de endereço precisam ser informados");
        return;
      }

      if (!adminExists && password !== repeatPassword) {
        toast.error("Senhas diferentes");
        return;
      }

      await api.createFranchisees(payload);

      toast.success("Franquia criada com sucesso!");

      setTimeout(() => {
        store.dispatch(push(`/franchisees`));
      }, 2000);

      setValues({});
      setPassword("");
      setPermissions({});
      setRepeatPassword("");
    } catch (error) {
      console.log("Error ao criar a franquia");
      toast.error(error.message);
    }
  };

  const handleBirthChange = birthdate => {
    setValues({ ...values, birthdate });
  };

  useEffect(() => {
    const handleCities = async () => {
      const cities = await api.cities();

      setCities(cities);
    };

    handleCities();
  }, []);

  return (
    <div className="container__franchisees__add">
      <div className="wrapper">
        <div className="header">
          <h1>Adicionar franquia</h1>
        </div>

        <div className="main">
          <form>
            <div className="form__header">
              <h5>Dados da franquia</h5>
            </div>
            <div className="wrapper__form">
              <div className="form-group">
                <label>CPF/CNPJ</label>
                <input
                  type="text"
                  className="form-control"
                  name="documentNumber"
                  value={values.documentNumber}
                  onChange={handleCpfCnpj}
                />
              </div>

              <div className="form-group">
                <label>Razão Social</label>
                <input
                  type="text"
                  className="form-control"
                  name="social"
                  value={values.social}
                  onChange={HandleOnChange}
                />
              </div>
            </div>
          </form>
        </div>

        <div className="main">
          <form>
            <div className="form__header">
              <h5>Dados do franqueado</h5>
            </div>
            <div className="wrapper__form">
              <div className="form-group">
                <label>Nome Completo</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={values.name}
                  onChange={HandleOnChange}
                />
              </div>

              <div className="form-group">
                <label>CPF</label>
                <input
                  type="text"
                  className="form-control"
                  name="cpfCnpj"
                  value={values.cpfCnpj}
                  onChange={handleCpfCnpj}
                />
              </div>

              <div className="form-group">
                <label>Data de Nascimento: </label>
                <CustomDatePicker
                  showYearDropdown
                  dropdownMode="select"
                  date={values.birthdate}
                  maxDate={moment.utc().subtract(18, "years")}
                  dateDatefult={moment
                    .utc()
                    .subtract(18, "years")
                    .startOf("day")}
                  onChange={handleBirthChange}
                />
              </div>

              <div className="form-group">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={values.email}
                  onChange={HandleOnChange}
                />
              </div>

              <div className="form-group">
                <label>Telefone</label>
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  value={values.phone}
                  onChange={handlePhone}
                />
              </div>

              <div className="form-group">
                <label>Praça</label>
                <select
                  className="form-control custom-select"
                  name="cities"
                  value={values.cities}
                  onChange={HandleOnChange}
                >
                  <option value="" />
                  {cities.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="wrapper">
        <div className="main">
          <form>
            <div className="form__header">
              <h5>Endereço</h5>
            </div>
            <div className="wrapper__form">
              <div className="form-group">
                <label>Rua</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="100"
                  name="street"
                  value={values?.address?.street}
                  onChange={HandleOnChangeAddressInfo}
                />
              </div>
              <div className="form-group">
                <label>Número</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="30"
                  name="street_number"
                  value={values?.address?.street_number}
                  onChange={HandleOnChangeAddressInfo}
                />
              </div>
              <div className="form-group">
                <label>CEP</label>
                <InputMask
                  type="text"
                  className="form-control"
                  name="zip_code"
                  data-type="cep"
                  value={values?.address?.zip_code}
                  onChange={HandleOnChangeAddressInfo}
                  mask="99999-999"
                />
              </div>
              <div className="form-group">
                <label>Bairro</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="30"
                  name="district"
                  value={values?.address?.district}
                  onChange={HandleOnChangeAddressInfo}
                />
              </div>
              <div className="form-group">
                <label>Cidade</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="50"
                  name="city"
                  value={values?.address?.city}
                  onChange={HandleOnChangeAddressInfo}
                />
              </div>
              <div className="form-group">
                <label>Estado</label>
                <select
                  className="form-control custom-select"
                  name="state"
                  value={values?.address?.state}
                  onChange={HandleOnChangeAddressInfo}
                >
                  <option value="" />
                  {states.map(state => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>

      {!adminExists && (
        <div className="wrapper">
          <div className="main">
            <form>
              <div className="form__header">
                <h5>Senha do franqueado</h5>
              </div>
              <div className="wrapper__form">
                <div className="form-group">
                  <label>Senha</label>
                  <input
                    className="form-control"
                    type="text"
                    name="password"
                    placeholder="Senha"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                  />
                </div>

                <div className="form-group">
                  <label>Repetir senha</label>
                  <input
                    className="form-control"
                    type="text"
                    name="passwordRepeat"
                    placeholder="password"
                    value={repeatPassword}
                    onChange={e => setRepeatPassword(e.target.value)}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="wrapper">
        <div className="main">
          <form>
            <div className="form__header">
              <h5>Visão do franqueado</h5>
            </div>

            <h6>Selecione as abas do menu que o franqueado terá acesso:</h6>
            <div className="wrapper__form">
              <div className="content__checked">
                {newArray.map((item, index) => (
                  <FormGroup key={item.index} check inline className="check">
                    <Label check>
                      <Input
                        type="checkbox"
                        // checked={permissions[item] === item}
                        name={item.name}
                        value={item.value}
                        onChange={handlePermissions}
                      />
                      <span>{item.name}</span>
                    </Label>
                  </FormGroup>
                ))}
              </div>
            </div>
          </form>

          <div className="btn">
            <button onClick={handleCreateFranchisees}>Adicionar</button>
          </div>
        </div>
      </div>
    </div>
  );
};
