import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { push } from 'react-router-redux';
import { RouteWithSubRoutes } from '../';
import { store } from '../store';
import '../styles/css/driver-info.css';

class RiderPage extends Component {

  static propTypes = {
    rider: PropTypes.object,
    match: PropTypes.object,
    location: PropTypes.object
  }

  constructor(props) {
    super();

    this.showInfo = this.showInfo.bind(this)
    this.showRides = this.showRides.bind(this)
  }

  showInfo() {
    store.dispatch(push(`/riders/id/${this.props.match.params.id}/info`));
  }

  showRides() {
    store.dispatch(push(`/riders/id/${this.props.match.params.id}/rides`));
  }

  render() {
    const path = this.props.location.pathname.split('/').pop();

    const { rider } = this.props;

    return (
      <div>
        <div style={{display:'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <h3>
            {
              !!rider.name &&
                `${rider.name} ${rider.surname}`
            }
          </h3>
          <ul className="nav nav-pills" style={{ marginBottom: 30 }}>
            <li className="nav-item">
              <a className={`nav-link ${path == 'info' ? 'active' : ''}`} onClick={this.showInfo}>Informações</a>
            </li>
            <li className="nav-item">
              <a className={`nav-link ${path == 'rides' ? 'active' : ''}`} onClick={this.showRides}>Viagens</a>
            </li>
          </ul>
        </div>

        {
          this.props.routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  rider: state.riders.rider
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({

  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RiderPage)

