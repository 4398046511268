import {
  GET_CARS_INACTIVE,
  GET_CAR_INACTIVE,
  UPDATE_INACTIVE_CAR,
  DELETE_CAR,
} from "../actions/inactiveCars";

const initialState = {
  cars: [],
  selectedCar: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CARS_INACTIVE:
      return { ...state, cars: [...action.cars] };

    case GET_CAR_INACTIVE:
      return { ...state, selectedCar: action.car };

    case UPDATE_INACTIVE_CAR:
      return {
        ...state,
        selectedCar: action.car,
        cars: state.cars.filter(c => c.carId !== action.car.id),
      };

    case DELETE_CAR:
      return {
        ...state,
        cars: state.cars.filter(c => c.carId !== action.id),
      };

    default:
      return state;
  }
};

export default reducer;
