import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../styles/css/cupons.css";

const initialFilters = {
  search: '',
  category_id: '',
  status: '',
  order_by: '',
}

function FilterCupons({ categories, onSubmit }) {
  const [ filters, setFilters ] = useState(initialFilters)

  const handleChange = event => {
    setFilters({
      ...filters,
      [event.target.name]: event.target.value
    })
  }

  const handleSubmit = event => {
    event.preventDefault()

    onSubmit(filters)
  }

  return (
    <form className="form-row" onSubmit={handleSubmit}>
      <div className="form-group col-md-3">
        <label>Busca:</label>
        <input
          type="text"
          name="search"
          value={filters.search}
          onChange={handleChange}
          className="form-control"
          placeholder="Busca por nome do cupom"
        />
      </div>
      <div className="form-group col-md-2">
        <label>Categoria:</label>
        <select
          className="form-control custom-select"
          name="category_id"
          value={filters.category_id}
          onChange={handleChange}
        >
          <option value="" />
          {
            Array.isArray(categories) && categories.map(category => (
              <option
                key={category.id}
                value={category.id}
              >
                {category.name}
              </option>
            ))
          }
        </select>
      </div>
      <div className="form-group col-md-2">
        <label> Status:</label>
        <select
          className="form-control custom-select"
          name="status"
          onChange={handleChange}
        >
          <option value="" />
          {
            statuses.map(status => (
              <option
                key={status.id}
                value={status.id}
                selected={filters.status === status.id}
              >
                {status.name}
              </option>
            ))
          }
        </select>
      </div>

      <div className="form-group col-md-2">
        <label> Ordenar por:</label>
        <select
          className="form-control custom-select"
          name="order_by"
          onChange={handleChange}
        >
          <option value="" />
          {
            orderOptions.map(status => (
              <option
                key={status.id}
                value={status.id}
                selected={filters.status === status.id}
              >
                {status.name}
              </option>
            ))
          }
        </select>
      </div>

      <div className="form-group col-md-1">
        <label> &nbsp;&nbsp;</label>
        <button type="submit" className="btn btn-primary form-control">
          {" "}
          OK
        </button>
      </div>
    </form>
  );
}
export default FilterCupons;

const statuses = [
  {
    id: 'active',
    name: 'Ativo',
  },
  {
    id: 'paused',
    name: 'Pausado',
  },
  {
    id: 'inactive',
    name: 'Inativo',
  },
]

const orderOptions = [
  {
    id: 'used',
    name: 'Ativo',
  },
  {
    id: 'total',
    name: 'Total',
  },
  {
    id: 'discount_amount',
    name: 'Total desconto',
  },
  {
    id: 'created_at',
    name: 'Data de criação',
  },
]