import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "react-router-redux";
import {
  Navbar,
  //NavLink,
  NavbarBrand,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { NavLink } from "react-router-dom";

import { logout } from "../actions/auth";
import CityFilters from "../components/city-filters";

import imgLogo from "../assets/images/mobyGO.png";
import "../styles/css/login.css";
import { getCities } from "../actions/cities";

const NavMenu = () => {
  const dispatch = useDispatch();

  const [opened, setOpened] = useState(false);
  const [dropdownOpened, setDropdownOpened] = useState(false);
  const [analyticsOpened, setAnalyticsOpened] = useState(false);

  const user = useSelector(state => state.account);
  const currentCity = useSelector(state => state.preferences);

  const handleLogout = () => dispatch(logout());

  const goToHistoric = () => dispatch(push(`/notification`));

  const goToAdd = () => dispatch(push(`/notification-add`));

  const goToAnalyticsRides = () => dispatch(push("/analytics-rides"));

  const goToAnalyticsUsers = () => dispatch(push("/analytics-users"));

  const toggleOpen = () => setOpened(v => !v);

  const toggleDropDown = () => setDropdownOpened(v => !v);

  const toggleAnalytics = () => setAnalyticsOpened(v => !v);

  if (!user.isLoggedIn) {
    return <div />;
  }

  return (
    <Navbar className="navbar-dark bg-dark" expand="md">
      <NavbarBrand href="/" />

      <CityFilters />
      {/* <GlobalMenu /> */}

      {/*style={{display: opened ? 'block': 'none'}}*/}
      <Nav className={opened ? "isOpened" : null} style={{ display: "none" }}>
        {(user?.role === "admin" || user?.permissions?.map) && (
          <NavItem>
            <NavLink to="/" exact>
              Mapa
            </NavLink>
          </NavItem>
        )}

        {(user?.role === "admin" || user?.permissions?.analytics) && (
          <NavItem>
            <NavLink to="/statistics/indicators" exact={false}>
              Estatísticas
            </NavLink>
          </NavItem>
        )}

        {user?.role === "admin" && (
          <NavItem>
            <NavLink to="/ads" exact={false}>
              Anúncios
            </NavLink>
          </NavItem>
        )}

        {(user?.role === "admin" || user?.permissions?.promocodes) && (
          <NavItem>
            <NavLink to="/promocodes" exact={false}>
              Cupons
            </NavLink>
          </NavItem>
        )}

        {(user?.role === "admin" || user?.permissions?.drivers) && (
          <NavItem>
            <NavLink to="/drivers" exact={false}>
              Motoristas
            </NavLink>
          </NavItem>
        )}

        {(user?.role === "admin" || user?.permissions?.riders) && (
          <NavItem>
            <NavLink to="/riders" exact={false}>
              Passageiros
            </NavLink>
          </NavItem>
        )}

        {(user?.role === "admin" || user?.permissions?.aspirant_drivers) && (
          <NavItem>
            <NavLink to="/aspirant-drivers" exact={false}>
              Pré-Cadastro Motoristas
            </NavLink>
          </NavItem>
        )}

        {(user?.role === "admin" || user?.permissions?.aspirant_drivers) && (
          <NavItem>
            <NavLink to="/aspirant-cars" exact={false}>
              Pré-Cadastro Veículos
            </NavLink>
          </NavItem>
        )}

        {user?.role === "admin" && (
          <NavItem>
            <NavLink to="/categories" exact={false}>
              Categorias
            </NavLink>
          </NavItem>
        )}

        {user?.role === "admin" && (
          <NavItem>
            <NavLink to="/cities" exact={false}>
              Tarifas
            </NavLink>
          </NavItem>
        )}

        {user?.role === "city_manager" && user?.permissions?.fares && (
          <NavItem>
            <NavLink to={`/cities/id/${currentCity?.city_id}`} exact={false}>
              Tarifas
            </NavLink>
          </NavItem>
        )}

        {(user?.role === "admin" || user?.permissions?.rides) && (
          <NavItem>
            <NavLink to="/rides" exact={false}>
              Viagens
            </NavLink>
          </NavItem>
        )}

        {(user?.role === "admin" || user?.permissions?.push_notifications) && (
          <Dropdown isOpen={dropdownOpened} toggle={toggleDropDown}>
            <DropdownToggle caret>Notificações</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={goToHistoric}>Histórico</DropdownItem>
              <DropdownItem onClick={goToAdd}>Criar notificação</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        )}

        {user?.role === "admin" && (
          <NavItem>
            <NavLink to="/franchisees" exact={false}>
              Franquias
            </NavLink>
          </NavItem>
        )}
        {user?.role === "city_manager" && user?.permissions?.operation && (
          <NavItem>
            <NavLink
              to={`/franchisees-details/id/${currentCity?.city_id}`}
              exact={false}
            >
              Franquia
            </NavLink>
          </NavItem>
        )}
        {user?.role === "city_manager" && user?.permissions?.operation && (
          <NavItem>
            <NavLink
              to={`/franchisees-payment/id/${currentCity?.city_id}`}
              exact={false}
            >
              Pagamentos
            </NavLink>
          </NavItem>
        )}

        {user?.role === "admin" && (
          <Dropdown isOpen={analyticsOpened} toggle={toggleAnalytics}>
            <DropdownToggle caret>Analytics</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={goToAnalyticsRides}>Corridas</DropdownItem>
              <DropdownItem onClick={goToAnalyticsUsers}>Usuários</DropdownItem>
              {/* <DropdownItem onClick={goToAdd}>Passageiros</DropdownItem> */}
            </DropdownMenu>
          </Dropdown>
        )}

        {user?.role === "admin" && (
          <NavItem>
            <NavLink to="/team" exact={false}>
              Equipe
            </NavLink>
          </NavItem>
        )}

        <NavItem>
          <a href="javascript:;" onClick={handleLogout}>
            Sair
          </a>
        </NavItem>
      </Nav>
      <div className="Burguer" onClick={toggleOpen}>
        <i className="fas fa-bars" />
      </div>
    </Navbar>
  );
};

export default NavMenu;
