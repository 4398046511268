import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { push } from 'react-router-redux';
import { RouteWithSubRoutes } from '../';
import { store } from '../store';
import { getRiders } from '../actions/riders';
import {
  UserStatusLabels
} from '../api/types';
import Avatar from '../components/avatar';
import Paginator from '../components/paginator';
import RidersFilter from '../components/riders-filter';
import '../styles/css/riders.css';

class RidersPage extends Component {

  static propTypes = {
    riders: PropTypes.array,
    total: PropTypes.number,
    page: PropTypes.number,
    pages: PropTypes.number,
    getRiders: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.routes = props.routes;

    this.onChangePage = this.onChangePage.bind(this);
    this.onChangeParams = this.onChangeParams.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.add = this.add.bind(this);
    this.capSentence = this.capSentence.bind(this)

    this.state = {
      date: null
    }
  }

  componentDidMount() {
    this.props.getRiders(this.props.params);
  }

  onChangePage(page) {
    this.props.getRiders({ ...this.props.params, page, date: this.state.date});
  }

  show(id) {
    store.dispatch(push(`/riders/id/${id}/info`));
  }

  add() {
    store.dispatch(push(`/riders/create`));
  }

  onChangeParams(params) {
    let {date} = params
    this.setState({
      date
    })
    this.props.getRiders({ ...params, page: 1 })
  }

  capSentence(text) {
    let wordsArray = text.toLowerCase().split(' ')
    let capsArray = wordsArray.map(word=>{
      if(word) {
        return word[0].toUpperCase() + word.slice(1)
      } else {
        return ' '
      }
    })
    return capsArray.join(' ')
  }

  renderItem(item) {
    return (
      <tr key={item.id} style={{cursor: 'pointer'}} onClick={() => this.show(item.id)}>
        <td>{item.id.substring(0, 8)}</td>
        <td><Avatar source={item.avatar_small} /></td>
        <td>{item.name ? this.capSentence(`${item.name} ${item.surname}`) : ''}</td>
        <td>{item.phone}</td>
        <td>{item.city_name}</td>
        <td>{UserStatusLabels[item.status]}</td>
        <td>{item.n_rides}</td>
        {this.props.params.rides == 10 && <td>{item.userscancel}</td>}

        <td>{item.created_at ? moment(item.created_at).format('DD/MM/YY HH:mm') : ''}</td>
      </tr>
    )
  }


  getTotalRiders(riders) {
    let lenghtRidersWhoSignUp = riders.filter(rider => rider.name != "").length
    let lenghtRiderWhoNotSignUp = riders.filter(rider => rider.name == "").length

    return (
      <div>
        <p>Passageiros que se cadastraram: {lenghtRidersWhoSignUp}</p>
        <p>Passageiros que não se cadastraram: {lenghtRiderWhoNotSignUp}</p>
      </div>
    )
  }

  render() {
    const { match, riders } = this.props;

    return (
      <div className="page-list">

        {
          match.path === '/riders' && match.isExact &&
          <div>
            <div className="header-buttons">
              <h3>Passageiros</h3>
              <button className="btn btn-primary" onClick={this.add}>Adicionar</button>
            </div>
            <p style={{clear:'both'}} />

            <RidersFilter
              params={this.props.params}
              categories={this.props.categories}
              onSubmit={this.onChangeParams}
            />
            <div style={{height: 100}}>
              {riders && this.getTotalRiders(riders)}

            </div>

            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th style={{width: 50}}>ID</th>
                  <th style={{width: 60}}>&nbsp;</th>
                  <th>Nome</th>
                  <th>Telefone</th>
                  <th>Cidade</th>
                  <th>Status</th>
                  <th>Viagens</th>
                  {this.props.params.rides == 10 && <td>Cancelamento</td>}
                  <th>Entrou em</th>
                </tr>
              </thead>
              <tbody>
                {
                  riders.map(item => this.renderItem(item))
                }
              </tbody>
            </table>

            <Paginator
              page={this.props.page}
              pages={this.props.pages}
              onChange={this.onChangePage}
            />

            {
              !!this.props.total &&
                `Exibindo ${riders.length} de ${this.props.total}`
            }
          </div>
        }

        {
          this.routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))
        }
      </div>
    )
  }
}


const mapStateToProps = state => ({
  categories: state.categories.all,
  riders: state.riders.riders,
  page: state.riders.params.page,
  pages: state.riders.pages,
  total: state.riders.total,
  params: state.riders.params
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getRiders
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RidersPage)
