import React from "react";
import { object } from "prop-types";
import { push } from "react-router-redux";
import { SumaryComponent } from "./SumaryComponent/SumaryComponent";
import { WhiteDrawList } from "./WhiteDrawList/WhiteDrawList";
import "../../styles/franchisees-payment.scss";
import "bootstrap/dist/css/bootstrap.min.css";

import { store } from "../../store";

export const FranchiseesPayment = ({ match }) => {
  const { id } = match.params;

  const showOperation = () => {
    store.dispatch(push(`/franchisees-details/id/${id}`));
  };

  return (
    <div className="container__franchisees__payment">
      <div className="wrapper">
        <div className="header">
          <h1>
            Detalhes franqueados - <span>Pagamentos</span>
          </h1>

          <div className="btns">
            <button onClick={() => showOperation()}>Ver franquia</button>
          </div>
        </div>

        <div className="main">
          <hr />
          <SumaryComponent id={id} />
          <hr />
          <WhiteDrawList id={id} />
        </div>
      </div>
    </div>
  );
};

FranchiseesPayment.propTypes = {
  match: object,
};
