import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { push } from "react-router-redux";
import { store } from "../store";
import { getRides } from "../actions/rides";
import { listPromocodes } from "../actions/promocodes";
import Paginator from "../components/paginator";
import Rides from "../components/rides";
import RidesTotals from "../components/riders-totals";
import RidesFilter from "../components/rides-filter";
import "../styles/css/drivers.css";

const RidesPage = () => {
  const dispatch = useDispatch();

  const {
    categories,
    promocodes,
    rides,
    totals,
    page,
    pages,
    params,
    city_id,
  } = useSelector((state) => ({
    categories: state.categories.all,
    promocodes: state.promocodes.all,
    rides: state.rides.list.rides,
    totals: state.rides.list.totals,
    page: state.rides.list.page,
    pages: state.rides.list.pages,
    params: state.rides.list.params,
    city_id: state.preferences.city_id,
  }));

  useEffect(() => {
    dispatch(getRides(params));

    dispatch(listPromocodes());
  }, [city_id]);

  const onChangePage = (page) => {
    dispatch(getRides({ ...params, page, city_id }));
  };

  const onSelectRide = (id) => {
    store.dispatch(push(`/ride/id/${id}`));
  };

  const onChangeParams = (params) => {
    dispatch(getRides({ ...params, page: 1 }));
  };

  return (
    <div
      className="container-fluid"
      style={{ paddingLeft: 40, paddingRight: 40 }}
    >
      <div>
        <div className="header-buttons">
          <h3>Viagens</h3>
        </div>
        <p style={{ clear: "both" }} />

        <RidesFilter
          params={params}
          categories={categories}
          promocodes={promocodes}
          onSubmit={onChangeParams}
        />

        <RidesTotals totals={totals} />

        <Rides rides={rides} params={params} onSelect={onSelectRide} />

        <Paginator page={page} pages={pages} onChange={onChangePage} />

        {`Exibindo ${rides.length} de ${totals.n_rides}`}
      </div>
    </div>
  );
};

export default RidesPage;
