import Api from '../api';

export const GET_FARE = 'GET_FARE';
export const GET_FARE_SUCCESS = 'GET_FARE_SUCCESS';
export const GET_FARE_ERROR = 'GET_FARE_ERROR';

export const GET_FARES = 'GET_FARES';
export const GET_FARES_SUCCESS = 'GET_FARES_SUCCESS';
export const GET_FARES_ERROR = 'GET_FARES_ERROR';

export const CREATE_FARE = 'CREATE_FARE';
export const CREATE_FARE_SUCCESS = 'CREATE_FARE_SUCCESS';
export const CREATE_FARE_ERROR = 'CREATE_FARE_ERROR';

export const UPDATE_FARE = 'UPDATE_FARE';
export const UPDATE_FARE_SUCCESS = 'UPDATE_FARE_SUCCESS';
export const UPDATE_FARE_ERROR = 'UPDATE_FARE_ERROR';

export const DELETE_FARE = 'DELETE_FARE';
export const DELETE_FARE_SUCCESS = 'DELETE_FARE_SUCCESS';
export const DELETE_FARE_ERROR = 'DELETE_FARE_ERROR';

export const UPDATE_FARE_PROPS = 'UPDATE_FARE_PROPS';
export const CLEAR_FARE = 'CLEAR_FARE';

export const getFares = () => async (dispatch, getState) => {
  dispatch({
    type: GET_FARES
  })

  try {
    let fares = await Api.fares();

    return dispatch({
      type: GET_FARES_SUCCESS,
      fares
    })
  } catch (error) {
    return dispatch({
      type: GET_FARES_ERROR,
      error
    })
  }
}

export const clearFare = () => async (dispatch, getState) => {
  return dispatch({
    type: CLEAR_FARE
  })
}

export const getFare = id => async (dispatch, getState) => {
  dispatch({
    type: GET_FARE
  })

  try {
    let fare = await Api.getFare(id);

    return dispatch({
      type: GET_FARE_SUCCESS,
      fare
    })
  } catch (error) {
    return dispatch({
      type: GET_FARE_ERROR,
      error
    })
  }
}

export const createFare = fare => async (dispatch, getState) => {
  dispatch({
    type: CREATE_FARE,
    fare
  })

  try {
    fare = await Api.createFare(fare);

    let category = getState().categories.all.find(cat => cat.id == fare.category_id);
    if (category) {
      fare.name = category.name;
    }

    return dispatch({
      type: CREATE_FARE_SUCCESS,
      fare
    })
  } catch (error) {
    return dispatch({
      type: CREATE_FARE_ERROR,
      error
    })
  }
}

export const updateFare = fare => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_FARE,
    fare
  })

  try {
    fare = await Api.updateFare(fare);

    return dispatch({
      type: UPDATE_FARE_SUCCESS,
      fare
    })
  } catch (error) {
    return dispatch({
      type: UPDATE_FARE_ERROR,
      error
    })
  }
}

export const deleteFare = fare => async (dispatch, getState) => {
  dispatch({
    type: DELETE_FARE
  })

  try {
    await Api.deleteFare(fare.id);

    return dispatch({
      type: DELETE_FARE_SUCCESS,
      fare
    })
  } catch (error) {
    return dispatch({
      type: DELETE_FARE_ERROR,
      error
    })
  }
}

export const updateFareProps = props => async (dispatch, getState) => {
  return dispatch({
    type: UPDATE_FARE_PROPS,
    props
  })
}