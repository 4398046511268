import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export default class RidesTotals extends Component {
  static propTypes = {
    totals: PropTypes.object,
  };

  render() {
    let { totals } = this.props;
    let somatorio =
      Number(totals.credit_card_total) + Number(totals.cash_total);

    return (
      <div>
        <hr />
        <Totals>
          <h5>Sumário</h5>
          <div>
            <div>
              <br />
              Total Motorista:{" "}
              <span style={{ color: "#999" }}>
                R${" "}
                {Number(
                  parseFloat(totals.profit_total).toFixed(2)
                ).toLocaleString("pt-BR")}
              </span>
              <br />
              Total Franqueado:{" "}
              <span style={{ color: "#999" }}>
                R${" "}
                {Number(
                  parseFloat(totals.moby_debit_total).toFixed(2)
                ).toLocaleString("pt-BR")}
              </span>
              <br />
              Total:{" "}
              <span style={{ color: "#999" }}>
                R${" "}
                {Number(
                  somatorio && parseFloat(somatorio).toFixed(2)
                ).toLocaleString("pt-BR")}
              </span>
            </div>
            <div>
              Qtd de corridas:{" "}
              <span style={{ color: "#999" }}>
                {Number(totals.n_rides).toLocaleString("pt-BR")}
              </span>
              <br />
              Qtd finalizada:{" "}
              <span style={{ color: "#999" }}>
                {Number(totals.n_rides_status50).toLocaleString("pt-BR")}
              </span>
              <br />
              Vlr em dinheiro:{" "}
              <span style={{ color: "#999" }}>
                {Number(totals.cash_total).toLocaleString("pt-BR")}
              </span>
              <br />
              Vlr em cartão:{" "}
              <span style={{ color: "#999" }}>
                {Number(totals.credit_card_total).toLocaleString("pt-BR")}
              </span>
            </div>
            <div>
              Qtd de solicitações:{" "}
              <span style={{ color: "#999" }}>{totals.n_rides_status10}</span>
              <br />
              Qtd aguardando motorista:{" "}
              <span style={{ color: "#999" }}>{totals.n_rides_status20}</span>
              <br />
              Qtd motorista a caminho:{" "}
              <span style={{ color: "#999" }}>{totals.n_rides_status30}</span>
              <br />
              Qtd corrida iniciada:{" "}
              <span style={{ color: "#999" }}>{totals.n_rides_status40}</span>
            </div>
            <div>
              Qtd motorista cancelou:{" "}
              <span style={{ color: "#999" }}>
                {Number(totals.n_rides_status100).toLocaleString("pt-BR")}
              </span>
              <br />
              Qtd usuário cancelou:{" "}
              <span style={{ color: "#999" }}>
                {Number(totals.n_rides_status105).toLocaleString("pt-BR")}
              </span>
              <br />
              Qtd sem motorista - cancelado:{" "}
              <span style={{ color: "#999" }}>
                {Number(totals.n_rides_status110).toLocaleString("pt-BR")}
              </span>
              <br />
              Qtd pagamento cancelado:{" "}
              <span style={{ color: "#999" }}>
                {Number(totals.n_rides_status115).toLocaleString("pt-BR")}
              </span>
            </div>
            <div>
              Cupons utilizados:{" "}
              <span style={{ color: "#999" }}>
                {Number(totals.discount_rides).toLocaleString("pt-BR")}
              </span>
              <br />
              Cupons - Desconto aplicado:{" "}
              <span style={{ color: "#999" }}>
                R$ {Number(totals.discount_amount).toLocaleString("pt-BR")}
              </span>
              <br />
              Cupons - Receita:{" "}
              <span style={{ color: "#999" }}>
                R$ {Number(totals.discount_total).toLocaleString("pt-BR")}
              </span>
              <br />
            </div>
          </div>
        </Totals>
      </div>
    );
  }
}

const Totals = styled.table`
  width: 100%;
  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;
