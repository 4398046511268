import Api from "../api";
import { push } from "react-router-redux";
import { store, storePersistor } from "../store";

export const AUTH = {
  LOGIN: "Login",
  LOGIN_ERROR: "LoginError",
  LOGGED_IN: "LoggedIn",
  LOGOUT: "Logout",
  LOGGED_OUT: "LoggedOut",
};

export const login = (email, password) => async (dispatch, getState) => {
  dispatch({ type: AUTH.LOGIN });

  let user;
  try {
    user = await Api.login(email, password);

    localStorage.setItem("user", JSON.stringify(user));

    Api.setTokens(user.api_token, user.firebase_token);

    store.dispatch(push("/drivers"));
  } catch (error) {
    return dispatch({
      type: AUTH.LOGIN_ERROR,
      error,
    });
  }

  return dispatch({
    type: AUTH.LOGGED_IN,
    user,
  });
};

export const logout = () => dispatch => {
  console.log("clear");

  store.dispatch(push("/login"));
  localStorage.removeItem("user");
  storePersistor.purge();
  window.localStorage.clear();

  return Promise.resolve(
    dispatch({
      type: AUTH.LOGGED_OUT,
    })
  );
};
