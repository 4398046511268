import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";
import { produce } from "immer";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import { Button, FormGroup, Label, Input } from "reactstrap";
import { InputText } from "primereact/inputtext";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import hereApi from "../../api/here";
import { Form } from "./styles";
import { getCategories } from "../../actions/categories"
import { getPromocode, createPromocode, updatePromocode } from "../../actions/promocodes"
import "../../styles/css/driver-info.css";
import "../../styles/css/cupons.css";
import "../../components/buttonsCupons/styleComponent.css";
import { store } from "../../store";
import { push } from "react-router-redux";

const initialState = {
  id: null,
  code: '',
  description: '',
  discount: 0,
  max_per_user: 1,
  total: 1000,
  cities: [],
  places: [],
  categories: [],
  start_at: null,
  end_at: null,
};

const Promocode = ({ id }) => {
  const dispatch = useDispatch()

  const {
    account,
    city_id,
    cities,
    categories,
  } = useSelector(state => ({
    account: state.account,
    city_id: state.preferences.city_id,
    cities: state.cities.all,
    categories: state.categories.all,
  }))

  const [promocode, setPromocode] = useState(initialState)
  const [busy, setBusy] = useState(false)


  useEffect(() => {
    if (!categories || !categories.length) {
      dispatch(getCategories())
    }
  }, [])

  useEffect(() => {
    if (!id) {
      setPromocode(initialState)
    }
    else {
      dispatch(getPromocode(id))
        .then(({ promocode, error }) => {
          if (error) {
            toast.error('Não encontrado')
          }

          setPromocode(promocode || initialState)
        })
    }
  }, [ id ])

  const handleInputChange = ({ target }) => {
    const { name, type, checked } = target;
    let value = target.value;

    if (name === 'discount') {
      value = Math.min(Math.max(0, parseInt(value.substr(0, 2))), 40);
    } else if (['max_per_user', 'total'].includes(name)) {
      value = Math.max(0, parseInt(value))
    } else if (name === 'code') {
      value = value.toUpperCase()
        .replace(/[^A-Z0-9]/g, '')
        .substr(0, 12)
    }

    setPromocode({
      ...promocode,
      [name]: type === "checkbox"
        ? !!checked
        : value,
    })
  }

  const handleSubmit = async e => {
    e.preventDefault();

    if (busy) {
      return;
    }
    if (promocode.code.length < 5) {
      return toast.error('O código promocional deve conter no mínimo 5 catecteres')
    }
    if (!moment(promocode.end_at).isValid()) {
      return toast.error('Escolha uma data de validade do promocode')
    }
    if (promocode.discount < 1) {
      return toast.error('Desconto inválido')
    }
    if (promocode.categories.length < 1) {
      return toast.error('Selecione pelo menos uma categoria')
    }
    if (!promocode.description) {
      return toast.error('Escolha uma descrição para o promocode')
    }
    setBusy(true);

    const exec = data => {
      return dispatch(
        promocode.id
          ? updatePromocode(data)
          : createPromocode(data)
      )
    }

    const { error } = await exec({
      ...promocode,
      cities: account.role === 'city_manager'
        ? [ city_id]
        : promocode.cities.map(c => c.id),
    });

    if (error) {
      toast.error(error.message);
    } else {
      toast.success(`Promocode ${promocode.id ? 'atualizado' : 'criado'} com sucesso`);

      store.dispatch(push('/promocodes'));
    }
    setBusy(false);
  }

  const handleChangeCategory = ({ target }) => {
    setPromocode(
      produce(promocode, promocode => {
        if (target.checked) {
          promocode.categories.push(Number(target.value))
        } else {
          promocode.categories = promocode.categories.filter(id => id !== Number(target.value))
        }
      })
    )
  }

  const handleChangeCities = cities => {
    setPromocode({
      ...promocode,
      cities,
    })
  }

  const handleChangeAddresses = type => places => {
    if (!Array.isArray(places)) {
      places = []
    }

    setPromocode({
      ...promocode,
      places: [
        ...promocode.places.filter(p => p.type !== type),
        ...places,
      ],
    })
  }

  const handleSearchAddress = type => async (q, callback) => {
    const { data } = await hereApi.get("geocode", {
      params: {
        [atob(`YXBpS2V5`)]: atob(`Z2VIczFUbXU0LW55QVVLaEFBbm82dlo0TEtJTWREUUNTNmdDenM5MGtINA==`),
        q: q,
        in: "countryCode:BRA",
        qq: "country=Brasil",
      },
    });

    if (!data) callback([]);

    callback(
      data.items.map(({ title, position }) => ({
        name: title,
        location: {
          latitude: position.lat,
          longitude: position.lng,
        },
        type,
      }))
    )
  }

  return (
    <Form onSubmit={handleSubmit}>
      {
        account?.role === 'admin' &&
          <div className="form-group">
            <label> Cidades</label>
            <Select
              placeholder="Cidades"
              isMulti
              name="cities"
              options={cities}
              onChange={handleChangeCities}
              value={promocode.cities}
              getOptionLabel={c => c.name}
              getOptionValue={c => c.id}
              className="basic-multi-select focus"
            />
          </div>
      }

      <div className="form-group">
        <label htmlFor="discount">Porcentagem de desconto (%)</label>
        <div className="input-group mb-2 ">
          <div className="input-group-append inputPrime">
            <div className="input-group-text">%</div>
          </div>
          <input
            type="number"
            className="form-control"
            id="discount"
            name="discount"
            max="40"
            onChange={handleInputChange}
            value={promocode.discount}
            readOnly={!!promocode.id}
          />
        </div>
        <p style={{ fontSize: 13 }}>
          <b>OBS.:</b>(A % de cupom deve ser <b>igual</b> ou <b>menor</b> da
          taxa da moby nas cidades selecionadas.){" "}
        </p>
      </div>

      <div className="form-group">
        <label htmlFor="name">Código do Cupom</label>
        <input
          type="text"
          className="form-control"
          maxLength="15"
          id="code"
          name="code"
          onChange={handleInputChange}
          value={promocode.code}
          readOnly={!!promocode.id}
        />
      </div>

      <div className="form-group">
        <label htmlFor="description">Descrição</label>
        <textarea
          type="textarea"
          className="form-control"
          maxLength="200"
          id="description"
          name="description"
          rows="4"
          onChange={handleInputChange}
          value={promocode.description}
        />
      </div>

      <div className="form-group ">
        <div className="category">
          <label htmlFor="checkCateg"> Categorias</label>
        </div>
        <div className="display ">
          {
            categories.map(category => (
              <FormGroup
                key={category.id}
                check
                inline
                className="check"
              >
                <Label check>
                  <Input
                    type="checkbox"
                    checked={promocode.categories.includes(category.id)}
                    value={category.id}
                    onChange={handleChangeCategory}
                  />
                  {category.name}
                </Label>
              </FormGroup>
            ))
          }
        </div>
      </div>

      <div className="form-group">
        <div className="dataContainer">
          <FormGroup>
            <Label htmlFor="start_at">Data de início</Label>
            {/*<DatePicker
              showYearDropdown
              maxDate={moment.utc().subtract(18, "years")}
              dropdownMode="select"
              customInput={<DateInput />}
              selected={promocode.start_at}
              onChange={handleInputChange}
            />*/}

            <Input
              type="date"
              name="start_at"
              id="start_at"
              placeholder="date placeholder"
              onChange={handleInputChange}
              value={moment(promocode.start_at).format('YYYY-MM-DD')}
            />
          </FormGroup>

          <FormGroup style={{ marginLeft: 20 }}>
            <Label htmlFor="end_at">Data de término</Label>
            <Input
              type="date"
              name="end_at"
              id="end_at"
              placeholder="date placeholder"
              onChange={handleInputChange}
              value={moment(promocode.end_at).format('YYYY-MM-DD')}
            />
          </FormGroup>
        </div>
      </div>

      {
        account?.role === 'admin' &&
          <>
            <div className="form-group">
              <label>Origem </label>
              <AsyncSelect
                placeholder=""
                isMulti
                cacheOptions
                name="origins"
                getOptionLabel={c => c.name}
                getOptionValue={c => c}
                value={promocode.places.filter(p => p.type === 'origin')}
                loadOptions={handleSearchAddress('origin')}
                onChange={handleChangeAddresses('origin')}
                className="basic-multi-select"
                classNamePrefix="Cidades"
              />
            </div>

            <div className="form-group">
              <label>Destino </label>
              <AsyncSelect
                placeholder=""
                isMulti
                cacheOptions
                name="destinations"
                getOptionLabel={c => c.name}
                getOptionValue={c => c}
                value={promocode.places.filter(p => p.type === 'destination')}
                loadOptions={handleSearchAddress('destination')}
                onChange={handleChangeAddresses('destination')}
                className="basic-multi-select"
                classNamePrefix="Cidades"
              />
            </div>
          </>
      }

      <div className="form-group">
        <label htmlFor="total">Limite de Resgates</label>
        <input
          type="number"
          className="form-control"
          id="total"
          name="total"
          onChange={handleInputChange}
          value={promocode.total}
        />
      </div>

      <div className="form-group">
        <label htmlFor="max_per_user">
          Limite de utilização por usuário (Individual)
        </label>
        <input
          type="number"
          className="form-control"
          id="max_per_user"
          name="max_per_user"
          onChange={handleInputChange}
          value={promocode.max_per_user}
        />
      </div>

      {
        !!promocode.id &&
          <>
            <div className="form-group">
              <label htmlFor="descCupom" readOnly>
                Receita das viagens com o promocode
              </label>
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon">R$</span>
                <InputText className="textValue form-control focus" readOnly value={(promocode.received_amount / 100).toFixed(2)} />
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="inputValor">
                Total de desconto aplicado
              </label>
              <div className="p-inputgroup">
                <span className="p-inputgroup-addon">R$</span>
                <InputText className="textValue form-control focus" readOnly value={(promocode.discount_amount / 100).toFixed(2)} />
              </div>
            </div>
          </>
      }

      <div>
        <div className="containerButton">
          <Link to={"/promocodes"}>
            <button
              type="button"
              className="btn btn-secondary pull-left btnCancel"
            >
              Cancelar
            </button>
          </Link>
          <Button
            className="btnCriar"
            type="submit"
            color="success"
          >
            Salvar
          </Button>
        </div>
      </div>

      <div className="containerButtonUpdate" style={{ display: "none" }}>
        <Link to={"/promocodes"}>
          <button
            type="button"
            className="btn btn-secondary pull-left btnCancel"
          >
            Cancelar
          </button>
        </Link>

        <button
          type="submit"
          className="btn btn-primary pull-left btnCancel"
        >
          Salvar
        </button>
      </div>
    </Form>
  );
};


export default Promocode
