import React from 'react';
import { Checkbox, CheckboxGroup } from 'rsuite';
import { RadioWrapper } from './style'


const CheckBoxDaysWeek = props => {
  return (
    <RadioWrapper>
      <CheckboxGroup onChange={props.handleDaysWeekCheckbox} inline name="checkboxList">
        <Checkbox value="0">Domingo</Checkbox>
        <Checkbox value="1">Segunda</Checkbox>
        <Checkbox value="2">Terça</Checkbox>
        <Checkbox value="3">Quarta</Checkbox>
        <Checkbox value="4">Quinta</Checkbox>
        <Checkbox value="5">Sexta</Checkbox>
        <Checkbox value="6">Sábado</Checkbox>
      </CheckboxGroup>
    </RadioWrapper>
  )}

export default CheckBoxDaysWeek;
