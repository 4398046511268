import Api from '../api'

export const GET_ANALYTICS_USERS = 'GET_ANALYTICS_USERS'
export const GET_ANALYTICS_USERS_SUCCESS = 'GET_ANALYTICS_USERS_SUCCESS'
export const GET_ANALYTICS_USERS_ERROR = 'GET_ANALYTICS_USERS_ERROR'

export const GET_ANALYTICS_USERS_RIDERS = 'GET_ANALYTICS_USERS_RIDERS'
export const GET_ANALYTICS_USERS_RIDERS_SUCCESS = 'GET_ANALYTICS_USERS_RIDERS_SUCCESS'
export const GET_ANALYTICS_USERS_RIDERS_ERROR = 'GET_ANALYTICS_USERS_RIDERS_ERROR'


export const getAnalyticsUsers = params => async dispatch => {
  dispatch({
    type: GET_ANALYTICS_USERS
  })

  try {
    let users = await Api.listUsersAnalytics(params)
    dispatch({
      type: GET_ANALYTICS_USERS_SUCCESS,
      users
    })
  } catch (error) {
    dispatch({
      type: GET_ANALYTICS_USERS_ERROR,
      error
    })
  }
}


// NÂO USAR
export const getAnalyticsUsersRiders = params => async dispatch => {
  dispatch({
    type: GET_ANALYTICS_USERS_RIDERS
  })

  try {
    let drivers = await Api.listUsersAnalytics(params)
    dispatch({
      type: GET_ANALYTICS_USERS_RIDERS_SUCCESS,
      drivers
    })
  } catch (error) {
    dispatch({
      type: GET_ANALYTICS_USERS_RIDERS_ERROR,
      error
    })
  }
}