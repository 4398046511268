import React, { useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import { toast } from 'react-toastify';
import { createNotification } from '../../../actions/push-notification'
import { FormGroup, InputMessage, Input, ButtonPicker, TextEmojiWrapper, ButtonSend } from './style';

const Form = props => {
  const account = useSelector(state => state.account);
  const city_id = useSelector(state => state.preferences.city_id);
  const [emojiPickerState, SetEmojiPicker] = useState(false);

  let emojiPicker;
  if (emojiPickerState) {
    emojiPicker = (
      <Picker
        title="Pick your emoji…"
        emoji="point_up"
        name="message"
        onClick={emoji => props.emoji(emoji.native)}
      />
    );
  }

  let buttonSubmit;
  if (props.showButton) {
    buttonSubmit = <ButtonSend type="submit"> Enviar notificação</ButtonSend>
  }

  function triggerPicker(event) {
    event.preventDefault();
    SetEmojiPicker(!emojiPickerState);
  }

  const handleChange = async e => {
    e.preventDefault();

    const notification = { ...props.notification }

    if (account.role === 'city_manager' && !notification.cidades?.length) {
      notification.cidades = [city_id]
    }

    if(notification.audiencia == 3 || notification.audiencia ==4 ) {
      if(notification.sendOne.length == 0) {
        return toast.error('Adicione o id do usuário!')
      }
    }

    const { error } = await props.createNotification(notification);
    if (error) {
      toast.error('Falha ao enviar a notificação: ' + error)
      console.log('error',error)
      return
    }

    toast.success('Notificação enviada com sucesso')
  }

  return (
    <FormGroup onSubmit={handleChange}>
      <TextEmojiWrapper>
        <Input
          onChange={props.handleInputChange}
          name={'title'}
          placeholder="Digite o título"
          value={props.notification.title}
        />
        <ButtonPicker onClick={triggerPicker}>😁</ButtonPicker>
      </TextEmojiWrapper>
      <InputMessage
        onChange={props.handleInputChange}
        name={'message'}
        placeholder="Digite a mensagem"
        value={props.notification.message}
      />
      {emojiPicker}
      {buttonSubmit}

    </FormGroup>
  )
}



const mapStateToProps = state => ({

});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    createNotification
  }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form);
