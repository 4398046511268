import {
  LIST_ADS_SUCCESS,
  GET_ADS_SUCCESS,
  CREATE_ADS_SUCCESS,
  UPDATE_ADS_SUCCESS,
  FILTER_ADS_BY_STATUS
} from '../actions/ads';

const initialState = {
  all: [],
  statusFilter: 1
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_ADS_SUCCESS : {
      console.log('SUCCESS', action.all)
      return { ...state, all: action.all }
    }

    case FILTER_ADS_BY_STATUS : {
      return { ...state, statusFilter: action.status }
    }

    case GET_ADS_SUCCESS : {
      let all = state.all.filter(({ id }) => id != action.ads.id);
      all.push(action.ads);

      return { ...state, all }
    }

    case CREATE_ADS_SUCCESS : {
      let all = state.all.filter(({ id }) => id != action.ads.id);
      all.unshift(action.ads);

      return { ...state, all }
    }

    case UPDATE_ADS_SUCCESS : {
      let all = state.all.map(ads => {
        return ads.id == action.ads.id
          ? action.ads
          : ads
      })

      return { ...state, all }
    }

    default:
      return state;
  }
}

export default reducer
