import Api from '../api';

export const LIST_ADS = 'LIST_ADS';
export const LIST_ADS_ERROR = 'LIST_ADS_ERROR';
export const LIST_ADS_SUCCESS = 'LIST_ADS_SUCCESS';

export const GET_ADS = 'GET_ADS';
export const GET_ADS_ERROR = 'GET_ADS_ERROR';
export const GET_ADS_SUCCESS = 'GET_ADS_SUCCESS';

export const CREATE_ADS = 'CREATE_ADS';
export const CREATE_ADS_ERROR = 'CREATE_ADS_ERROR';
export const CREATE_ADS_SUCCESS = 'CREATE_ADS_SUCCESS';

export const UPDATE_ADS = 'UPDATE_ADS';
export const UPDATE_ADS_ERROR = 'UPDATE_ADS_ERROR';
export const UPDATE_ADS_SUCCESS = 'UPDATE_ADS_SUCCESS';

export const REMOVE_ADS = 'REMOVE_ADS';
export const REMOVE_ADS_ERROR = 'REMOVE_ADS_ERROR';
export const REMOVE_ADS_SUCCESS = 'REMOVE_ADS_SUCCESS';

export const FILTER_ADS_BY_STATUS = 'FILTER_ADS_BY_STATUS';

export const filterAdsByStatus = status => async (dispatch, getState) => {
  return dispatch({
    type: FILTER_ADS_BY_STATUS,
    status
  })
}

export const listAds = () => async (dispatch, getState) => {
  dispatch({
    type: LIST_ADS
  })

  try {
    let all = await Api.listAds();

    return dispatch({
      type: LIST_ADS_SUCCESS,
      all
    })
  } catch (error) {
    return dispatch({
      type: LIST_ADS_ERROR,
      error
    })
  }
}

export const getAds = id => async (dispatch, getState) => {
  dispatch({
    type: GET_ADS,
    id
  })

  try {
    let ads = await Api.getAds(id);

    return dispatch({
      type: GET_ADS_SUCCESS,
      ads
    })
  } catch (error) {
    return dispatch({
      type: GET_ADS_ERROR,
      error
    })
  }
}

export const createAds = ads => async (dispatch, getState) => {
  dispatch({
    type: CREATE_ADS
  })

  try {
    ads = await Api.createAds(ads);

    return dispatch({
      type: CREATE_ADS_SUCCESS,
      ads
    })
  } catch (error) {
    return dispatch({
      type: CREATE_ADS_ERROR,
      error
    })
  }
}

export const updateAds = ads => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_ADS
  })

  try {
    ads = await Api.updateAds(ads);

    return dispatch({
      type: UPDATE_ADS_SUCCESS,
      ads
    })
  } catch (error) {
    return dispatch({
      type: UPDATE_ADS_ERROR,
      error
    })
  }
}

export const deleteAds = id => async (dispatch, getState) => {
  dispatch({
    type: REMOVE_ADS,
    id
  })

  try {
    await Api.deleteAds(id);

    return dispatch({
      type: REMOVE_ADS_SUCCESS,
      id
    })
  } catch (error) {
    return dispatch({
      type: REMOVE_ADS_ERROR,
      error
    })
  }
}
