import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CurrencyFormat from 'react-currency-format';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  createCategory,
  updateCategory,
  updateCategoryProps,
  getCategory,
  deleteCategory,
  clearCategory
} from '../actions/categories';
import '../styles/css/driver-info.css';


class CategoryPage extends Component {

  static propTypes = {
    createCategory: PropTypes.func,
    updateCategory: PropTypes.func,
    updateCategoryProps: PropTypes.func,
    getCategory: PropTypes.func,
    deleteCategory: PropTypes.func,
    clearCategory: PropTypes.func,
    category: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
    categories: PropTypes.array
  }

  constructor(props) {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.save = this.save.bind(this);
    this.remove = this.remove.bind(this);
    this.close = this.close.bind(this);
  }

  componentDidMount() {
    let { id } = this.props.match.params;

    if (id && id !== 'new') {
      this.props.getCategory(id)
    } else {
      this.props.clearCategory()
    }
  }


  validate() {
    let { category } = this.props;

    if (category.name.length < 3) {
      return toast.warn('Nome da categoria inválido');
    }

    if (Number(category.percentage_moby) < 0 || Number(category.percentage_moby) > 50) {
      return toast.warn('Porcentagem MobyGo inválida');
    }

    if (Number(category.price_base) < 1) {
      return toast.warn('Preço da bandeira inválido');
    }

    if (Number(category.price_minimum) < 1) {
      return toast.warn('Preço mínimo inválido');
    }

    if (Number(category.price_km) < 0.1) {
      return toast.warn('Preço por quilômetro inválido');
    }

    if (Number(category.price_minute) < 0.1) {
      return toast.warn('Preço por minuto inválido');
    }

    return true;
  }


  async save() {
    if (this.validate() !== true) {
      return;
    }

    let { category } = this.props;
    category = {
      ...category,
      percentage_moby: Number(category.percentage_moby),
      price_moby: 0,
      price_base: Number(category.price_base),
      price_minimum: Number(category.price_minimum),
      price_km: Number(category.price_km),
      price_minute: Number(category.price_minute)
    }
    let rs;

    if (category.id) {
      rs = await this.props.updateCategory(category);
    } else {
      rs = await this.props.createCategory(category);
    }

    let { error } = rs;
    if (error) {
      toast.error(
        error.name == 'ValidationError'
          ? error.message
          : 'Erro ao salvar a categoria'
      )
    } else {
      toast.success(category.id ? 'Atualizado!' : 'Criado com sucesso')
    }
  }

  async remove() {
    if (window.confirm('Deseja realmente excluir esta categoria?')) {
      let { error } = await this.props.deleteCategory(this.props.category.id)
      if (error) {
        toast.error('Erro ao remover a categoria')
      } else {
        toast.success('Categoria removida com sucesso');
        this.close();
      }
    }
  }

  close() {
    this.props.history.goBack();
  }

  handleChange({ target }) {
    const getAttr = attr => {
      let item = target.attributes.getNamedItem(attr)
      return item ? item.value : null;
    }

    let type = getAttr('data-type');
    let prop = getAttr('data-name');
    let value = target.value;
    if (type == 'currency') {
      value = Number(value.replace(/[^0-9.]/g, '')).toFixed(2);
    } else if (type == 'integer') {
      value = parseInt(value.replace(/[^0-9]/g, ''), 10);
    }

    this.props.updateCategoryProps({[prop]: value})
  }

  handleCurrencyChange(props) {
    this.props.updateCategoryProps(props)
  }

  render() {
    const { category } = this.props;

    return (
      <div style={{ width: 500, marginLeft: 'auto', marginRight: 'auto' }}>
        <div className="card bg-light">
          <div className="card-header">Categoria</div>
          <div className="card-body">
            <form>
              {
                !!category.id &&
                  <div className="form-group">
                    <label>ID</label>
                    <input
                      readOnly
                      className="form-control-plaintext"
                      value={category.id}
                    />
                  </div>
              }
              <div className="form-group">
                <label htmlFor="inputName">Nome</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="15"
                  id="inputName"
                  data-name="name"
                  value={category.name}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label>Capacidade</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="3"
                  data-name="capacity"
                  value={category.capacity}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label>Porcentagem MobyGo</label>
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">%</div>
                  </div>
                  <input
                    className="form-control"
                    data-name="percentage_moby"
                    data-type="integer"
                    maxLength={2}
                    onChange={this.handleChange}
                    value={category.percentage_moby}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Bandeira</label>
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">R$</div>
                  </div>
                  <CurrencyInput
                    name="price_base"
                    onChange={this.handleCurrencyChange}
                    value={category.price_base}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Preço mínimo</label>
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">R$</div>
                  </div>
                  <CurrencyInput
                    name="price_minimum"
                    onChange={this.handleCurrencyChange}
                    value={category.price_minimum}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>KM Rodado</label>
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">R$</div>
                  </div>
                  <CurrencyInput
                    name="price_km"
                    onChange={this.handleCurrencyChange}
                    value={category.price_km}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Minuto</label>
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">R$</div>
                  </div>
                  <CurrencyInput
                    name="price_minute"
                    onChange={this.handleCurrencyChange}
                    value={category.price_minute}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-success pull-left"
            onClick={this.save}
          >Salvar</button>

          {
            !!category.id &&
              <button
                type="button"
                className="btn btn-danger pull-left"
                onClick={this.remove}
              >Remover</button>
          }

          <button
            type="button"
            className="btn btn-secondary pull-left"
            data-dismiss="modal"
            onClick={this.close}
          >Cancelar</button>
        </Modal.Footer>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  category: state.categories.current,
  categories: state.categories.all
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    createCategory,
    updateCategory,
    updateCategoryProps,
    getCategory,
    deleteCategory,
    clearCategory,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryPage)

export class CurrencyInput extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ])
  }

  render() {
    return (
      <CurrencyFormat
        allowNegative={false}
        fixedDecimalScale
        className="form-control"
        decimalScale={2}
        value={this.props.value}
        isAllowed={({ floatValue }) => {
          return floatValue < 99
        }}
        thousandSeparator="none"
        onValueChange={({ formattedValue }) => {
          this.props.onChange({ [this.props.name]: formattedValue })
        }}
      />
    )
  }
}
