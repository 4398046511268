import { AUTH } from '../actions/auth';
import {SET_CITY} from '../actions/preferences';

const initialState = {
  city_id: null,
  city_name: null,
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case AUTH.LOGGED_IN : {
      const cities = action.user.cities

      if (!cities?.length) {
        return state
      }

      return {
        ...state,
        city_id: cities[0].id,
        city_name: cities[0].name
      }
    }

    case SET_CITY:
      return {
        ...state,
        city_id: action.city_id,
        city_name: action.city_name
      }

    default:
      return state
  }
}

export default reducer
