import React, { CSSProperties } from "react";
import { CircleLoader } from "react-spinners";

type SpinnerType = {
  loading: boolean;
  color?: string;
  size?: number;
};

const Spinner: React.FC<SpinnerType> = ({
  loading = false,
  color = "#50efb5",
  size = 150,
}) => {
  return (
    <div className="sweet-loading">
      <CircleLoader
        color={color}
        loading={loading}
        size={size}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default Spinner;
