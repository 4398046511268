import {
  GET_FARE,
  GET_FARE_SUCCESS,
  GET_FARES_SUCCESS,
  UPDATE_FARE_PROPS,
  CREATE_FARE_SUCCESS,
  UPDATE_FARE_SUCCESS,
  DELETE_FARE_SUCCESS,
  CLEAR_FARE
} from '../actions/fares';

const initialState = {
  all: [],
  current: {
    id: null,
    city_id: null,
    category_id: null,
    price_moby: 0,
    price_base: 0,
    price_minimum: 0,
    price_km: 0,
    price_minute: 0
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FARES_SUCCESS :
      return { ...state, all: action.fares }

    case GET_FARE_SUCCESS :
      return { ...state, current: action.fare }

    case GET_FARE :
    case CLEAR_FARE :
      return { ...state, current: { ...initialState.current }}

    case UPDATE_FARE_PROPS :
      return { ...state, current: { ...state.current, ...action.props }}

    case CREATE_FARE_SUCCESS : {
      let all = [ action.fare, ...state.all ]
      return { all, current: action.fare }
    }

    case UPDATE_FARE_SUCCESS : {
      let all = state.all.map(item => {
        return item.id == action.fare.id
          ? { ...item, ...action.fare }
          : item;
      })

      return { ...state, all }
    }

    case DELETE_FARE_SUCCESS : {
      let all = state.all.filter(item => item.id != action.fare.id)

      return { all, current: { ...initialState.current }}
    }

    default:
      return state;
  }
}

export default reducer
