import React from 'react';
import { DatePicker } from 'rsuite';
import moment from 'moment';
import isBefore from 'date-fns/isBefore'
import 'rsuite/dist/styles/rsuite-default.css';


const PushDate = props => {
  return (
    <DatePicker
      onSelect={date => props.date(date)}
      disabledDate={date => isBefore(date, moment().hours(0).minutes(0).seconds(0).toDate())}
      style={{zIndex: '0', width: '240px', marginBottom: '20px'}}
      format="YYYY-MM-DD HH:mm"
      ranges={[
        {
          label: 'Now',
          value: new Date()
        }
      ]}
    />
  );

}

export default PushDate;
