import "../styles/teamAdd.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { push } from "react-router-redux";
import { useDispatch, useSelector } from "react-redux";
import { getOperations } from "../actions/cities";
import { FormGroup, Label, Input } from "reactstrap";

import { store } from "../store";
import api from "../api";

const newArray = [
  { name: "Mapa", value: "map" },
  { name: "Cupons", value: "promocodes" },
  { name: "Passageiros", value: "riders" },
  { name: "Motoristas", value: "drivers" },
  { name: "Cadastro Motorista", value: "aspirant_drivers" },
  { name: "Tarifas", value: "fares" },
  { name: "Viagens", value: "rides" },
  { name: "Notificações", value: "push_notifications" },
  { name: "Equipe", value: "admins" },
  { name: "Estatísticas", value: "analytics" },
];

const initialValues = {
  name: "",
  cpfCnpj: "",
  email: "",
  phone: "",
  role: "",
  cities: "",
  role_description: "",
};

export const TeamAdd = () => {
  const dispatch = useDispatch();

  const cities = useSelector(state => state.cities.operations);
  const [values, setValues] = useState(initialValues);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [permissions, setPermissions] = useState({});

  const HandleOnChange = e => {
    const { name, value } = e.target;

    setValues({ ...values, [name]: value });
  };

  const handlePermissions = e => {
    const { value, checked } = e.target;

    if (checked) {
      setPermissions({ ...permissions, [value]: true });
    } else {
      Object.keys(permissions).forEach(key => {
        if (key === value) {
          delete permissions[key];
          setPermissions({ ...permissions });
        }
      });
    }
  };

  const handleCreateCollaborator = async () => {
    try {
      const payload = {
        name: values.name,
        email: values.email,
        password: password,
        role: values.role,
        role_description: values.role_description,
        cities: [
          {
            id: values.cities,
          },
        ],
        permissions,
      };

      console.log(payload);

      // if (password === "") {
      //   toast.error("O campo senha não pode ser vazioS");
      // }

      if (password !== repeatPassword) {
        toast.error("Senhas diferentes");
        return;
      }

      await api.createCollaborator(payload);

      toast.success("Colaborador criado com sucesso");

      setTimeout(() => {
        store.dispatch(push(`/team`));
      }, 2000);

      setValues({});
      setPassword("");
      setPermissions({});
      setRepeatPassword("");
    } catch (error) {
      console.log("Error ao criar colaborador");
      toast.error(error.message);
    }
  };

  const back = () => {
    store.dispatch(push(`/team`));
  };

  useEffect(() => {
    dispatch(getOperations())
  }, []);

  return (
    <div className="container__franchisees__add">
      <div className="wrapper">
        <div className="header">
          <div className="title">
            <i className="pi pi-arrow-left" onClick={back} />
            <h1>Adicionar colaborador</h1>
          </div>
        </div>
        <div className="main">
          <form>
            <div className="form__header">
              <h5>Dados do colaborador</h5>
            </div>
            <div className="wrapper__form">
              <div className="form-group">
                <label>Nome Completo</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={values.name}
                  onChange={HandleOnChange}
                />
              </div>

              <div className="form-group">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={values.email}
                  onChange={HandleOnChange}
                />
              </div>
              <div className="form-group">
                <label>Cargo</label>
                <input
                  type="text"
                  className="form-control"
                  name="role_description"
                  value={values.role_description}
                  onChange={HandleOnChange}
                />
              </div>

              <div className="form-group">
                <label>Praça</label>
                <select
                  className="form-control custom-select"
                  name="cities"
                  value={values.cities}
                  onChange={HandleOnChange}
                >
                  <option value="" />
                  {cities.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="wrapper">
        <div className="main">
          <form>
            <div className="form__header">
              <h5>Senha do colaborador</h5>
            </div>
            <div className="wrapper__form">
              <div className="form-group">
                <label>Senha</label>
                <input
                  className="form-control"
                  type="text"
                  name="password"
                  placeholder="Senha"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </div>

              <div className="form-group">
                <label>Repetir senha</label>
                <input
                  className="form-control"
                  type="text"
                  name="passwordRepeat"
                  placeholder="password"
                  value={repeatPassword}
                  onChange={e => setRepeatPassword(e.target.value)}
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="wrapper">
        <div className="main">
          <form>
            <div className="form__header">
              <h5>Visão do colaborador</h5>
            </div>

            <h6>Selecione as abas do menu que o colaborador terá acesso:</h6>
            <div className="wrapper__form">
              <div className="content__checked">
                {newArray.map((item, index) => (
                  <FormGroup key={item.index} check inline className="check">
                    <Label check>
                      <Input
                        type="checkbox"
                        // checked={permissions[item] === item}
                        name={item.name}
                        value={item.value}
                        onChange={handlePermissions}
                      />
                      <span>{item.name}</span>
                    </Label>
                  </FormGroup>
                ))}
              </div>
            </div>
          </form>

          <div className="btn">
            <button onClick={handleCreateCollaborator}>Adicionar</button>
          </div>
        </div>
      </div>
    </div>
  );
};
