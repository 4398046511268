import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import PropTypes from 'prop-types';
import Geosuggest from 'react-geosuggest';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Places from '../api/places';
import {
  getCity,
  getCities,
  createCity,
  clearCity,
  updateCity,
  deleteCity,
  updateCityProps
} from '../actions/cities';
import { store } from '../store';
import '../styles/css/cities.css';


class AddCityPage extends Component {

  static propTypes = {
    createCity: PropTypes.func,
    updateCity: PropTypes.func,
    deleteCity: PropTypes.func,
    updateCityProps: PropTypes.func,
    clearCity: PropTypes.func,
    getCity: PropTypes.func,
    cities: PropTypes.array,
    city: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object
  }

  constructor(props) {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
    this.remove = this.remove.bind(this);
    this.close = this.close.bind(this);
    this.updateCity = this.updateCity.bind(this);
    this.focusAddress = this.focusAddress.bind(this);
    this.onSuggestSelect = this.onSuggestSelect.bind(this);

    this.state = {
      focused: false,
      address: ''
    }
  }

  componentDidMount() {
    let { id } = this.props.match.params;

    if (id && id !== 'new') {
      this.props.getCity(id)
    } else {
      this.props.clearCity();
    }
  }

  updateCity() {
    let { city_id } = this.props.match.params;

    let city = this.props.cities.find(city => city.id == city_id);
    if (city) {
      this.props.updateCityProps({
        city,
        city_id: city.id
      })
    }

    return city;
  }


  validate() {
    let { city } = this.props;

    if (!city.name) {
      return toast.warn('Cidade inválida');
    }

    return true;
  }


  async save() {
    if (this.validate() !== true) {
      return;
    }

    let {
      id,
      name
    } = this.props.city;

    let city = {
      name
    }
    if (id) {
      city.id = id;
    }

    let rs;

    if (id) {
      rs = await this.props.updateCity(city);
    } else {
      rs = await this.props.createCity(city);

      if (!rs.error) {
        store.dispatch(push(`/cities/id/${rs.city.id}`));
      }
    }

    let { error } = rs;
    if (error) {
      toast.error(
        error.name == 'ValidationError'
          ? error.message
          : 'Erro ao salvar o registro'
      )
    } else {
      toast.success(id ? 'Atualizado!' : 'Criado com sucesso')
    }
  }

  async remove() {
    if (window.confirm('Tem certeza que deseja excluir esta cidade? Todas as tarifas relacionadas a ela também serão removidas')) {
      let { error } = await this.props.deleteCity(this.props.city.id)
      if (error) {
        toast.error('Erro ao remover a cidade')
      } else {
        toast.success('Cidade removida com sucesso');
        this.close();
      }
    }
  }

  close() {
    this.props.history.goBack();
  }

  handleChange({ target }) {
    const getAttr = attr => {
      let item = target.attributes.getNamedItem(attr)
      return item ? item.value : null;
    }

    let type = getAttr('data-type');
    let prop = getAttr('data-name');
    let value = target.value;
    if (type == 'currency') {
      value = Number(value.replace(/[^0-9.]/g, '')).toFixed(2);
    } else if (type == 'number') {
      value = Number(value)
    }

    this.props.updateCityProps({[prop]: value})
  }

  handleCurrencyChange(props) {
    this.props.updateCityProps(props)
  }

  focusAddress() {
    this.setState({ address: '', focused: true })
  }

  async onSuggestSelect(suggest) {
    if (suggest && suggest.gmaps && suggest.gmaps.address_components) {
      let name = Places.locality(suggest.gmaps.address_components);

      this.props.updateCityProps({ name })
      this._geoSuggest.update(name)

    }
  }

  render() {
    const { city } = this.props;

    return (
      <div style={{ width: 500, marginLeft: 'auto', marginRight: 'auto' }}>
        <div className="card bg-light">
          <div className="card-header">Cidade</div>
          <div className="card-body">
            <form>
              {
                !!city.id &&
                  <div className="form-group">
                    <label>ID</label>
                    <input
                      readOnly
                      className="form-control-plaintext"
                      value={city.id}
                    />
                  </div>
              }

              <div style={{position: 'relative', height: '30px'}}>
                <Geosuggest
                  ref={el => this._geoSuggest = el}
                  placeholder="Procure por uma cidade.."
                  initialValue=""
                  country="br"
                  types={["(regions)"]}
                  inputClassName="form-control"
                  onBlur={value => {
                    if (city.name != value) {
                      this._geoSuggest.update('')
                    }
                  }}
                  placeDetailFields={['name','geometry', 'place_id','address_components']}
                  googleMaps={window.google.maps}
                  style={{ 'suggests': styles.suggests, 'suggestItem': styles.suggestItem }}
                  onSuggestSelect={this.onSuggestSelect}
                />
              </div>
            </form>
          </div>
        </div>

        <Modal.Footer>
          <div style={{flex: 1, alignSelf: 'stretch'}}>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => this.props.history.goBack()}
            >
              Voltar
            </button>
          </div>

          <button
            type="button"
            className="btn btn-success pull-left"
            onClick={this.save}
          >
            Salvar
          </button>

          {
            !!city.id &&
              <button
                type="button"
                className="btn btn-danger pull-left"
                onClick={this.remove}
              >
                Remover
              </button>
          }

          <button
            type="button"
            className="btn btn-secondary pull-left"
            data-dismiss="modal"
            onClick={this.close}
          >
            Cancelar
          </button>
        </Modal.Footer>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  city: state.cities.current,
  cities: state.cities.all,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    createCity,
    updateCity,
    updateCityProps,
    getCity,
    deleteCity,
    clearCity,
    getCities
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddCityPage)

const styles = {
  suggests: {
    padding: '0 10px',
    backgroundColor: 'white',
    border: '#cccccc 1px solid',
    marginTop: '10px'

  },
  suggestItem: {
    listStyleType: 'none',
    padding: '10px',
    borderBottom: '#cccccc 1px solid'
  }
}

