import styled from 'styled-components'
import ReactSelect from 'react-select'

export const FormWrapper = styled.div`
  width: 60%;
  z-index: 10;  
`

export const MultiSelect = styled(ReactSelect)`
  width: 60%;
  margin-bottom: 20px;
  
      
  &.Select--multi  {
    .Select-value {
    display: inline-flex;
    align-items: center;
    z-index: 999;
    }
  }

  & .Select-placeholder {
  font-size: smaller;
  z-index: 900;
  }
`