import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Cars from "../components/cars";
import { getCarInactive, updateInactiveCar, getCarsInactive, deleteCar } from "../actions/inactiveCars";

class AspirantCarPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };

    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.softDelete = this.softDelete.bind(this)

    let { id } = props.match.params;
    props.getCarInactive(id);
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  saveCar = car => {
    this.props.updateInactiveCar(car);
    this.props.getCarsInactive();
  };

  softDelete = async () => {
    let { id } = this.props.match.params;
    let {error} = await this.props.deleteCar(id)
    if(error) {
      toast.error(error.message)
    } else {
      toast.success('Carro deletado com sucesso!')
    }
  }

  render() {
    let { car } = this.props;

    return (
      <div style={{ width: 500, marginLeft: "auto", marginRight: "auto" }}>
        <div className="card bg-light">
          <div className="card-header">Informações Pessoais</div>
          <div className="card-body">
            <form>
              <div className="form-group">
                <label>ID</label>
                <input readOnly className="form-control" value={car.driverId} />
              </div>
              <div className="form-group">
                <label htmlFor="inputName">Nome</label>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  maxLength="15"
                  id="inputName"
                  data-name="name"
                  value={car.driverName}
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputName">Sobrenome</label>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  maxLength="30"
                  id="inputSurname"
                  data-name="surname"
                  value={car.driverSurname}
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputEmail">Email</label>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  maxLength="50"
                  id="inputEmail"
                  data-name="email"
                  value={car.driverEmail}
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputPhone">Celular</label>
                <input
                  readOnly
                  type="text"
                  className="form-control"
                  maxLength="11"
                  id="inputPhone"
                  data-name="phone"
                  data-type="number"
                  value={car.driverPhone}
                />
              </div>
            </form>
          </div>
        </div>
        <Cars key={car.id} car={car} i={0} softDelete={this.softDelete} onSave={this.saveCar} />
        <button
          type="button"
          className="btn btn-danger"
          onClick={this.softDelete}
        >
          Deletar Carro
        </button>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  car: state.inactiveCars.selectedCar
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getCarInactive,
      getCarsInactive,
      updateInactiveCar,
      deleteCar
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AspirantCarPage);
