import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import cloneDeep from "clone-deep";
import { getOnePush, updatePush, createNotification } from '../../actions/push-notification'
import * as S from './styled'
import moment from 'moment'

const EditNotificationPage = props => {
  useEffect(() => {
    props.getOnePush(props.match.params.id)
  }, [])
  console.log('PushOne', props.pushNotification)

  const modoDeEnvio = modo => {
    const options = {
      1: 'Now',
      2: 'Agendado',
      3: 'Recorrente'
    }
    return options[modo] || ''
  }

  const modoRecorrente = modo => {
    const options = {
      'week': 'Semanal',
      'days': 'Dia do mês'
    }
    return options[modo] || ''
  }

  const diaSemana = modo => {
    if (modo) {
      const obj = []
      modo.forEach(el => {
        if (el == 0) {
          obj.push('Domingo ')
        }
        if (el == 1) {
          obj.push('Segunda-feira ')
        }
        if (el == 2) {
          obj.push('Terça-feira ')
        }
        if (el == 3) {
          obj.push('Quarta-feira ')
        }
        if (el == 4) {
          obj.push('Quinta-feira ')
        }
        if (el == 5) {
          obj.push('Sexta-feira ')
        }
        if (el == 6) {
          obj.push('Sábado ')
        }

      })
      return obj
    }
  }

  const diaMes = modo => {
    if (modo) {
      return ('modo', moment(modo).format('DD'))
    } else {
      return ''
    }
  }


  const horarioDeEnvio = (week, days, modoEnvio, criadoEm ) => {
    if(modoEnvio == 1) {
      return moment(criadoEm).format('HH:mm A')
    }
    if(modoEnvio == 2) {
      return moment(week).format('DD/MM - HH:mm A')
    }
    if(week) {
      return moment(week).format('HH:mm A')
    }
    if (days) {
      return moment(days).format('HH:mm A')
    }
  }

  const statusEnvio = some => {
    if (some.send_at == 1 && some.deleted != true) {
      return `Enviado`
    }
    if (some.deleted === true) {
      return 'Cancelado'
    }

    if (some.send_at == 2 || some.send_at == 3) {
      return 'Enviando nos dias agendados'
    }
  }

  const publicoAlvo = some => {
    if (some.target == 1) {
      return 'Motoristas'
    }

    if (some.target == 2) {
      return 'Passageiros'
    }

    if (some.target == 3) {
      return 'Motorista'
    }

    if (some.target == 4) {
      return 'Passageiro'
    }
  }

  const cidades = some => {
    if ( some.cidades) {
      return (
        <select>
          {some.cidades && some.cidades.map((el, index) => {
            return <option value="1" key={index}>{el}</option>
          })}
        </select>
      )
    }
    return ''
  }

  const handleInputChange = event => {
    props.updatePush({[event.target.name]: event.target.value})
  }

  const reenviarPush = e => {
    e.preventDefault();
    let push = cloneDeep(props.pushNotification)
    push.cidades = push.cities
    push.audiencia = push.target
    push.sendModeRecorrently = push.recorrently_mode
    push.sendModeRecorrentlyDaysDay = push.days_to_repeat
    push.sendModeRecorrentlyWeekDays = push.days_week
    push.sendModeRecorrentlyWeekTime = push.scheduled_to
    props.createNotification(push);
  }

  return (
    <>
      <S.Title>Push Notification Info</S.Title>
      <S.PushInfoWrapper>
        <S.DividorPushInfo>
          <S.SubTitle>Modo de envio: {modoDeEnvio(props.pushNotification.send_at)}</S.SubTitle>
          <S.SubTitle>Modo Recorrente: {modoRecorrente(props.pushNotification.recorrently_mode)}</S.SubTitle>
          <S.SubTitle>Dia(s) da semana: {diaSemana(props.pushNotification.days_week)}</S.SubTitle>
          <S.SubTitle>Dia do mês: {diaMes(props.pushNotification.days_to_repeat)}</S.SubTitle>
          <S.SubTitle>Horário de envio:  {horarioDeEnvio(
            props.pushNotification.scheduled_to,
            props.pushNotification.days_to_repeat,
            props.pushNotification.send_at,
            props.pushNotification.created_at
          )}</S.SubTitle>
        </S.DividorPushInfo>
        <S.DividorPushInfo>
          <S.SubTitle>Status: {statusEnvio(props.pushNotification)}</S.SubTitle>
          <S.SubTitle>Público alvo: {publicoAlvo(props.pushNotification)}</S.SubTitle>
          <S.SubTitle>Cidades envidas: {cidades(props.pushNotification)}</S.SubTitle>
        </S.DividorPushInfo>
      </S.PushInfoWrapper>

      <S.FormWrapper onSubmit={reenviarPush}>
        <S.FormEdit>
          <S.InputTitle onChange={e => handleInputChange(e)} type="text" name="title" value={props.pushNotification.title} />
          <S.InputMessage onChange={e => handleInputChange(e)} type="text" name="message" value={props.pushNotification.message} />
          <button type="submit">Reenviar</button>
        </S.FormEdit>
      </S.FormWrapper>
    </>
  )
}


const mapStateToProps = state => ({
  pushNotification: state.notifications.onePush
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    getOnePush,
    updatePush,
    createNotification
  }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditNotificationPage);
