import React, { useEffect } from "react";
import "../../../styles/statistics.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { ActiveUsersMonth } from "./ActiveUsersMonth";
import { ActiveUsersToday } from "./ActiveUsersToday";
import { useDispatch, useSelector } from "react-redux";
import {
  getStatisticsIndicators,
  setStatisticsLoading,
  setStatisticsNotLoading,
} from "../../../actions/statistics";

export const StatisticsIndicators = () => {
  const dispatch = useDispatch();
  const city_id = useSelector((state) => state.preferences.city_id);

  const fetchListAnalytics = async () => {
    dispatch(setStatisticsLoading());
    try {
      const params = {
        city_id,
      };

      await dispatch(getStatisticsIndicators(params));
    } catch (error) {
      console.log("erro aqui:", error);
    } finally {
      dispatch(setStatisticsNotLoading());
    }
  };

  useEffect(() => {
    fetchListAnalytics();
  }, [city_id]);

  return (
    <div className="main">
      <div className="general__wrapper">
        <ActiveUsersMonth />
        <ActiveUsersToday />
      </div>
    </div>
  );
};
