import Api from "../api";

export const GET_ASPIRANT_DRIVERS = "GET_ASPIRANT_DRIVERS";
export const GET_ASPIRANT_DRIVERS_SUCCESS = "GET_ASPIRANT_DRIVERS_SUCCESS";
export const GET_ASPIRANT_DRIVERS_ERROR = "GET_ASPIRANT_DRIVERS_ERROR";

export const GET_ASPIRANT_DRIVER = "GET_ASPIRANT_DRIVER";
export const GET_ASPIRANT_DRIVER_SUCCESS = "GET_ASPIRANT_DRIVER_SUCCESS";
export const GET_ASPIRANT_DRIVER_ERROR = "GET_ASPIRANT_DRIVER_ERROR";

export const GET_ASPIRANT_BANK_DRIVER = "GET_ASPIRANT_BANK_DRIVER";
export const GET_ASPIRANT_BANK_DRIVER_SUCCESS =
  "GET_ASPIRANT_BANK_DRIVER_SUCCESS";
export const GET_ASPIRANT_BANK_DRIVER_ERROR = "GET_ASPIRANT_BANK_DRIVER_ERROR";

export const UPDATE_ASPIRANT_DRIVER = "UPDATE_ASPIRANT_DRIVER";
export const UPDATE_ASPIRANT_DRIVER_SUCCESS = "UPDATE_ASPIRANT_DRIVER_SUCCESS";
export const UPDATE_ASPIRANT_DRIVER_ERROR = "UPDATE_ASPIRANT_DRIVER_ERROR";

export const SET_ASPIRANT_DRIVER = "SET_ASPIRANT_DRIVER";
export const UPDATE_ASPIRANT_DRIVER_PROPS = "UPDATE_ASPIRANT_DRIVER_PROPS";

export const UPDATE_ASPIRANT_DRIVER_BANK = "UPDATE_ASPIRANT_DRIVER_BANK";
export const UPDATE_ASPIRANT_DRIVER_BANK_SUCCESS =
  "UPDATE_ASPIRANT_DRIVER_BANK_SUCCESS";
export const UPDATE_ASPIRANT_DRIVER_BANK_ERROR =
  "UPDATE_ASPIRANT_DRIVER_BANK_ERROR";

export const getAspirantDrivers =
  (requestParams) => async (dispatch, getState) => {
    dispatch({
      type: GET_ASPIRANT_DRIVERS,
    });

    const { city_id } = getState().preferences;

    try {
      let {
        results: drivers,
        params,
        pages,
        total,
      } = await Api.getAspirantDrivers({
        ...requestParams,
        city_id,
      });

      console.log({ requestParams, drivers });

      return dispatch({
        type: GET_ASPIRANT_DRIVERS_SUCCESS,
        payload: {
          drivers,
          params,
          pages,
          total,
        },
      });
    } catch (error) {
      console.error(error);
      return dispatch({
        type: GET_ASPIRANT_DRIVERS_ERROR,
        error,
      });
    }
  };

export const getAspirantDriver = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_ASPIRANT_DRIVER,
    id,
  });

  try {
    let driver = await Api.getAspirantDriver(id);

    return dispatch({
      type: GET_ASPIRANT_DRIVER_SUCCESS,
      driver,
    });
  } catch (error) {
    return dispatch({
      type: GET_ASPIRANT_DRIVER_ERROR,
      error,
    });
  }
};

export const updateAspirantDriver = (driver) => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_ASPIRANT_DRIVER,
    driver,
  });

  try {
    driver = await Api.updateAspirantDriver(driver);

    return dispatch({
      type: UPDATE_ASPIRANT_DRIVER_SUCCESS,
      driver,
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_ASPIRANT_DRIVER_ERROR,
      error,
    });
  }
};

export const updateAspirantDriverProps =
  (props) => async (dispatch, getState) => {
    return dispatch({
      type: UPDATE_ASPIRANT_DRIVER_PROPS,
      props,
    });
  };

export const setAspirantDriver = (driver) => async (dispatch, getState) => {
  return dispatch({
    type: SET_ASPIRANT_DRIVER,
    driver,
  });
};

export const getAspirantBankDriver = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_ASPIRANT_BANK_DRIVER,
    id,
  });

  let account = {};

  try {
    const data = await Api.getAspirantDriver(id);
    const { bank_account } = data;

    if (bank_account) {
      account = {
        id: bank_account?.id || "",
        type: bank_account?.accountType || "",
        bankNumber: bank_account?.bank || "",
        bankName: bank_account?.bank || "",
        agencyNumber: bank_account?.agency || "",
        agencyCheckNumber: bank_account?.agencyDigit || "",
        accountNumber: bank_account?.accountNumber || "",
        accountCheckNumber: bank_account?.accountDigit || "",
        holder: {
          cpf: bank_account?.accountOwner?.cpf || "",
          name: bank_account?.accountOwner?.name || "",
        },
      };
    }

    return dispatch({
      type: GET_ASPIRANT_BANK_DRIVER_SUCCESS,
      account,
    });
  } catch (error) {
    console.log(error);
    return dispatch({
      type: GET_ASPIRANT_BANK_DRIVER_ERROR,
      error,
    });
  }
};

export const updateAspirantDriverBank =
  (driverId, bank) => async (dispatch) => {
    const bankAccount = {
      type: bank.type,
      bank_number: bank.bankNumber,
      bank_name: bank.bankName,
      agency_number: bank.agencyNumber,
      agency_check_number: bank.agencyCheckNumber,
      account_number: bank.accountNumber,
      account_check_number: bank.accountCheckNumber,
      holder: {
        name: bank.holder.name,
        cpf: bank.holder.cpf,
      },
    };

    dispatch({
      type: UPDATE_ASPIRANT_DRIVER_BANK,
      bankAccount,
    });

    try {
      await Api.updateAspirantDriverBankAccount(driverId, bankAccount);

      return dispatch({
        type: UPDATE_ASPIRANT_DRIVER_BANK_SUCCESS,
        driver,
      });
    } catch (error) {
      return dispatch({
        type: UPDATE_ASPIRANT_DRIVER_BANK_SUCCESS,
        error,
      });
    }
  };
