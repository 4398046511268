import XLSX from 'xlsx'
import moment from 'moment'

const exportRidesAnalyticsToExcel = corridas => {
  const mapping = {
    data: [],
    cidade: [],
    estado: [],
    valorTotalCorridas: [],
    valorWireCard: [],
    valorMobiGo: [],
    valorMotorista: [],
    formaPagamento: [],
    totalCorridasDia: []
  }

  corridas.forEach(el => {
    let totalPrice = Number(el.cash_total) + Number(el.credit_card_total)
    let totalDriver = Number(el.cash_total) + Number(el.credit_card_total) - el.moip_tax - el.moby_tax
    mapping.data.push(moment(el.data).format('DD-MM-YYYY'))
    mapping.cidade.push(el.city_name ? el.city_name : 'Não cadastrado')
    mapping.estado.push(el.city_name ? el.city_name.split(',')[1]: 'Não cadastrado')
    mapping.valorTotalCorridas.push(el.price ? totalPrice.toFixed(2).replace('.', ',') : 0)
    mapping.valorWireCard.push(el.moip_tax ? el.moip_tax.replace('.',',') : 0)
    mapping.valorMobiGo.push(el.moby_tax ? el.moby_tax.replace('.',',') : 0 )
    mapping.valorMotorista.push(el.profit ? totalDriver.toFixed(2).replace('.',',') : 0)
    mapping.formaPagamento.push(el.payment_type == 1 ? 'Dinheiro' : 'Cartão')
    mapping.totalCorridasDia.push(el.n_total_rides ? el.n_total_rides : 'Não cadastrado')
  })
  let wb = XLSX.utils.book_new()
  let rows = [['Data', 'Cidade', 'Estado', ' Valor Total Corridas', 'Valor Wire Card', 'Valor Moby Go','Valor Motorista', 'Forma de pagamento', 'Total de Corridas no dia']]
  let numberOfResultRows = Math.min(mapping.valorWireCard.length, mapping.formaPagamento.length)
  for (let i = 0; i < numberOfResultRows; i++) {
    const row = []
    row.push(mapping.data[i])
    row.push(mapping.cidade[i])
    row.push(mapping.estado[i])
    row.push(mapping.valorTotalCorridas[i])
    row.push(mapping.valorWireCard[i])
    row.push(mapping.valorMobiGo[i])
    row.push(mapping.valorMotorista[i])
    row.push(mapping.formaPagamento[i])
    row.push(mapping.totalCorridasDia[i])
    rows.push(row)
  }

  let ws = XLSX.utils.aoa_to_sheet(rows)
  XLSX.utils.book_append_sheet(wb, ws, 'Moby GO')
  XLSX.writeFile(wb, 'relatorio.xlsx')
}
export default exportRidesAnalyticsToExcel
