import React, { Component } from "react";
import moment from "moment";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { push } from "react-router-redux";
import { RouteWithSubRoutes } from "../";
import { store } from "../store";

import { getCarsInactive } from "../actions/inactiveCars";

class AspirantCarsPage extends Component {
  constructor(props) {
    super(props);
    this.routes = props.routes;
    this.renderItem = this.renderItem.bind(this);
  }

  componentDidMount() {
    this.props.getCarsInactive();
  }

  show(id) {
    store.dispatch(push(`/aspirant-cars/id/${id}`));
  }

  renderItem(car) {
    return (
      <tr
        key={car.carId}
        style={{ cursor: "pointer" }}
        onClick={() => this.show(car.carId)}
      >
        <td>{car.id ? car.id.substring(0, 8) : "123456789"}</td>
        <td>{car.name}</td>
        <td>{car.surname}</td>
        <td>{car.email}</td>
        <td>{car.phone}</td>
        <td>
          {car.created_at
            ? moment(car.created_at)
              .subtract(3, "hours")
              .format("DD/MM/YY HH:mm")
            : "Sem registro"}
        </td>
      </tr>
    );
  }

  render() {
    const { match, cars } = this.props;

    return (
      <div className="full-page-list">
        {match.path == "/aspirant-cars" && match.isExact && (
          <div>
            <div className="header-buttons">
              <h3>Pré-Cadastro Veículos</h3>
            </div>
            <p style={{ clear: "both" }} />
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th style={{ width: 50 }}>ID</th>
                  <th>Nome</th>
                  <th>Sobrenome</th>
                  <th>email</th>
                  <th>phone</th>
                  <th>Cadastrado em</th>
                </tr>
              </thead>
              <tbody>
                {cars.map(car => {
                  return this.renderItem(car);
                }) &&
                  cars.map(car => {
                    return this.renderItem(car);
                  })}
              </tbody>
            </table>

            {!!this.props.total &&
              `Exibindo ${cars.length} de ${this.props.total}`}
          </div>
        )}
        {this.routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  cars: state.inactiveCars.cars
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getCarsInactive
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AspirantCarsPage);
