import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import firebase from 'firebase/app';
import * as uuid from 'uuid/v4';
import {
  updateAds,
  createAds,
  deleteAds,
  getAds
} from '../actions/ads';
import { getCategories } from '../actions/categories';
import { GOOGLE_API_KEY } from '../constants';
import '../styles/css/ads.css';
import { AdsType, AdsStatus } from '../api/types';

const autoCompleteService = new window.google.maps.places.AutocompleteService();
const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));

class AdsPage extends Component {
  static propTypes = {
    match :PropTypes.object,
    history :PropTypes.object,
    list :PropTypes.array,
    categories :PropTypes.array,
    updateAds :PropTypes.func,
    createAds :PropTypes.func,
    deleteAds :PropTypes.func,
    getAds: PropTypes.func,
    getCategories: PropTypes.func,
  }

  constructor(props) {
    super();
    this.onAddressBlur = this.onAddressBlur.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeAddress = this.handleChangeAddress.bind(this);
    this.handleSelectFile = this.handleSelectFile.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.save = this.save.bind(this);
    this.remove = this.remove.bind(this);
    this.close = this.close.bind(this);

    const initialState = {
      name: '',
      description: '',
      client_name: '',
      price: '',
      geolocation: null,
      address: '',
      url: '',
      n_max_prints: '',
      n_max_clicks: '',
      radius: '',
      type: 1,
      categories: []
    }

    let { id } = props.match.params;

    let ads = props.list.find(ads => ads.id == id);
    if (ads) {
      ads = { ...ads, _address: ads.address };
    }

    this.state = ads || initialState;

    props.getCategories();

    if (!ads && id != 'new') {
      this.state = { loading: true }
      props
        .getAds(id)
        .then(({ ads, error }) => {
          if (error) {
            this.setState({ error: 'Não encontrado'})
          } else if (ads) {
            this.setState({ ...ads, _address: ads.address })
          }
          this.setState({ loading: false })
        })
    }
  }


  validate() {
    let ads = this.state;

    if (ads.name.length < 3) {
      return toast.warn('Nome inválido');
    }

    if (ads.description.length < 3) {
      return toast.warn('Descrição inválida');
    }

    if (ads.client_name.length < 3) {
      return toast.warn('Nome do cliente inválido');
    }

    if (Number(ads.price) < 1) {
      return toast.warn('Preço inválido');
    }

    if (Number(ads.n_max_prints) < 1) {
      return toast.warn('Máximo de impressões inválida');
    }

    if (Number(ads.n_max_clicks) < 1 && Number(ads.type) == 2) {
      return toast.warn('Máximo de resgates inválido');
    }

    if (!ads.geolocation) {
      return toast.warn('Selecione o endereço');
    }

    if (ads.address.length < 3) {
      return toast.warn('Endereço inválido');
    }

    if (!ads.image || ads.image.length < 3) {
      return toast.warn('Selecione uma imagem');
    }

    if (ads.status == 1 && (Number(ads.n_max_clicks) <= ads.n_clicks)) {
      return toast.warn(`Aumente a quota de ${ads.type == 1 ? 'clicks' : 'resgates'}`);
    }

    if (ads.status == 1 && (Number(ads.n_max_prints) <= ads.n_prints)) {
      return toast.warn('Aumente a quota de impressões');
    }

    return true;
  }


  async save() {
    if (this.validate() !== true) {
      return;
    }

    const {
      id,
      name,
      description,
      client_name,
      price,
      geolocation,
      address,
      url,
      n_max_prints,
      n_max_clicks,
      radius,
      type,
      status,
      categories,
      image
    } = this.state;

    let ads = {
      id,
      name,
      description,
      client_name,
      price,
      geolocation,
      address,
      url,
      n_max_prints,
      n_max_clicks,
      radius,
      type,
      status,
      categories,
      image
    }
    if (!ads.id) {
      delete ads.id;
    }

    let rs;

    if (ads.id) {
      rs = await this.props.updateAds(ads);
    } else {
      rs = await this.props.createAds(ads);
    }

    let { error } = rs;
    if (error) {
      toast.error(
        error.name == 'ValidationError'
          ? error.message
          : 'Erro ao salvar'
      )
    } else {
      toast.success(ads.id ? 'Atualizado!' : 'Criado com sucesso')
      if (!ads.id) {
        this.close();
      }
    }
  }

  async remove() {
    if (window.confirm('Deseja realmente excluir este registro?')) {
      let { error } = await this.props.deleteAds(this.state.id)
      if (error) {
        toast.error('Erro ao remover o registro')
      } else {
        toast.success('Removido com sucesso');
        this.close();
      }
    }
  }

  close() {
    this.props.history.goBack();
  }

  handleChange({ target }) {
    const getAttr = attr => {
      let item = target.attributes.getNamedItem(attr)
      return item ? item.value : null;
    }

    let type = getAttr('data-type');
    let prop = getAttr('data-name');
    let value = target.value;
    if (type == 'number') {
      value = value.replace(/[^0-9]/g, '')
    } else if (type == 'number-strict') {
      value = Number(value.replace(/[^0-9]/g, ''))
    } else if (type == 'currency') {
      value = Number(value.replace(/[^0-9.]/g, '')).toFixed(2);
    }

    this.setState({[prop]: value})
  }

  handleChangeAddress({ target }) {
    let value = target.value;

    this.setState({
      address: value
    })

    this.searchPlaces()
  }

  async handleSelectFile(event) {
    let input = event.target;
    let files = event.target.files;
    if (files.length < 1) {
      return;
    }

    let file = files[0];
    let prop = input.attributes.getNamedItem('data-name').value;
    let propName = prop.replace(/\./g, '_');
    let ref = firebase.storage().ref().child('ads/' + uuid() + '.jpg');
    let uploadTask = ref.put(file);

    const setProgress = p => {
      this.setState({
        [`${propName}_error`]: false,
        [`${propName}_progress`]: p
      })
    }
    setProgress(0);

    uploadTask.on('state_changed', snapshot => {
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setProgress(Math.round(progress));

      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          console.log('Upload is paused');
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          console.log('Upload is running');
          break;

        default:
      }
    }, error => {
      this.setState({
        [`${propName}_error`]: true
      })
    }, () => {
      uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
        setProgress(null);
        this.setState({
          [`${propName}_name`]: file.name
        })

        this.setState({
          [prop]: downloadURL
        })
      })
    })
  }

  showAddressList(action) {
    if (action == false) {
      return;
    }
    this.setState({
      showAddressList: action
    })

    if (action && !this.places) {
      this.searchPlaces();
    }
  }

  async searchPlaces() {
    const { address } = this.state;
    if (!address) {
      return;
    }

    try {
      let places = await new Promise((resolve, reject) => {
        autoCompleteService.getQueryPredictions({ input: address }, (predictions, status) => {
          if (status != window.google.maps.places.PlacesServiceStatus.OK) {
            resolve([])
            return;
          }

          resolve(predictions);
        })
      })


      this.setState({
        places
      })

    } catch (e) {
      //err
      console.log(e)
    }
  }

  handleCurrencyChange(props) {
    this.setState(props)
  }

  handleCategoryChange({ target }) {
    let id = parseInt(target.attributes.getNamedItem('data-name').value);
    let checked = !!target.checked;

    let categories = [ ...this.state.categories ];
    if (!checked) {
      categories = categories.filter(cid => cid != id);
    } else if (categories.indexOf(id) == -1) {
      categories.push(id);
    }

    this.setState({ categories })
  }

  onAddressBlur({ target }) {
    if (target.value != this.state._address) {
      this.setState({
        address: this.state._address
      })
    }

    setTimeout(() => {
      this.setState({
        showAddressList: false
      })
    }, 300)
  }

  setPlace(place) {
    this.setState({
      address: place.description,
      showAddressList: false
    })

    placesService.getDetails({ placeId: place.place_id }, (details, status) => {
      let { lat, lng } = details.geometry.location;

      this.setState({
        _address: place.description,
        geolocation: {
          latitude: lat(),
          longitude: lng()
        }
      })
    })


  }

  render() {
    const ads = this.state;

    if (ads.error) {
      return <div style={{textAlign: 'center'}}>{ads.error}</div>;
    }

    if (ads.loading) {
      return <div style={{textAlign: 'center'}}>Carregando..</div>;
    }

    return (
      <div style={{ width: 500, marginLeft: 'auto', marginRight: 'auto' }}>
        <div className="card bg-light">
          <div className="card-header">Anúncio/Cupon</div>
          <div className="card-body">
            <form>
              {
                !!ads.id &&
                  <div className="form-group">
                    <label>ID</label>
                    <input
                      readOnly
                      className="form-control-plaintext"
                      value={ads.id}
                    />
                  </div>
              }
              <div className="form-group">
                <label htmlFor="inputName">Nome</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="30"
                  id="inputName"
                  data-name="name"
                  value={ads.name}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label>Descrição</label>
                <textarea
                  style={{height: 200}}
                  className="form-control"
                  maxLength="300"
                  data-name="description"
                  onChange={this.handleChange}
                >
                  {ads.description}
                </textarea>
              </div>
              <div className="form-group">
                <label>Cliente</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="30"
                  data-name="client_name"
                  value={ads.client_name}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label>Endereço</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="255"
                  data-name="address"
                  value={ads.address}
                  onFocus={() => this.showAddressList(true)}
                  onBlur={this.onAddressBlur}
                  onChange={this.handleChangeAddress}
                />

                {
                  !!this.state.showAddressList &&
                  !!this.state.places &&
                  this.state.places.length > 0 &&
                    <div className="places-list">
                      <div className="title">Selecione o endereço abaixo:</div>
                      <div className="list">
                        {
                          this.state.places.map((place, index) => (
                            <div key={index} onClick={() => this.setPlace(place)}>
                              <div>{ place.description }</div>
                            </div>
                          ))
                        }
                      </div>
                    </div>
                }

                {
                  ads.geolocation &&
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://maps.google.com/?q=${ads.geolocation.latitude},${ads.geolocation.longitude}`}
                    >
                      <img
                        alt="map"
                        style={{width: '100%', marginTop: 10, borderWidth: 1, borderColor: '#ced4da', borderStyle: 'solid'}}
                        src={`https://maps.googleapis.com/maps/api/staticmap?markers=color:blue%7C${ads.geolocation.latitude},${ads.geolocation.longitude}&key=${GOOGLE_API_KEY}&size=500x350&zoom=19`}
                      />
                    </a>
                }
              </div>
              <div className="form-group">
                <label>Raio (km)</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="30"
                  data-name="radius"
                  data-type="number-strict"
                  value={ads.radius}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label>Tipo</label>
                <div style={{clear:'all',height: 8}} />
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="type"
                    data-name="type"
                    value={1}
                    data-type="number-strict"
                    checked={ads.type == 1}
                    onChange={this.handleChange}
                    id="defaultCheck1"
                  />
                  <label className="form-check-label" htmlFor="defaultCheck1">
                    {AdsType[1]}
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="type"
                    data-name="type"
                    data-type="number-strict"
                    value={2}
                    checked={ads.type == 2}
                    onChange={this.handleChange}
                    id="defaultCheck2"
                  />
                  <label className="form-check-label" htmlFor="defaultCheck2">
                    {AdsType[2]}
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label>Categorias</label>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: 300 }}>
                  {
                    this.props.categories.map(category => {
                      return (
                        <div
                          key={category.id}
                          className="custom-control custom-checkbox"
                          style={{ margin: '5px 0'}}
                        >
                          <input
                            type="checkbox"
                            id={`cat-${category.id}`}
                            className="custom-control-input"
                            data-name={category.id}
                            checked={ads.categories.indexOf(category.id) !== -1}
                            value={category.id}
                            onChange={this.handleCategoryChange}
                          />
                          <label className="custom-control-label" htmlFor={`cat-${category.id}`}>
                            { category.name }
                          </label>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
              <div className="form-group">
                <label>Imagem: (970 x 230 pixels) *.png</label>
                <div className="custom-file">
                  <input
                    type="file"
                    data-name="image"
                    className="custom-file-input"
                    id="customFile"
                    onChange={this.handleSelectFile}
                  />
                  <label className="custom-file-label" htmlFor="customFile">
                    {
                      ads.image_progress >= 0
                        ? 'Enviando imagem..'
                        : ''
                    }
                  </label>
                </div>
                {
                  !!ads.image &&
                    <img src={ads.image} alt="" style={{marginTop: 10, width: '100%'}} />
                }
              </div>
              <div className="row form-group">
                <div className="col form-group">
                  <label>Quota de impressões</label>
                  <input
                    type="text"
                    className="form-control"
                    maxLength="30"
                    data-name="n_max_prints"
                    data-type="number-strict"
                    value={ads.n_max_prints}
                    onChange={this.handleChange}
                  />
                </div>
                {
                  !!ads.id &&
                    <div className="col form-group">
                      <label>Impressões contabilizadas</label>
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        maxLength="30"
                        data-name="n_prints"
                        data-type="number"
                        value={ads.n_prints}
                        onChange={this.handleChange}
                      />
                    </div>
                }
              </div>
              <div className="row form-group">
                <div className="col form-group">
                  <label>Quota de {ads.type == 1 ? 'Clicks' : 'Resgates'}</label>
                  <input
                    type="text"
                    className="form-control"
                    maxLength="30"
                    data-name="n_max_clicks"
                    data-type="number-strict"
                    value={ads.n_max_clicks}
                    onChange={this.handleChange}
                  />
                </div>
                {
                  !!ads.id &&
                    <div className="col form-group">
                      <label>{ads.type == 1 ? 'Clicks' : 'Resgates'} Contabilizados</label>
                      <input
                        type="text"
                        readOnly
                        className="form-control"
                        maxLength="30"
                        data-name="n_clicks"
                        data-type="number"
                        value={ads.n_clicks}
                        onChange={this.handleChange}
                      />
                    </div>
                }
              </div>
              <div className="form-group">
                <label>Preço</label>
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">R$</div>
                  </div>
                  <input
                    data-name="price"
                    className="form-control"
                    data-type="number-strict"
                    onChange={this.handleChange}
                    value={ads.price}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>URL</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="255"
                  data-name="url"
                  value={ads.url}
                  onChange={this.handleChange}
                />
              </div>

              <div className="form-group">
                <label>Status</label>
                <select
                  className="form-control custom-select"
                  data-name="status"
                  value={ads.status}
                  onChange={this.handleChange}
                >
                  {
                    Object.keys(AdsStatus).map(id => (
                      <option key={id} value={id}>
                        {AdsStatus[id]}
                      </option>
                    ))
                  }
                </select>
              </div>
            </form>
          </div>
        </div>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-success pull-left"
            onClick={this.save}
          >Salvar</button>

          {
            !!ads.id &&
              <button
                type="button"
                className="btn btn-danger pull-left"
                onClick={this.remove}
              >Remover</button>
          }

          <button
            type="button"
            className="btn btn-secondary pull-left"
            data-dismiss="modal"
            onClick={this.close}
          >Cancelar</button>
        </Modal.Footer>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  list: state.ads.all,
  categories: state.categories.all
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    updateAds,
    createAds,
    deleteAds,
    getAds,
    getCategories
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AdsPage)
