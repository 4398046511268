import cloneDeep from "clone-deep";
import {
  GET_ASPIRANT_DRIVER,
  GET_ASPIRANT_DRIVER_SUCCESS,
  GET_ASPIRANT_DRIVERS_SUCCESS,
  UPDATE_ASPIRANT_DRIVER_SUCCESS,
  SET_ASPIRANT_DRIVER,
  UPDATE_ASPIRANT_DRIVER_PROPS,
} from "../actions/aspirant-drivers";
import { BankAccountInitialValues } from "constants/BankAccount.constant";

const initialState = {
  drivers: [],
  params: {
    page: 0,
    search: "",
    status: null,
  },
  page: 0,
  pages: 0,
  driver: {
    id: "",
    name: "",
    surname: "",
    email: "",
    phone: "",
    categories: [],
    car: {
      name: "",
      year: "",
      color: "",
      license_plate: "",
      renavam: "",
      crlv_img: "",
      owner: 1,
    },
    info: {
      cpf: "",
      cnh: "",
      cnh_img: "",
      criminal_records: "",
      profile_img: "",
      gender: 1,
      address: {
        city: "",
        state: "",
        street: "",
        country: "BRA",
        zipCode: "",
        district: "",
        streetNumber: "",
      },
      birthdate: "",
    },
    bankAccount: BankAccountInitialValues,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ASPIRANT_DRIVER:
      return { ...state, driver: cloneDeep(initialState.driver) };

    case GET_ASPIRANT_DRIVER_SUCCESS:
      return { ...state, driver: action.driver };

    case GET_ASPIRANT_DRIVERS_SUCCESS:
      return { ...state, ...action.payload };

    case UPDATE_ASPIRANT_DRIVER_PROPS:
      return { ...state, driver: { ...state.driver, ...action.props } };

    case UPDATE_ASPIRANT_DRIVER_SUCCESS: {
      let drivers = state.drivers.map((driver) => {
        if (driver.id == action.driver.id) {
          return { ...driver, ...action.driver };
        }
        return driver;
      });

      return { ...state, driver: action.driver, drivers };
    }
    case SET_ASPIRANT_DRIVER:
      return {
        ...state,
        driver: action.driver
          ? { ...action.driver }
          : cloneDeep(initialState.driver),
      };

    default:
      return state;
  }
};

export default reducer;
