import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { UserStatusLabels } from "../api/types";
import {
  getRider,
  updateRider,
  updateRiderProps,
  setRider,
  unlockUser,
} from "../actions/riders";
import { getCities } from "../actions/cities";
import "../styles/css/driver-info.css";

import cloneDeep from "clone-deep";
import { toast } from "react-toastify";
import objectPath from "object-path";
import { UserIsActiveStatus } from "../constants";

class RiderInfoPage extends Component {
  static propTypes = {
    getRider: PropTypes.func,
    rider: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,

    updateRider: PropTypes.func,
    updateRiderProps: PropTypes.func,
    setRider: PropTypes.func,
  };

  constructor(props) {
    super();
    this.close = this.close.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.save = this.save.bind(this);
    this.unlockThisUser = this.unlockThisUser.bind(this);

    this.state = {
      busy: false,
    };
  }

  componentDidMount() {
    let { id } = this.props.match.params;

    if (id) {
      this.props.getRider(id).then(rs => console.log(rs));
    }
    console.log(this.props.rider);
  }

  async save() {
    let rider = cloneDeep(this.props.rider);
    if (rider.rating) {
      rider.rating = Number(rider.rating);
    }

    let rs;

    this.setState({ busy: true });

    if (rider.id) {
      rs = await this.props.updateRider(rider);
      console.log("Deebug", rs);
    }

    let { error } = rs;
    if (error) {
      toast.error(
        error.name == "ValidationError"
          ? error.message
          : "Erro ao editar o passageiro"
      );
    } else {
      toast.success(rider.id ? "Atualizado!" : "Criado com sucesso");
    }

    this.setState({ busy: false });
  }

  unlockThisUser() {
    let { id } = this.props.match.params;
    this.props.unlockUser(id);
    toast.success("Passageiro destravado");
  }

  handleChange({ target }) {
    const getAttr = attr => {
      let item = target.attributes.getNamedItem(attr);
      return item ? item.value : null;
    };

    let type = getAttr("data-type");
    let prop = getAttr("data-name");
    let value = target.value;

    if (type == "number") {
      value = value.replace(/[^0-9]/g, "");
    } else if (type == "number-strict") {
      value = Number(value.replace(/[^0-9]/g, ""));
    } else if (type == "cep") {
      value = value.replace(/[^0-9]/g, "").replace(/(\d{5})(\d{3})/, "$1-$2");
    }

    if (prop.indexOf(".") !== -1) {
      let rider = cloneDeep(this.props.rider);

      objectPath.set(rider, prop, value);
      this.props.setRider(rider);
    } else {
      this.props.updateRiderProps({ [prop]: value });
    }
  }

  close() {
    this.props.history.goBack();
  }

  render() {
    const { rider } = this.props;
    return (
      <div style={{ width: 500, marginLeft: "auto", marginRight: "auto" }}>
        <div className="card bg-light">
          <div className="card-header">Passageiro</div>
          <div className="card-body">
            <form>
              <div className="form-group">
                <label>ID</label>
                <input readOnly className="form-control" value={rider.id} />
              </div>
              <div className="form-group">
                <label>MOIP ID</label>
                <input
                  type="text"
                  readOnly
                  className="form-control"
                  value={rider.customer_id || ""}
                />
              </div>
              <div className="form-group">
                <label>Pagarme ID</label>
                <input
                  type="text"
                  readOnly
                  className="form-control"
                  value={rider.pagarme_custumer_id || ""}
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputName">Nome</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="15"
                  id="inputName"
                  data-name="name"
                  value={rider.name}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputName">Sobrenome</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="30"
                  id="inputSurname"
                  data-name="surname"
                  value={rider.surname}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputCpf">CPF</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="11"
                  id="inputCpf"
                  data-type="number"
                  data-name="cpf"
                  value={rider.cpf}
                  //onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputRating">Avaliação</label>
                <input
                  type="number"
                  step="0.01"
                  min="0"
                  max="5"
                  className="form-control"
                  data-name="rating"
                  id="inputRating"
                  value={Number(rider.rating)}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label>Cidade</label>
                <select
                  className="form-control custom-select"
                  data-name="city_id"
                  value={rider.city_id}
                  onChange={this.handleChange}
                >
                  {this.props.cities &&
                    this.props.cities.map(city => {
                      return (
                        <option key={city.id} value={city.id}>
                          {city.name}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  value={rider.email}
                />
              </div>
              <div className="form-group">
                <label>Celular</label>
                <input
                  type="text"
                  className="form-control"
                  data-name="phone"
                  value={rider.phone}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label>Status</label>
                <input
                  type="text"
                  readOnly
                  className="form-control"
                  value={UserStatusLabels[rider.status]}
                />
              </div>
              <div className="form-group">
                <label>Ativo</label>
                <select
                  className="form-control custom-select"
                  data-name="is_active"
                  value={rider.is_active}
                  onChange={this.handleChange}
                >
                  <option value={UserIsActiveStatus.ATIVO}>Sim</option>
                  <option value={UserIsActiveStatus.INATIVO}>Não</option>
                </select>
              </div>
            </form>
          </div>
        </div>

        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary pull-left"
            onClick={this.close}
          >
            Voltar
          </button>

          <button
            type="button"
            enabled={!this.state.busy}
            className="btn btn-success pull-left"
            onClick={this.save}
          >
            {this.state.busy ? "Salvando.." : "Salvar"}
          </button>

          <button
            type="button"
            enabled={!this.state.busy}
            className="btn btn-danger pull-left"
            data-dismiss="modal"
            onClick={this.unlockThisUser}
          >
            Destravar Passageiro
          </button>
        </Modal.Footer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  rider: state.riders.rider,
  cities: state.cities.all,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getRider,
      updateRider,
      updateRiderProps,
      setRider,
      unlockUser,
      getCities,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(RiderInfoPage);
