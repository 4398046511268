import { useEffect, useState } from "react";
import { formatPrice } from "../../../utils/string";
import api from "../../../api";
import * as S from "./styled";

type WhiteDrawItemType = {
  id: string,
  amount: number,
  status: string
}

type WhiteDrawListType = WhiteDrawItemType[];

export const WhiteDrawList = ({ id }: { id: string }) => {
  const [operatorWhiteDrawList, setOperatorWhiteDrawList] = useState<WhiteDrawListType>([]);

  const handleFranchisees = async () => {
    const operatorWhiteDrawList = await api.getOperatorWhiteDrawList(id) as unknown as WhiteDrawListType;

    setOperatorWhiteDrawList(operatorWhiteDrawList);
  };

  useEffect(() => {
    handleFranchisees();
  }, []);

  return (
    <S.Container>
      {operatorWhiteDrawList?.length > 0 ? (
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>id</th>
              <th>Valor</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {operatorWhiteDrawList?.map((item: WhiteDrawItemType) => (
              <tr key={item.id}>
                <td width={120}>{item?.id}</td>
                <td>{formatPrice(item?.amount/100)}</td>
                <td>{item?.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <h4>Sem dados para exibir</h4>
      )}
    </S.Container>
  );
};
