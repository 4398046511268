import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import { AspirantDriverStatusLabels } from '../api/types'

export default class AspirantDriversFilters extends Component {
  static propTypes = {
    params: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.submit = this.submit.bind(this)

    this.state = {
      ...props.params,
      params: props.params
    }
  }

  static getDerivedStateFromProps(props, state) {
    return isEqual(props.params, state.params)
      ? null
      : { ...props.params, params: props.params };
  }

  submit() {
    let state = { ...this.state };
    delete state.params;

    this.props.onSubmit(state);
  }

  onChange(event) {
    const getAttr = name => {
      let attr = event.target.attributes.getNamedItem(name);

      return attr ? attr.value : null;
    }
    let value = getAttr('data-type') == 'number'
      ? Number(event.target.value)
      : event.target.value;

    this.setState({ [event.target.name]: value })
  }

  handleSubmit(event) {
    this.submit()
    event.preventDefault();
  }

  render() {
    let params = this.state;

    return (
      <form className="form-row" onSubmit={this.handleSubmit}>
        <div className="form-group col-md-3">
          <label>Busca: </label>
          <input
            type="text"
            name="search"
            className="form-control"
            value={params.search || ''}
            onChange={this.onChange}
            placeholder="Busca por nome, email ou telefone.."
          />
        </div>

        <div className="form-group col-md-2">
          <label>Status: </label>
          <select
            value={params.status || ''}
            onChange={this.onChange}
            className="form-control custom-select"
            name="status"
          >
            <option value="" />
            {
              Object.keys(AspirantDriverStatusLabels).map(key => {
                return (
                  <option key={AspirantDriverStatusLabels[key]} value={key}>{AspirantDriverStatusLabels[key]}</option>
                )
              })
            }
          </select>
        </div>

        <div className="form-group col-md-1">
          <label>&nbsp;&nbsp;</label>
          <button type="submit" className="btn btn-primary form-control">OK</button>
        </div>
      </form>
    )
  }
}
