import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import io from 'socket.io-client';
import { compose, withProps } from 'recompose';
import { withGoogleMap, GoogleMap, Marker, InfoWindow } from 'react-google-maps';
import axios from 'axios';
import Api from '../api';
import { DriverStatusLabels } from '../api/types';
import '../styles/css/maps.css';

//scaledSize

const MapComponent = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCGuxuxtDWOe6dWaZ4jCFTAeIYThXVHGWA",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `500px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withGoogleMap
)(props =>
  (
    <GoogleMap
      ref={ref => props.onRef(ref)}
      defaultZoom={12}
      onDragEnd={props.onDragEnd}
      defaultCenter={props.defaultCenter}
    >
      {
        props.drivers.map(driver => (
          <Marker
            key={driver.id}
            title={`${driver.name}\n${DriverStatusLabels[driver.status]}`}
            icon={{
              url: driver.avatar_small,
              scaledSize: new window.google.maps.Size(30, 30),
              // The origin for this image is (0, 0).
              origin: new window.google.maps.Point(0, 0),
              // The anchor for this image is the base of the flagpole at (0, 32).
              anchor: new window.google.maps.Point(15, 15)
            }}
            style={{width:60,height:60,backgroundColor: 'red'}}
            position={{ lat: driver.latitude, lng: driver.longitude }}
            onClick={() => props.onToggleOpen(driver.id)}
          >
            {
              driver.isOpen &&
                <InfoWindow onCloseClick={props.onToggleOpen}>
                  <div>
                    <div>{ driver.name }</div>
                    <div>{ DriverStatusLabels[driver.status] }</div>
                  </div>
                </InfoWindow>
            }
          </Marker>
        ))
      }
    </GoogleMap>
  )
);


class MapsPage extends Component {

  static propTypes = {
    account: PropTypes.object
  }

  constructor(props) {
    super(props);

    this.init = this.init.bind(this);
    this.getDrivers = this.getDrivers.bind(this);
    this.onCenterChanged = this.onCenterChanged.bind(this);
    this.onToggleOpen = this.onToggleOpen.bind(this);

    let defaultCenter = { lat: null, lng: null }

    //test
    defaultCenter = { lat: -30.0248627, lng: -51.2013986 }

    this.center = defaultCenter;

    this.state = {
      drivers: [],
      defaultCenter,
      lat: null,
      lon: null,
      teste: null
    }
  }

  async componentDidMount() {
    this.initTimeout = setTimeout(this.init, 500);

    // let result = await navigator.permissions.query({name:'geolocation'})
    // console.log(result)
    // if(result.state === 'denied') {
    //   let teste = { lat: -30.0248627, lng: -51.2013986 }
    //   this.setState({defaultCenter: teste})
    //   return
    // }

    // navigator.geolocation.getCurrentPosition(
    //   position => {
    //     var lat = parseFloat(position.coords.latitude);
    //     var long = parseFloat(position.coords.longitude);

    //     var initialRegion = {
    //       lat: lat,
    //       lng: long,
    //     }

    //     this.setState({ defaultCenter: initialRegion});
    //   },
    //   error => this.setState({ error: error.message }),
    //   { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 },
    // );
    console.log(this.state)
  }

  componentWillUnmount() {
    this.unmount = true;

    clearInterval(this.pingInterval);
    clearTimeout(this.getDriversTimeout)
    clearTimeout(this.initTimeout)

    this.socket && this.socket.disconnect();
  }

  init() {
    const socket = io(process.env.REACT_APP_SOCKET_IO_URL, {
      transports: ['websocket'],
      randomizationFactor: 0,
      upgrade: false,
      rejectUnauthorized: false
    });

    socket.on('authenticated', data => {
      console.log('authenticated', data)
      console.log('socketId', socket.id)

      clearInterval(this.pingInterval);

      this.pingInterval = setInterval(() => {
        socket.emit('PING', '1');
      }, 2000)
    })

    socket.on('unauthorized', data => {
      console.log('unauthorized', data)
    })

    socket.on('connect', () => {
      let token = this.props.account.api_token;
      console.log('authenticating..', { token } )
      socket.emit('authentication', { token });
    })

    socket.on('disconnect', () => {
      clearInterval(this.pingInterval);

      console.log('disconnected')
    })

    socket.on('UPDATE_DRIVER_POSITION', ({ driver_id, location }) => {
      if (this.unmount) {
        return;
      }

      let drivers = this.state.drivers.map(driver => {
        if (driver.id == driver_id) {
          console.log('UPDATE_DRIVER_POSITION', driver_id, location)
          return {
            ...driver,
            lat: location.latitude,
            lng: location.longitude,
            bearing: location.bearing
          }
        }

        return { ...driver };
      })

      setTimeout(() => {
        this.setState({
          drivers
        })
      }, 100)
    })

    this.socket = socket;

    this.getDrivers()
  }

  onCenterChanged() {
    let center = this.map.getCenter();
    this.center = {
      lat: center.lat(),
      lng: center.lng()
    }

    this.getDrivers();
  }

  onToggleOpen(id) {
    let drivers = this.state.drivers.map(driver => {
      return { ...driver, isOpen: driver.id == id ?
        !driver.isOpen
        : false
      }
    })

    this.setState({ drivers })
  }

  async getDrivers() {
    clearTimeout(this.getDriversTimeout);

    let pastDrivers = this.state.drivers.map(({ id }) => id);

    let drivers;
    try {
      if (this.cancelToken) {
        this.cancelToken.cancel('cancelled');
      }
      this.cancelToken = axios.CancelToken.source();

      let { lat:latitude, lng: longitude } = this.center;
      let radius = this.getRadius();

      drivers = await Api.getOnlineDrivers(
        {
          latitude,
          longitude,
          city_id: this.props.city_id,
        },
        radius,
        this.cancelToken.token
      );

      if (this.unmount) {
        return;
      }

      /*
      for (let driver of drivers) {
        if (pastDrivers.indexOf(driver.id) == -1) { // subscribe
          console.log('trackDriver', driver.id)
          this.socket.emit('TRACK_DRIVER', driver.id)
        }
      }

      let driversIds = drivers.map(({ id }) => id);
      for (let id of pastDrivers) {
        if (driversIds.indexOf(id) == -1) { //unsubscribe
          console.log('untrackDriver', id)
          this.socket.emit('UNTRACK_DRIVER', id);
        }
      }
      */

      this.setState({ drivers })
    } catch (e) {
      console.log(e)
    }

    this.getDriversTimeout = setTimeout(this.getDrivers, 4000);
  }

  getRadius() {
    let zoom = 15;
    let radius = 0;
    if (this.map) {
      zoom = this.map.getZoom();
    }

    if (zoom <= 8) {
      radius = 10000;
    } else if (zoom <= 10) {
      radius = 300;
    } else if (zoom <= 12) {
      radius = 100;
    } else if (zoom == 13) {
      radius = 30;
    } else if (zoom == 14) {
      radius = 20;
    } else {
      radius = 10;
    }

    console.log({
      zoom,
      radius
    })

    return radius * 1000;
  }

  render() {



    return (
      <div className="page-list">


        {
          // this.state.defaultCenter.lat && this.state.defaultCenter.lng ?
          <MapComponent
            onRef={ref => { this.map = ref }}
            drivers={this.state.drivers}
            onDragEnd={this.onCenterChanged}
            defaultCenter={this.state.defaultCenter && this.state.defaultCenter}
            onToggleOpen={this.onToggleOpen}
          />
        }

      </div>
    )
  }
}


const mapStateToProps = state => ({
  account: state.account,
  city_id: state.preferences.city_id,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MapsPage)
