import React, { useEffect, useCallback, useState } from "react";
import { Button } from "reactstrap";
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { push } from 'react-router-redux';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import "bootstrap/dist/css/bootstrap.min.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "../styles/css/cupons.css";
import "../styles/css/theme.css";

import { RouteWithSubRoutes } from '../';
import { store } from '../store';
import FilterCupons from "../components/filter-promo";
import { Avatar } from "primereact/avatar";
import { getCategories } from '../actions/categories';
import { listPromocodes, updatePromocode, removePromocode } from '../actions/promocodes';
import { PromocodeStatus } from "../api/types";

const PromocodesPage = ({ match, routes, categories, promocodes, listPromocodes, updatePromocode, removePromocode, getCategories }) => {
  const city_id = useSelector(state => state.preferences.city_id);

  useEffect(() => {
    if (!categories || categories.length < 1) {
      getCategories()
    }
  }, [])

  useEffect(() => {
    listPromocodes(city_id ? { city_id } : null)
  }, [ city_id ])

  const [ busy, setBusy] = useState({})

  const handleChangeFilters = filters => listPromocodes({...filters, city_id })

  const showPromo = useCallback(id => store.dispatch(push(`/promocodes/id/${id}`)))

  const deletePromo = async (id, event) => {
    event.stopPropagation()

    if (!window.confirm('Deseja realmente excluir este promocode?')) {
      return
    }

    const { error } = await removePromocode(id)
    if (error) {
      toast.error(error.message)
    } else {
      toast.success('Promocode removido com sucesso')
    }
  };

  const handleLock = async (promocode, event) => {
    event.stopPropagation()

    if (!['active', 'paused'].includes(promocode.status) || busy[promocode.id]) {
      return
    }

    setBusy({
      ...busy,
      [promocode.id]: true
    })
    const { error } = await updatePromocode({
      id: promocode.id,
      status: promocode.status === 'active'
        ? 'paused'
        : 'active'
    })
    if (error) {
      toast.error(error.message)
    }
    setBusy({
      ...busy,
      [promocode.id]: false
    })
  }

  if (!(match.path === "/promocodes" && match.isExact)) {
    return routes.map((route, i) => <RouteWithSubRoutes key={i} {...route} />);
  }

  return (
    <div>
      <div
        className="container-fluid"
        style={{ paddingLeft: 40, paddingRight: 40 }}
      >
        <div className="header-buttons">
          <div>
            <h3>Promocodes</h3>{" "}
          </div>

          <div>
            {" "}
            <Link to={"/promocodes/create"}>
              {" "}
              <Button color="success">Criar novo cupom</Button>{" "}
            </Link>
          </div>
        </div>

        <main>
          <FilterCupons
            categories={categories}
            onSubmit={handleChangeFilters}
          />

          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th>Código</th>
                <th>% </th>
                <th>Resgatados</th>
                <th>Utilizados</th>
                <th>Limite de utilização individual</th>
                <th>Total</th>
                <th>Receita</th>
                <th>Descontado</th>
                <th>Validade</th>
                <th>Status</th>
                <th>Ações</th>
              </tr>
            </thead>

            <tbody>
              {promocodes.map((cupom, index) => (
                <tr
                  key={index}
                  onClick={() => showPromo(cupom.id)}
                  style={{ cursor: "pointer" }}
                >
                  <td>
                    {cupom.code}
                  </td>
                  <td>
                    {cupom.discount}
                  </td>
                  <td>
                    {cupom.added || 0}
                  </td>
                  <td>
                    {cupom.used || 0}
                  </td>
                  <td>
                    {cupom.max_per_user}
                  </td>
                  <td>
                    {cupom.total}
                  </td>
                  <td>
                    R$ {(cupom.received_amount / 100).toFixed(2)}
                  </td>
                  <td>
                    R$ {(cupom.discount_amount / 100).toFixed(2)}
                  </td>
                  <td>
                    {moment(cupom.end_at).format('DD/MM/YYYY')}
                  </td>
                  <td>
                    { PromocodeStatus[cupom.status === 'active' && moment(cupom.end_at).isBefore(moment()) ? 'inactive' : cupom.status] }
                  </td>

                  <td>
                    <div className="action" style={{ display: "flex" }}>
                      <div className="action-icon1">
                        <button onClick={e => deletePromo(cupom.id, e)}>
                          {" "}
                          <Avatar
                            icon="pi pi-trash"
                            className="p-mr-2 avatar"
                            style={{
                              backgroundColor: "#50efb6",
                              color: "black",
                            }}
                            shape="circle"
                          />
                        </button>
                      </div>
                      <div>
                        {
                          (cupom.status === 'active' || cupom.status === 'paused') &&
                            <div className={`action-icon${cupom.status === 'active' ? 2 : 3}`}>
                              <button
                                title={cupom.status === 'paused' ? 'Ativar' : 'Pausar'}
                                onClick={e => handleLock(cupom, e)}
                              >
                                {" "}
                                <Avatar
                                  icon={`pi pi-lock${cupom.status === 'paused' ? '-open': ''}`}
                                  className="p-mr-2 avatar"
                                  style={{
                                    backgroundColor: cupom.status === 'active'
                                      ? "#50efb6"
                                      : "#feffff",
                                    color: "black",
                                  }}
                                  shape="circle"
                                />
                              </button>
                            </div>
                        }
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </main>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  promocodes: state.promocodes.all,
  categories: state.categories.all,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    listPromocodes,
    updatePromocode,
    removePromocode,
    getCategories,
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PromocodesPage)
