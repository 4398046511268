import React from "react";
import "../../../styles/statistics.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { object } from "prop-types";
import { useSelector } from "react-redux";

export const DriversOnline = () => {
  const { statisticsDrivers } = useSelector((state) => state.statistics);
  return (
    <div className="driver__on_off">
      <div className="title">
        <ScheduleIcon />
        <h4>Motoristas Online/Total</h4>
      </div>

      <div>
        <span>
          <b>{statisticsDrivers.online}/</b>{" "}
          <strong>{statisticsDrivers.total}</strong>
        </span>
      </div>
    </div>
  );
};

DriversOnline.propTypes = {
  statisticsDrivers: object,
};
