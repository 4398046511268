import styled from "styled-components";

export const WhiteDrawSumary = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 60px;
`;

export const WhiteDrawTitle = styled.div`
  font-weight: 800;
  font-size: 18px;
`;

export const WhiteDrawValue = styled.div`
  font-weight: 400;
  font-size: 16px;
`;

export const RequestWhiteDrawButton = styled.button`
  padding: 15px;
  background-color: ${({ disabled }) => (disabled ? "gray" : "#50efb6")};
  color: ${({ disabled }) => (disabled ? "white" : "#212529")};
`;

export const RequestWhiteDrawInfo = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
`;
