import { BankAccountInitialValues } from "constants/BankAccount.constant";
import {
  GET_DRIVERS_SUCCESS,
  GET_DRIVER,
  GET_DRIVER_SUCCESS,
  UPDATE_DRIVER_PROPS,
  SET_DRIVER,
  CREATE_DRIVER_SUCCESS,
  UPDATE_DRIVER_SUCCESS,
  GET_DEBITS_SUCESS,
  UPDATE_DRIVER_CAR,
  UNLOCK_DRIVER,
} from "../actions/drivers";

const initialState = {
  debits: 0,
  drivers: [],
  params: {
    page: 0,
    search: "",
    category: null,
    situation: null,
    status: null,
    rides: null,
  },
  pages: 0,
  driver: {
    id: "",
    name: "",
    surname: "",
    email: "",
    phone: "",
    avatar_small: "",
    situation: 1,
    status: 0,
    rating: 0,
    n_ratings: 0,
    n_rides: 0,
    categories: [],
    moip_account_id: "",
    payment_methods: 3,
    inactive_id: "",
    car: {
      name: "",
      color: "",
      license_plate: "",
    },
    cars: [],
    info: {
      cpf: "",
      gender: 1,
      address: {
        city: "",
        state: "",
        street: "",
        country: "BRA",
        zipCode: "",
        district: "",
        streetNumber: "",
      },
      birthdate: null,
    },
    bankAccount: BankAccountInitialValues,
  },
};

const reducer = (state = initialState, action) => {
  //console.log('State', state)
  //console.log('action', action)
  switch (action.type) {
    case GET_DRIVERS_SUCCESS:
      return { ...state, ...action.payload };

    case GET_DRIVER: {
      return {
        ...state,
        driver: { ...initialState.driver, ...initialState.debits },
      };
    }

    case UPDATE_DRIVER_PROPS: {
      return { ...state, driver: { ...state.driver, ...action.props } };
    }

    case SET_DRIVER: {
      return {
        ...state,
        driver:
          action.driver != null ? action.driver : { ...initialState.driver },
      };
    }

    case UPDATE_DRIVER_CAR: {
      const { driver } = state;
      return {
        ...state,
        driver: {
          ...driver,
          cars: driver.cars.map((car) =>
            car.id === action.car.id ? { ...action.car } : car
          ),
        },
      };
    }

    case GET_DRIVER_SUCCESS: {
      let driver =
        action.driver != null ? action.driver : { ...initialState.driver };

      if (!driver.car) {
        driver.car = {};
      }

      if (!driver.info) {
        driver.info = {
          address: {},
        };
      }

      return { ...state, driver };
    }

    case CREATE_DRIVER_SUCCESS: {
      let drivers = [action.driver, ...state.drivers];
      return { ...state, drivers, driver: action.driver };
    }

    case UPDATE_DRIVER_SUCCESS: {
      let drivers = state.drivers.map((driver) => {
        return driver.id == action.driver.id
          ? { ...driver, ...action.driver }
          : driver;
      });

      return { ...state, drivers };
    }

    case GET_DEBITS_SUCESS: {
      return { ...state, debits: action.debits };
    }

    case UNLOCK_DRIVER: {
      return { ...state };
    }

    default:
      return state;
  }
};

export default reducer;
