import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { func, object, array} from 'prop-types';
import { push } from 'react-router-redux';
import { RouteWithSubRoutes } from "../";
import { store } from "../store";
import '../styles/css/driver-info.css';


class DriverPage extends Component {

  static propTypes = {
    getDriver: func,
    createDriver: func,
    updateDriver: func,
    updateDriverProps: func,
    setDriver: func,
    driver: object,
    match: object,
    history: object,
    categories: array,
    location: object,
    routes: object
  }

  constructor(props) {
    super();

    this.showInfo = this.showInfo.bind(this)
    this.showRides = this.showRides.bind(this)
    this.showExtract = this.showExtract.bind(this)
    this.showBankAccount = this.showBankAccount.bind(this)
  }

  showInfo() {
    store.dispatch(push(`/drivers/id/${this.props.match.params.id}/info`));
  }

  showRides() {
    store.dispatch(push(`/drivers/id/${this.props.match.params.id}/rides`));
  }

  showExtract() {
    store.dispatch(push(`/drivers/id/${this.props.match.params.id}/extracts`));
  }

  showBankAccount() {
    store.dispatch(push(`/drivers/id/${this.props.match.params.id}/bank-account`));
  }

  render() {
    const path = this.props.location.pathname.split('/').pop();

    return (
      <div>
        <div className="header-buttons">
          <h3>{ this.props.driver.name } { this.props.driver.surname }</h3>
        </div>

        <ul className="nav nav-pills" style={{ marginBottom: 30 }}>
          <li className="nav-item">
            <a className={`nav-link ${path == 'info' ? 'active' : ''}`} onClick={this.showInfo}>Informações</a>
          </li>
          <li className="nav-item">
            <a className={`nav-link ${path == 'rides' ? 'active' : ''}`} onClick={this.showRides}>Viagens</a>
          </li>
          <li className="nav-item">
            <a className={`nav-link ${path == 'extracts' ? 'active' : ''}`} onClick={this.showExtract}>Extrato</a>
          </li>
          <li className="nav-item">
            <a className={`nav-link ${path == 'bank-account' ? 'active' : ''}`} onClick={this.showBankAccount}>Dados Bancários</a>
          </li>
        </ul>
        {
          this.props.routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))
        }
      </div>
    )
  }

}

const mapStateToProps = state => ({
  driver: state.drivers.driver,
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({

  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DriverPage)
