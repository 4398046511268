import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { login } from '../actions/auth';
import '../styles/css/login.css';
import imgLogo from "../assets/images/mobyGO-circle.png";

class LoginPage extends Component {
  constructor() {
    super();
    this.state = { email: '', password: '' };
    this._handleSubmit = this._handleSubmit.bind(this);
    this._handleInputChange = this._handleInputChange.bind(this);
  }

  async _handleSubmit(event) {
    event.preventDefault();

    let { email, password } = this.state;
    if (email.length < 5) {
      return toast.warn('Preencha seu email')
    }
    if (password.length < 5) {
      return toast.warn('Preencha sua senha')
    }

    let { error } = await this.props.login(this.state);

    if (error) {
      toast.error(
        error.message == 'NotFoundError'
          ? 'Acesso negado'
          : 'Erro efetuar o login'
      )
    } else {
      toast.success('Autenticado');
    }
  }

  _handleInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  render() {
    return (
      <div className="container login-page">
        <div className="card card-container">
          <img id="profile-img" src={imgLogo} alt="" />
          <p id="profile-name" className="profile-name-card" />
          <form onSubmit={this._handleSubmit} className="form-signin">
            <input
              className="form-control"
              type="email"
              name="email"
              placeholder="email"
              value={this.state.email}
              onChange={this._handleInputChange}
            />
            <input
              className="form-control"
              type="password"
              name="password"
              placeholder="password"
              value={this.state.password}
              onChange={this._handleInputChange}
            />
            <button className="btn btn-lg btn-primary btn-block btn-signin" type="submit">Login</button>
          </form>
        </div>
      </div>
    )
  }
}

LoginPage.propTypes = {
  login: PropTypes.func,
  account: PropTypes.object
}

const mapStateToProps = state => ({
  account: state.account
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    login
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);