/* eslint-disable arrow-parens */
import React, { useCallback, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";

import { getBankAccountByDriver, updateDriverBank } from "../actions/drivers";
import BankAccount from "components/bankAccount/BankAccount";
import { object, func } from "prop-types";
import { BankAccountInitialValues } from "constants/BankAccount.constant";

const DriverBankAccount = ({ driver, getBankAccountByDriver, match }) => {
  const [bank, setBank] = useState(BankAccountInitialValues);
  const driverId = driver?.id || match?.params?.id;
  const dispatch = useDispatch();

  const getBankAccountDriver = useCallback(async () => {
    try {
      const { account } = await getBankAccountByDriver(driverId);
      setBank(account);
    } catch (error) {
      console.error(error);
    }
  }, [getBankAccountByDriver, driverId]);

  useEffect(() => {
    getBankAccountDriver();
  }, [getBankAccountDriver]);

  const updateBank = async (bankAccount) => {
    try {
      dispatch(updateDriverBank(driverId, bankAccount));
    } catch (error) {
      console.error(error.message);
    }
  };

  let intermed = Object.assign({}, driver);

  let saldo = intermed.balance;
  let saldoFinal = saldo ? saldo.toFixed(2) / 100 : (0.0).toFixed(2);

  return (
    <div>
      <div style={{ width: 500, marginLeft: "auto", marginRight: "auto" }}>
        <div style={{ width: 500, marginLeft: "auto", marginRight: "auto" }}>
          <div className="card bg-light">
            <div className="card-body">
              <div className="form-group">
                <label>Saldo a receber</label>
                <div>R$ {saldoFinal} </div>
              </div>
            </div>
          </div>

          <BankAccount
            bank={bank}
            getBankAccountDriver={getBankAccountDriver}
            updateBank={updateBank}
          />
        </div>
      </div>
    </div>
  );
};

DriverBankAccount.propTypes = {
  getBankAccountByDriver: func,
  updateDriverBank: func,
  driver: object,
  match: object,
};

const mapStateToProps = (state) => ({
  driver: state.drivers.driver,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getBankAccountByDriver,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DriverBankAccount);
