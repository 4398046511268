import React, { useEffect, useState } from "react";
import "../styles/franchiseeDetails.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { store } from "../store";
import { push } from "react-router-redux";
import api from "../api";
import { useSelector } from "react-redux";

export const FranchiseeDetails = ({ match }) => {
  const user = useSelector(state => state.account);

  const [franchisees, setFranchisees] = useState([]);
  const [operation, setOperation] = useState({});
  const { id } = match.params;

  useEffect(() => {
    const handleFranchisees = async () => {
      const operation = await api.getFranchiseesId(id);
      setOperation(operation);

      const collaborations = await api.getCollaboratorCity({
        city_id: id,
      });
      setFranchisees(collaborations);
    };

    handleFranchisees();
  }, []);

  const show = () => {
    store.dispatch(push(`/franchisees-payment/id/${id}`));
  };

  const back = () => {
    const route = id
      ? `/franchisees-details/id/${id}`
      : `/franchisees`

    store.dispatch(push(route));
  };

  const personalData = id => {
    store.dispatch(push(`/franchisees-details/id/${id}`));
  };

  const personalDataAdmin = id_admin => {
    store.dispatch(push(`/franchisees-details/id/${id_admin}/admin/${id}`));
  };

  return (
    <div className="container__franchisees">
      <div className="wrapper">
        <div className="header">
          <div className="title">
            <i className="pi pi-arrow-left" onClick={back} />
            <h1>
              Equipe - <span>{operation.city?.name || ''}</span>
            </h1>
          </div>

          <div className="btns">
            <button className="btn" onClick={() => personalData(id)}>
              Ver dados da franquia
            </button>

            <button onClick={() => show()}>Ver pagamentos</button>

            {(user.role === 'admin' || user.permissions.admins) &&
              <Link to="/franchisees-add-login" className="btn__add__login">
                <button>Adicionar login</button>
              </Link>
            }
          </div>
        </div>

        <div className="filter">
          {/* <FilterFranchisees data={cities} /> */}
        </div>

        <div className="main">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                {/* <th style={{ width: 50 }}>ID</th> */}
                <th>Nome</th>
                <th>Email</th>

                {/* <th>Telefone</th> */}
                <th>Cargo</th>
              </tr>
            </thead>
            <tbody>
              {franchisees.length &&
                franchisees.map((item, index) => (
                  <tr key={index} onClick={() => personalDataAdmin(item.id)}>
                    <td>{item?.name}</td>
                    <td>{item?.email}</td>

                    <td>
                      <span>{item.role_description}</span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
