import Api from '../api'

export const LIST_PROMOCODES = 'LIST_PROMOCODES'
export const LIST_PROMOCODES_ERROR = 'LIST_PROMOCODES_ERROR'
export const LIST_PROMOCODES_SUCCESS = 'LIST_PROMOCODES_SUCCESS'

export const GET_PROMOCODE = 'GET_PROMOCODE'
export const GET_PROMOCODE_ERROR = 'GET_PROMOCODE_ERROR'
export const GET_PROMOCODE_SUCCESS = 'GET_PROMOCODE_SUCCESS'

export const CREATE_PROMOCODE = 'CREATE_PROMOCODE'
export const CREATE_PROMOCODE_ERROR = 'CREATE_PROMOCODE_ERROR'
export const CREATE_PROMOCODE_SUCCESS = 'CREATE_PROMOCODE_SUCCESS'

export const UPDATE_PROMOCODE = 'UPDATE_PROMOCODE'
export const UPDATE_PROMOCODE_ERROR = 'UPDATE_PROMOCODE_ERROR'
export const UPDATE_PROMOCODE_SUCCESS = 'UPDATE_PROMOCODE_SUCCESS'

export const REMOVE_PROMOCODE = 'REMOVE_PROMOCODE'
export const REMOVE_PROMOCODE_ERROR = 'REMOVE_PROMOCODE_ERROR'
export const REMOVE_PROMOCODE_SUCCESS = 'REMOVE_PROMOCODE_SUCCESS'

export const listPromocodes = params => async (dispatch, getState) => {
  dispatch({
    type: LIST_PROMOCODES
  })

  try {
    const all = await Api.listPromocodes(params)

    return dispatch({
      type: LIST_PROMOCODES_SUCCESS,
      all
    })
  } catch (error) {
    return dispatch({
      type: LIST_PROMOCODES_ERROR,
      error
    })
  }
}

export const getPromocode = id => async (dispatch, getState) => {
  dispatch({
    type: GET_PROMOCODE
  })

  try {
    const promocode = await Api.getPromocode(id)

    return dispatch({
      type: GET_PROMOCODE_SUCCESS,
      promocode,
    })
  } catch (error) {
    return dispatch({
      type: GET_PROMOCODE_ERROR,
      error
    })
  }
}

export const createPromocode = promocode => async (dispatch, getState) => {
  dispatch({
    type: CREATE_PROMOCODE
  })

  try {
    promocode = await Api.createPromocode(promocode)

    return dispatch({
      type: CREATE_PROMOCODE_SUCCESS,
      promocode
    })
  } catch (error) {
    return dispatch({
      type: CREATE_PROMOCODE_ERROR,
      error
    })
  }
}

export const updatePromocode = promocode => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_PROMOCODE
  })

  try {
    promocode = await Api.updatePromocode(promocode)

    return dispatch({
      type: UPDATE_PROMOCODE_SUCCESS,
      promocode
    })
  } catch (error) {
    return dispatch({
      type: UPDATE_PROMOCODE_ERROR,
      error
    })
  }
}

export const removePromocode = id => async (dispatch, getState) => {
  dispatch({
    type: REMOVE_PROMOCODE,
    id,
  })

  try {
    await Api.removePromocode(id)

    return dispatch({
      type: REMOVE_PROMOCODE_SUCCESS,
      id,
    })
  } catch (error) {
    return dispatch({
      type: REMOVE_PROMOCODE_ERROR,
      error
    })
  }
}
