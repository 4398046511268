import styled from 'styled-components';
import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';

export const DropDownHistory = styled(Dropdown)`
  width: 100px;
  color: black;

`

export const DropdownToggleHistory = styled(DropdownToggle)`
`

export const DropdownMenuHistory = styled(DropdownMenu)`

`

export const DropdownItemHistory = styled(DropdownItem)`

`
