import "../styles/franchiseesPaymentAdd.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import moment from "moment";

import { store } from "../store";
import { push } from "react-router-redux";
import api from "../api";

const initialValues = {
  price: "",
  date: "",
  cod: "",
  observation: "",
  payment: "",
};

export const FranchiseesPaymentAdd = ({ match }) => {
  const [, setCities] = useState([]);
  const [values, setValues] = useState(initialValues);

  const { id, payment_id } = match.params

  useEffect(() => {
    if (payment_id) {
      api.getPayment(id, payment_id)
        .then(rs => {
          setValues({
            price: rs.amount,
            payment: rs.payment_method,
            cod: rs.code,
            date: rs.date,
            observation: rs.description,
            operation_id: rs.operation_id,
          })
        })
    }
  }, [])

  const HandleOnChange = e => {
    const { name, value } = e.target;

    setValues({ ...values, [name]: value });
  };

  const handleCreateUpdatePayment = async () => {
    try {
      const payload = {
        amount: Number(values.price),
        payment_method: values.payment,
        code: values.cod,
        description: values.observation,
        operation_id: id,
        date: values.date,
      }

      if (payment_id) {
        await api.updatePayment(payment_id, payload);
      } else {
        await api.createPayment(payload);
      }

      toast.success(`Pagamento ${payment_id ? 'editado' : 'criado'} com sucesso!`);

      setTimeout(() => {
        store.dispatch(push(`/franchisees-payment/id/${id}`));
      }, 2000);
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
    }
  };

  const handleChangeDate = date => setValues(values => ({...values, date }))

  useEffect(() => {
    const handleCities = async () => {
      const cities = await api.cities();
      setCities(cities);
    };

    handleCities();
  }, []);

  return (
    <div className="container__franchisees__payment__add">
      <div className="wrapper">
        <div className="header">
          <h1>Adicionar novo pagamento</h1>
        </div>
        <div className="main">
          <form>
            <div className="form__header">
              <h5>Dados do novo pagamento</h5>
            </div>
            <div className="wrapper__form">
              <div className="form-group">
                <label>Valor</label>
                <input
                  type="text"
                  placeholder="R$"
                  className="form-control"
                  name="price"
                  value={values.price}
                  onChange={HandleOnChange}
                />
              </div>

              <div className="form-group">
                <label>Data</label>
                <DatePicker
                  showYearDropdown
                  dropdownMode="select"
                  customInput={<CustomDateInput />}
                  selected={
                    values.date
                      ? moment(values.date)
                      : moment()
                  }
                  onChange={handleChangeDate}
                />
              </div>

              <div className="form-group">
                <label>Código</label>
                <input
                  type="text"
                  placeholder="XXXX"
                  className="form-control"
                  name="cod"
                  value={values.cod}
                  onChange={HandleOnChange}
                />
              </div>

              <div className="form-group">
                <label>Observação</label>
                <input
                  type="text"
                  placeholder="Insira aqui uma observação"
                  className="form-control"
                  name="observation"
                  value={values.observation}
                  onChange={HandleOnChange}
                />
              </div>

              <div className="form-group">
                <label>Método de pagamento</label>
                <input
                  type="text"
                  placeholder="Insira aqui um método de pagamento"
                  className="form-control"
                  name="payment"
                  value={values.payment}
                  onChange={HandleOnChange}
                />
              </div>
            </div>
          </form>

          <div className="btn">
            <button
              onClick={() =>
                store.dispatch(push(`/franchisees-payment/id/${id}`))
              }
            >
              Cancelar
            </button>
            <button onClick={handleCreateUpdatePayment}>
              {
                payment_id
                  ? 'Editar'
                  : 'Adicionar'
              }
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};


class CustomDateInput extends React.PureComponent {
  render() {
    return (
      <div onClick={this.props.onClick}>
        <div className="input-group">
          <div className="input-group-prepend">
            <span
              className="input-group-text"
              id="validationTooltipUsernamePrepend"
            >
              <i className="far fa-calendar-alt" />
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            value={
              this.props.value
                ? moment(this.props.value).format("DD/MM/YYYY")
                : ""
            }
            readOnly
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
    );
  }
}
