// @flow
import Api from "../api";
import { ListRidesParams } from "../api/types";

export const GET_RIDES = "GET_RIDES";
export const GET_RIDES_SUCCESS = "GET_RIDES_SUCCESS";
export const GET_RIDES_ERROR = "GET_RIDES_ERROR";

export const GET_RIDE = "GET_RIDE";
export const GET_RIDE_SUCCESS = "GET_RIDE_SUCCESS";
export const GET_RIDE_ERROR = "GET_RIDE_ERROR";

export const RELEASE_RIDE = "RELEASE_RIDE";
export const RELEASE_RIDE_SUCCESS = "RELEASE_RIDE_SUCCESS";
export const RELEASE_RIDE_ERROR = "RELEASE_RIDE_ERROR";

export const getRides =
  (params: ListRidesParams) => async (dispatch, getState) => {
    dispatch({
      type: GET_RIDES,
      params,
    });

    let { city_id } = getState().preferences;

    try {
      let {
        results: rides,
        totals,
        page,
        pages,
      } = await Api.getRides({ ...params, city_id });

      return dispatch({
        type: GET_RIDES_SUCCESS,
        rides,
        totals,
        page,
        pages,
        params,
      });
    } catch (error) {
      console.log(error);

      return dispatch({
        type: GET_RIDES_ERROR,
        error,
        params,
      });
    }
  };

export const getRide = (id) => async (dispatch, getState) => {
  dispatch({
    type: GET_RIDE,
    id,
  });

  try {
    let ride = await Api.getRide(id);

    return dispatch({
      type: GET_RIDE_SUCCESS,
      ride,
    });
  } catch (error) {
    console.log(error);

    return dispatch({
      type: GET_RIDE_ERROR,
      error,
      id,
    });
  }
};

export const releaseRide = (id) => async (dispatch, getState) => {
  dispatch({
    type: RELEASE_RIDE,
    id,
  });

  try {
    let ride = await Api.releaseRide(id);

    return dispatch({
      type: RELEASE_RIDE_SUCCESS,
      ride,
    });
  } catch (error) {
    console.log(error);

    return dispatch({
      type: RELEASE_RIDE_ERROR,
      error,
      id,
    });
  }
};
