import React, { useState } from 'react'
import * as S from './style'

const FormFilters = props => {
  let [dropdownOpen, setDropdownOpen] = useState(false)

  const toggle = () => {
    setDropdownOpen(!dropdownOpen)
  }

  return (
    <S.DropDownHistory isOpen={dropdownOpen} toggle={toggle}>
      <S.DropdownToggleHistory caret>
        Filtar Notificações
      </S.DropdownToggleHistory>
      <S.DropdownMenuHistory >
        <S.DropdownItemHistory header>Tipo de notificações</S.DropdownItemHistory>
        <S.DropdownItemHistory onClick={() => props.handleClean()} value={''}>Todas</S.DropdownItemHistory>
        <S.DropdownItemHistory onClick={e => props.handleScheduled(e)} value={'scheduled'}>Agendadas</S.DropdownItemHistory>
        <S.DropdownItemHistory divider />
        <S.DropdownItemHistory header>Público Alvo</S.DropdownItemHistory>
        <S.DropdownItemHistory onClick={e => props.handleTarget(e)} value={1}>Motoristas</S.DropdownItemHistory>
        <S.DropdownItemHistory onClick={e => props.handleAgended(e)} value={'MotoristasAgendados'}>Motoristas Agendado</S.DropdownItemHistory>
        <S.DropdownItemHistory onClick={e => props.handleTarget(e)} value={2}>Passageiros</S.DropdownItemHistory>
        <S.DropdownItemHistory onClick={e => props.handleAgended(e)} value={'PassageiroAgendados'}>Passageiros Agendado</S.DropdownItemHistory>
        <S.DropdownItemHistory onClick={e => props.handleTarget(e)} value={3}>Motorista</S.DropdownItemHistory>
        <S.DropdownItemHistory onClick={e => props.handleTarget(e)} value={4}>Passageiro</S.DropdownItemHistory>

      </S.DropdownMenuHistory>
    </S.DropDownHistory>
  )
}

export default FormFilters
