import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getRides } from '../actions/rides';
import Paginator from '../components/paginator';
import Rides from '../components/rides';
import RidesTotals from '../components/riders-totals';
import RidesFilter from '../components/rides-filter';
import '../styles/css/drivers.css';

class RidesContainer extends Component {

  static propTypes = {
    rides: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    totals: PropTypes.object,
    byUserId: PropTypes.object,
    searchParams: PropTypes.object,
    categories: PropTypes.array,
    page: PropTypes.number,
    pages: PropTypes.number,
    getRides: PropTypes.func,
    onSelectRide: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);

    this.onChangePage = this.onChangePage.bind(this);
    this.onChangeParams = this.onChangeParams.bind(this);
    this.onSelectRide = this.onSelectRide.bind(this);

    let { user_id, driver_id } = props.searchParams;

    this.user_id = user_id || driver_id;
  }

  componentDidMount() {
    let { user_id, driver_id } = this.props.searchParams;

    this.props.getRides({ user_id, driver_id });
  }

  onChangePage(page) {
    let { user_id, driver_id } = this.props.searchParams;

    this.props.getRides({ user_id, driver_id, page });
  }

  onSelectRide(id) {
    //store.dispatch(push(`/ride/id/${id}`));
    this.props.onSelectRide(id);
  }

  onChangeParams(params) {
    this.props.getRides({ ...params, page: 1 })
  }

  render() {
    let data = this.props.byUserId[this.user_id];
    if (!data) {
      return <div />;
    }

    const { rides, totals, page, pages, params } = data;

    return (
      <div>
        <div>
          <RidesFilter
            params={params}
            categories={this.props.categories}
            onSubmit={this.onChangeParams}
          />

          <RidesTotals
            totals={totals}
          />

          <Rides
            rides={rides}
            params={params}
            showMobyDebit
            onSelect={this.onSelectRide}
          />

          <Paginator
            page={page}
            pages={pages}
            onChange={this.onChangePage}
          />

          {
            `Exibindo ${rides.length} de ${totals.n_rides}`
          }
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  byUserId: state.rides.byUserId,
  categories: state.categories.all
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    getRides
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RidesContainer)
