import React, { Component } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import moment from "moment";
import isEqual from "react-fast-compare";
import { faturedRideConst } from "../constants/index";

export default class RidesFilter extends Component {
  static propTypes = {
    params: PropTypes.object,
    categories: PropTypes.array.isRequired,
    promocodes: PropTypes.array,
    onSubmit: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.handleStartDtChange = this.handleStartDtChange.bind(this);
    this.handleEndDtChange = this.handleEndDtChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.submit = this.submit.bind(this);

    this.state = {
      ...props.params,
      params: props.params,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return isEqual(props.params, state.params)
      ? null
      : { ...props.params, params: props.params };
  }

  submit() {
    let state = { ...this.state };
    delete state.params;

    this.props.onSubmit(state);
  }

  handleStartDtChange(start_at) {
    this.setState({ start_at });
  }

  handleEndDtChange(end_at) {
    this.setState({ end_at });
  }

  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    this.submit();
    event.preventDefault();
  }

  render() {
    let params = this.state;

    return (
      <form className="form-row" onSubmit={this.handleSubmit}>
        <div className="form-group col-md-2">
          <label>Busca por local: </label>
          <input
            type="text"
            name="search"
            className="form-control"
            value={params.search || ""}
            onChange={this.onChange}
            placeholder="Endereço ou cidade.."
          />
        </div>
        <div className="form-group col-md-1">
          <label>Categoria: </label>
          <br />
          <select
            className="form-control custom-select"
            name="category"
            value={params.category || ""}
            onChange={this.onChange}
          >
            <option value="" />
            {this.props.categories.map(category => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group col-md-1">
          <label> Pagamento: </label>
          <select
            value={params.payment_method || ""}
            onChange={this.onChange}
            className="form-control custom-select"
            name="payment_method"
          >
            <option value="" />
            <option value={1}>Dinheiro</option>
            <option value={2}>Cartão</option>
          </select>
        </div>
        {Array.isArray(this.props.promocodes) && (
          <div className="form-group col-md-1">
            <label>Cupom: </label>
            <br />
            <select
              className="form-control custom-select"
              name="promocode_id"
              value={params.promocode_id || ""}
              onChange={this.onChange}
            >
              <option value="" />
              {this.props.promocodes.map(promocode => (
                <option key={promocode.id} value={promocode.id}>
                  {promocode.code}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="form-group col-md-1" style={{ minWidth: "160px" }}>
          <label>Status: </label>
          <select
            value={params.status_method || ""}
            onChange={this.onChange}
            className="form-control custom-select"
            name="status_method"
          >
            <option value="" />
            <option value={10}>Solicitando viagem</option>
            <option value={20}>Aguardando a resposta do motorista</option>
            <option value={30}>Aguardando motorista</option>
            <option value={40}>Em andamento</option>
            <option value={50}>Finalizada</option>
            <option value={100}>Cancelada pelo motorista</option>
            <option value={105}>Cancelada pelo usuário</option>
            <option value={110}>Cancelada sem motorista</option>
            <option value={115}>Pagamento cancelado</option>
          </select>
        </div>
        <div className="form-group col-md-1">
          <label>Cartão</label>
          <select
            value={params.payment_status_method || ""}
            onChange={this.onChange}
            className="form-control custom-select"
            name="payment_status_method"
          >
            <option value="" />
            <option value={0}>Não processado</option>
            <option value={9}>Aguardando</option>
            <option value={10}>Autorizada</option>
            <option value={12}>Em Análise</option>
            <option value={20}>Pago</option>
            <option value={21}>Creditada</option>
            <option value={31}>Cancelada</option>
            <option value={32}>Estornada</option>
            <option value={33}>Revertida</option>
            <option value={50}>Erro</option>
          </select>
        </div>

        <div className="form-group col-md-1" style={{ minWidth: "120px" }}>
          <label>Apartir de: </label>
          <DatePicker
            isClearable
            customInput={<CustomDateInput />}
            selected={this.state.start_at}
            onChange={this.handleStartDtChange}
          />
        </div>

        <div className="form-group col-md-1" style={{ minWidth: "100px" }}>
          <label>Até: </label>
          <DatePicker
            isClearable
            customInput={<CustomDateInput />}
            selected={this.state.end_at}
            onChange={this.handleEndDtChange}
          />
        </div>

        <div className="form-group col-1" style={{ minWidth: "120px" }}>
          <label>Viagem </label>
          <select
            value={params.fatured_ride || ""}
            onChange={this.onChange}
            className="form-control custom-select"
            name="fatured_ride"
          >
            <option value="" />
            <option value={faturedRideConst.BILLED}>Faturada</option>
            <option value={faturedRideConst.PENDING}>Pendente</option>
          </select>
        </div>

        <div className="form-group col-md-1">
          <label>&nbsp;&nbsp;</label>
          <button type="submit" className="btn btn-primary form-control">
            OK
          </button>
        </div>
      </form>
    );
  }
}

class CustomDateInput extends React.PureComponent {
  static propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func,
  };

  render() {
    return (
      <div onClick={this.props.onClick}>
        <input
          type="text"
          className="form-control"
          value={
            this.props.value
              ? moment(this.props.value).format("DD/MM/YYYY")
              : ""
          }
          readOnly
          style={{ cursor: "pointer" }}
        />
      </div>
    );
  }
}
