import cloneDeep from 'clone-deep';
import {
  GET_CITY,
  GET_CITY_SUCCESS,
  GET_CITIES_SUCCESS,
  UPDATE_CITY_PROPS,
  CREATE_CITY_SUCCESS,
  UPDATE_CITY_SUCCESS,
  DELETE_CITY_SUCCESS,
  CLEAR_CITY,
  GET_OPERATIONS_SUCCESS
} from '../actions/cities';
import {
  CREATE_FARE_SUCCESS,
  UPDATE_FARE_SUCCESS,
  DELETE_FARE_SUCCESS
} from '../actions/fares';

const initialState = {
  all: [],
  operations: [],
  current: {
    id: null,
    name: '',
    fares: []
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case CREATE_FARE_SUCCESS :
    case DELETE_FARE_SUCCESS :
    case UPDATE_FARE_SUCCESS : {
      let city = state.current;

      if (city.id == action.fare.city_id) {
        city = cloneDeep(city);
        if (action.type == CREATE_FARE_SUCCESS) {
          city.fares.push(action.fare)
        } else if (action.type == UPDATE_FARE_SUCCESS) {
          city.fares = city.fares.map(fare => {
            return action.fare.id == fare.id
              ? { ...fare, ...action.fare }
              : fare
          })
        } else if (action.type == DELETE_FARE_SUCCESS) {
          city.fares = city.fares.filter(fare => fare.id != action.fare.id)
        }
      }

      return { ...state, current: city }
    }

    case GET_OPERATIONS_SUCCESS :
      return { ...state, operations: action.operations.map(operation => operation.city) }

    case GET_CITIES_SUCCESS :
      return { ...state, all: action.cities }

    case GET_CITY_SUCCESS :
      return { ...state, current: action.city }

    case GET_CITY :
    case CLEAR_CITY :
      return { ...state, current: { ...initialState.current }}

    case UPDATE_CITY_PROPS :
      return { ...state, current: { ...state.current, ...action.props }}

    case CREATE_CITY_SUCCESS : {
      let all = [ action.city, ...state.all ]
      return { all, current: action.city }
    }

    case UPDATE_CITY_SUCCESS : {
      let all = state.all.map(cat => {
        return cat.id == action.city.id
          ? { ...cat, ...action.city }
          : cat;
      })

      let current = state.current.id == action.city.id
        ? { ...state.current, ...action.city }
        : state.current

      return { ...state, all, current }
    }

    case DELETE_CITY_SUCCESS : {
      let all = state.all.filter(cat => cat.id != action.id)

      return { all, current: { ...initialState.current }}
    }

    default:
      return state;
  }
}

export default reducer
