import axios from "axios";
import { push } from "react-router-redux";
import { store } from "../store";

class Api {
  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      timeout: 15000,
    });

    let token = localStorage.getItem("api_token");
    if (token) {
      this.setApiToken(token);
    }

    this.api.interceptors.response.use(
      response => {
        if (response.data.ok) {
          return Promise.resolve(response.data.data);
        } else {
          return Promise.reject(response.data.error);
        }
      },
      error => {
        console.log(error);
        if (error && error.response && error.response.status == 401) {
          store.dispatch(push("/login"));
        } else {
          if (!axios.isCancel(error)) {
            // alert("Error");
          }
        }
        return Promise.reject(error);
      }
    );
  }

  async setTokens(api_token, firebase_token) {
    this.setApiToken(api_token);

    //store.dispatch(push('/drivers'))
  }

  setApiToken(api_token) {
    this.api.defaults.headers.common["Authorization"] = "Bearer " + api_token;

    localStorage.setItem("api_token", api_token);

    this.isLoggedIn = true;
  }

  async refreshTokens(api_token = null) {
    if (api_token) {
      this.setApiToken(api_token);
    }
    const data = await this.api.post("/api/refresh-token");

    this.setTokens(data.api_token, data.firebase_token);

    return data;
  }

  async login({ email, password }) {
    let data = await this.api.post("/auth/adm/login", { email, password });

    this.setTokens(data.api_token, data.firebase_token);

    return data;
  }

  getAspirantDrivers(params) {
    return this.api.post(`/api/aspirant-drivers`, params);
  }

  getAspirantDriver(id) {
    return this.api.get(`/api/aspirant-drivers/id/${id}`);
  }

  updateAspirantDriver(driver) {
    return this.api.post(`/api/aspirant-drivers/id/${driver.id}`, driver);
  }

  getRiders(params) {
    return this.api.post(`/api/users`, params);
  }

  getRider(id) {
    return this.api.get(`/api/users/id/${id}`);
  }

  getDrivers(params) {
    return this.api.post(`/api/drivers`, params);
  }
  /* Ver aqui */
  getDebits(params) {
    return this.api.post(`/api/drivers/debits`, params);
  }

  getOnlineDrivers(location, radius, cancelToken) {
    return this.api.post(
      `/api/drivers/online`,
      { location, radius },
      { cancelToken }
    );
  }

  getAllDrivers() {
    return this.api.get(`/api/drivers/export`);
  }

  getDriverBankAccount(id) {
    return this.api.get(`/api/drivers/bank-account/id/${id}`);
  }

  getDriver(id) {
    return this.api.get(`/api/drivers/id/${id}`);
  }

  getExtracts(id, period) {
    return this.api.get(`/api/drivers/id/${id}/${period}-extract`);
  }

  createDriver(driver) {
    return this.api.post(`/api/drivers/create`, driver);
  }

  updateDriver(driver) {
    return this.api.put(`/api/drivers/id/${driver.id}`, driver);
  }

  updateDriverBankAccount(driverId, driverBank) {
    const response = this.api.put(
      `/api/drivers/bank-account/id/${driverId}`,
      driverBank
    );

    return response;
  }

  updateAspirantDriverBankAccount(driverId, driverBank) {
    const response = this.api.put(
      `/api/aspirant-drivers/bank-account/id/${driverId}`,
      driverBank
    );

    return response;
  }

  updateUser(rider) {
    return this.api.put(`/api/users/id/${rider.id}`, rider);
  }
  updateUserDashboard(rider) {
    return this.api.put(`/api/users/id/${rider.id}/dashboard`, rider);
  }

  riders(page) {
    return this.api.post(`/api/users`, { page });
  }

  categories(page) {
    return this.api.get(`/api/categories`);
  }

  getCategory(id) {
    return this.api.get(`/api/categories/id/${id}`);
  }

  updateCategory(category) {
    return this.api.post(`/api/categories/id/${category.id}`, category);
  }

  deleteCategory(id) {
    return this.api.delete(`/api/categories/id/${id}`);
  }

  createCategory(category) {
    return this.api.post(`/api/categories/create`, category);
  }

  getRides(params) {
    return this.api.post(`/api/rides/list`, params);
  }

  getRide(id) {
    return this.api.get(`/api/ride/id/${id}/info`);
  }

  releaseRide(id) {
    return this.api.post(`/api/ride/id/${id}/release`);
  }

  listAds() {
    return this.api.get(`/api/ads`);
  }

  getAds(id) {
    return this.api.get(`/api/ads/id/${id}`);
  }

  createAds(ads) {
    return this.api.post(`/api/ads`, ads);
  }

  updateAds(ads) {
    return this.api.put(`/api/ads/id/${ads.id}`, ads);
  }

  deleteAds(id) {
    return this.api.delete(`/api/ads/id/${id}`);
  }

  // FARES
  fares() {
    return this.api.get(`/api/fares`);
  }

  getFare(id) {
    return this.api.get(`/api/fares/id/${id}`);
  }

  updateFare(fare) {
    return this.api.post(`/api/fares/id/${fare.id}`, fare);
  }

  deleteFare(id) {
    return this.api.delete(`/api/fares/id/${id}`);
  }

  createFare(fare) {
    return this.api.post(`/api/fares/create`, fare);
  }

  //CITIES
  cities() {
    return this.api.get(`/api/cities`);
  }

  getCity(id) {
    return this.api.get(`/api/cities/id/${id}`);
  }

  updateCity(city) {
    return this.api.put(`/api/cities/id/${city.id}`, city);
  }

  deleteCity(id) {
    return this.api.delete(`/api/cities/id/${id}`);
  }

  createCity(city) {
    return this.api.post(`/api/cities/create`, city);
  }

  // Drivers inactive reason

  getDriversInactiveReason() {
    return this.api.get("/api/drivers-inactive-reason");
  }

  updateCar(car) {
    return this.api.put("/api/drivers-car/", car);
  }

  deleteCar(id) {
    return this.api.post(`/api/drivers-car/deletecar/${id}`);
  }

  // Drivers Cars

  getCarsInactive(params) {
    return this.api.post("/api/drivers-car/carsinactive", params);
  }

  getCarInactive(id) {
    return this.api.post(`/api/drivers-car/carinactive/id/${id}`);
  }

  // Unlock Drivers
  unlockDriver(id) {
    return this.api.post(`/api/drivers/id/${id}/unlockdriver`);
  }

  // Unlock Users
  unlockUser(id) {
    return this.api.post(`/api/users/id/${id}/unlockuser`);
  }

  // Push Notification

  createNotification(data) {
    return this.api.post(`/api/push-notification/`, data);
  }

  getAllNotification(params) {
    console.log("params api", params);
    return this.api.post(`/api/push-notification/all`, params);
  }

  cancelNotification(id) {
    return this.api.post(`/api/push-notification/unsheduled`, { id: id });
  }

  getOnePush(id) {
    console.log("api", id);
    return this.api.post(`/api/push-notification/push`, { id: id });
  }

  unlockRide(id) {
    return this.api.post(`/api/ride/id/${id.id}/unlock`);
  }

  // Analytics

  listRidesAnalytics(params) {
    return this.api.post("/api/analytics/rides", params);
  }

  exportRidesAnalytics(params) {
    return this.api.post("/api/analytics/rides-export", params);
  }

  listUsersAnalytics(params) {
    return this.api.post("/api/analytics/users", params);
  }

  // Statistcs

  listAnalyticsFinancial(params) {
    return this.api.get("/api/analytics", { params });
  }

  listAnalyticsMonthly(params) {
    return this.api.get("/api/analytics/monthly", { params });
  }

  listAnalyticsDrivers(params) {
    return this.api.get("/api/analytics/drivers", { params });
  }

  listAnalyticsRides(params) {
    return this.api.get("/api/analytics/rides", { params });
  }

  listAnalyticsRoyalties(params) {
    return this.api.get("/api/analytics/royalties", { params });
  }

  listAnalyticsRiders(params) {
    return this.api.get("/api/analytics/riders", { params });
  }

  listAnalyticsIndicators(params) {
    return this.api.get("/api/analytics/indicators", { params });
  }

  // team

  createCollaborator(payload) {
    return this.api.post("/api/admins", payload);
  }

  getCollaborator(payload) {
    return this.api.get("/api/admins", payload);
  }

  getCollaboratorCity(params) {
    return this.api.get("/api/admins", { params });
  }

  getCollaboratorId(id) {
    return this.api.get(`/api/admins/id/${id}`);
  }

  updateCollaboratorId({ id, payload }) {
    return this.api.put(`/api/admins/id/${id}`, payload);
  }

  removeCollaborator(id) {
    return this.api.delete(`/api/admins/id/${id}`);
  }

  //#region promocodes
  listPromocodes(params) {
    return this.api.get("/api/promocodes/list", { params });
  }

  getPromocode(id) {
    return this.api.get(`/api/promocodes/id/${id}`);
  }

  createPromocode(promocode) {
    return this.api.post("/api/promocodes/create", promocode);
  }

  updatePromocode(promocode) {
    return this.api.patch(`/api/promocodes/id/${promocode.id}`, promocode);
  }

  removePromocode(id) {
    return this.api.delete(`/api/promocodes/id/${id}`);
  }
  //#endregion

  // Franchisees
  createFranchisees(payload) {
    return this.api.post("/api/operations", payload);
  }

  updateFranchisees({ id, payload }) {
    return this.api.put(`/api/operations/id/${id}`, payload);
  }

  removeFranchisees(id) {
    return this.api.delete(`/api/operations/id/${id}`);
  }

  getFranchisees() {
    return this.api.get(`/api/operations`);
  }

  getFranchiseesId(id) {
    return this.api.get(`/api/operations/id/${id}`);
  }

  // payment

  getPayments(operation_id) {
    return this.api.get(`/api/operations/id/${operation_id}/payments`);
  }

  getPayment(operation_id, payment_id) {
    return this.api.get(
      `/api/operations/id/${operation_id}/payments/id/${payment_id}`
    );
  }

  async getOperatorBalance(operation_id) {
    const { availableAmount, waitingAmount } = await this.api.get(
      `/api/operations/balance/${operation_id}`
    );

    return {
      availableAmount,
      waitingAmount,
    };
  }

  async getOperatorWhiteDraw(operation_id) {
    const response = await this.api.post(
      `/api/operations/transfer/${operation_id}`
    );

    return response;
  }

  async getOperatorWhiteDrawList(operation_id) {
    return await this.api.get(`/api/operations/transfer/${operation_id}`);
  }

  createPayment({ operation_id, payload }) {
    return this.api.post(
      `/api/operations/id/${operation_id}/payments`,
      payload
    );
  }

  updatePayment(id, payload) {
    return this.api.put(
      `/api/operations/id/${payload.operation_id}/payments/id/${id}`,
      payload
    );
  }

  removePayment({ operation_id, payment_id }) {
    return this.api.delete(
      `/api/operations/id/${operation_id}/payments/id/${payment_id}`
    );
  }

  adminExists(email) {
    return this.api.get(`/api/admins/exists`, { params: { email } });
  }

  generateDriverPagarme(id) {
    return this.api.post(`/api/drivers/recipients/${id}`);
  }

  async getProofOfLive(id) {
    return this.api.post(`/api/operations/recipients/validate/${id}`);

  }
}

export default new Api();
