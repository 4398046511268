import {
  GET_ANALYTICS_USERS_SUCCESS,
} from "../actions/analytics-users";

const initialState = {
  usersAnalytics: [],
  page: 0,
  pages: 0,
  total: 0,
  params: {
    page: 1
  }
}

const reducer = (state = initialState, action) => {
  switch(action.type) {
    case GET_ANALYTICS_USERS_SUCCESS: {
      return {
        ...state,
        usersAnalytics: action.users.results,
        page: action.users.page,
        pages: action.users.pages,
        total: action.users.total,
        params: { page: action.users.page }
      }
    }
    default:
      return state
  }
}

export default reducer