import {
  GET_CATEGORY,
  GET_CATEGORY_SUCCESS,
  GET_CATEGORIES_SUCCESS,
  UPDATE_CATEGORY_PROPS,
  CREATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_SUCCESS,
  CLEAR_CATEGORY
} from '../actions/categories';

const initialState = {
  all: [],
  current: {
    id: null,
    name: '',
    percentage_moby: 0,
    price_base: 0,
    price_minimum: 0,
    price_km: 0,
    price_minute: 0,
    capacity: ''
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CATEGORIES_SUCCESS :
      return { ...state, all: action.categories }

    case GET_CATEGORY_SUCCESS :
      return { ...state, current: action.category }

    case GET_CATEGORY :
    case CLEAR_CATEGORY :
      return { ...state, current: { ...initialState.current }}

    case UPDATE_CATEGORY_PROPS :
      return { ...state, current: { ...state.current, ...action.props }}

    case CREATE_CATEGORY_SUCCESS : {
      let all = [ action.category, ...state.all ]
      return { all, current: action.category }
    }

    case UPDATE_CATEGORY_SUCCESS : {
      let all = state.all.map(cat => {
        return cat.id == action.category.id
          ? { ...cat, ...action.category }
          : cat;
      })

      return { ...state, all }
    }

    case DELETE_CATEGORY_SUCCESS : {
      let all = state.all.filter(cat => cat.id != action.id)

      return { all, current: { ...initialState.current }}
    }

    default:
      return state;
  }
}

export default reducer