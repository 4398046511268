import React, { useEffect, useState } from "react";
import { banks } from "../../utils/banks";
import { toast } from "react-toastify";
import { validateCpfCnpj } from "utils/string";
import { BankAccountInitialValues } from "constants/BankAccount.constant";

interface BankAccountProps {
  updateBank: Function;
  bank: any;
}

const BankAccount: React.FC<BankAccountProps> = ({ bank, updateBank }) => {
  const [bankAccount, setBankAccount] = useState(BankAccountInitialValues);
  const [busy, setBusy] = useState(false);

  useEffect(() => {
    setBankAccount(bank);
  }, [bank]);

  const handleOnChangeBankHolderInfo = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name } = e.target;
    const field = name === "favored_name" ? "name" : "cpf";

    let value = e.target.value;

    if (field === "cpf") {
      value = validateCpfCnpj(value);
    }

    setBankAccount(() => ({
      ...bankAccount,
      holder: {
        ...bankAccount?.holder,
        [field]: value,
      },
    }));
  };

  const handleOnChangeBankInfo = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setBankAccount(() => ({
      ...bankAccount,
      [name]: value,
    }));
  };

  const validate = () => {
    if (
      !bankAccount?.bankNumber ||
      !bankAccount?.agencyNumber ||
      !bankAccount?.agencyCheckNumber ||
      !bankAccount?.accountNumber ||
      !bankAccount?.accountCheckNumber ||
      !bankAccount?.type ||
      !bankAccount?.holder?.cpf ||
      !bankAccount?.holder?.name
    ) {
      toast.error("Todos os dados bancários precisam ser informados");
      return;
    }
    return true;
  };

  const save = async () => {
    if (!validate()) {
      return;
    }

    setBusy(true);

    try {
      updateBank(bankAccount);

      toast.success("Dados bancários informados com sucesso!");
    } catch (err: any) {
      toast.error(err.message);
    } finally {
      setBusy(false);
    }
  };

  return (
    <div>
      <div style={{ width: 500, marginLeft: "auto", marginRight: "auto" }}>
        {busy === true && "Carregando.."}
        {!busy && (
          <div className="card bg-light">
            <div className="card-header">Dados bancários</div>
            <div className="card-body">
              <form>
                <div className="form-group row">
                  <label>Banco</label>
                  <select
                    className="form-control custom-select"
                    value={bankAccount?.bankNumber}
                    name="bankNumber"
                    onChange={handleOnChangeBankInfo}
                    data-name="bankNumber"
                    id="bankNumber"
                    required
                  >
                    <option value="" />
                    {banks.map((item, index) => {
                      return (
                        <option key={index} value={item.id}>
                          {item.id} - {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="form-group row">
                  <label>Agencia</label>
                </div>

                <div className="form-group row">
                  <input
                    type="text"
                    className="form-control col-9"
                    name="agencyNumber"
                    value={bankAccount?.agencyNumber}
                    onChange={handleOnChangeBankInfo}
                    required
                  />
                  <span className="col-1">-</span>
                  <input
                    type="text"
                    className="form-control col-2"
                    name="agencyCheckNumber"
                    value={bankAccount?.agencyCheckNumber}
                    onChange={handleOnChangeBankInfo}
                    required
                  />
                </div>

                <div className="form-group row">
                  <label>Conta</label>
                </div>

                <div className="form-group row">
                  <input
                    type="text"
                    className="form-control col-9"
                    name="accountNumber"
                    value={bankAccount?.accountNumber}
                    onChange={handleOnChangeBankInfo}
                    required
                  />
                  <span className="col-1">-</span>
                  <input
                    type="text"
                    className="form-control col-2"
                    name="accountCheckNumber"
                    value={bankAccount?.accountCheckNumber}
                    onChange={handleOnChangeBankInfo}
                    required
                  />
                </div>

                <div className="form-group row">
                  <label>Tipo</label>
                  <select
                    className="form-control custom-select"
                    value={bankAccount?.type}
                    onChange={handleOnChangeBankInfo}
                    name="type"
                    id="type"
                    required
                  >
                    <option value="" />
                    <option key="SAVING" value="SAVING">
                      Conta Poupança
                    </option>
                    <option key="CHECKING" value="CHECKING">
                      Conta Corrente
                    </option>
                  </select>
                </div>

                <div className="form-group row">
                  <label>Nome do favorecido</label>
                  <input
                    type="text"
                    className="form-control"
                    name="favored_name"
                    value={bankAccount?.holder?.name}
                    onChange={handleOnChangeBankHolderInfo}
                    required
                  />
                </div>

                <div className="form-group row">
                  <label>CPF/CNPJ do favorecido</label>
                  <input
                    type="text"
                    className="form-control"
                    name="favored_cpf"
                    value={bankAccount?.holder?.cpf}
                    onChange={handleOnChangeBankHolderInfo}
                    required
                  />
                </div>
                <div className="col-md-12 text-right">
                  <button
                    type="button"
                    disabled={!!busy}
                    className="btn btn-success"
                    onClick={save}
                  >
                    {busy ? "Salvando.." : "Salvar"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BankAccount;
