import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { func, object, array, string } from "prop-types";
import { Modal } from "react-bootstrap";
import objectPath from "object-path";
import { toast } from "react-toastify";
import validate from "validate.js";
import cloneDeep from "clone-deep";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
  DriverStatusLabels,
  AcceptedPaymentMethodsLabels,
  DiscountMethodLabels,
} from "../api/types";
import {
  getDriver,
  createDriver,
  updateDriver,
  unlockDriver,
  updateDriverCar,
  updateDriverProps,
  setDriver,
} from "../actions/drivers";
import { getCities } from "../actions/cities";
import { getInactiveReason } from "../actions/drives-inactive-reason";
import estadosBrasil from "../utils/estados-brasil";
import "../styles/css/driver-info.css";
import InputMask from "react-input-mask";
import Api from "../api";

import Cars from "../components/cars";

class DriverInfoPage extends Component {
  static formatExcel(driver) {
    const { address } = driver.info;

    let data = {
      ID: driver.id,
      Nome: driver.name + " " + driver.surname,
      Email: driver.email,
      Telefone: driver.phone,
      CPF: driver.info?.cpf,
      "Data de Nascimento": moment(driver.info?.birthdate).format("DD/MM/YYYY"),
      Categorias: driver.categories,
      "MOIP ID": driver.moip_account_id,
      "Pagarme ID": driver.pagarme_recipient_id,
      "Pagarme quitação ID": driver.pagarme_customer_id,
      Situação: driver.situation,
      Endereço: `${address?.street} ${address?.streetNumber}\nCEP: ${address?.zipCode}\nBairro: ${address?.district}`,
      Cidade: address?.city,
      UF: address?.state,
      Veículo: driver.car.name,
      Cor: driver.car.color,
      Placa: driver.car.license_plate,
    };

    let header = Object.keys(data).join("\t");
    let body = Object.keys(data)
      .map(key => `"${data[key]}"`)
      .join("\t");

    return header + "\n" + body;
  }

  static propTypes = {
    getCities: func,
    getDriver: func,
    createDriver: func,
    updateDriver: func,
    updateDriverProps: func,
    setDriver: func,
    driver: object,
    match: object,
    history: object,
    categories: array,
    cities: array,
    getInactiveReason: func,
    inactiveReason: array,
  };

  constructor(props) {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.handleBirthChange = this.handleBirthChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.save = this.save.bind(this);
    this.close = this.close.bind(this);
    this.unlockThisDriver = this.unlockThisDriver.bind(this);
    this.generateDriverPagarme = this.generateDriverPagarme.bind(this);

    this.state = {
      busy: false,
      excel: null,
      states: estadosBrasil,
    };

    validate.extend(validate.validators.datetime, {
      // The value is guaranteed not to be null or undefined but otherwise it
      // could be anything.
      parse: function (value, options) {
        return moment.utc(value);
      },
      // Input is a unix timestamp
      format: function (value, options) {
        var format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
        return moment.utc(value).format(format);
      },
    });

    props.setDriver(null);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { id } = nextProps.match.params;
    if (
      nextProps.driver &&
      nextProps.driver.id &&
      nextProps.driver.id == id &&
      !prevState.excel
    ) {
      return {
        ...prevState,
        excel: DriverInfoPage.formatExcel(nextProps.driver),
      };
    }

    return null;
  }

  componentDidMount() {
    this.props.getInactiveReason();
    let { id } = this.props.match.params;

    if (id) {
      this.props.getDriver(id).then(({ error }) => {
        if (!error) {
          this.getCities();
        }
      });
    }
  }

  unlockThisDriver() {
    let { id } = this.props.match.params;
    this.props.unlockDriver(id);
    toast.success("Motorista destravado");
  }

  async getCities() {
    let { city_id } = this.props.driver;
    let exists = this.props.cities.find(({ id }) => id == city_id);
    if (!exists) {
      this.props.getCities();
    }
  }

  saveCar = car => {
    this.props.updateDriverCar(car);
  };

  validate() {
    let { driver } = this.props;

    let rules = {
      name: {
        length: { minimum: 3, message: "Nome inválido" },
      },
      surname: {
        length: { minimum: 3, message: "Sobrenome inválido" },
      },
      phone: {
        length: { minimum: 11, message: "Celular inválido" },
        numericality: {
          onlyInteger: true,
          message: "Celular inválido",
        },
      },
      email: {
        email: {
          message: "Email inválido",
        },
      },
      categories: {
        length: { minimum: 1, message: "Selecione pelo menos uma categoria" },
      },
      /*
      "car.name": {
        length: { minimum: 2, message: "Preencha o nome do carro" }
      },
      "car.color": {
        length: { minimum: 2, message: "Preencha a cor do carro" }
      },
      "car.license_plate": {
        length: { minimum: 7, message: "Preencha corretamente a placa carro" }
      },
      */
      "info.cpf": {
        length: { minimum: 11, message: "CPF inválido" },
      },
      "info.birthdate": {
        datetime: { message: "Selecione sua data de nascimento" },
      },
      "info.address.street": {
        length: { minimum: 2, message: "Preencha o endereço" },
      },
      "info.address.streetNumber": {
        length: { minimum: 2, message: "Preencha o número do endereço" },
      },
      "info.address.district": {
        length: { minimum: 2, message: "Preencha o bairro" },
      },
      "info.address.zipCode": {
        format: { pattern: "[0-9]{5}-[0-9]{3}", message: "CEP Inválido" },
        length: { minimum: 9, message: "Preencha o CEP" },
      },
      "info.address.city": {
        length: { minimum: 2, message: "Preencha a Cidade" },
      },
      "info.address.state": {
        length: { minimum: 2, message: "Preencha o Estado (UF)" },
      },
    };

    let rs = validate.validate(driver, rules, {
      fullMessages: false,
    });

    let errors = [];
    for (let k in rs) {
      errors.push(rs[k][0]);
    }

    if (errors.length > 0) {
      toast.warn(errors[0]);
      return false;
    }

    return true;
  }

  async save() {
    if (!this.validate()) {
      return;
    }

    let driver = cloneDeep(this.props.driver);

    if (driver.rating) {
      driver.rating = Number(driver.rating);
    }
    driver.info.birthdate = moment(driver.info?.birthdate).format("YYYY-MM-DD");
    driver.info.address.zipCode = driver.info?.address?.zipCode
      ?.replace("-", "")
      ?.replace(".", "");

    let rs;

    this.setState({ busy: true });

    driver.address = driver.info.address;
    driver.birthdate = driver.info.birthdate;

    if (driver.id) {
      rs = await this.props.updateDriver({
        ...driver,
        car: undefined,
      });
    } else {
      rs = await this.props.createDriver(driver);
    }

    let { error } = rs;
    if (error) {
      toast.error(
        error.name == "ValidationError"
          ? error.message
          : "Erro ao salvar o motorista"
      );
    } else {
      toast.success(driver.id ? "Atualizado!" : "Criado com sucesso");
    }

    this.setState({ busy: false });
  }

  remove() {}

  close() {
    this.props.history.goBack();
  }

  handleChange({ target }) {
    const getAttr = attr => {
      let item = target.attributes.getNamedItem(attr);
      return item ? item.value : null;
    };

    let type = getAttr("data-type");
    let prop = getAttr("data-name");
    let value = target.value;

    if (type == "number") {
      value = value.replace(/[^0-9]/g, "");
    } else if (type == "number-strict") {
      value = Number(value.replace(/[^0-9]/g, ""));
    }

    if (prop.indexOf(".") !== -1) {
      let driver = cloneDeep(this.props.driver);

      objectPath.set(driver, prop, value);
      this.props.setDriver(driver);
    } else {
      this.props.updateDriverProps({ [prop]: value });
    }
  }

  handleCategoryChange({ target }) {
    let id = parseInt(target.attributes.getNamedItem("data-name").value);
    let checked = !!target.checked;

    let categories = [...this.props.driver.categories];
    if (!checked) {
      categories = categories.filter(cid => cid != id);
    } else if (categories.indexOf(id) == -1) {
      categories.push(id);
    }

    this.props.updateDriverProps({ categories });
  }

  handleBirthChange(birthdate) {
    birthdate = birthdate.toISOString();
    let driver = cloneDeep(this.props.driver);

    objectPath.set(driver, "info.birthdate", birthdate);
    this.props.setDriver(driver);
  }

  generateDriverPagarme() {
    this.setState({ busy: true });

    let { driver } = this.props;

    if (driver?.id && !driver.pagarme_recipient_id) {
      Api.generateDriverPagarme(driver.id).then(() => {
        this.setState({ busy: false });
      });
    }

    this.setState({ busy: false });
  }

  render() {
    const { driver } = this.props;

    return (
      <div style={{ width: 500, marginLeft: "auto", marginRight: "auto" }}>
        <div className="card bg-light">
          <div className="card-header">Motorista</div>
          <div className="card-body">
            <form>
              <div className="form-group">
                <label>ID</label>
                <input readOnly className="form-control" value={driver.id} />
              </div>
              <div className="form-group">
                <label>MOIP ID</label>
                <input
                  type="text"
                  readOnly
                  className="form-control"
                  value={driver.moip_account_id || ""}
                />
              </div>
              <div className="form-group">
                <label>Pagarme - Código de recebimento</label>
                <input
                  type="text"
                  readOnly
                  className="form-control"
                  value={driver.pagarme_recipient_id || ""}
                />
                {driver?.id && !driver?.pagarme_recipient_id && (
                  <button onClick={this.generateDriverPagarme}>
                    Gerar recebedor Pagarme
                  </button>
                )}
              </div>
              <div className="form-group">
                <label>Pagarme - Código quitar via cartão</label>
                <input
                  type="text"
                  readOnly
                  className="form-control"
                  value={driver.pagarme_customer_id || ""}
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputName">Nome</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="15"
                  id="inputName"
                  data-name="name"
                  value={driver.name}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputName">Sobrenome</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="30"
                  id="inputSurname"
                  data-name="surname"
                  value={driver.surname}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputEmail">Email</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="50"
                  id="inputEmail"
                  data-name="email"
                  value={driver.email}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputPhone">Celular</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="11"
                  id="inputPhone"
                  data-name="phone"
                  data-type="number"
                  value={driver.phone}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputInfoCpf">Cpf</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="11"
                  id="inputInfoCpf"
                  data-name="info.cpf"
                  data-type="number"
                  value={driver.info?.cpf}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label>Data de Nascimento: </label>
                <DatePicker
                  showYearDropdown
                  maxDate={moment.utc().subtract(18, "years")}
                  dropdownMode="select"
                  customInput={<CustomDateInput />}
                  selected={
                    driver.info?.birthdate
                      ? moment(driver.info?.birthdate)
                      : moment.utc().subtract(18, "years").startOf("day")
                  }
                  onChange={this.handleBirthChange}
                />
              </div>
              <div className="form-group">
                <label>Débito</label>
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">R$</div>
                  </div>
                  <input
                    onChange={this.handleChange}
                    className="form-control"
                    value={driver.debit}
                    data-name="debit"
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Método de pagamento para a MobyGo</label>
                <div className="input-group">
                  <input
                    type="text"
                    readOnly
                    className="form-control"
                    value={DiscountMethodLabels[driver.discount_method]}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Recebe pagamentos em</label>
                <div className="input-group">
                  <input
                    type="text"
                    readOnly
                    className="form-control"
                    value={AcceptedPaymentMethodsLabels[driver.payment_methods]}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Categorias</label>
                {!!driver &&
                  !!driver.categories &&
                  this.props.categories.map(category => {
                    return (
                      <div
                        key={category.id}
                        className="custom-control custom-checkbox"
                        style={{ margin: "5px 0" }}
                      >
                        <input
                          type="checkbox"
                          id={`cat-${category.id}`}
                          className="custom-control-input"
                          data-name={category.id}
                          checked={
                            driver.categories.indexOf(category.id) !== -1
                          }
                          value={category.id}
                          onChange={this.handleCategoryChange}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor={`cat-${category.id}`}
                        >
                          {category.name}
                        </label>
                      </div>
                    );
                  })}
              </div>
              <div className="form-group">
                <label>Praça</label>
                <select
                  className="form-control custom-select"
                  data-name="city_id"
                  value={driver?.city_id}
                  onChange={this.handleChange}
                >
                  <option value="" />
                  {this.props.cities.map(city => {
                    return (
                      <option key={city.id} value={city.id}>
                        {city.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              {!!driver.id && (
                <div>
                  <div className="form-group">
                    <label>Status</label>
                    <input
                      type="text"
                      readOnly
                      className="form-control"
                      value={DriverStatusLabels[driver.status]}
                    />
                  </div>
                </div>
              )}
              <div className="form-group">
                <label>Situação</label>
                <select
                  className="form-control custom-select"
                  data-name="situation"
                  data-type="number-strict"
                  value={driver.situation}
                  onChange={this.handleChange}
                >
                  <option key="0" value="0">
                    INATIVO
                  </option>
                  <option key="1" value="1">
                    ATIVO
                  </option>
                </select>
              </div>
              {!!driver.situation == 0 ? (
                <div className="form-group">
                  <label>Motivo inativação</label>
                  <select
                    className="form-control custom-select"
                    data-name="inactive_id"
                    value={driver.inactive_id}
                    onChange={this.handleChange}
                  >
                    <option value="-1" />
                    {this.props.inactiveReason.map(reason => {
                      return (
                        <option key={reason.id} value={reason.id}>
                          {reason.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : null}

              {driver.situation == 0 ? (
                <div className="form-group">
                  <label>Observação</label>
                  <textarea
                    type="text"
                    className="form-control"
                    data-name="inactive_observation"
                    value={driver.inactive_observation}
                    onChange={this.handleChange}
                  />
                </div>
              ) : null}

              <div className="form-group">
                <label htmlFor="inputRating">Avaliação</label>
                <input
                  type="number"
                  step="0.01"
                  min="0"
                  max="5"
                  className="form-control"
                  data-name="rating"
                  id="inputRating"
                  value={Number(driver.rating)}
                  onChange={this.handleChange}
                />
              </div>
            </form>
          </div>
        </div>

        <div className="card bg-light">
          <div className="card-header">Endereço</div>
          <div className="card-body">
            <form>
              <div className="form-group">
                <label>Endereço</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="100"
                  data-name="info.address.street"
                  value={driver?.info?.address?.street}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label>Número</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="30"
                  data-name="info.address.streetNumber"
                  value={driver?.info?.address?.streetNumber}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label>CEP</label>
                <InputMask
                  type="text"
                  className="form-control"
                  data-name="info.address.zipCode"
                  data-type="cep"
                  value={driver?.info?.address?.zipCode}
                  onChange={this.handleChange}
                  mask="99999-999"
                />
              </div>
              <div className="form-group">
                <label>Bairro</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="30"
                  data-name="info.address.district"
                  value={driver.info?.address?.district}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label>Cidade</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="50"
                  data-name="info.address.city"
                  value={driver.info?.address?.city}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label>Estado</label>
                <select
                  className="form-control custom-select"
                  data-name="info.address.state"
                  value={driver.info?.address?.state}
                  onChange={this.handleChange}
                >
                  <option value="" />
                  {this.state.states.map(state => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>
            </form>
          </div>
        </div>

        <div>
          {this.props.driver.cars.map((car, i) => (
            <Cars car={car} key={i} i={i} onSave={this.saveCar} />
          ))}
        </div>

        <Modal.Footer style={{ position: "relative" }}>
          {!!this.state.excel && (
            <a
              type="button"
              title="condutor.xls"
              href={`data:application/vnd.ms-excel;charset=utf-8,${encodeURIComponent(
                this.state.excel
              )}`}
              download={`condutor-${driver.id}.xls`}
              style={{ position: "absolute", left: 0 }}
              className="btn btn-light"
            >
              Download Excel
            </a>
          )}

          <button
            type="button"
            enabled={!this.state.busy}
            className="btn btn-success pull-left"
            onClick={this.save}
          >
            {this.state.busy ? "Salvando.." : "Salvar"}
          </button>

          <button
            type="button"
            enabled={!this.state.busy}
            className="btn btn-secondary pull-left"
            data-dismiss="modal"
            onClick={this.close}
          >
            Cancelar
          </button>

          <button
            type="button"
            enabled={!this.state.busy}
            className="btn btn-danger pull-left"
            data-dismiss="modal"
            onClick={this.unlockThisDriver}
          >
            Destravar Motorista
          </button>
        </Modal.Footer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  account: state.account,
  driver: state.drivers.driver,
  categories: state.categories.all,
  cities: state.cities.all,
  inactiveReason: state.driversInactiveReason.all,
});

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getCities,
      getDriver,
      createDriver,
      updateDriver,
      updateDriverProps,
      setDriver,
      getInactiveReason,
      updateDriverCar,
      unlockDriver,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(DriverInfoPage);

class CustomDateInput extends React.PureComponent {
  static propTypes = {
    value: string,
    onClick: func,
  };

  render() {
    return (
      <div onClick={this.props.onClick}>
        <div className="input-group">
          <div className="input-group-prepend">
            <span
              className="input-group-text"
              id="validationTooltipUsernamePrepend"
            >
              <i className="far fa-calendar-alt" />
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            value={
              this.props.value &&
              !moment(this.props.value)
                .startOf("day")
                .isSame(moment().subtract(18, "years").startOf("day"))
                ? moment(this.props.value).format("DD/MM/YYYY")
                : ""
            }
            readOnly
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
    );
  }
}
