import ptBR from "date-fns/locale/pt-BR";
import { format, parseISO } from "date-fns";

export const validateCpfCnpj = (value) => {
  value = value.replace(/\D/g, "");

  if (value.length > 14) {
    value = value.slice(0, -1);
  }

  if (value.length > 11) {
    value = value.replace(/(\d{2})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1/$2");
    value = value.replace(/(\d{4})(\d)/, "$1-$2");
  } else {
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1.$2");
    value = value.replace(/(\d{3})(\d)/, "$1-$2");
  }
  return value;
};

export const validatePhone = (phone) => {
  phone = phone.replace(/\D/g, "");
  phone = phone.replace(/^(\d{2})(\d)/g, "($1) $2");
  phone = phone.replace(/(\d)(\d{4})$/, "$1-$2");

  return phone;
};

export const formatPrice = (string) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(string);
};

export const formattedDate = (string) => {
  const newDate = parseISO(string);

  return format(newDate, "'Dia' dd 'de' MMMM', às ' HH:mm'h'", {
    locale: ptBR,
  });
};
