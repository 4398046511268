import styled from 'styled-components'

export const PushInfoWrapper = styled.div`
  height: 180px;
  width: 100%;
  display: flex;
  background-color: #F6F4FC;
`

export const DividorPushInfo = styled.div `
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 20px;
`
export const Title = styled.h2 `
  font-size: 24px;
  font-weight: 300;
  padding: 20px;
  color: rgb(55, 58, 60);
`

export const SubTitle = styled.h2 `
  font-size: 16px;
  font-weight: bold;

`

export const FormWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 40px;
`

export const FormEdit = styled.form`
  width: 60%;
  height: 400px;
`

export const InputTitle = styled.input`
  width: 100%;
  height: 60px;
  border: none;
  border-bottom: 0.1px solid black;
  font-size: 26px;
  font-weight: bold;
  padding: 10px;
  background-color: #F6F4FC;
`

export const InputMessage = styled.textarea `
  width: 100%;
  height: 100%;
  border: none; 
  font-size: 24px;
  padding: 10px;
  background-color: #F6F4FC;
`


