import React from "react";
import "../../../styles/statistics.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { formatPrice } from "../../../utils/string";
import { object } from "prop-types";
import { useSelector } from "react-redux";

export const GeneralGains = () => {
  const { statisticsFinancial } = useSelector((state) => state.statistics);

  return (
    <div className="general__gain">
      <div className="title">
        <AttachMoneyIcon />
        <h4>Ganhos Gerais</h4>
      </div>

      <div className="content">
        <span>
          Quantidade de corridas finalizada:{" "}
          <strong>
            {(statisticsFinancial &&
              statisticsFinancial?.financial.total_rides) ||
              "0"}
          </strong>
        </span>

        <span>
          Faturamento bruto:
          <strong>
            {statisticsFinancial && statisticsFinancial?.financial.gross_amount
              ? formatPrice(
                  statisticsFinancial &&
                    statisticsFinancial?.financial.gross_amount
                )
              : "0"}
          </strong>
        </span>

        <span>
          Cupons utilizados:{" "}
          <strong>
            {" "}
            {(statisticsFinancial &&
              statisticsFinancial?.financial.total_cupons) ||
              "0"}
          </strong>
        </span>

        <span>
          Faturamento Liquido:
          <strong>
            {statisticsFinancial && statisticsFinancial?.financial.liquid_amount
              ? formatPrice(
                  statisticsFinancial &&
                    statisticsFinancial?.financial.liquid_amount
                )
              : "0"}
          </strong>
        </span>
      </div>
    </div>
  );
};

GeneralGains.propTypes = {
  statisticsFinancial: object,
};
