import React, { useState, useEffect, useMemo } from 'react'
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import Form from '../form/'
import TargetSelect from '../target-select'
import CitySelect from '../city-select'
import { Container, Divisor, Title, SubTitle, InputSingle } from './style'
import { createNotification } from '../../../actions/push-notification'
import PushDate from '../date-picker'
import DatePickerRecorrently from '../date-picker-recorrently'
import RadioButton from '../radio-button'
import RadioButtonRecorrently from '../radio-button-recorrently'
import CheckBoxDaysWeek from '../radio-days-week'
import DatePickerTimeOnly from '../datepicker-time-only'
import DatePickerDay from '../date-picker-days'

const CreateNotificationPage = props => {
  const {
    account,
    city_id,
  } = useSelector(state => ({
    account: state.account,
    city_id: state.preferences.city_id,
  }))

  const cities = useMemo(() => {
    if (account.role === 'admin') {
      return props.cities
    }

    return props.cities.filter(city => account.cities.some(c => c.id === city.id))
  }, [props.cities.length])

  const options = [
    { value: '1', label: 'Motoristas' },
    { value: '2', label: 'Passageiros' },
    account.role === 'admin' && { value: '3', label: 'Motorista' },
    account.role === 'admin' && { value: '4', label: 'Passageiro' },
  ].filter(Boolean)

  // State Inicial
  const[notification, setNotification] = useState(() => ({
    title: '',
    message: '',
    audiencia: null,
    cidades: city_id
      ? [ city_id ]
      : [],
    sendOne: '',
    send_at: 1,
    date: '',
    author: '',
    sendModeRecorrently: '',
    sendModeRecorrentlyWeekDays: [],
    sendModeRecorrentlyWeekTime: '',
    sendModeRecorrentlyDaysDay: ''
  }))

  const[uiState, setUiState] = useState({
    cidades: false,
    sendOne: false,
    showButton: false,
    date: false,
    send_at: false,
    datePickerRecorrently: false,
    radioButtonRecorrently: false,
    sendMode: 1,
    radioDaysWeek: false,
    dateRecorrently: false,

  })

  useEffect(() => {
    setNotification({
      ...notification,
      author: account.name,
    })
  }, [])




  // Lida com os inputs de título e mensagem
  const handleInputChange = event => {
    setNotification({
      ...notification,
      [event.target.name]: event.target.value
    });
  }


  // Lida com os selects de audiência: Motoristas, passageiros, etc...
  const handleSelectChangeAudiencia = selectedOption => {
    // Mostra os campos extras dependendo do selecionado
    if(selectedOption.value == 1 || selectedOption.value == 2 ) {
      // Limpa state para não enviar mensagem para um único usuário junto com os outros
      setNotification({
        ...notification,
        sendOne: '',
        audiencia: selectedOption.value,
      })

      setUiState({
        ...uiState,
        cidades: true,
        sendOne: false,
        send_at: true,
      })
    }

    if(selectedOption.value == 3 || selectedOption.value == 4) {
      setUiState({
        ...uiState,
        cidades: false,
        sendOne: true,
        send_at: false,
        date: false
      })

      setNotification({
        ...notification,
        audiencia: selectedOption.value
      })
    }
  }

  const handleSelectChangeCidade = selectedOption => {
    let c = []
    if(selectedOption == null) {
      return setNotification({
        ...notification,
        cidades: c = []
      })

    } else {
      selectedOption.map(sO => {
        return c.push(sO.value)
      })
      setNotification({
        ...notification,
        cidades: c
      })
    }
  }

  // const handleSelectChangeCidade = selectedOption => {
  //   // if(selectedOption.value == null) {
  //   //   setNotification({
  //   //     ...notification,

  //   //   })
  //   // }
  //   console.log('HANDLE', selectedOption)


  // }

  const handleEmoticon = e => {
    setNotification({
      ...notification,
      message: notification.message + e
    })
  }

  const handleDate = date => {
    setNotification({
      ...notification,
      date: date
    }, console.log('state', notification))
  }

  const handleRadio = when => {
    if(when == 1) {
      setNotification({
        ...notification,
        date: '',
        radioDaysWeek: false
      })

      setUiState({
        ...uiState,
        date: false,
        datePickerRecorrently: false,
        radioButtonRecorrently: false,
        radioDaysWeek: false,
        dateRecorrently: false
      })
    }

    if(when == 2) {
      setUiState({
        ...uiState,
        date: true,
        datePickerRecorrently: false,
        radioButtonRecorrently: false,
        radioDaysWeek: false,
        dateRecorrently: false
      })
    }

    if(when == 3) {
      setUiState({
        ...uiState,
        date: false,
        datePickerRecorrently: false,
        radioButtonRecorrently: true
      })
    }

    setNotification({
      ...notification,
      send_at: when
    }, console.log(uiState))

  }

  const handleDaysWeekRadio = mode => {
    console.log('weekRadio', mode)
    if (mode == 1) {
      setNotification({
        ...notification,
        sendModeRecorrently: 'week',
      })

      setUiState({
        ...uiState,
        radioDaysWeek: true,
        dateRecorrently: false
      })
    }

    if (mode == 2) {
      setNotification({
        ...notification,
        sendModeRecorrently: 'days',
      })

      setUiState({
        ...uiState,
        radioDaysWeek: false,
        dateRecorrently: true
      })
    }
  }

  const handleDaysWeekCheckbox = algo => {
    setNotification({
      ...notification,
      sendModeRecorrentlyWeekDays: algo
    }, console.log(notification))
  }

  const handleTimeOnly = date => {
    setNotification({
      ...notification,
      sendModeRecorrentlyWeekTime: date
    })
  }

  const handleDateRecorrentlyDay = date => {
    setNotification({
      ...notification,
      sendModeRecorrentlyDaysDay: date
    })
  }

  let citySelected;
  if (uiState.cidades) {
    citySelected = (<>
      <SubTitle style={{marginTop: '10px'}}>Cidades a serem enviadas</SubTitle>
      <div style={{width: '60%', marginBottom: '30px'}}>
        <CitySelect style={{marginBottom: '30px'}}
          options={cities.map(c => {
            return {
              value: c.id,
              label: c.name
            }
          })}
          isMulti
          handleSelectChangeCidade={handleSelectChangeCidade}
          value={notification.cidades}
        />
      </div>

    </>)
  }

  let inputUnique;
  if(uiState.sendOne) {
    inputUnique = <InputSingle placeholder="Digite o id do usuário selecionado" name="sendOne" onChange={handleInputChange} />

  }

  let inputRadio;
  if(uiState.send_at) {
    inputRadio = <RadioButton handleAgended={handleRadio} />
  }

  let inputDate;
  if(uiState.date) {
    inputDate = <PushDate date={handleDate} />
  }

  let datePickerRecorrently;
  if (uiState.datePickerRecorrently) {
    datePickerRecorrently = <DatePickerRecorrently />
  }

  let radioButtonRecorrently;
  if(uiState.radioButtonRecorrently) {
    radioButtonRecorrently = <RadioButtonRecorrently sendMode={uiState.sendMode} handleDaysWeekRadio={handleDaysWeekRadio} />
  }

  let radioDaysWeek;
  if(uiState.radioDaysWeek) {
    radioDaysWeek = (<>
      <CheckBoxDaysWeek handleDaysWeekCheckbox={handleDaysWeekCheckbox} />
      <DatePickerTimeOnly date={handleTimeOnly} />
    </>)
  }

  // Todo
  let dateRecorrently;
  if(uiState.dateRecorrently) {
    dateRecorrently = <DatePickerDay handleDateRecorrentlyDay={handleDateRecorrentlyDay} />
  }


  return (
    <Container>
      <Divisor>
        <Title>Criar Notificação</Title>
        <Form
          handleInputChange={handleInputChange}
          emoji={handleEmoticon}
          notification={notification}
          showButton
        />
      </Divisor>

      <Divisor>
        <SubTitle>Grupo de audiência</SubTitle>
        <TargetSelect style={{zIndex: 99999}} handleSelect={handleSelectChangeAudiencia} options={options} />
        {citySelected}
        {inputUnique}
        {inputRadio}
        {inputDate}
        {radioButtonRecorrently}
        {datePickerRecorrently}
        {radioDaysWeek}
        {dateRecorrently}
      </Divisor>
    </Container>
  )
}

const mapStateToProps = state => ({
  cities: state.cities.all,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    createNotification
  }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNotificationPage);