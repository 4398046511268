import React, { useEffect, useState } from "react";
import "../../../styles/statistics.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { formatPrice } from "../../../utils/string";
import { object } from "prop-types";
import { useSelector } from "react-redux";

export const RoyaltiesInfo = () => {
  const { statisticsRoyalties } = useSelector((state) => state.statistics);

  const [index, setIndex] = useState(0);
  const [royalt, setRoyalt] = useState(0);

  useEffect(() => {
    setRoyalt(statisticsRoyalties[index]);
  }, [statisticsRoyalties, index]);

  const moveIndexNext = () => {
    if (statisticsRoyalties.length === 0) {
      return;
    }
    var nextIndex = index + 1;
    if (nextIndex === statisticsRoyalties.length) {
      setIndex(0);
    } else {
      setIndex(nextIndex);
    }
  };

  const moveIndexPrevious = () => {
    if (statisticsRoyalties.length === 0) {
      return;
    }
    let previousIndex = index - 1;
    if (previousIndex === -1) {
      setIndex(statisticsRoyalties.length - 1);
    } else {
      setIndex(previousIndex);
    }
  };

  return (
    <div className="royalties">
      <div className="title">
        <div>
          <AttachMoneyIcon />
          <h4>Royalties</h4>
        </div>

        {!!statisticsRoyalties?.length && (
          <div>
            <i className="pi pi-arrow-left" onClick={moveIndexPrevious} />
            <span>{royalt?.label}</span>
            <i className="pi pi-arrow-right" onClick={moveIndexNext} />
          </div>
        )}
      </div>

      <div className="content">
        {royalt ? (
          <>
            <span>
              Quantidade de corridas finalizadas:
              <strong>{royalt?.total_rides}</strong>
            </span>

            <span>
              Desconto aplicado:
              <strong>{formatPrice(royalt?.cupons_amount / 100)}</strong>
            </span>
            <span>
              Royalties:
              <strong>{formatPrice(royalt?.royalties_amount / 100)}</strong>
            </span>
          </>
        ) : (
          <div>Nenhum dado a ser exibido</div>
        )}
      </div>
    </div>
  );
};

RoyaltiesInfo.propTypes = {
  royalties: object,
};
