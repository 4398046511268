export const STATIC_MAPS_API_KEY = "AIzaSyCtj9VICiUqexIDFcjrUBjLLKE_mkQEza4";
export const GOOGLE_API_KEY = "AIzaSyCtj9VICiUqexIDFcjrUBjLLKE_mkQEza4";

export class PostType {
  static NORMAL = 1;
  static CHECKIN = 2;
  static RIDE = 3;
}

export class PostPrivacyType {
  static PUBLIC = 1;
  static FRIENDS = 2;
  static PRIVATE = 3;
}

export class UserIsActiveStatus {
  static ATIVO = true;
  static INATIVO = false;
}

export const faturedRideConst = {
  PENDING: "PENDING",
  BILLED: "BILLED",
};
