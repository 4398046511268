import cloneDeep from 'clone-deep';
import {
  GET_RIDES,
  GET_RIDES_SUCCESS,
  GET_RIDE_SUCCESS,
  RELEASE_RIDE_SUCCESS
} from '../actions/rides';

const initialState = {
  list: {
    rides: [],
    totals: {},
    page: 1,
    pages: 1,
    params: {}
  },
  byUserId: {},
  ridesById: {}
}

const reducer = (state = initialState, action) => {
  let { params } = action;

  switch (action.type) {
    case GET_RIDES : {
      let user_id = params.driver_id || params.user_id;
      let list = { ...initialState.list, page: params.page, params };

      if (!user_id) {
        return { ...state, list }
      } else {
        let byUserId = { ...state.byUserId, [user_id]: list };

        return { ...state, byUserId }
      }
    }

    case GET_RIDES_SUCCESS : {
      let user_id = params.driver_id || params.user_id;
      let list = {
        rides: action.rides,
        totals: action.totals,
        params: action.params,
        page: action.page,
        pages: action.pages
      }

      if (!user_id) {
        return { ...state, list }
      } else {
        let byUserId = { ...state.byUserId, [user_id]: list };

        return { ...state, byUserId }
      }
    }

    case GET_RIDE_SUCCESS : {
      let ridesById = { ...state.ridesById, [action.ride.id]: action.ride }

      return { ...state, ridesById };
    }

    case RELEASE_RIDE_SUCCESS : {
      let ridesById = { ...state.ridesById }
      if (ridesById[action.ride.id]) {
        ridesById[action.ride.id] = cloneDeep({
          ...ridesById[action.ride.id],
          ...action.ride
        })
      }

      let list = cloneDeep(state.list);

      for (let ride of state.list.rides) {
        if (ride.id == action.ride.id) {
          Object.assign(ride, action.ride)
        }
      }

      return { ...state, ridesById, list }
    }

    default:
      return state;
  }
}

export default reducer
