import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ModalConfirmWhiteDraw } from "../ModalConfirmWhiteDraw/ModalConfirmWhiteDraw";

import api from "../../../api";
import * as S from "./styled";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ProofLiveStatus } from "../ProofLive/ProofLive.constant";
import { ModalProofLive } from "../ProofLive/ProofLive";

type balenceType = {
  availableAmount: number;
  waitingAmount: number;
};

const tooltip = (
  <Tooltip id="tooltip">
    Para solicitar saques é necessário um saldo atual maior que zero
  </Tooltip>
);

export const SumaryComponent = ({ id }: { id: string }) => {
  const [futureBalance, setFutureBalance] = useState(0.0);
  const [currentBalance, setCurrentBalance] = useState(0.0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [operation, setOperation] = useState<any>({});
  const [modalProofShow, setModalProofShow] = useState(false);
  const [urlValidate, setUrlValidate] = useState("");

  const requestWithdrawal = async () => {
    setModalIsOpen(false);
    await api.getOperatorWhiteDraw(id);
    toast.success("Solicitação realizada com sucesso!");
  };

  const getBalance = useCallback(async () => {
    const balance: balenceType = await api.getOperatorBalance(id);

    setFutureBalance(balance?.waitingAmount || 0.0);
    setCurrentBalance(balance?.availableAmount || 0.0);
  }, [id]);

  const handleFranchisees = useCallback(async () => {
    const operation = await api.getFranchiseesId(id);
    setOperation(operation);
  }, [id]);

  const requestProofOfLive = useCallback(async () => {
    const { url }: { url: string } = (await api.getProofOfLive(id)) as any;

    if (url) {
      setUrlValidate(url);
    }
    setModalProofShow(true);
  }, [id]);

  const changeStatusModal = useCallback(async () => {
    handleFranchisees();
    setModalProofShow(false);
  }, [handleFranchisees]);

  const handleModalProofClosed = useCallback(async () => {
    setModalProofShow(false);
  }, []);

  const tooltipPoofOfLife = (
    <Tooltip id="tooltip">
      Seu cadastro no meio de pagamento não foi aprovado ainda.
      {operation.pagarme_kyc_reason && operation.pagarme_kyc_status && (
        <>
          Detalhes: {operation.pagarme_kyc_status} |{" "}
          {operation.pagarme_kyc_reason}
        </>
      )}
    </Tooltip>
  );

  useEffect(() => {
    handleFranchisees();
    getBalance();
  }, [currentBalance, futureBalance, handleFranchisees, getBalance]);

  return (
    <div>
      <hr />
      <S.WhiteDrawSumary>
        <div>
          <S.WhiteDrawTitle>Saldo atual </S.WhiteDrawTitle>
          <S.WhiteDrawValue>R$ {currentBalance}</S.WhiteDrawValue>
        </div>
        <div>
          <S.WhiteDrawTitle>Saldo futuro </S.WhiteDrawTitle>
          <S.WhiteDrawValue>R$ {futureBalance}</S.WhiteDrawValue>
        </div>

        {operation.pagarme_kyc_status === ProofLiveStatus.PARTIALLY_DENIED &&
          operation.pagarme_kyc_status === ProofLiveStatus.DENIED && (
          <S.RequestWhiteDrawButton onClick={requestProofOfLive}>
            Solicitar aprovação
          </S.RequestWhiteDrawButton>
        )}

        {operation.pagarme_kyc_status === ProofLiveStatus.PENDING &&
        (
          <OverlayTrigger placement="top" overlay={tooltipPoofOfLife}>
            <S.RequestWhiteDrawButton
              disabled={currentBalance <= 0}
              onClick={() => setModalIsOpen(true)}
            >
              Aguardando aprovação
            </S.RequestWhiteDrawButton>
          </OverlayTrigger>
        )}

        {operation.pagarme_kyc_status === ProofLiveStatus.APPROVED && (
          <div>
            {currentBalance <= 0 ? (
              <OverlayTrigger placement="top" overlay={tooltip}>
                <S.RequestWhiteDrawButton
                  disabled={currentBalance <= 0}
                  onClick={() => setModalIsOpen(true)}
                >
                  Solicitar Saque
                </S.RequestWhiteDrawButton>
              </OverlayTrigger>
            ) : (
              <S.RequestWhiteDrawButton
                disabled={currentBalance <= 0}
                onClick={() => setModalIsOpen(true)}
              >
                Solicitar Saque
              </S.RequestWhiteDrawButton>
            )}
          </div>
        )}
      </S.WhiteDrawSumary>
      <S.RequestWhiteDrawInfo>
        <span>Para realização de saque é cobrada uma taxa de R$ 1,99</span>
      </S.RequestWhiteDrawInfo>
      {operation?.bank_account && (
        <ModalConfirmWhiteDraw
          modalIsOpen={modalIsOpen}
          setModalIsOpen={() => setModalIsOpen(!modalIsOpen)}
          requestWithdrawal={requestWithdrawal}
          currentBalance={currentBalance}
          operation={operation}
        />
      )}

      {urlValidate !== "" && (
        <ModalProofLive
          modalIsOpen={modalProofShow}
          changeStatusModal={changeStatusModal}
          handleModalProofClosed={handleModalProofClosed}
          key={`modal_proof-${id}`}
          url={urlValidate}
        />
      )}
    </div>
  );
};
