import "../styles/franchiseesDetails.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect, useCallback } from "react";
import { push } from "react-router-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { validateCpfCnpj, validatePhone } from "../utils/string";
import { Button, FormGroup, Label, Input } from "reactstrap";

import { store } from "../store";
import api from "../api";


const newArray = [
  { name: "Mapa", value: "map" },
  { name: "Cupons", value: "promocodes" },
  { name: "Passageiros", value: "riders" },
  { name: "Motoristas", value: "drivers" },
  { name: "Cadastro Motorista", value: "aspirant_drivers" },
  { name: "Tarifas", value: "fares" },
  { name: "Viagens", value: "rides" },
  { name: "Notificações", value: "push_notifications" },
  { name: "Equipe", value: "admins" },
  { name: "Estatísticas", value: "analytics" },
  { name: "Franquia", value: "operation" },
];

const initialValues = {
  name: "",
  cpfCnpj: "",
  social: "",
  email: "",
  phone: "",
  cities: "",
  role_description: '',
};

export const FranchiseesDetailsAdmin = ({ match }) => {
  const [cities, setCities] = useState([]);
  const [values, setValues] = useState(initialValues);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [permissions, setPermissions] = useState({});

  const user = useSelector(state => state.account);

  const { id, id_admin } = match.params;

  const handleCpfCnpj = e => {
    const { name, value } = e.target;

    const newValue = validateCpfCnpj(value);

    setValues({ ...values, [name]: newValue });
  };

  const handlePhone = e => {
    const { name, value } = e.target;

    const newValue = validatePhone(value);

    setValues({ ...values, [name]: newValue });
  };

  const HandleOnChange = e => {
    const { name, value } = e.target;

    setValues({ ...values, [name]: value });
  };

  const handlePermissions = e => {
    const { value, checked } = e.target;

    if (checked) {
      setPermissions({ ...permissions, [value]: true });
    } else {
      Object.keys(permissions).forEach(key => {
        if (key === value) {
          delete permissions[key];
          setPermissions({ ...permissions });
        }
      });
    }
  };

  const handleCreateFranchisees = async () => {
    try {
      const payload = {
        active: true,
        id: values.cities,
        cpf: values.cpfCnpj,
        phone: values.phone,
        document_number: values.cpfCnpj,
        cities: [{ id: values.cities }],
        name: values.name,
        email: values.email,
        password: password,
        permissions,
        role_description: values.role_description,
      };

      if (password) {
        payload.password = password
      }

      console.log(payload);

      if (password !== repeatPassword) {
        toast.error("Senhas diferentes");
        return;
      }

      await api.updateCollaboratorId({ payload, id: id_admin });

      toast.success("Franqueado atualizado com sucesso!");

      setTimeout(() => {
        back();
      }, 2000);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleRemoveCollaborator = async () => {
    if (values.is_owner) {
      window.alert('Este usuário não pode ser removido porque é o proprietário da franquia.')
      return
    }

    if (!window.confirm('Deseja realmente excluir este usuário?')) {
      return;
    }

    try {
      await api.removeCollaborator(id_admin);

      toast.success('Usuário removido com sucesso');

      back();
    } catch (e) {
      toast.error(e.message);
    }
  }

  const back = () => {
    store.dispatch(push(`/franchisee-details/id/${id}`));
  };

  useEffect(() => {
    const handleFetchData = async () => {
      try {
        const { id_admin } = match.params;

        const [
          operation,
          collaborator,
          cities
        ] = await Promise.all([
          api.getFranchiseesId(id),
          api.getCollaboratorId(id_admin),
          api.cities()
        ]);

        setCities(cities);

        const newValues = {
          name: collaborator.name,
          cpfCnpj: collaborator.cpf === null ? "" : collaborator.cpf,
          email: collaborator.email,
          phone: collaborator.phone,
          cities: collaborator.cities[0]?.id,
          role_description: collaborator.role_description,
          is_owner: operation.admin_owner_id === collaborator.id,
        };

        setValues(newValues);

        const permissions = collaborator.permissions;

        setPermissions(permissions);
      } catch (error) {
        console.log("Erro em handleFetchData:", error.message);
      }
    };
    handleFetchData();
  }, []);

  return (
    <div className="container__franchisees__details">
      <div className="wrapper">
        <div className="header">
          <div className="title">
            <i className="pi pi-arrow-left" onClick={back} />
            <h1>
              Detalhes do franqueado - <span>Dados pessoais</span>
            </h1>
          </div>
        </div>
        <Container>
          <form>
            <div className="form__header">
              <h5>Dados do franqueados</h5>
            </div>
            <div className="wrapper__form">
              <div className="form-group">
                <label>Nome Completo</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={values.name}
                  onChange={HandleOnChange}
                />
              </div>

              <div className="form-group">
                <label>CPF/CNPJ</label>
                <input
                  type="text"
                  className="form-control"
                  name="cpfCnpj"
                  value={values.cpfCnpj}
                  onChange={handleCpfCnpj}
                />
              </div>

              { <div className="form-group">
                <label>Cargo</label>
                <input
                  type="text"
                  placeholder="Lorem ipsum"
                  className="form-control"
                  name="role_description"
                  value={values.role_description}
                  onChange={HandleOnChange}
                />
              </div>}

              <div className="form-group">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={values.email}
                  onChange={HandleOnChange}
                />
              </div>

              <div className="form-group">
                <label>Telefone</label>
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  value={values.phone}
                  onChange={handlePhone}
                />
              </div>

              <div className="form-group">
                <label>Praça</label>
                <select
                  className="form-control custom-select"
                  name="cities"
                  readOnly
                  style={{pointerEvents: 'none'}}
                  value={values.cities}
                  onChange={HandleOnChange}
                >
                  <option value="" />
                  {cities.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </form>
        </Container>
      </div>

      <div className="wrapper">
        <Container>
          <form>
            <div className="form__header">
              <h5>Senha do franqueado</h5>
            </div>
            <div className="wrapper__form">
              <div className="form-group">
                <label>Senha</label>
                <input
                  className="form-control"
                  type="text"
                  name="password"
                  placeholder="Senha"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </div>

              <div className="form-group">
                <label>Repetir senha</label>
                <input
                  className="form-control"
                  type="text"
                  name="passwordRepeat"
                  placeholder="password"
                  value={repeatPassword}
                  onChange={e => setRepeatPassword(e.target.value)}
                />
              </div>
            </div>
          </form>
        </Container>
      </div>

      <div className="wrapper">
        <Container>
          <form>
            <div className="form__header">
              <h5>Visão do franqueado</h5>
            </div>

            <h6>Selecione as abas do menu que o franqueado terá acesso:</h6>
            <div className="wrapper__form">
              <div className="content__checked">
                {newArray.map((item, index) => (
                  <FormGroup key={item.index} check inline className="check">
                    <Label check>
                      <Input
                        type="checkbox"
                        // eslint-disable-next-line no-prototype-builtins
                        checked={permissions?.hasOwnProperty(item.value)}
                        name={item.name}
                        value={item.value}
                        onChange={handlePermissions}
                      />
                      <span>{item.name}</span>
                    </Label>
                  </FormGroup>
                ))}
              </div>
            </div>
          </form>

          {
            (user.role === 'admin' || user.permissions?.admins) &&
              <div className="btn">
                <button
                  onClick={handleRemoveCollaborator}
                >
                  Remover
                </button>

                <button onClick={handleCreateFranchisees}>Salvar alterações</button>
              </div>
          }
        </Container>
      </div>
    </div>
  );
};

const Container = ({ children }) => (
  <div className="main">
    {children}
  </div>
)