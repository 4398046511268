// @flow
import axios from 'axios';
import uuid from 'uuid/v4';
import { GOOGLE_API_KEY } from '../constants';
import { LatLng, Place } from './types';

const session_token = uuid();

const axiosConfig = {
  timeout: 8000,
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
}
const client = axios.create(axiosConfig)


const AUTOCOMPLETE_URL = `https://maps.googleapis.com/maps/api/place/autocomplete/json`;
const DETAILS_URL = `https://maps.googleapis.com/maps/api/place/details/json`;
const NEARBY_URL = `https://maps.googleapis.com/maps/api/place/nearbysearch/json`;
const GEOCODE_URL = `https://maps.googleapis.com/maps/api/geocode/json`;


export default class Places {
  static async autocomplete(text, latlng :LatLng = null) :Promise<Places[]> {
    let location = latlng ? `${latlng.latitude},${latlng.longitude}` : '';

    let url = parseUrlParams(AUTOCOMPLETE_URL, {
      input: encodeURIComponent(text),
      location,
      radius: 50,
      //components: 'country:br',
      language: 'pt-BR',
      key: GOOGLE_API_KEY,
      session_token
    })

    let rs = await client.get(url, axiosConfig);

    return rs.data.predictions.map(place => {
      return {
        id: uuid(),
        place_id: place.place_id,
        name: place.structured_formatting.main_text,
        address: place.description
      }
    })
  }

  static locality(address_components) :Promise<Place> {
    let place = [
      'administrative_area_level_2',
      'administrative_area_level_1',
      'country'
    ]

    for (let component of address_components) {
      for (let i in place) {
        let field = place[i];
        if (component.types.indexOf(field) !== -1) {
          place[i] = component.short_name;
        }
      }
    }

    return place.join(', ')
  }

  static async details(placeid, shortenAddress = false) :Promise<Place> {
    let url = parseUrlParams(DETAILS_URL, {
      placeid,
      fields: 'id,name,formatted_address,place_id,geometry,address_components',
      region: 'BR',
      language: 'pt-BR',
      key: GOOGLE_API_KEY,
      session_token
    })

    let rs = await client.get(url);

    let { name, formatted_address : address, place_id, geometry, address_components } = rs.data.result;

    return {
      id: uuid(),
      place_id,
      name,
      address: shortenAddress ? parseAddr(address_components) : address,
      latlng: {
        latitude: geometry.location.lat,
        longitude: geometry.location.lng,
      }
    }
  }

  static async nearby(latlng :LatLng = null) :Promise<Places[]> {
    let location = `${latlng.latitude},${latlng.longitude}`;

    let url = parseUrlParams(NEARBY_URL, {
      location,
      rankby: 'distance',
      language: 'pt-BR',
      key: GOOGLE_API_KEY,
      session_token
    })

    let rs = await client.get(url);

    return rs.data.results.map(place => {
      return {
        id: uuid(),
        place_id: place.place_id,
        name: place.name,
        address: place.vicinity
      }
    })
  }

  static async reverseGeocode(latlng :LatLng) :Promise<Place> {
    latlng = `${latlng.latitude},${latlng.longitude}`;

    let url = parseUrlParams(GEOCODE_URL, {
      latlng,
      region: 'BR',
      language: 'pt-BR',
      //result_type: 'street_address',
      key: GOOGLE_API_KEY,
      session_token
    })

    let { data } = await client.get(url);

    let { formatted_address : address, place_id, geometry, address_components } = data.results[0];

    return {
      id: uuid(),
      place_id,
      name: parseAddr(address_components),
      address,
      latlng: {
        latitude: geometry.location.lat,
        longitude: geometry.location.lng,
      }
    }
  }
}

const parseAddr = address_components => {
  let route = '';
  let street_number;

  for (let comp of address_components) {
    if (comp.types.indexOf('route') !== -1) {
      route = comp.short_name;
    } else if(comp.types.indexOf('street_number') !== -1) {
      street_number = comp.short_name;
    }
  }

  return `${route}${street_number ? (' - ' + street_number) : ''}`
}

const parseUrlParams = (uri, obj) => {
  let params = []
  for (let k in obj) {
    params.push(`${k}=${obj[k]}`)
  }
  return uri + '?' + params.join('&')
}