import { GET_CITIES_SUCCESSO } from "../actions/globalMenu";

const initialState = {
  all: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CITIES_SUCCESSO:
      return { ...state, all: action.cities };

    default:
      return state;
  }
};

export default reducer;
