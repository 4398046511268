import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import * as moment from "moment";
import { DatePicker } from "rsuite";
import { getRidesAnalytics } from "../../actions/analytics";
import ReactSelect from "react-select";
import * as S from "./styled";
import "rsuite/dist/styles/rsuite-default.css";
import Paginator from "../../components/paginator";
import exportRidesAnalyticsToExcel from "../../utils/export";
import Api from "../../api";

const AnalyticsRides = (props) => {
  let [page] = useState(1);
  let [dates, setDates] = useState({
    initialDate: "",
    finalDate: "",
    exportInitialDate: "",
    exportFinalDate: "",
  });
  const { city_name, city_id } = useSelector((state) => state.preferences);

  const [city, setCity] = useState({
    id: city_id,
    name: city_name,
  });

  useEffect(() => {
    props.getRidesAnalytics({ ...props.params, page });
  }, []);

  const handleInitialDate = (date) => {
    setDates({
      ...dates,
      initialDate: date,
    });
  };

  const handleExportInitialDate = (date) => {
    setDates({
      ...dates,
      exportInitialDate: date,
    });
  };

  const handleFinalDate = (date) => {
    setDates({
      ...dates,
      finalDate: date,
    });
  };

  const handleExportFinalDate = (date) => {
    setDates({
      ...dates,
      exportFinalDate: date,
    });
  };

  const handleCleanInitialDate = () => {
    setDates({
      ...dates,
      initialDate: "",
    });
  };

  const handleCleanFinalDate = () => {
    setDates({
      ...dates,
      finalDate: "",
    });
  };

  const handleCleanExportInitialDate = () => {
    setDates({
      ...dates,
      exportInitialDate: "",
    });
  };

  const handleCleanExportFinalDate = () => {
    setDates({
      ...dates,
      exportFinalDate: "",
    });
  };

  const onChangePage = (page) => {
    props.getRidesAnalytics({
      ...props.params,
      page,
      initialDate: dates.initialDate,
      finalDate: dates.finalDate,
      city_id: city?.id,
    });
  };

  const handleList = () => {
    props.getRidesAnalytics({
      ...props.params,
      page,
      initialDate: dates.initialDate,
      finalDate: dates.finalDate,
      city_id: city?.id,
    });
  };

  const handleExportList = async () => {
    let corridas = await Api.exportRidesAnalytics({
      exportInitialDate: dates.exportInitialDate,
      exportFinalDate: dates.exportFinalDate,
      city_id: city?.id,
    });
    exportRidesAnalyticsToExcel(corridas);
  };

  const renderRowsTable = (data, i) => {
    let dia = data.data;
    let { moip_tax, moby_tax, cash_total, credit_card_total } = data;

    let totalDriver =
      Number(cash_total) + Number(credit_card_total) - moip_tax - moby_tax;
    let totalPrice = Number(cash_total) + Number(credit_card_total);

    let estado;

    if (data.city_name) {
      estado = data.city_name.split(",")[1];
    } else {
      estado = "Não cadastrado";
    }
    return (
      <S.TRow index={i}>
        <S.TD>{moment(dia).format("DD-MM-YYYY")}</S.TD>
        <S.TD>{data.city_name}</S.TD>
        <S.TD>{estado}</S.TD>
        <S.TD>{totalPrice.toFixed(2)}</S.TD>
        <S.TD>{data.moip_tax} </S.TD>
        <S.TD>{data.moby_tax} </S.TD>
        <S.TD>{totalDriver.toFixed(2)} </S.TD>
        <S.TD>{data.payment_type}</S.TD>
      </S.TRow>
    );
  };

  return (
    <div>
      <S.Title>Analytics Rides</S.Title>
      <div style={{ display: "flex" }}>
        <S.WrapperFilters>
          <div style={{ marginRight: "20px" }}>
            <S.SubTitle>Data inicial</S.SubTitle>
            <DatePicker
              selected={null}
              style={{ width: 200 }}
              onSelect={(date) =>
                handleInitialDate(moment(date).startOf("day"))
              }
              onClean={() => handleCleanInitialDate()}
            />
          </div>
          <div style={{ marginRight: "20px" }}>
            <S.SubTitle>Data final</S.SubTitle>
            <DatePicker
              selected={null}
              style={{ width: 200 }}
              onSelect={(date) => handleFinalDate(moment(date).startOf("day"))}
              onClean={() => handleCleanFinalDate()}
            />
          </div>
          <div>
            <S.SubTitle>Mostrar Resultado</S.SubTitle>
            <button onClick={() => handleList()}>Buscar</button>
          </div>
        </S.WrapperFilters>
        {/* Para exportação */}
        <S.WrapperFilters>
          <div style={{ marginRight: "20px" }}>
            <S.SubTitle>Data inicial para exportação</S.SubTitle>
            <DatePicker
              style={{ width: 200 }}
              onSelect={(date) =>
                handleExportInitialDate(moment(date).startOf("day"))
              }
              onClean={() => handleCleanExportInitialDate()}
            />
          </div>
          <div style={{ marginRight: "20px" }}>
            <S.SubTitle>Data final para exportação</S.SubTitle>
            <DatePicker
              style={{ width: 200 }}
              onSelect={(date) =>
                handleExportFinalDate(moment(date).startOf("day"))
              }
              onClean={() => handleCleanExportFinalDate()}
            />
          </div>
          <div>
            <S.SubTitle>Exportar para excel</S.SubTitle>
            <button onClick={() => handleExportList()}>Exportar</button>
          </div>
        </S.WrapperFilters>
      </div>
      <S.WrapperFilters>
        <div style={{ width: "400px", marginBottom: "30px" }}>
          <ReactSelect
            style={{ width: "100%" }}
            placeholder="Exibir cidade desejada"
            options={props.cities.map((c) => {
              return {
                value: c.id,
                label: c.name,
              };
            })}
            onChange={(defaultValue) =>
              setCity({
                id: defaultValue.value,
                name: defaultValue.label,
              })
            }
          />
        </div>

        <div style={{ width: "400px", marginLeft: "20%" }}>
          <ReactSelect
            style={{ width: "100%" }}
            placeholder="Exportar cidade desejada"
            options={props.cities.map((c) => {
              return {
                value: c.name,
                label: c.name,
              };
            })}
            onChange={(defaultValue) => setCity(defaultValue.value)}
          />
        </div>
      </S.WrapperFilters>

      <S.WrapperTable>
        <S.Table>
          <S.THead>
            <S.TRow>
              <S.TH>Data</S.TH>
              <S.TH>Cidade</S.TH>
              <S.TH>Estado</S.TH>
              <S.TH>R$ Total Corridas</S.TH>
              <S.TH>R$ Total meio de pagamento</S.TH>
              <S.TH>R$ Total Moby Go</S.TH>
              <S.TH>R$ Total Motorista</S.TH>
              <S.TH>Forma de pagamento</S.TH>
            </S.TRow>
          </S.THead>
          <S.TBody>
            {props.rides &&
              props.rides.map((data, i) => renderRowsTable(data, i))}
          </S.TBody>
        </S.Table>
      </S.WrapperTable>
      <Paginator
        page={props.page}
        pages={props.pages}
        onChange={onChangePage}
      />
      {`Exibindo ${props.rides.length} de ${props.total}`}
    </div>
  );
};

const mapStateToProps = (state) => ({
  rides: state.analytics.ridesAnalytics,
  page: state.analytics.page,
  pages: state.analytics.pages,
  total: state.analytics.total,
  params: state.analytics.params,
  exportRides: state.analytics.exportRides,
  geral: state.analytics,
  cities: state.cities.all,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getRidesAnalytics,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsRides);
