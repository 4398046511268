import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#50efb5",
      main: "#50efb5",
      dark: "#50efb5",
      contrastText: "#fff"
    }
  }
});
