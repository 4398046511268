import {
  LIST_PROMOCODES_SUCCESS,
  CREATE_PROMOCODE_SUCCESS,
  UPDATE_PROMOCODE_SUCCESS,
  REMOVE_PROMOCODE_SUCCESS,
} from '../actions/promocodes'

const initialState = {
  all: []
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_PROMOCODES_SUCCESS :
      return { ...state, all: action.all }

    case CREATE_PROMOCODE_SUCCESS :
      return { ...state, all: [ ...state.all, action.promocode ]}

    case UPDATE_PROMOCODE_SUCCESS :
      return {
        ...state,
        all: state.all.map(promocode => {
          if (promocode.id === action.promocode.id) {
            return action.promocode
          }
          return promocode
        })
      }

    case REMOVE_PROMOCODE_SUCCESS :
      return {
        ...state,
        all: state.all.filter(({ id }) => id !== action.id)
      }

    default:
      return state
  }
}

export default reducer
