import React from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { updateDriverCar } from "../actions/drivers";
import { deleteCar } from '../actions/inactiveCars'

Modal.setAppElement("#root");
class ModalCar extends React.Component {
  state = { ...this.props.car };



  handleChange = ({ target }) => {
    const { name, value } = target;
    this.setState({
      [name]: value
    });
  };

  save = e => {
    e.preventDefault();
    this.props.onSave(this.state)
    this.props.handleCloseModal()
    toast.success('Atualizado')
  };

  deleteCar = async id => {
    let {error} = await this.props.deleteCar(id)
    if ( error) {
      toast.error(error.message)
    } else {
      toast.success('Carro deletado com sucesso!')
    }
  }

  buttonDelete = () => {
    return (
      <button
        style={{ position: "absolute", right: "100px" }}
        type="button"
        className="btn btn-danger pull-left"
        onClick={() => this.deleteCar(this.state.id)}
      >
        Deletar Carro
      </button>
    )
  }


  render() {
    return (
      <Modal
        isOpen={this.props.showModal}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.75)"
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            marginLeft: "-250px",
            marginTop: "-300px",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "4px",
            outline: "none",
            padding: "20px",
            width: "500px",
            height: "610px"
          }
        }}
      >
        <div />
        <button
          onClick={this.props.handleCloseModal}
          style={{ position: "absolute", right: "10px" }}
          className="btn btn-secondary"
        >
          Fechar
        </button>

        <div style={{ marginTop: "50px" }} className="card bg-light">
          <div
            style={{ marginTop: "50px !important" }}
            className="card-header"
          >{`Veículo ${this.props.i + 1}`}</div>
          <div className="card-body">
            <form>
              <div className="form-group">
                <label htmlFor="inputCarName">Modelo</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="15"
                  id="inputCarName"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="inputCarPlate">Placa</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="8"
                  id="inputCarPlate"
                  name="license_plate"
                  value={this.state.license_plate}
                  onChange={this.handleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="inputCarColor">Cor</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="10"
                  id="inputCarColor"
                  name="color"
                  value={this.state.color}
                  onChange={this.handleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="inputCarYear">Ano do veículo</label>
                <input
                  type="string"
                  className="form-control"
                  id="inputCarYear"
                  name="year"
                  value={this.state.year}
                  onChange={this.handleChange}
                />
              </div>

              <div className="form-group">
                <label htmlFor="inputCarRenavam">Renavam</label>
                <input
                  type="string"
                  className="form-control"
                  id="inputCarRenavam"
                  name="renavam"
                  value={this.state.renavam}
                  onChange={this.handleChange}
                />
              </div>

              <div className="form-group">
                <label>Status</label>
                <select
                  className="form-control custom-select"
                  name="status"
                  data-type="number-strict"
                  value={this.state.status}
                  onChange={this.handleChange}
                >
                  <option key="0" value={0}>
                    INATIVO
                  </option>
                  <option key="1" value={1}>
                    ATIVO
                  </option>
                </select>
              </div>

              <div className="form-group">
                <label>Deletado</label>
                <select
                  className="form-control custom-select"
                  name="deleted"
                  data-type="number-strict"
                  value={this.state.deleted}
                  onChange={this.handleChange}
                >
                  <option key="0" value={0}>
                    NÃO DELETADO
                  </option>
                  <option key="1" value={1}>
                    DELETADO
                  </option>
                </select>
              </div>
            </form>
          </div>
        </div>

        <button
          style={{ position: "absolute", right: "10px" }}
          type="button"
          //enabled={!this.state.busy}
          className="btn btn-success pull-left"
          onClick={this.save}
        >
          Salvar
        </button>

        {this.state.deleted == 0 ? this.buttonDelete() : null}
      </Modal>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      updateDriverCar,
      deleteCar
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(ModalCar);
