import React, { useState, useEffect } from "react";
import "../styles/franchiseesDetails.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { push } from "react-router-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FormGroup, Label, Input } from "reactstrap";
import { object } from "prop-types";
import moment from "moment";
import InputMask from "react-input-mask";
import estadosBrasil from "../utils/estados-brasil";

import { validateCpfCnpj, validatePhone } from "../utils/string";
import { store } from "../store";
import api from "../api";
import { banks } from "../utils/banks";
import { CustomDatePicker } from "components/customDatePiecker/CustomDatePiecker";

const newArray = [
  { name: "Mapa", value: "map" },
  { name: "Cupons", value: "promocodes" },
  { name: "Passageiros", value: "riders" },
  { name: "Motoristas", value: "drivers" },
  { name: "Cadastro Motorista", value: "aspirant_drivers" },
  { name: "Tarifas", value: "fares" },
  { name: "Viagens", value: "rides" },
  { name: "Notificações", value: "push_notifications" },
  { name: "Equipe", value: "admins" },
  { name: "Estatísticas", value: "analytics" },
  { name: "Franquia", value: "operation" },
];

const initialValues = {
  name: "",
  cpfCnpj: "",
  social: "",
  email: "",
  phone: "",
  cities: "",
  bank_account: {
    bank_number: "",
    agency_number: "",
    agency_check_number: "",
    account_number: "",
    account_check_number: "",
    type: "",
    holder: {
      cpf: "",
      name: "",
    },
  },
};

export const FranchiseesDetails = ({ match }) => {
  const [cities, setCities] = useState([]);
  const [values, setValues] = useState(initialValues);
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [permissions, setPermissions] = useState({});
  const [states, setStates] = useState(estadosBrasil);

  const user = useSelector(state => state.account);

  const { id } = match.params;

  const handleCpfCnpj = e => {
    const { name, value } = e.target;

    const newValue = validateCpfCnpj(value);

    setValues({ ...values, [name]: newValue });
  };

  const handlePhone = e => {
    const { name, value } = e.target;

    const newValue = validatePhone(value);

    setValues({ ...values, [name]: newValue });
  };

  const HandleOnChange = e => {
    const { name, value } = e.target;

    setValues({ ...values, [name]: value });
  };

  const HandleOnChangeBankInfo = e => {
    const { name, value } = e.target;

    setValues({
      ...values,
      bank_account: { ...values?.bank_account, [name]: value },
    });
  };

  const HandleOnChangeAddressInfo = e => {
    const { name, value } = e.target;

    setValues({
      ...values,
      address: { ...values?.address, [name]: value },
    });
  };

  const HandleOnChangeBankHolderInfo = e => {
    const { name } = e.target;
    const field = name === "favored_name" ? "name" : "cpf";

    let value = e.target.value;

    if (field === "cpf") {
      value = validateCpfCnpj(value);
    }

    setValues({
      ...values,
      bank_account: {
        ...values?.bank_account,
        holder: {
          ...values?.bank_account?.holder,
          [field]: value,
        },
      },
    });
  };

  const handlePermissions = e => {
    const { value, checked } = e.target;

    if (checked) {
      setPermissions({ ...permissions, [value]: true });
    } else {
      Object.keys(permissions).forEach(key => {
        if (key === value) {
          delete permissions[key];
          setPermissions({ ...permissions });
        }
      });
    }
  };

  const handleCreateFranchisees = async () => {
    try {
      const payload = {
        active: true,
        id: values.cities,
        company_name: values.social,
        document_number: values.documentNumber,
        admin: {
          name: values.name,
          email: values.email,
          phone: values.phone,
          birthdate: moment(values.birthdate).format("YYYY-MM-DD"),
          cpf: values.cpfCnpj,
          permissions,
        },
        bank_account: {
          bank_number: values?.bank_account?.bank_number,
          agency_number: values?.bank_account?.agency_number,
          agency_check_number: values?.bank_account?.agency_check_number,
          account_number: values?.bank_account?.account_number,
          account_check_number: values?.bank_account?.account_check_number,
          type: values?.bank_account?.type,
          holder: {
            cpf: values?.bank_account?.holder?.cpf,
            name: values?.bank_account?.holder?.name,
          },
        },
        address: {
          street: values?.address?.street,
          street_number: values?.address?.street_number,
          zip_code: values?.address?.zip_code
            ?.replace("-", "")
            ?.replace(".", ""),
          district: values?.address?.district,
          city: values?.address?.city,
          state: values?.address?.state,
        },
      };

      if (
        !payload?.address?.street ||
        !payload?.address?.street_number ||
        !payload?.address?.zip_code ||
        !payload?.address?.district ||
        !payload?.address?.city ||
        !payload?.address?.state
      ) {
        toast.error("Todos os dados de endereço precisam ser informados");
        return;
      }

      if (
        !payload?.bank_account?.bank_number ||
        !payload?.bank_account?.agency_number ||
        !payload?.bank_account?.agency_check_number ||
        !payload?.bank_account?.account_number ||
        !payload?.bank_account?.account_check_number ||
        !payload?.bank_account?.type ||
        !payload?.bank_account?.holder?.cpf ||
        !payload?.bank_account?.holder?.name
      ) {
        toast.error("Todos os dados bancários precisam ser informados");
        return;
      }

      if (password !== repeatPassword) {
        toast.error("Senhas diferentes");
        return;
      }

      if (password !== repeatPassword) {
        toast.error("Senhas diferentes");
        return;
      }

      await api.updateFranchisees({ payload, id });

      toast.success("Franqueado atualizado com sucesso!");

      setTimeout(() => {
        store.dispatch(push(`/franchisee-details/id/${id}`));
      }, 2000);
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleBirthChange = birthdate => {
    setValues({ ...values, birthdate });
  };

  const handleRemoveFranchisee = async () => {
    if (
      !window.confirm(
        "Deseja realmente excluir esta franquia? Esta operação não pode ser desfeita"
      )
    ) {
      return;
    }

    try {
      const rs = await api.removeFranchisees(id);
      if (rs) {
        toast.success("Franquia removida com sucesso");
        store.dispatch(push("/franchisees"));
      } else {
        throw new Error("Não foi possível remover a franquia");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const back = () => {
    store.dispatch(push(`/franchisees`));
  };

  const handleFetchData = async () => {
    try {
      const { id } = match.params;

      const operation = await api.getFranchiseesId(id);

      const cities = await api.cities();
      setCities(cities);

      const newValues = {
        moip_account_id: operation.moip_account_id,
        pagarme_recipient_id: operation.pagarme_recipient_id,
        name: operation?.admin?.name,
        cpfCnpj: operation?.admin?.cpf,
        documentNumber: operation?.document_number,
        social: operation?.company_name,
        email: operation?.admin?.email,
        phone: operation?.admin?.phone,
        cities: operation?.city.id,
        bank_account: operation?.bank_account,
        birthdate: operation?.admin?.birthdate,
        address: operation?.address,
      };

      setValues(newValues);

      const permissions = operation?.admin?.permissions;

      setPermissions(permissions);
    } catch (error) {
      console.log("Erro em handleFetchData:", error.message);
    }
  };

  useEffect(() => {
    handleFetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={`container__franchisees__details ${
        user.role === "admin" ? "" : "disabled"
      }`}
    >
      <div className="wrapper">
        <div className="header">
          <div className="title">
            {user.role === "admin" && (
              <i className="pi pi-arrow-left" onClick={back} />
            )}
            <h1>Detalhes da franquia{/* - <span>Dados pessoais</span>*/}</h1>
          </div>

          <div className="btns">
            <button
              onClick={() =>
                store.dispatch(push(`/franchisees-payment/id/${id}`))
              }
            >
              Ver pagamentos
            </button>

            <button
              onClick={() =>
                store.dispatch(push(`/franchisee-details/id/${id}`))
              }
            >
              Equipe
            </button>
          </div>
        </div>
        <Container>
          <form>
            <div className="form__header">
              <h5>Dados da franquia</h5>
            </div>
            <div className="wrapper__form">
              <div className="form-group">
                <label>MOIP ID</label>
                <input
                  type="text"
                  readOnly
                  className="form-control"
                  style={{ pointerEvents: "none" }}
                  value={values?.moip_account_id || ""}
                />
              </div>
              <div className="form-group">
                <label>Pagarme ID</label>
                <input
                  type="text"
                  readOnly
                  className="form-control"
                  style={{ pointerEvents: "none" }}
                  value={values?.pagarme_recipient_id || ""}
                />
              </div>
              <div className="form-group">
                <label>Razão Social</label>
                <input
                  type="text"
                  className="form-control"
                  name="social"
                  value={values.social}
                  onChange={HandleOnChange}
                />
              </div>
              <div className="form-group">
                <label>CPF/CNPJ</label>
                <input
                  type="text"
                  className="form-control"
                  name="documentNumber"
                  value={values.documentNumber}
                  onChange={handleCpfCnpj}
                />
              </div>
            </div>
          </form>
        </Container>

        <Container>
          <form>
            <div className="form__header">
              <h5>Dados do franqueado</h5>
            </div>
            <div className="wrapper__form">
              <div className="form-group">
                <label>Nome Completo</label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  value={values.name}
                  onChange={HandleOnChange}
                />
              </div>

              <div className="form-group">
                <label>CPF</label>
                <input
                  type="text"
                  className="form-control"
                  name="cpfCnpj"
                  value={values.cpfCnpj}
                  onChange={handleCpfCnpj}
                />
              </div>

              <div className="form-group">
                <label>Data de Nascimento: </label>
                <CustomDatePicker
                  key={`birthdate-${values.birthdate}`}
                  showYearDropdown
                  date={values.birthdate}
                  maxDate={moment.utc().subtract(18, "years")}
                  dateDatefult={moment
                    .utc()
                    .subtract(18, "years")
                    .startOf("day")}
                  onChange={handleBirthChange}
                />
              </div>

              <div className="form-group">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  name="email"
                  value={values.email}
                  onChange={HandleOnChange}
                />
              </div>

              <div className="form-group">
                <label>Telefone</label>
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  value={values.phone}
                  onChange={handlePhone}
                />
              </div>

              <div className="form-group">
                <label>Praça</label>
                <select
                  className="form-control custom-select"
                  name="cities"
                  value={values.cities}
                  readOnly
                  style={{ pointerEvents: "none" }}
                >
                  <option value="" />
                  {cities.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </form>
        </Container>

        <Container>
          <form>
            <div className="form__header">
              <h5>Endereço</h5>
            </div>
            <div className="wrapper__form">
              <div className="form-group">
                <label>Rua</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="100"
                  name="street"
                  value={values?.address?.street}
                  onChange={HandleOnChangeAddressInfo}
                />
              </div>
              <div className="form-group">
                <label>Número</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="30"
                  name="street_number"
                  value={values?.address?.street_number}
                  onChange={HandleOnChangeAddressInfo}
                />
              </div>
              <div className="form-group">
                <label>CEP</label>
                <InputMask
                  type="text"
                  className="form-control"
                  name="zip_code"
                  data-type="cep"
                  value={values?.address?.zip_code}
                  onChange={HandleOnChangeAddressInfo}
                  mask="99999-999"
                />
              </div>
              <div className="form-group">
                <label>Bairro</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="30"
                  name="district"
                  value={values?.address?.district}
                  onChange={HandleOnChangeAddressInfo}
                />
              </div>
              <div className="form-group">
                <label>Cidade</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="50"
                  name="city"
                  value={values?.address?.city}
                  onChange={HandleOnChangeAddressInfo}
                />
              </div>
              <div className="form-group">
                <label>Estado</label>
                <select
                  className="form-control custom-select"
                  name="state"
                  value={values?.address?.state}
                  onChange={HandleOnChangeAddressInfo}
                >
                  <option value="" />
                  {states.map(state => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </form>
        </Container>

        <Container>
          <form>
            <div className="form__header">
              <h5>Dados bancários</h5>
            </div>
            <div className="wrapper__form">
              <div className="form-group row">
                <label>Banco</label>
                <select
                  className="form-control custom-select"
                  value={values?.bank_account?.bank_number}
                  name="bank_number"
                  onChange={HandleOnChangeBankInfo}
                  data-name="bank_number"
                  id="bank_number"
                  required
                >
                  <option value="" />
                  {banks.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.id} - {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="form-group row">
                <label>Agencia</label>
              </div>

              <div className="form-group row">
                <input
                  type="text"
                  className="form-control col-9"
                  name="agency_number"
                  value={values?.bank_account?.agency_number}
                  onChange={HandleOnChangeBankInfo}
                  required
                />
                <span className="col-1">-</span>
                <input
                  type="text"
                  className="form-control col-2"
                  name="agency_check_number"
                  value={values?.bank_account?.agency_check_number}
                  onChange={HandleOnChangeBankInfo}
                  required
                />
              </div>

              <div className="form-group row">
                <label>Conta</label>
              </div>

              <div className="form-group row">
                <input
                  type="text"
                  className="form-control col-9"
                  name="account_number"
                  value={values?.bank_account?.account_number}
                  onChange={HandleOnChangeBankInfo}
                  required
                />
                <span className="col-1">-</span>
                <input
                  type="text"
                  className="form-control col-2"
                  name="account_check_number"
                  value={values?.bank_account?.account_check_number}
                  onChange={HandleOnChangeBankInfo}
                  required
                />
              </div>

              <div className="form-group row">
                <label>Tipo</label>
                <select
                  className="form-control custom-select"
                  value={values?.bank_account?.type}
                  onChange={HandleOnChangeBankInfo}
                  name="type"
                  id="type"
                  required
                >
                  <option value="" />
                  <option key="SAVING" value="SAVING">
                    Conta Poupança
                  </option>
                  <option key="CHECKING" value="CHECKING">
                    Conta Corrente
                  </option>
                </select>
              </div>

              <div className="form-group row">
                <label>Nome do favorecido</label>
                <input
                  type="text"
                  className="form-control"
                  name="favored_name"
                  value={values?.bank_account?.holder?.name}
                  onChange={HandleOnChangeBankHolderInfo}
                  required
                />
              </div>

              <div className="form-group row">
                <label>CPF/CNPJ do favorecido</label>
                <input
                  type="text"
                  className="form-control"
                  name="favored_cpf"
                  value={values?.bank_account?.holder?.cpf}
                  onChange={HandleOnChangeBankHolderInfo}
                  required
                />
              </div>
            </div>
          </form>
        </Container>
      </div>

      <div className="wrapper">
        <Container>
          <form>
            <div className="form__header">
              <h5>Senha do franqueado</h5>
            </div>
            <div className="wrapper__form">
              <div className="form-group">
                <label>Senha</label>
                <input
                  className="form-control"
                  type="text"
                  name="password"
                  placeholder="Senha"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
              </div>

              <div className="form-group">
                <label>Repetir senha</label>
                <input
                  className="form-control"
                  type="text"
                  name="passwordRepeat"
                  placeholder="password"
                  value={repeatPassword}
                  onChange={e => setRepeatPassword(e.target.value)}
                />
              </div>
            </div>
          </form>
        </Container>
      </div>

      <div className="wrapper">
        <Container>
          <form>
            <div className="form__header">
              <h5>Visão do franqueado</h5>
            </div>

            <h6>Selecione as abas do menu que o franqueado terá acesso:</h6>
            <div className="wrapper__form">
              <div className="content__checked">
                {newArray.map((item, index) => (
                  <FormGroup key={item.index} check inline className="check">
                    <Label check>
                      <Input
                        type="checkbox"
                        // eslint-disable-next-line no-prototype-builtins
                        checked={permissions?.hasOwnProperty(item.value)}
                        name={item.name}
                        value={item.value}
                        onChange={handlePermissions}
                      />
                      <span>{item.name}</span>
                    </Label>
                  </FormGroup>
                ))}
              </div>
            </div>
          </form>

          {user.role === "admin" && (
            <div className="btn">
              <button onClick={handleRemoveFranchisee}>Remover</button>

              <button onClick={handleCreateFranchisees}>
                Salvar alterações
              </button>
            </div>
          )}
        </Container>
      </div>
    </div>
  );
};

FranchiseesDetails.propTypes = {
  match: object,
};

const Container = ({ children }) => <div className="main">{children}</div>;

Container.propTypes = {
  children: object,
};
