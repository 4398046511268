export const BankAccountInitialValues = {
  id: "",
  accountCheckNumber: "",
  accountNumber: "",
  agencyCheckNumber: "",
  agencyNumber: "",
  bankNumber: "",
  bankName: "",
  type: "",
  holder: {
    cpf: "",
    name: "",
  },
};
