import styled from 'styled-components';

export const Form = styled.form`
  .inputPrime input {
    border: 0.1px solid #ced4da;
    border-radius: 0px 0px 0px 0px !important;
    padding: 0.375rem 0.75rem;
    background-color: #fff;
    background-clip: padding-box;
  }
  
  .auto {
    border: 0.1px solid #ced4da;
    border-radius: 0px 0px 0px 0px !important;
    padding: 0.375rem 0.75rem;
    background-color: #fff;
    background-clip: padding-box;
  }
  
  .textValue {
    border: 0.1px solid #ced4da;
    border-radius: 0px 0px 0px 0px !important;
    padding: 0.375rem 0.75rem;
    background-color: #fff;
    background-clip: padding-box;
  }
 
  .dataContainer {
    display: flex;
    margin-top: 30px;
  }  

  .category {
    margin-bottom: 20px;
  }

  input[readOnly] {
    cursor: default;
    pointer-events: none;
  }
`;
