import {
  CREATE_NOTIFICATION,
  GET_NOTIFICATIONS_SUCCESS,
  CANCEL_NOTIFICATION_SUCCESS,
  GET_ONE_PUSH_SUCCESS,
  UPDATE_PUSH_TITLE
} from '../actions/push-notification';


const initialState = {
  onePush: {},
  notifications: [],
  page: 0,
  pages: 0,
  total: 0,
  params: {
    page: 1
  }
}

const reducer = (state = initialState, action) => {

  switch(action.type) {
    case CREATE_NOTIFICATION : {
      return {...state, push: action.payload}
    }

    case GET_NOTIFICATIONS_SUCCESS : {
      return {
        ...state,
        notifications: action.notifications.results,
        page: action.notifications.page,
        pages: action.notifications.pages,
        total: action.notifications.total,
        params: { page: action.notifications.page}
      }
    }

    case CANCEL_NOTIFICATION_SUCCESS : {
      let pushId = action.id
      return {
        ...state,
        notifications: state.notifications.map(notification => {
          if(notification.id == pushId) {
            return {
              ...notification,
              deleted: true
            }

          }
          return {...notification}
        })

      }
    }

    case GET_ONE_PUSH_SUCCESS: {
      return {
        ...state,
        onePush: action.payload
      }
    }

    case UPDATE_PUSH_TITLE : {
      let {onePush} = state
      return {
        ...state,
        onePush: { ...onePush, ...action.props}
      }
    }
    default:
      return state
  }
}

export default reducer
