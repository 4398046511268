import Api from "../api";

export const STATISTICS_FINANCIAL = "STATISTICS_FINANCIAL";
export const STATISTICS_FINANCIAL_ERROR = "STATISTICS_FINANCIAL_ERROR";
export const STATISTICS_FINANCIAL_SUCCESS = "STATISTICS_FINANCIAL_SUCCESS";

export const STATISTICS_MONTHLY = "STATISTICS_MONTHLY";
export const STATISTICS_MONTHLY_ERROR = "STATISTICS_MONTHLY_ERROR";
export const STATISTICS_MONTHLY_SUCCESS = "STATISTICS_MONTHLY_SUCCESS";

export const STATISTICS_DRIVERS = "STATISTICS_DRIVERS";
export const STATISTICS_DRIVERS_ERROR = "STATISTICS_DRIVERS_ERROR";
export const STATISTICS_DRIVERS_SUCCESS = "STATISTICS_DRIVERS_SUCCESS";

export const STATISTICS_RIDERS = "STATISTICS_RIDERS";
export const STATISTICS_RIDERS_ERROR = "STATISTICS_RIDERS_ERROR";
export const STATISTICS_RIDERS_SUCCESS = "STATISTICS_RIDERS_SUCCESS";

export const STATISTICS_INDICATORS = "STATISTICS_INDICATORS";
export const STATISTICS_INDICATORS_ERROR = "STATISTICS_INDICATORS_ERROR";
export const STATISTICS_INDICATORS_SUCCESS = "STATISTICS_INDICATORS_SUCCESS";

export const STATISTICS_ROYALTIES = "STATISTICS_ROYALTIES";
export const STATISTICS_ROYALTIES_ERROR = "STATISTICS_ROYALTIES_ERROR";
export const STATISTICS_ROYALTIES_SUCCESS = "STATISTICS_ROYALTIES_SUCCESS";

export const STATISTICS_RIDES = "STATISTICS_RIDES";
export const STATISTICS_RIDES_ERROR = "STATISTICS_RIDES_ERROR";
export const STATISTICS_RIDES_SUCCESS = "STATISTICS_RIDES_SUCCESS";

export const STATISTICS_SET_INITIAL_DATE = "STATISTICS_SET_INITIAL_DATE";
export const STATISTICS_SET_FINAL_DATE = "STATISTICS_SET_FINAL_DATE";

export const STATISTICS_SET_LOADING = "STATISTICS_SET_LOADING";
export const STATISTICS_SET_NOT_LOADING = "STATISTICS_SET_NOT_LOADING";

export const setStatisticsLoading = () => (dispatch) => {
  return dispatch({
    type: STATISTICS_SET_LOADING,
  });
};

export const setStatisticsNotLoading = () => (dispatch) => {
  return dispatch({
    type: STATISTICS_SET_NOT_LOADING,
  });
};

export const setInitialDate = (initialDate) => (dispatch) => {
  return dispatch({
    type: STATISTICS_SET_INITIAL_DATE,
    initialDate,
  });
};

export const setFinalDate = (finalDate) => (dispatch) => {
  return dispatch({
    type: STATISTICS_SET_FINAL_DATE,
    finalDate,
  });
};

export const setCity = (city_id, city_name) => (dispatch, getState) => {
  return dispatch({
    type: SET_CITY,
    city_id,
    city_name,
  });
};

export const getStatisticsFinancial = (params) => async (dispatch) => {
  dispatch({
    type: STATISTICS_FINANCIAL,
    params,
  });

  try {
    let statisticsFinancial = await Api.listAnalyticsFinancial(params);

    return dispatch({
      type: STATISTICS_FINANCIAL_SUCCESS,
      statisticsFinancial,
    });
  } catch (error) {
    return dispatch({
      type: STATISTICS_FINANCIAL_ERROR,
      error,
    });
  }
};

export const getStatisticsMonthly = (params) => async (dispatch) => {
  dispatch({
    type: STATISTICS_MONTHLY,
    params,
  });

  try {
    let statisticsMonthly = await Api.listAnalyticsMonthly(params);

    return dispatch({
      type: STATISTICS_MONTHLY_SUCCESS,
      statisticsMonthly,
    });
  } catch (error) {
    return dispatch({
      type: STATISTICS_MONTHLY_ERROR,
      error,
    });
  }
};

export const getStatisticsDrivers = (params) => async (dispatch, getState) => {
  dispatch({
    type: STATISTICS_DRIVERS,
    params,
  });

  try {
    let statisticsDrivers = await Api.listAnalyticsDrivers(params);

    return dispatch({
      type: STATISTICS_DRIVERS_SUCCESS,
      statisticsDrivers,
    });
  } catch (error) {
    return dispatch({
      type: STATISTICS_DRIVERS_ERROR,
      error,
    });
  }
};

export const getStatisticsRiders = (params) => async (dispatch, getState) => {
  dispatch({
    type: STATISTICS_RIDERS,
    params,
  });

  try {
    let statisticsRiders = await Api.listAnalyticsRiders(params);

    return dispatch({
      type: STATISTICS_RIDERS_SUCCESS,
      statisticsRiders,
    });
  } catch (error) {
    return dispatch({
      type: STATISTICS_RIDERS_ERROR,
      error,
    });
  }
};

export const getStatisticsIndicators =
  (params) => async (dispatch, getState) => {
    dispatch({
      type: STATISTICS_INDICATORS,
      params,
    });

    try {
      let statisticsIndicators = await Api.listAnalyticsIndicators(params);

      return dispatch({
        type: STATISTICS_INDICATORS_SUCCESS,
        statisticsIndicators,
      });
    } catch (error) {
      return dispatch({
        type: STATISTICS_INDICATORS_ERROR,
        error,
      });
    }
  };

export const getStatisticsRoyalties =
  (params) => async (dispatch, getState) => {
    dispatch({
      type: STATISTICS_ROYALTIES,
      params,
    });

    try {
      let statisticsRoyalties = await Api.listAnalyticsRoyalties(params);

      return dispatch({
        type: STATISTICS_ROYALTIES_SUCCESS,
        statisticsRoyalties,
      });
    } catch (error) {
      return dispatch({
        type: STATISTICS_ROYALTIES_ERROR,
        error,
      });
    }
  };

export const getStatisticsRides = (params) => async (dispatch, getState) => {
  dispatch({
    type: STATISTICS_RIDES,
    params,
  });

  try {
    let statisticsRides = await Api.listAnalyticsRides(params);

    return dispatch({
      type: STATISTICS_RIDES_SUCCESS,
      statisticsRides,
    });
  } catch (error) {
    return dispatch({
      type: STATISTICS_RIDES_ERROR,
      error,
    });
  }
};
