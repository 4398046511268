import React from "react";
import "../../../styles/statistics.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { useSelector } from "react-redux";

export const RidesInProgress = () => {
  const { statisticsRides } = useSelector((state) => state.statistics);

  return (
    <div className="races__in__progress">
      <div className="title">
        <ScheduleIcon />
        <h4>Corridas em andamento</h4>
      </div>
      <div>
        <strong>{statisticsRides?.in_progress || 0}</strong>
      </div>
    </div>
  );
};
