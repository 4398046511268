/* eslint-disable import/no-anonymous-default-export */
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/storage'
let app

const initializeApp = () => {
  if (app) {
    return
  }

  app = firebase.initializeApp({
    apiKey: "AIzaSyCtj9VICiUqexIDFcjrUBjLLKE_mkQEza4",
    authDomain: "moby-go-app.firebaseapp.com",
    databaseURL: "https://moby-go-app.firebaseio.com",
    projectId: "moby-go-app",
    storageBucket: "moby-go-app.appspot.com",
    messagingSenderId: "369003337029",
  })

  try {
    firebase.auth().signInAnonymously();
  } catch (e) {
    //
  }
}

export default {
  initializeApp,
}
