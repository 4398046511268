import React from "react";

import ModalCar from "./modalCar";

class Cars extends React.Component {
  constructor() {
    super();
    this.state = {
      showModal: false
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  handleOpenModal() {
    this.setState({ showModal: true });
  }

  handleCloseModal() {
    this.setState({ showModal: false });
  }

  render() {
    const { car, i, onSave } = this.props;

    return (
      <div className="card bg-light">
        <div className="card-header">{`Veículo ${i + 1}`}</div>
        <div className="card-body">
          <form>
            <div className="form-group">
              <label htmlFor="inputCarName">Modelo</label>
              <input
                type="text"
                className="form-control"
                maxLength="15"
                id="inputCarName"
                data-name={`cars.${i}.name`}
                value={car.name}
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputCarPlate">Placa</label>
              <input
                type="text"
                className="form-control"
                maxLength="8"
                id="inputCarPlate"
                value={car.license_plate}
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputCarColor">Cor</label>
              <input
                type="text"
                className="form-control"
                maxLength="10"
                id="inputCarColor"
                value={car.color}
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputCarYear">Ano do veículo</label>
              <input
                type="string"
                className="form-control"
                id="inputCarYear"
                value={car.year}
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputCarRenavam">Renavam</label>
              <input
                type="string"
                className="form-control"
                id="inputCarRenavamr"
                value={car.renavam}
                readOnly
              />
            </div>
            <div className="form-group">
              <label>Status</label>
              <input
                readOnly
                className="form-control"
                value={Number(car.status) ? "ATIVO" : "INATIVO"}
              />
            </div>

            <div className="form-group">
              <label>Deletado</label>
              <input
                readOnly
                className="form-control"
                value={Number(car.deleted) == 1 ? "Deletado" : "Não deletado"}
              />
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <a
                href={car.crlv_img}
                target="_blank"
                rel="noopener noreferrer"
                style={{ margin: "0 auto" }}
              >
                <img src={car.crlv_img} style={{ maxHeight: 300 }} alt="" />
              </a>
            </div>

            <button
              type="button"
              className="btn btn-secondary"
              onClick={this.handleOpenModal}
            >
              Editar
            </button>
            <ModalCar
              showModal={this.state.showModal}
              handleCloseModal={this.handleCloseModal}
              onSave = {onSave}
              i={i}
              car={car}
            />
          </form>
        </div>
      </div>
    );
  }
}

export default Cars;
