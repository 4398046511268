/* eslint-disable react/prop-types */
import React from 'react'
import * as S from './style'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment'
import { SelectedTarget } from '../../../api/types'
import {cancelNotification} from '../../../actions/push-notification'

const CardNotification = props => {

  const getTarget = () => {

    console.log('card', props)
    const { target } = props;
    const options = {
      [SelectedTarget.MOTORISTAS]: 'Motoristas',
      [SelectedTarget.PASSAGEIROS]: 'Passageiros',
      [SelectedTarget.MOTORISTA]: 'Motorista',
      [SelectedTarget.PASSAGEIRO]: 'Passageiro'
    };
    return options[target] || 'mdsss';
  }

  const handleCancelPush = () => {
    if (window.confirm('Deseja realmente deletar essa notificação?')) {
      props.cancelNotification(props.id)
    }
  }

  const checkIfIsDeleted = props => {
    if (props.isAgended && props.send_at == 2) {
      if(props.scheduledTo != undefined) {
        if(!moment(new Date()).isSameOrBefore(props.scheduledTo)) {
          return '✅'
        }
      }

    }
    if(props.isAgended && props.deleted != true) {
      return <S.ButtonCancel onClick={handleCancelPush}>Cancelar agendamento</S.ButtonCancel>
    } else if(props.send_at == 1) {
      return '✅'
    } else {
      return 'Cancelado'
    }
  }

  return (
    <S.CardWrapper >
      <S.CardTitle onClick={() => props.editPush(props.id)}>{props.title}</S.CardTitle>
      <S.CardMessage>{props.message}</S.CardMessage>
      <S.WrapperBadget>
        <i className="fas fa-at" />&#32;<S.BadgetParagraph>{props.author}</S.BadgetParagraph>
        <i className="far fa-clock" /><S.BadgetParagraph>{moment(props.created_at).format('DD-MM-YYYY HH:mm')}</S.BadgetParagraph>
        <i className="fas fa-user" /><S.BadgetParagraph>{getTarget()}</S.BadgetParagraph>
        {props.targetAmount> 0 ? (<div><i className="fas fa-users" /><S.BadgetParagraph>{props.targetAmount}</S.BadgetParagraph> </div>) : null}
        {checkIfIsDeleted(props)}
      </S.WrapperBadget>
    </S.CardWrapper>
  )
}



const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    cancelNotification
  }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardNotification);