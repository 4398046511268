import Api from '../api';

export const GET_INACTIVE_REASON = 'GET_INACTIVE_REASON';
export const GET_INACTIVE_REASON_SUCCESS = 'GET_INACTIVE_REASON_SUCCESS';
export const GET_INACTIVE_REASON_ERROR = 'GET_INACTIVE_REASON_ERROR';

export const getInactiveReason = () => async dispatch => {
  dispatch({
    type: GET_INACTIVE_REASON
  })

  try {
    let reasons = await Api.getDriversInactiveReason();

    return dispatch({
      type: GET_INACTIVE_REASON_SUCCESS,
      reasons
    })
  } catch (error) {
    return dispatch({
      type: GET_INACTIVE_REASON_ERROR,
      error
    })
  }
}