const banksItens = [
  {
    id: "001",
    name: "Banco do Brasil S.A.",
  },
  {
    id: "003",
    name: "Banco da Amazônia S.A.",
  },
  {
    id: "004",
    name: "Banco do Nordeste do Brasil S.A.",
  },
  {
    id: "012",
    name: "Banco Inbursa S.A.",
  },
  {
    id: "014",
    name: "State Street Brasil S.A. - Banco Comercial",
  },
  {
    id: "017",
    name: "BNY Mellon Banco S.A.",
  },
  {
    id: "021",
    name: "BANESTES S.A. Banco do Estado do Espírito Santo",
  },
  {
    id: "024",
    name: "Banco BANDEPE S.A.",
  },
  {
    id: "025",
    name: "Banco Alfa S.A.",
  },
  {
    id: "029",
    name: "Banco Itaú Consignado S.A.",
  },
  {
    id: "033",
    name: "Banco Santander (Brasil) S.A.",
  },
  {
    id: "036",
    name: "Banco Bradesco BBI S.A.",
  },
  {
    id: "037",
    name: "Banco do Estado do Pará S.A.",
  },
  {
    id: "040",
    name: "Banco Cargill S.A.",
  },
  {
    id: "041",
    name: "Banco do Estado do Rio Grande do Sul S.A.",
  },
  {
    id: "047",
    name: "Banco do Estado de Sergipe S.A.",
  },
  {
    id: "062",
    name: "Hipercard Banco Múltiplo S.A.",
  },
  {
    id: "063",
    name: "Banco Bradescard S.A.",
  },
  {
    id: "065",
    name: "Banco Andbank (Brasil) S.A.",
  },
  {
    id: "066",
    name: "Banco Morgan Stanley S.A.",
  },
  {
    id: "070",
    name: "BRB - Banco de Brasília S.A.",
  },
  {
    id: "074",
    name: "Banco J. Safra S.A.",
  },
  {
    id: "077",
    name: "Banco Inter S.A.",
  },
  {
    id: "082",
    name: "Banco Topázio S.A.",
  },
  {
    id: "083",
    name: "Banco da China Brasil S.A.",
  },
  {
    id: "094",
    name: "Banco Finaxis S.A.",
  },
  {
    id: "095",
    name: "Travelex Banco de Câmbio S.A.",
  },
  {
    id: "096",
    name: "Banco B3 S.A.",
  },
  {
    id: 104,
    name: "Caixa Econômica Federal",
  },
  {
    id: 107,
    name: "Banco BOCOM BBM S.A.",
  },
  {
    id: 119,
    name: "Banco Western Union do Brasil S.A.",
  },
  {
    id: 120,
    name: "Banco Rodobens S.A.",
  },
  {
    id: 121,
    name: "Banco Agibank S.A.",
  },
  {
    id: 125,
    name: "Banco Genial S.A.",
  },
  {
    id: 128,
    name: "Braza Bank S.A. Banco de Câmbio",
  },
  {
    id: 129,
    name: "UBS Brasil Banco de Investimento S.A.",
  },
  {
    id: 144,
    name: "BEXS Banco de Câmbio S.A.",
  },
  {
    id: 184,
    name: "Banco Itaú BBA S.A.",
  },
  {
    id: 208,
    name: "Banco BTG Pactual S.A.",
  },
  {
    id: 212,
    name: "Banco Original S.A.",
  },
  {
    id: 217,
    name: "Banco John Deere S.A.",
  },
  {
    id: 218,
    name: "Banco BS2 S.A.",
  },
  {
    id: 222,
    name: "Banco Credit Agricole Brasil S.A.",
  },
  {
    id: 224,
    name: "Banco Fibra S.A.",
  },
  {
    id: 233,
    name: "Banco Cifra S.A.",
  },
  {
    id: 237,
    name: "Banco Bradesco S.A.",
  },
  {
    id: 243,
    name: "Banco Master S.A.",
  },
  {
    id: 246,
    name: "Banco ABC Brasil S.A.",
  },
  {
    id: 249,
    name: "Banco Investcred Unibanco S.A.",
  },
  {
    id: 250,
    name: "BCV - Banco de Crédito e Varejo S.A.",
  },
  {
    id: "254",
    name: "Paraná Banco S.A.",
  },
  {
    id: 269,
    name: "HSBC Brasil S.A. - Banco de Investimento",
  },
  {
    id: 276,
    name: "Banco Senff S.A.",
  },
  {
    id: 290,
    name: "Banco PagBank",
  },
  {
    id: 299,
    name: "Banco Afinz S.A. Banco Múltiplo",
  },
  {
    id: 318,
    name: "Banco BMG S.A.",
  },
  {
    id: 320,
    name: "China Construction Bank (Brasil) Banco Múltiplo S.A.",
  },
  {
    id: 323,
    name: "Banco Mercado Pago",
  },
  {
    id: 341,
    name: "Itaú Unibanco S.A.",
  },
  {
    id: 348,
    name: "Banco XP S.A.",
  },
  {
    id: 336,
    name: "Banco C6",
  },
  {
    id: 366,
    name: "Banco Société Générale Brasil S.A.",
  },
  {
    id: 370,
    name: "Banco Mizuho do Brasil S.A.",
  },
  {
    id: 376,
    name: "Banco J. P. Morgan S.A.",
  },
  {
    id: 389,
    name: "Banco Mercantil do Brasil S.A.",
  },
  {
    id: 394,
    name: "Banco Bradesco Financiamentos S.A.",
  },
  {
    id: 403,
    name: "Banco Cora",
  },
  {
    id: 399,
    name: "Kirton Bank S.A. - Banco Múltiplo",
  },
  {
    id: 422,
    name: "Banco Safra S.A.",
  },
  {
    id: 456,
    name: "Banco MUFG Brasil S.A.",
  },
  {
    id: 464,
    name: "Banco Sumitomo Mitsui Brasileiro S.A.",
  },
  {
    id: 473,
    name: "Banco Caixa Geral - Brasil S.A.",
  },
  {
    id: 477,
    name: "Citibank N.A.",
  },
  {
    id: 479,
    name: "Banco ItauBank S.A",
  },
  {
    id: "487",
    name: "Deutsche Bank S.A. - Banco Alemão",
  },
  {
    id: 488,
    name: "JPMorgan Chase Bank, National Association",
  },
  {
    id: 505,
    name: "Banco Credit Suisse (Brasil) S.A.",
  },
  {
    id: 536,
    name: "Neon Pagamentos",
  },
  {
    id: 600,
    name: "Banco Luso Brasileiro S.A.",
  },
  {
    id: 604,
    name: "Banco Industrial do Brasil S.A.",
  },
  {
    id: 610,
    name: "Banco VR S.A.",
  },
  {
    id: 611,
    name: "Banco Paulista S.A.",
  },
  {
    id: "612",
    name: "Banco Guanabara S.A.",
  },
  {
    id: 623,
    name: "Banco PAN S.A.",
  },
  {
    id: 626,
    name: "Banco C6 Consignado S.A.",
  },
  {
    id: 630,
    name: "Banco Letsbank S.A.",
  },
  {
    id: 633,
    name: "Banco Rendimento S.A.",
  },
  {
    id: 634,
    name: "Banco Triângulo S.A.",
  },
  {
    id: 643,
    name: "Banco Pine S.A.",
  },
  {
    id: 653,
    name: "Banco Voiter S.A.",
  },
  {
    id: 654,
    name: "Banco Digimais S.A.",
  },
  {
    id: 655,
    name: "Banco Votorantim S.A.",
  },
  {
    id: 707,
    name: "Banco Daycoval S.A.",
  },
  {
    id: 739,
    name: "Banco Cetelem S.A.",
  },
  {
    id: 743,
    name: "Banco Semear S.A.",
  },
  {
    id: 745,
    name: "Banco Citibank S.A.",
  },
  {
    id: "746",
    name: "Banco Modal S.A.",
  },
  {
    id: 747,
    name: "Banco Rabobank International Brasil S.A.",
  },
  {
    id: 748,
    name: "Banco Cooperativo Sicredi S.A.",
  },
  {
    id: 751,
    name: "Scotiabank Brasil S.A. Banco Múltiplo",
  },
  {
    id: 752,
    name: "Banco BNP Paribas Brasil S.A.",
  },
  {
    id: 755,
    name: "Bank of America Merrill Lynch Banco Múltiplo S.A.",
  },
  {
    id: 756,
    name: "Banco Cooperativo Sicoob S.A.",
  },
  {
    id: "260",
    name: "NU PAGAMENTOS S.A. (Nubank)",
  },
  {
    id: "380",
    name: "PicPay Serviços S.A.",
  },
];

const banks = banksItens.slice().sort((a, b) => {
  if (typeof a.id === "string" && typeof b.id === "string") {
    return a.id.localeCompare(b.id);
  } else {
    return a.id - b.id;
  }
});

export { banks };
