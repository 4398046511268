import styled from 'styled-components'

export const dateWrapper = styled.div`  
  display: flex;
  z-index: 0;

`

export const SubTitle = styled.h3 `
  font-size: 14px;
  font-weight: bold; 
`