import React, { useEffect, useMemo } from "react";
import ReactSelect from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getCities } from "../actions/cities";
import { setCity } from "../actions/preferences";

const CityFilters = () => {
  const dispatch = useDispatch()

  const user = useSelector(state => state.account);

  useEffect(() => {
    if (user.isLoggedIn) {
      dispatch(getCities())
    }
  }, [ user?.isLoggedIn ]);

  const {
    account,
    cities,
    preferences,
  } = useSelector(state => ({
    account: state.account,
    cities: state.account.role === 'admin' ? state.cities.all : state.account.cities,
    preferences: state.preferences,
  }))

  const options = useMemo(() => {
    return cities.map(({ id, name }) => ({
      value: id,
      label: name,
    }))
  }, [ cities.map(city => city.id).join(',') ])

  const handleClick = async selectedOption => {
    if (selectedOption == null) return handleClean();
    await dispatch(setCity(selectedOption.value, selectedOption.label));
  };

  const handleClean = async () => {
    await dispatch(setCity());
  }

  return (
    <div style={{ flex: 1, zIndex: 9999999 }}>
      <div style={{ maxWidth: 400 }}>
        <ReactSelect
          style={{ width: "400px" }}
          isClearable={account.role === 'admin'}
          options={options}
          value={
            preferences.city_id
              ? {value: preferences.city_id, label: preferences.city_name}
              : null
          }
          onChange={selectedOption => handleClick(selectedOption)}
          placeholder={'Selecione a cidade..'}
        />
      </div>
    </div>
  );
};

export default CityFilters;
