import React from 'react'
import { DatePicker } from 'rsuite';

const DatePickerDay = props => {

  return (
    <DatePicker
      onSelect={date => props.handleDateRecorrentlyDay(date)}
      style={{zIndex: 0, width: '20%'}}
      format="YYYY-MM-DD HH:mm"
      ranges={[
        {
          label: 'Now',
          value: new Date()
        }
      ]}
    />
  )
}

export default DatePickerDay
