import React from "react";
import { object } from "prop-types";
import { push } from "react-router-redux";
import { RouteWithSubRoutes } from "../../";
import "../../styles/css/statistics.css";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "components/spinner/Spinner";

export const StatisticsPage = ({ location, routes }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.statistics);

  const path = location.pathname.split("/").pop();

  const showGeneral = () => {
    dispatch(push(`/statistics/general`));
  };

  const showRidersInfo = () => {
    dispatch(push(`/statistics/riders`));
  };

  const showIndicators = () => {
    dispatch(push(`/statistics/indicators`));
  };

  return (
    <div className="container__statistics">
      <div className="wrapper">
        <div className="header">
          <div className="div">
            <h1>Estatísticas</h1>
            <span>
              Hoje,{" "}
              {new Date().toLocaleString("pt-BR", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </span>
          </div>
        </div>

        <>
          <ul className="nav nav-pills tab-item-menu">
            <li className="tab-item-menu-li nav-item">
              <a
                className={`nav-link ${path == "indicators" ? "active" : ""}`}
                onClick={() => (loading ? {} : showIndicators())}
              >
                Indicadores
              </a>
            </li>
            <li className="tab-item-menu-li nav-item">
              <a
                className={`nav-link ${path == "riders" ? "active" : ""}`}
                onClick={() => (loading ? {} : showRidersInfo())}
              >
                Passageiros
              </a>
            </li>
            <li className="tab-item-menu-li nav-item">
              <a
                className={`nav-link ${path == "general" ? "active" : ""}`}
                onClick={() => (loading ? {} : showGeneral())}
              >
                Geral
              </a>
            </li>
            <li className="tab-item-menu-li nav-item">
              <a>
                <Spinner loading={loading} size={26} />
              </a>
            </li>
          </ul>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </>
      </div>
    </div>
  );
};

StatisticsPage.propTypes = {
  match: object,
  history: object,
  location: object,
  routes: object,
};

export default StatisticsPage;
