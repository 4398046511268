import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/integration/react";
import { theme } from "./utils/theme";
import { Route } from "react-router";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@material-ui/core/styles";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

import { store, storePersistor, history } from "./store";
import { queryClient } from "./utils/queryClient";

import firebase from "./services/firebase";

import NavMenu from "./components/nav-menu";
import { routes } from "./routes";
import Api from "./api";

import "./styles/css/theme.css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "./styles/css/index.css";

firebase.initializeApp();

try {
  const persistedReducers = localStorage.getItem("persist:root");
  if (persistedReducers) {
    let { account } = JSON.parse(persistedReducers);
    if (account) {
      account = JSON.parse(account);
      if (account?.api_token) {
        Api.setApiToken(account.api_token);
      }
    }
  }
} catch (e) {
  console.error(e);
}

export const RouteWithSubRoutes = route => (
  <Route
    path={route.path}
    exact={route.exact}
    render={props => <route.component {...props} routes={route.routes} />}
  />
);

const App = (
  <QueryClientProvider client={queryClient}>
    {process.env.REACT_APP_NODE_ENV === "development" && <ReactQueryDevtools />}
    <Provider store={store}>
      <PersistGate loading={null} persistor={storePersistor}>
        <ConnectedRouter history={history}>
          <div>
            <ThemeProvider theme={theme}>
              <NavMenu />
              {routes.map((route, i) => (
                <RouteWithSubRoutes key={i} {...route} />
              ))}

              <ToastContainer
                autoClose={5000}
                position="bottom-center"
                toastClassName="alert"
              />
            </ThemeProvider>
          </div>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </QueryClientProvider>
);

ReactDOM.render(App, document.getElementById("root"));
