import Api from '../api';

export const GET_CITY = 'GET_CITY';
export const GET_CITY_SUCCESS = 'GET_CITY_SUCCESS';
export const GET_CITY_ERROR = 'GET_CITY_ERROR';

export const GET_CITIES = 'GET_CITIES';
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
export const GET_CITIES_ERROR = 'GET_CITIES_ERROR';

export const CREATE_CITY = 'CREATE_CITY';
export const CREATE_CITY_SUCCESS = 'CREATE_CITY_SUCCESS';
export const CREATE_CITY_ERROR = 'CREATE_CITY_ERROR';

export const UPDATE_CITY = 'UPDATE_CITY';
export const UPDATE_CITY_SUCCESS = 'UPDATE_CITY_SUCCESS';
export const UPDATE_CITY_ERROR = 'UPDATE_CITY_ERROR';

export const DELETE_CITY = 'DELETE_CITY';
export const DELETE_CITY_SUCCESS = 'DELETE_CITY_SUCCESS';
export const DELETE_CITY_ERROR = 'DELETE_CITY_ERROR';

export const UPDATE_CITY_PROPS = 'UPDATE_CITY_PROPS';
export const CLEAR_CITY = 'CLEAR_CITY';

export const GET_OPERATIONS = 'GET_OPERATIONS';
export const GET_OPERATIONS_SUCCESS = 'GET_OPERATIONS_SUCCESS';
export const GET_OPERATIONS_ERROR = 'GET_OPERATIONS_ERROR';

export const getCities = () => async (dispatch, getState) => {
  dispatch({
    type: GET_CITIES
  })

  try {
    let cities = await Api.cities();

    return dispatch({
      type: GET_CITIES_SUCCESS,
      cities
    })
  } catch (error) {
    return dispatch({
      type: GET_CITIES_ERROR,
      error
    })
  }
}

export const getOperations = () => async (dispatch, getState) => {
  dispatch({
    type: GET_OPERATIONS
  })

  try {
    const operations = await Api.getFranchisees();

    return dispatch({
      type: GET_OPERATIONS_SUCCESS,
      operations
    })
  } catch (error) {
    return dispatch({
      type: GET_OPERATIONS_ERROR,
      error
    })
  }
}


export const clearCity = () => async (dispatch, getState) => {
  return dispatch({
    type: CLEAR_CITY
  })
}

export const getCity = id => async (dispatch, getState) => {
  dispatch({
    type: GET_CITY
  })

  try {
    let city = await Api.getCity(id);

    return dispatch({
      type: GET_CITY_SUCCESS,
      city
    })
  } catch (error) {
    return dispatch({
      type: GET_CITY_ERROR,
      error
    })
  }
}

export const createCity = city => async (dispatch, getState) => {
  dispatch({
    type: CREATE_CITY,
    city
  })

  try {
    city = await Api.createCity(city);
    city.fares = [];

    return dispatch({
      type: CREATE_CITY_SUCCESS,
      city
    })
  } catch (error) {
    return dispatch({
      type: CREATE_CITY_ERROR,
      error
    })
  }
}

export const updateCity = city => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_CITY,
    city
  })

  try {
    city = await Api.updateCity(city);

    return dispatch({
      type: UPDATE_CITY_SUCCESS,
      city
    })
  } catch (error) {
    return dispatch({
      type: UPDATE_CITY_ERROR,
      error
    })
  }
}

export const deleteCity = id => async (dispatch, getState) => {
  dispatch({
    type: DELETE_CITY
  })

  try {
    await Api.deleteCity(id);

    return dispatch({
      type: DELETE_CITY_SUCCESS,
      id
    })
  } catch (error) {
    return dispatch({
      type: DELETE_CITY_ERROR,
      error
    })
  }
}

export const updateCityProps = props => async (dispatch, getState) => {
  return dispatch({
    type: UPDATE_CITY_PROPS,
    props
  })
}