import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as S from './style'
import CardNotification from '../card-notification'
import { getAllNotifications } from '../../../actions/push-notification'
import FormFilters from '../history-filters'
import { store } from '../../../store'
import { push } from 'react-router-redux'

const NotificationPage = props => {
  const city_id = useSelector(state => state.preferences.city_id)

  let [page] = useState(1)

  useEffect(() => {
    props.getAllNotifications({ ...props.params, page, city_id })
  }, [ city_id ])

  const onChangePage = page => {
    props.getAllNotifications({ ...props.params, page });
  }

  const handleScheduled = e => {
    props.getAllNotifications({ ...props.params, page, filter: e.target.value })
  }

  const handleTarget = e => {
    props.getAllNotifications({ ...props.params, page, target: e.target.value })
  }

  const handleAgended = e => {
    props.getAllNotifications({ ...props.params, page, target: e.target.value, agended: e.target.value })
  }

  const handleClean = () => {
    props.getAllNotifications({ ...props.params, page })
  }

  const editPush = id => {
    store.dispatch(push(`/notification/id/${id}/info`))
  }

  console.log('history', props)
  return (
    <div>
      { props.match.path === '/notification' && props.match.isExact &&

      <S.Container>



        <S.WrapperTitle>
          <S.Title>Histório de notificações</S.Title>
          <FormFilters
            handleClean={handleClean}
            handleScheduled={handleScheduled}
            handleTarget={handleTarget}
            handleAgended={handleAgended}
          />
        </S.WrapperTitle>
        <S.WrapperNotifications>
          {
            props.notifications && props.notifications.map(notification => {
              return (
                <CardNotification
                  key={notification.id}
                  title={notification.title}
                  message={notification.message}
                  author={notification.author}
                  cities={notification.cities}
                  id={notification.id}
                  created_at={notification.created_at}
                  target={notification.target}
                  isAgended={notification.is_agended}
                  endDate={notification.endDate}
                  targetAmount={notification.target_amount}
                  editPush={editPush}
                  deleted={notification.deleted}
                  send_at={notification.send_at}
                  scheduledTo={notification.scheduled_to}
                />
              )
            })
          }
          <S.WrapperPaginator>
            <S.PaginatorStyled
              page={props.page}
              pages={props.pages}
              onChange={onChangePage}
            />
            {
              `Exibindo ${props.notifications.length} de ${props.total}`
            }
          </S.WrapperPaginator>
        </S.WrapperNotifications>


      </S.Container>
      }
    </div>
  )
}

const mapStateToProps = state => ({
  notifications: state.notifications.notifications,
  page: state.notifications.page,
  pages: state.notifications.pages,
  total: state.notifications.total,
  params: state.notifications.params
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    getAllNotifications
  }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationPage);
