import React, { useEffect, useState } from "react";
import "../styles/franchisees.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { FilterFranchisees } from "../components/filterFranchisees";

import { validateCpfCnpj, validatePhone } from "../utils/string";
import { toast } from "react-toastify";
import { store } from "../store";
import { push } from "react-router-redux";
import api from "../api";

export const Franchisees = () => {
  const [franchisees, setFranchisees] = useState([]);
  const [cities, setCities] = useState([]);

  const handleFranchisees = async () => {
    const array_franchises = await api.getFranchisees();

    const cities = await api.cities();

    setFranchisees(array_franchises);
    setCities(cities);
  };

  const HandleUpdateActive = async item => {
    const promptMessage = `Deseja ${item.active ? 'inativar' : 'ativar'} esta franquia?`
    if (!window.confirm(promptMessage)) {
      return
    }

    try {
      let actived = !item.active;
      let payload = {
        active: actived,
      };
      await api.updateFranchisees({ id: item.id, payload });
      toast.success("Status atualizado com sucesso");

      setFranchisees(franchisees.map(franchisee => {
        if (franchisee.id === item.id) {
          franchisee.active = actived;
        }

        return franchisee
      }));
    } catch (error) {
      console.log("ERROR:", error);
    }
  };

  useEffect(() => {
    handleFranchisees();
  }, []);

  const show = id => {
    store.dispatch(push(`/franchisees-details/id/${id}`));
  };

  const renderItem = (item, index) => {
    return (
      <tr key={index}>
        <td onClick={() => show(item.id)}>{item?.admin?.name}</td>
        <td onClick={() => show(item.id)}>
          {validateCpfCnpj(
            item?.document_number === null ? "" : item?.document_number
          )}
        </td>
        <td onClick={() => show(item.id)}>{item?.city?.name}</td>
        <td onClick={() => show(item.id)}>
          {validatePhone(item?.phone === null ? "" : item?.phone)}
        </td>
        <td className="status">
          {item.active ? (
            <span onClick={() => HandleUpdateActive(item)}>Ativo</span>
          ) : (
            <span onClick={() => HandleUpdateActive(item)}>Inativo</span>
          )}
        </td>
      </tr>
    );
  };

  return (
    <div className="container__franchisees">
      <div className="wrapper">
        <div className="header">
          <h1>Franquias</h1>
          <Link to={"/franchisees-add"}>
            <button>Adicionar franquia</button>
          </Link>
        </div>
        {/*
        <div className="filter">
          <FilterFranchisees data={cities} onSubmit={HandleFiltered} />
        </div> */}

        <div className="main">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                {/* <th style={{ width: 50 }}>ID</th> */}
                <th>Nome</th>
                <th>CPF/CNPJ</th>
                <th>Praça</th>
                <th>Telefone</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {!!franchisees.length &&
                franchisees.map((item, index) => renderItem(item, index))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
