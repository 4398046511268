import {
  createStore,
  combineReducers,
  applyMiddleware
} from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import { createHashHistory } from 'history'
import { routerMiddleware, push, connectRouter } from 'connected-react-router'
import { composeWithDevTools } from 'redux-devtools-extension'
import storage from 'redux-persist/lib/storage'

import Api from '../api'
import reducers from '../reducers'
import { getCategories } from '../actions/categories'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['account', 'ads', 'preferences'],
}

export const history = createHashHistory()

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    ...reducers,
    router: connectRouter(history)
  })
)

export const store = createStore(
  persistedReducer,
  composeWithDevTools(
    applyMiddleware(routerMiddleware(history)),
    applyMiddleware(thunk)
  )
)

export const storePersistor = persistStore(store, {}, () => {
  const { account } = store.getState()

  if (account && account.api_token) {
    store.dispatch(getCategories());

    Api.refreshTokens(account.api_token);
  } else {
    store.dispatch(push("/login"));
  }
})