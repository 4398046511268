import Api from "../api";

export const GET_RIDERS = "GET_RIDERS";
export const GET_RIDERS_SUCCESS = "GET_RIDERS_SUCCESS";
export const GET_RIDERS_ERROR = "GET_RIDERS_ERROR";

export const GET_RIDER = "GET_RIDER";
export const GET_RIDER_SUCCESS = "GET_RIDER_SUCCESS";
export const GET_RIDER_ERROR = "GET_RIDER_ERROR";

export const UPDATE_RIDER = "UPDATE_RIDER";
export const UPDATE_RIDER_SUCCESS = "UPDATE_RIDER_SUCCESS";
export const UPDATE_RIDER_ERROR = "UPDATE_RIDER_ERROR";

export const SET_RIDER = "SET_RIDER";
export const UPDATE_RIDER_PROPS = "UPDATE_RIDER_PROPS";

export const UNLOCK_USER = "UNLOCK_USER";

export const getRiders = requestParams => async (dispatch, getState) => {
  dispatch({
    type: GET_RIDERS,
  });

  let { city_id } = getState().preferences;

  try {
    let {
      results: riders,
      params,
      pages,
      rides,
      total,
    } = await Api.getRiders({ ...requestParams, city_id });

    return dispatch({
      type: GET_RIDERS_SUCCESS,
      payload: {
        riders,
        params,
        pages,
        rides,
        total,
      },
    });
  } catch (error) {
    console.log(error);
    return dispatch({
      type: GET_RIDERS_ERROR,
      error,
    });
  }
};

export const unlockUser = id => async dispatch => {
  try {
    await Api.unlockUser(id);
    dispatch({
      type: UNLOCK_USER,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getRider = id => async (dispatch, getState) => {
  dispatch({
    type: GET_RIDER,
    id,
  });

  try {
    let rider = await Api.getRider(id);

    return dispatch({
      type: GET_RIDER_SUCCESS,
      rider,
    });
  } catch (error) {
    return dispatch({
      type: GET_RIDER_ERROR,
      error,
    });
  }
};

export const updateRider = rider => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_RIDER,
    rider,
  });

  try {
    rider = await Api.updateUserDashboard(rider);

    return dispatch({
      type: UPDATE_RIDER_SUCCESS,
      rider,
    });
  } catch (error) {
    return dispatch({
      type: UPDATE_RIDER_ERROR,
      error,
    });
  }
};

export const updateRiderProps = props => async (dispatch, getState) => {
  return dispatch({
    type: UPDATE_RIDER_PROPS,
    props,
  });
};

export const setRider = rider => async (dispatch, getState) => {
  return dispatch({
    type: SET_RIDER,
    rider,
  });
};
