import React from "react";
import ReactSelect from "react-select";

const CitySelect = props => {

  return (
    <ReactSelect
      style={{width: '400px'}}
      isMulti
      options={props.options}
      onChange={selectedOption => props.handleSelectChangeCidade(selectedOption)}
      placeholder="Vazio para todas as cidades"
    />
  )


}

export default CitySelect
