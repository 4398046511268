import Api from '../api';

export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const CREATE_NOTIFICATION_ERROR = "CREATE_NOTIFICATION_ERROR";
export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS"
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS"
export const GET_NOTIFICATIONS_ERROR = "GET_NOTIFICATIONS_ERROR"

export const CANCEL_NOTIFICATION = "CANCEL_NOTIFICATION"
export const CANCEL_NOTIFICATION_SUCCESS = "CANCEL_NOTIFICATION_SUCCESS"
export const CANCEL_NOTIFICATION_ERROR ="CANCEL_NOTIFICATION_ERROR"

export const GET_ONE_PUSH = "GET_ONE_PUSH"
export const GET_ONE_PUSH_SUCCESS = "GET_ONE_PUSH_SUCCESS"
export const GET_ONE_PUSH_ERROR = "GET_ONE_PUSH_ERROR"

export const UPDATE_PUSH_TITLE = "UPDATE_PUSH_TITLE"

export const createNotification = data => async dispatch => {
  try {
    let payload = await Api.createNotification(data);
    return dispatch({
      type: CREATE_NOTIFICATION,
      payload
    })
  } catch (error) {
    console.log(JSON.stringify(error))
    return dispatch({
      type: CREATE_NOTIFICATION_ERROR,
      error
    })
  }
}

export const getAllNotifications = page => async dispatch => {

  dispatch({
    type: GET_NOTIFICATIONS
  })

  try {
    let notifications = await Api.getAllNotification(page);
    console.log('results',notifications)
    return dispatch({
      type: GET_NOTIFICATIONS_SUCCESS,
      notifications
    })
  } catch (error) {
    console.log(error)
  }
}

export const cancelNotification = id => async dispatch => {
  dispatch({
    type: CANCEL_NOTIFICATION
  })

  try {
    let q = await Api.cancelNotification(id)
    console.log('cancel', q)
    return dispatch({
      type: CANCEL_NOTIFICATION_SUCCESS,
      id
    })
  } catch (error) {
    return dispatch({
      type: CANCEL_NOTIFICATION_ERROR,
      error
    })
  }
}

export const getOnePush = id => async dispatch => {
  dispatch({
    type: GET_ONE_PUSH
  })
  try {
    let payload = await Api.getOnePush(id)
    return dispatch({
      type: GET_ONE_PUSH_SUCCESS,
      payload
    })
  } catch (error) {
    return dispatch({
      type: GET_ONE_PUSH_ERROR,
      error
    })
  }
}

export const updatePush = props => async dispatch => {
  return dispatch({
    type:UPDATE_PUSH_TITLE,
    props
  })
}