import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import CurrencyFormat from 'react-currency-format';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  createFare,
  updateFare,
  updateFareProps,
  getFare,
  deleteFare,
  clearFare
} from '../actions/fares';
import {
  getCategories
} from '../actions/categories';
import {
  getCity
} from '../actions/cities';
import '../styles/css/driver-info.css';


class FarePage extends Component {

  static propTypes = {
    getCity: PropTypes.func,
    getCategories: PropTypes.func,
    createFare: PropTypes.func,
    updateFare: PropTypes.func,
    updateFareProps: PropTypes.func,
    getFare: PropTypes.func,
    deleteFare: PropTypes.func,
    clearFare: PropTypes.func,
    fare: PropTypes.object,
    match: PropTypes.object,
    history: PropTypes.object,
    fares: PropTypes.array,
    categories: PropTypes.array,
    city: PropTypes.object,
  }

  constructor(props) {
    super();
    this.handleChange = this.handleChange.bind(this);
    this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
    this.save = this.save.bind(this);
    this.remove = this.remove.bind(this);
    this.close = this.close.bind(this);
  }

  componentDidMount() {
    let { fare_id, id } = this.props.match.params;

    this.props.getCategories();

    if (this.props.city.id != id) {
      this.props.getCity(id);
    }

    if (fare_id && fare_id !== 'new') {
      this.props.getFare(fare_id)
    } else {
      this.props.clearFare();
      this.props.updateFareProps({ city_id: this.props.city.id })
    }
  }

  validate() {
    let { fare } = this.props;

    if (!fare.city_id) {
      return toast.warn('Cidade inválida');
    }

    if (Number(fare.percentage_moby) < 0 || Number(fare.percentage_moby) > 50) {
      return toast.warn('Porcentagem MobyGo inválida');
    }

    if (fare.category_id < 1) {
      return toast.warn('Categoria inválida');
    }

    if (Number(fare.price_base) < 1) {
      return toast.warn('Preço da bandeira inválido');
    }

    if (Number(fare.price_minimum) < 1) {
      return toast.warn('Preço mínimo inválido');
    }

    if (Number(fare.price_km) < 0.1) {
      return toast.warn('Preço por quilômetro inválido');
    }

    if (Number(fare.price_minute) < 0.1) {
      return toast.warn('Preço por minuto inválido');
    }

    return true;
  }


  async save() {
    if (this.validate() !== true) {
      return;
    }

    let {
      id,
      category_id,
      city_id,
      percentage_moby,
      price_moby,
      price_base,
      price_minimum,
      price_km,
      price_minute
    } = this.props.fare;

    let fare = {
      category_id,
      city_id,
      percentage_moby: Number(percentage_moby),
      price_moby: 0,
      price_base: Number(price_base),
      price_minimum: Number(price_minimum),
      price_km: Number(price_km),
      price_minute: Number(price_minute)
    }
    if (id) {
      fare.id = id;
    }

    let rs;

    if (id) {
      rs = await this.props.updateFare(fare);
    } else {
      rs = await this.props.createFare(fare);
    }

    let { error } = rs;
    if (error) {
      toast.error(
        error.name == 'ValidationError'
          ? error.message
          : 'Erro ao salvar a tarifa'
      )
    } else {
      toast.success(id ? 'Atualizado!' : 'Criado com sucesso')
    }
  }

  async remove() {
    if (window.confirm('Deseja realmente excluir esta tarifa?')) {
      let { error } = await this.props.deleteFare(this.props.fare)
      if (error) {
        toast.error('Erro ao remover a tarifa')
      } else {
        toast.success('Tarifa removida com sucesso');
        this.close();
      }
    }
  }

  close() {
    this.props.history.goBack();
  }

  handleChange({ target }) {
    const getAttr = attr => {
      let item = target.attributes.getNamedItem(attr)
      return item ? item.value : null;
    }

    let type = getAttr('data-type');
    let prop = getAttr('data-name');
    let value = target.value;
    if (type == 'currency') {
      value = Number(value.replace(/[^0-9.]/g, '')).toFixed(2);
    } else if (type == 'number') {
      value = Number(value)
    } else if (type == 'integer') {
      value = parseInt(value.replace(/[^0-9]/g, ''), 10);
    }

    this.props.updateFareProps({[prop]: value})
  }

  handleCurrencyChange(props) {
    this.props.updateFareProps(props)
  }

  render() {
    const { fare, city } = this.props;

    return (
      <div style={{ width: 500, marginLeft: 'auto', marginRight: 'auto' }}>
        <div className="card bg-light">
          <div className="card-header">Tarifa</div>
          <div className="card-body">
            <form>
              {
                !!fare.id &&
                  <div className="form-group">
                    <label>ID</label>
                    <input
                      readOnly
                      className="form-control-plaintext"
                      value={fare.id}
                    />
                  </div>
              }
              <div className="form-group">
                <label>Cidade</label>
                <input
                  type="text"
                  readOnly
                  className="form-control"
                  id="inputCity"
                  value={city ? city.name : ''}
                />
              </div>
              <div className="form-group">
                <label>Categoria</label>
                {
                  !fare.id ?
                    <select
                      className="form-control custom-select"
                      data-name="category_id"
                      data-type="number"
                      value={fare.category_id}
                      onChange={this.handleChange}
                    >
                      <option value="" />
                      {
                        this.props.categories.map(category => {
                          let disabled;
                          if (city && city.fares) {
                            disabled = city.fares.find(f => f.category_id == category.id)
                          }

                          return (
                            <option key={category.id} value={category.id} disabled={disabled}>
                              {category.name} {disabled ? ' (Já adicionada)' : ''}
                            </option>
                          )
                        })
                      }
                    </select>
                    :
                    <input
                      type="text"
                      readOnly
                      className="form-control"
                      value={fare.category ? fare.category.name : ''}
                    />
                }
              </div>
              <div className="form-group">
                <label>Porcentagem MobyGo</label>
                <div className="input-group mb-2">
                  <input
                    className="form-control"
                    data-name="percentage_moby"
                    data-type="integer"
                    maxLength={2}
                    onChange={this.handleChange}
                    value={fare.percentage_moby}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">%</div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Bandeira</label>
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">R$</div>
                  </div>
                  <CurrencyInput
                    name="price_base"
                    onChange={this.handleCurrencyChange}
                    value={fare.price_base}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Preço mínimo</label>
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">R$</div>
                  </div>
                  <CurrencyInput
                    name="price_minimum"
                    onChange={this.handleCurrencyChange}
                    value={fare.price_minimum}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>KM Rodado</label>
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">R$</div>
                  </div>
                  <CurrencyInput
                    name="price_km"
                    onChange={this.handleCurrencyChange}
                    value={fare.price_km}
                  />
                </div>
              </div>
              <div className="form-group">
                <label>Minuto</label>
                <div className="input-group mb-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">R$</div>
                  </div>
                  <CurrencyInput
                    name="price_minute"
                    onChange={this.handleCurrencyChange}
                    value={fare.price_minute}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>

        <Modal.Footer style={{position: 'relative'}}>
          <button
            type="button"
            className="btn btn-secondary"
            style={{position: 'absolute', left: 0}}
            onClick={() => this.props.history.goBack()}
          >Voltar</button>

          <button
            type="button"
            className="btn btn-success pull-left"
            onClick={this.save}
          >Salvar</button>

          {
            !!fare.id &&
              <button
                type="button"
                className="btn btn-danger pull-left"
                onClick={this.remove}
              >Remover</button>
          }

          <button
            type="button"
            className="btn btn-secondary pull-left"
            data-dismiss="modal"
            onClick={this.close}
          >Cancelar</button>
        </Modal.Footer>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  fare: state.fares.current,
  fares: state.fares.all,
  categories: state.categories.all,
  city: state.cities.current
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({
    createFare,
    updateFare,
    updateFareProps,
    getFare,
    deleteFare,
    clearFare,
    getCategories,
    getCity
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(FarePage)

export class CurrencyInput extends React.PureComponent {
  static propTypes = {
    onChange: PropTypes.func,
    name: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ])
  }

  render() {
    return (
      <CurrencyFormat
        allowNegative={false}
        fixedDecimalScale
        className="form-control"
        decimalScale={2}
        value={this.props.value}
        isAllowed={({ floatValue }) => {
          return floatValue < 99
        }}
        thousandSeparator="none"
        onValueChange={({ formattedValue }) => {
          this.props.onChange({ [this.props.name]: formattedValue })
        }}
      />
    )
  }
}
