import React from 'react'
import { DatePicker } from 'rsuite';

const DatePickerTimeOnly = props => {

  return (
    <DatePicker onSelect={date => props.date(date)} style={{width: '30%', zIndex: 0}} format="HH:mm" ranges={[]} />
  )
}

export default DatePickerTimeOnly
