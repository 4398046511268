import Api from '../api';

export const GET_RIDES_ANALYTICS = 'GET_RIDES_ANALYTICS'
export const GET_RIDES_ANALYTICS_SUCCESS = 'GET_RIDES_ANALYTICS_SUCCESS'
export const GET_RIDES_ANALYTICS_ERROR = 'GET_RIDES_ANALYTICS_ERROR'
export const EXPORT_RIDES_ANALYTICS = 'EXPORT_RIDES_ANALYTICS'
export const EXPORT_RIDES_ANALYTICS_SUCCESS = 'EXPORT_RIDES_ANALYTICS_SUCCESS'
export const EXPORT_RIDES_ANALYTICS_ERROR = 'RIDES_ANALYTICS_ERROR'

export const getRidesAnalytics = params => async dispatch => {
  dispatch({
    type: GET_RIDES_ANALYTICS
  })

  try {
    let rides = await Api.listRidesAnalytics(params)
    return dispatch({
      type: GET_RIDES_ANALYTICS_SUCCESS,
      rides
    })
  } catch (error) {
    return dispatch({
      type: GET_RIDES_ANALYTICS_ERROR,
      error
    })
  }
}
