/* eslint-disable import/no-named-as-default-member */
/* eslint-disable indent */
/* eslint-disable arrow-parens */
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import objectPath from "object-path";
import cloneDeep from "clone-deep";
import moment, { utc } from "moment";
import firebase from "firebase/app";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import validate from "validate.js";
import * as uuid from "uuid/v4";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  getAspirantDriver,
  updateAspirantDriver,
  setAspirantDriver,
  updateAspirantDriverProps,
} from "../actions/aspirant-drivers";
import { getCities } from "../actions/cities";
import { AspirantDriverStatusLabels, AspirantDriverStatus } from "../api/types";
import estadosBrasil from "../utils/estados-brasil";
import InputMask from "react-input-mask";
import { getBankAccountByDriver } from "actions/drivers";
import PropTypes, { func, object, array } from "prop-types";

class AspirantDriverInfoPage extends Component {
  static propTypes = {
    getCities: func,
    getAspirantDriver: func,
    updateAspirantDriver: func,
    updateAspirantDriverProps: func,
    setAspirantDriver: func,
    match: object,
    driver: object,
    categories: array,
    cities: array,
  };

  constructor(props) {
    super();
    this.handleSelectFile = this.handleSelectFile.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCategoryChange = this.handleCategoryChange.bind(this);
    this.handleBirthChange = this.handleBirthChange.bind(this);
    this.setStatus = this.setStatus.bind(this);
    this.save = this.save.bind(this);
    this.getBankAccountDriver = this.getBankAccountDriver.bind(this);

    this.state = {
      states: estadosBrasil,
      status: 0,
    };

    validate.extend(validate.validators.datetime, {
      parse: function (value, options) {
        return utc(value);
      },
      format: function (value, options) {
        var format = options.dateOnly ? "YYYY-MM-DD" : "YYYY-MM-DD hh:mm:ss";
        return utc(value).format(format);
      },
    });
  }

  componentDidMount() {
    this.getDriver();
  }

  async getBankAccountDriver() {
    try {
      const { account } = await getBankAccountByDriver(driverId);
      setBank(account);
    } catch (error) {
      console.error(error);
    }
  }

  async getDriver() {
    let { id } = this.props.match.params;

    if (id) {
      let { driver } = await this.props.getAspirantDriver(id);
      if (driver) {
        this.setState({
          status: driver.status,
        });

        this.getCities();
      }

      if (driver?.info?.address?.zipCode) {
        const hasHifen = driver?.info?.address?.zipCode.search("-");

        if (hasHifen === -1 && driver?.info?.address?.zipCode.length === 8) {
          driver.info.address.zipCode = `${driver.info.address.zipCode.substr(
            0,
            5
          )}-${driver.info.address.zipCode.substr(5, 3)}`;
        }
      }
    }
  }

  async getCities() {
    let { city_id } = this.props.driver;
    let exists = this.props.cities.find(({ id }) => id == city_id);
    if (!exists) {
      this.props.getCities();
    }
  }

  validate() {
    let { driver } = this.props;

    const dataAtual = new Date();
    const anoAtual = dataAtual.getFullYear();

    let rules = {
      name: {
        length: { minimum: 3, message: "Nome inválido" },
      },
      surname: {
        length: { minimum: 3, message: "Sobrenome inválido" },
      },
      phone: {
        length: { minimum: 11, message: "Celular inválido" },
        numericality: {
          onlyInteger: true,
          message: "Celular inválido",
        },
      },
      email: {
        email: {
          message: "Email inválido",
        },
      },
      city_id: {
        length: { minimum: 2, message: "Informe a praça" },
      },
      categories: {
        length: { minimum: 1, message: "Selecione pelo menos uma categoria" },
      },
      "car.name": {
        length: { minimum: 2, message: "Preencha o nome do carro" },
      },
      "car.year": {
        numericality: {
          onlyInteger: true,
          greaterThan: 2000,
          lessThanOrEqualTo: Number(anoAtual) + 1,
          message: "Seu carro deve ter no máximo 10 anos de uso",
        },
      },
      "car.color": {
        length: { minimum: 2, message: "Preencha a cor do carro" },
      },
      "car.license_plate": {
        length: { minimum: 7, message: "Preencha corretamente a placa carro" },
      },
      "car.renavam": {
        length: {
          minimum: 11,
          message: "Preencha corretamente o número do RENAVAM",
        },
      },
      "info.cpf": {
        length: { minimum: 11, message: "CPF inválido" },
      },
      "info.cnh": {
        length: {
          minimum: 11,
          message: "Preencha corretamente o número da CNH",
        },
      },
      "info.cnh_img": {
        length: {
          minimum: 10,
          message: "Selecione uma foto da sua carteira de motorista",
        },
      },
      "info.profile_img": {
        length: { minimum: 10, message: "Selecione uma foto sua de perfil" },
      },
      "info.birthdate": {
        datetime: { message: "Selecione sua data de nascimento" },
      },
      "info.address.street": {
        length: { minimum: 2, message: "Preencha o endereço" },
      },
      "info.address.streetNumber": {
        length: { minimum: 1, message: "Preencha o número do endereço" },
      },
      "info.address.district": {
        length: { minimum: 2, message: "Preencha o bairro" },
      },
      "info.address.zipCode": {
        format: { pattern: "[0-9]{5}-[0-9]{3}", message: "CEP Inválido" },
        length: { minimum: 9, message: "Preencha o CEP" },
      },
      "info.address.city": {
        length: { minimum: 2, message: "Preencha a cidade" },
      },
    };

    let rs = validate.validate(driver, rules, {
      fullMessages: false,
    });

    let errors = [];
    for (let k in rs) {
      errors.push(rs[k][0]);
    }

    if (errors.length > 0) {
      toast.warn(errors[0]);
      return false;
    }

    return true;
  }

  async save() {
    if (!this.validate()) {
      return;
    }

    let driver = { ...this.props.driver };
    driver.info.birthdate = moment(driver.info.birthdate).format("YYYY-MM-DD");
    driver.info.address.streetNumber = driver.info.address.streetNumber.replace(
      /[^0-9]/g,
      ""
    );
    driver.car.owner = Number(driver.car.owner);

    this.setState({ busy: true });

    driver.info.address.zipCode = driver.info.address.zipCode
      .replace("-", "")
      .replace(".", "");

    let rs = await this.props.updateAspirantDriver(driver);

    let { error } = rs;
    if (error) {
      toast.error(this.parseApiError(error));

      this.setState({ busy: false });
    } else {
      this.setState({
        busy: false,
        status: driver.status,
      });

      toast.success("Cadastro atualizado");
    }

    this.getDriver();
  }

  parseApiError(error) {
    if (error.name == "ValidationError") {
      return error.message;
    } else {
      return "Erro ao salvar o motorista";
    }
  }

  handleChange({ target }) {
    const getAttr = (attr) => {
      let item = target.attributes.getNamedItem(attr);
      return item ? item.value : null;
    };

    let type = getAttr("data-type");
    let prop = getAttr("data-name");
    let value = target.value;

    if (type == "number") {
      value = value.replace(/[^0-9]/g, "");
    }

    if (prop.indexOf(".") !== -1) {
      let driver = cloneDeep(this.props.driver);

      objectPath.set(driver, prop, value);
      this.props.setAspirantDriver(driver);
    } else {
      this.props.updateAspirantDriverProps({ [prop]: value });
    }
  }

  handleCategoryChange({ target }) {
    let id = parseInt(target.attributes.getNamedItem("data-name").value);
    let checked = !!target.checked;

    let categories = [...this.props.driver.categories];
    if (!checked) {
      categories = categories.filter((cid) => cid != id);
    } else if (categories.indexOf(id) == -1) {
      categories.push(id);
    }

    this.props.updateAspirantDriverProps({ categories });
  }

  handleBirthChange(birthdate) {
    let driver = cloneDeep(this.props.driver);

    objectPath.set(driver, "info.birthdate", birthdate);
    this.props.setAspirantDriver(driver);
  }

  async handleSelectFile(event) {
    let input = event.target;
    let files = event.target.files;
    if (files.length < 1) {
      return;
    }

    let file = files[0];
    const fileExtension = file.type === "application/pdf" ? ".pdf" : ".jpg";

    let prop = input.attributes.getNamedItem("data-name").value;
    let propName = prop.replace(/\./g, "_");
    let ref = firebase
      .storage()
      .ref()
      .child("public/" + uuid() + fileExtension);
    let uploadTask = ref.put(file);

    console.log("propName", propName);

    const setProgress = (p) => {
      this.setState({
        [`${propName}_error`]: false,
        [`${propName}_progress`]: p,
      });
    };
    setProgress(0);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setProgress(Math.round(progress));

        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running");
            break;

          default:
        }
      },
      (error) => {
        this.setState({
          [`${propName}_error`]: true,
        });
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          setProgress(null);
          this.setState({
            [`${propName}_name`]: file.name,
          });

          if (prop.indexOf(".") !== -1) {
            let driver = cloneDeep(this.props.driver);

            objectPath.set(driver, prop, downloadURL);

            this.props.setAspirantDriver(driver);
          } else {
            this.props.updateAspirantDriverProps({ [prop]: downloadURL });
          }
        });
      }
    );
  }

  setStatus(status) {
    this.props.updateAspirantDriverProps({ status: Number(status) });
  }

  render() {
    const { driver } = this.props;

    return (
      <div
        style={{
          maxWidth: 500,
          minWidth: 400,
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div className="form-header">
          <h3>Pré-Cadastro Motorista</h3>
        </div>

        <div
          className="form-group row"
          style={{
            position: "relative",
            display: "flex",
            margin: 0,
            justifyContent: "flex-end",
          }}
        >
          <label className="col-form-label">Status: &nbsp;</label>
          <UncontrolledDropdown>
            <DropdownToggle caret className="btn-primary-important">
              {AspirantDriverStatusLabels[driver.status]}
            </DropdownToggle>
            <DropdownMenu>
              {Object.keys(AspirantDriverStatusLabels).map((key) => {
                return (
                  <DropdownItem key={key} onClick={() => this.setStatus(key)}>
                    {AspirantDriverStatusLabels[key]}
                  </DropdownItem>
                );
              })}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>

        <div className="card">
          <div className="card-header">Informações Pessoais</div>
          <div className="card-body">
            <form>
              <div className="form-group">
                <label htmlFor="inputName">Nome</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="15"
                  id="inputName"
                  data-name="name"
                  value={driver.name}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputName">Sobrenome</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="30"
                  id="inputSurname"
                  data-name="surname"
                  value={driver.surname}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputEmail">Email</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="50"
                  id="inputEmail"
                  data-name="email"
                  value={driver.email}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputPhone">Celular</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="11"
                  id="inputPhone"
                  data-name="phone"
                  data-type="number"
                  value={driver.phone}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputInfoCpf">Cpf</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="11"
                  id="inputInfoCpf"
                  data-name="info.cpf"
                  data-type="number"
                  value={driver.info.cpf}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputInfoCnh">Número CNH</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="11"
                  id="inputInfoCnh"
                  data-name="info.cnh"
                  data-type="number"
                  value={driver.info.cnh}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label>Foto da carteira de motorista: </label>
                <div className="custom-file">
                  <input
                    type="file"
                    data-name="info.cnh_img"
                    className="custom-file-input"
                    id="customFile"
                    onChange={this.handleSelectFile}
                  />
                  <label className="custom-file-label" htmlFor="customFile">
                    {!!driver.info.cnh_img //eslint-disable-line
                      ? `Imagem anexada ${this.state.info_cnh_img_name || ""}`
                      : this.state.info_cnh_img_progress >= 0
                      ? "Enviando imagem.."
                      : "Procurar imagem.."}
                  </label>
                </div>
                {!!this.state.info_cnh_img_progress && (
                  <div className="progress" style={{ height: 2 }}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: this.state.info_cnh_img_progress + "%" }}
                      aria-valuenow={this.state.info_cnh_img_progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>
                )}
                {!!this.state.info_cnh_img_error && (
                  <label>Erro ao enviar a imagem</label>
                )}

                {!!driver.info.cnh_img && (
                  <div>
                    <a
                      href={driver.info.cnh_img}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={driver.info.cnh_img}
                        style={{ maxHeight: 300 }}
                        alt=""
                      />
                    </a>
                  </div>
                )}
              </div>
              <div className="form-group">
                <label>Foto de perfil: </label>
                <div className="custom-file">
                  <input
                    type="file"
                    data-name="info.profile_img"
                    className="custom-file-input"
                    id="customFile"
                    onChange={this.handleSelectFile}
                  />
                  <label className="custom-file-label" htmlFor="customFile">
                    {!!driver.info.profile_img //eslint-disable-line
                      ? `Imagem anexada ${
                          this.state.info_profile_img_name || ""
                        }`
                      : this.state.info_profile_img_progress >= 0
                      ? "Enviando imagem.."
                      : "Procurar imagem.."}
                  </label>
                </div>
                {!!this.state.info_profile_img_progress && (
                  <div className="progress" style={{ height: 2 }}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width: this.state.info_profile_img_progress + "%",
                      }}
                      aria-valuenow={this.state.info_profile_img_progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>
                )}
                {!!this.state.info_profile_img_error && (
                  <label>Erro ao enviar a imagem</label>
                )}

                {!!driver.info.profile_img && (
                  <div>
                    <a
                      href={driver.info.profile_img}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={driver.info.profile_img}
                        style={{ maxHeight: 300 }}
                        alt=""
                      />
                    </a>
                  </div>
                )}
              </div>

              <div className="form-group">
                <label>Certidão de antecedents criminais (PDF): </label>
                <div className="custom-file">
                  <input
                    type="file"
                    data-name="info.criminal_records"
                    className="custom-file-input"
                    id="customFile"
                    onChange={this.handleSelectFile}
                  />
                  <label className="custom-file-label" htmlFor="customFile">
                    {!!driver.info.criminal_records //eslint-disable-line
                      ? `Imagem anexada ${
                          this.state.info_criminal_records || ""
                        }`
                      : this.state.info_criminal_records_progress >= 0
                      ? "Enviando certidão.."
                      : "Procurar certidão.."}
                  </label>

                  {driver.info.criminal_records && (
                    <a
                      rel="noreferrer"
                      // eslint-disable-next-line react/jsx-no-target-blank
                      target="_blank"
                      href={driver.info.criminal_records}
                    >
                      Clique para ver a negativa
                    </a>
                  )}
                </div>
                {!!this.state.info_criminal_records_progress && (
                  <div className="progress" style={{ height: 2 }}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{
                        width: this.state.info_criminal_records_progress + "%",
                      }}
                      aria-valuenow={this.state.info_criminal_records_progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>
                )}
                {!!this.state.car_crlv_img_error && (
                  <label>
                    Erro ao enviar a certidão negativa de antecedentes criminais
                  </label>
                )}
              </div>

              <div className="form-group">
                <label>Data de Nascimento: </label>
                <DatePicker
                  showYearDropdown
                  maxDate={moment.utc().subtract(18, "years")}
                  dropdownMode="select"
                  customInput={<CustomDateInput />}
                  selected={
                    driver.info.birthdate
                      ? moment(driver.info.birthdate)
                      : moment()
                  }
                  onChange={this.handleBirthChange}
                />
              </div>

              <div className="form-group">
                <label>Categorias</label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: 300,
                  }}
                >
                  {!!driver &&
                    this.props.categories.map((category) => {
                      return (
                        <div
                          key={category.id}
                          className="custom-control custom-checkbox"
                          style={{ margin: "5px 0" }}
                        >
                          <input
                            type="checkbox"
                            id={`cat-${category.id}`}
                            className="custom-control-input"
                            data-name={category.id}
                            checked={
                              driver.categories.indexOf(category.id) !== -1
                            }
                            value={category.id}
                            onChange={this.handleCategoryChange}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={`cat-${category.id}`}
                          >
                            {category.name}
                          </label>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="form-group">
                <label>Praça</label>
                <select
                  className="form-control custom-select"
                  data-name="city_id"
                  id="city_id"
                  value={driver.city_id}
                  onChange={this.handleChange}
                >
                  <option value="" />
                  {this.props.cities.map((city) => {
                    return (
                      <option key={city.id} value={city.id}>
                        {city.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </form>
          </div>
        </div>

        <div className="card">
          <div className="card-header">Endereço</div>
          <div className="card-body">
            <form>
              <div className="form-group">
                <label>Endereço</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="100"
                  data-name="info.address.street"
                  value={driver.info.address.street}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label>Número</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="30"
                  data-name="info.address.streetNumber"
                  value={driver.info.address.streetNumber}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label>CEP</label>
                <InputMask
                  type="text"
                  mask="99999-999"
                  className="form-control"
                  data-name="info.address.zipCode"
                  data-type="cep"
                  value={driver.info.address.zipCode}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label>Bairro</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="30"
                  data-name="info.address.district"
                  value={driver.info.address.district}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label>Cidade</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="50"
                  data-name="info.address.city"
                  value={driver.info.address.city}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label>Estado</label>
                <select
                  className="form-control custom-select"
                  data-name="info.address.state"
                  value={driver.info.address.state}
                  onChange={this.handleChange}
                >
                  <option value="" />
                  {this.state.states.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>
            </form>
          </div>
        </div>

        <div className="card">
          <div className="card-header">Dados do veículo</div>
          <div className="card-body">
            <form>
              <div className="form-group">
                <label htmlFor="inputCarName">Modelo</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="15"
                  id="inputCarName"
                  data-name="car.name"
                  value={driver.car.name}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label>Ano do modelo</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="4"
                  data-name="car.year"
                  data-type="number"
                  value={driver.car.year}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputCarPlate">Placa</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="8"
                  id="inputCarPlate"
                  data-name="car.license_plate"
                  value={driver.car.license_plate}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputCarColor">Cor</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="10"
                  id="inputCarColor"
                  data-name="car.color"
                  value={driver.car.color}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="inputCarRenavam">Renavam</label>
                <input
                  type="text"
                  className="form-control"
                  maxLength="11"
                  id="inputCarRenavam"
                  data-name="car.renavam"
                  data-type="number"
                  value={driver.car.renavam}
                  onChange={this.handleChange}
                />
              </div>
              <div className="form-group">
                <label>Foto do documento do veículo (CRLV): </label>
                <div className="custom-file">
                  <input
                    type="file"
                    data-name="car.crlv_img"
                    className="custom-file-input"
                    id="customFile"
                    onChange={this.handleSelectFile}
                  />
                  <label className="custom-file-label" htmlFor="customFile">
                    {!!driver.car.crlv_img //eslint-disable-line
                      ? `Imagem anexada ${this.state.car_crlv_img_name || ""}`
                      : this.state.car_crlv_img_progress >= 0
                      ? "Enviando imagem.."
                      : "Procurar imagem.."}
                  </label>
                </div>
                {!!this.state.car_crlv_img_progress && (
                  <div className="progress" style={{ height: 2 }}>
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: this.state.car_crlv_img_progress + "%" }}
                      aria-valuenow={this.state.car_crlv_img_progress}
                      aria-valuemin="0"
                      aria-valuemax="100"
                    />
                  </div>
                )}
                {!!this.state.car_crlv_img_error && (
                  <label>Erro ao enviar a imagem</label>
                )}

                {!!driver.car.crlv_img && (
                  <div>
                    <a
                      href={driver.car.crlv_img}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src={driver.car.crlv_img}
                        style={{ maxHeight: 300 }}
                        alt=""
                      />
                    </a>
                  </div>
                )}
              </div>
              <div className="form-group">
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="owner"
                    id="ownerRadio1"
                    value="1"
                    data-name="car.owner"
                    data-type="number"
                    onChange={this.handleChange}
                    checked={driver.car.owner == 1}
                  />
                  <label className="form-check-label" htmlFor="ownerRadio1">
                    Carro Privado
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="owner"
                    id="ownerRadio2"
                    value="0"
                    data-name="car.owner"
                    data-type="number"
                    onChange={this.handleChange}
                    checked={driver.car.owner == 0}
                  />
                  <label className="form-check-label" htmlFor="ownerRadio2">
                    Carro Empresa
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="form-footer">
          {this.state.status !== AspirantDriverStatus.APPROVED && (
            <button
              type="button"
              disabled={!!this.state.busy}
              className="btn btn-primary submit-button"
              onClick={this.save}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  driver: state.aspirantDrivers.driver,
  categories: state.categories.all,
  cities: state.cities.all,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCities,
      getAspirantDriver,
      updateAspirantDriverProps,
      updateAspirantDriver,
      setAspirantDriver,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AspirantDriverInfoPage);

class CustomDateInput extends React.PureComponent {
  static propTypes = {
    value: PropTypes.string,
    onClick: PropTypes.func,
  };

  render() {
    return (
      <div onClick={this.props.onClick}>
        <div className="input-group">
          <div className="input-group-prepend">
            <span
              className="input-group-text"
              id="validationTooltipUsernamePrepend"
            >
              <i className="far fa-calendar-alt" />
            </span>
          </div>
          <input
            type="text"
            className="form-control"
            value={
              this.props.value
                ? moment(this.props.value).format("DD/MM/YYYY")
                : ""
            }
            readOnly
            style={{ cursor: "pointer" }}
          />
        </div>
      </div>
    );
  }
}
