import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import { DriverSituationLabels, DriverStatusLabels, FilterSort } from '../api/types'

export default class DriversFilters extends Component {
  static propTypes = {
    params: PropTypes.object,
    categories: PropTypes.array.isRequired,
    onSubmit: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.submit = this.submit.bind(this)
    this.handleDateChangeInitial = this.handleDateChangeInitial.bind(this)
    this.handleDateChangeFinal = this.handleDateChangeFinal.bind(this)

    this.state = {
      ...props.params,
      params: props.params,
      dateInitial: null,
      dateFinal: null,
    }
  }

  static getDerivedStateFromProps(props, state) {
    return isEqual(props.params, state.params) ? null : { ...props.params, params: props.params };
  }

  submit() {
    let state = { ...this.state };
    delete state.params;
    this.props.onSubmit(state);
  }

  onChange(event) {
    const getAttr = name => {
      let attr = event.target.attributes.getNamedItem(name);

      return attr ? attr.value : null;
    }
    let value = getAttr('data-type') == 'number'
      ? Number(event.target.value)
      : event.target.value;

    this.setState({ [event.target.name]: value })
  }

  handleSubmit(event) {
    this.submit()
    event.preventDefault();
  }

  handleDateChangeInitial (date) {
    this.setState({ dateInitial: date })
  }

  handleDateChangeFinal (date) {
    this.setState({ dateFinal: date })
  }

  render() {
    let params = this.state;

    return (
      <form className="form-row" onSubmit={this.handleSubmit}>
        <div className="form-group col-md-3">
          <label>Busca: </label>
          <input
            type="text"
            name="search"
            className="form-control"
            value={params.search || ''}
            onChange={this.onChange}
            placeholder="Busca por nome, email ou telefone.."
          />
        </div>
        <div className="form-group col-md-2">
          <label>Categoria: </label>
          <select
            className="form-control custom-select"
            name="category"
            data-type="number"
            value={params.category || ''}
            onChange={this.onChange}
          >
            <option value="" />
            {
              this.props.categories.map(category => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))
            }
          </select>
        </div>
        <div className="form-group col-md-2">
          <label>Situação: </label>
          <select
            value={params.situation || ''}
            onChange={this.onChange}
            className="form-control custom-select"
            name="situation"
          >
            <option value="" />
            {
              Object.keys(DriverSituationLabels).map(key => {
                return (
                  <option key={DriverSituationLabels[key]} value={key}>{DriverSituationLabels[key]}</option>
                )
              })
            }
          </select>
        </div>
        <div className="form-group col-md-2">
          <label>Status: </label>
          <select
            value={params.status || ''}
            onChange={this.onChange}
            className="form-control custom-select"
            name="status"
          >
            <option value="" />
            {
              Object.keys(DriverStatusLabels).map(key => {
                return (
                  <option key={DriverStatusLabels[key]} value={key}>{DriverStatusLabels[key]}</option>
                )
              })
            }
          </select>
        </div>

        <div className="form-group col-md-2">
          <label>Ordenar por: </label>
          <select
            value={params.rides || ''}
            onChange={this.onChange}
            className="form-control custom-select"
            name="rides"
          >
            <option value="" />
            {
              Object.keys(FilterSort).map(key => {
                return (
                  <option key={FilterSort[key]} value={key}>{FilterSort[key]}</option>
                )
              })
            }
          </select>
        </div>

        <div className="form-group col-md-1">
          <label>&nbsp;&nbsp;</label>
          <button type="submit" className="btn btn-primary form-control">OK</button>
        </div>

        {/* <div style={{marginTop:20, marginBottom: 20, flex: 1}} className="form-row">
          <div className="form-group col-md-1" style={{minWidth: '100px'}}>
            <label>Mês selecionado:  </label>
            <DatePicker
              isClearable
              customInput={<CustomDateInput />}
              selected={this.state.dateInitial}
              onChange={this.handleDateChangeInitial}
            />
          </div> */}

        {/* <div className="form-group col-md-1" style={{minWidth: '100px'}}>
            <label>Data Final:  </label>
            <DatePicker
              isClearable
              customInput={<CustomDateInput />}
              selected={this.state.dateFinal}
              onChange={this.handleDateChangeFinal}
            />
          </div> */}
        {/* </div> */}
      </form>
    )
  }
}


// class CustomDateInput extends React.PureComponent {
//   static propTypes = {
//     value: PropTypes.string,
//     onClick: PropTypes.func,
//   }

//   render() {
//     return (
//       <div onClick={this.props.onClick}>
//         <input
//           type="text"
//           className="form-control"
//           value={this.props.value ? moment(this.props.value).format('DD/MM/YYYY') : ''}
//           readOnly
//           style={{ cursor: 'pointer' }}
//         />
//       </div>
//     )
//   }
// }

